<template>
  <div>
    <GMapMap
      v-if="latitude && longitude"
      :center="{ lat: latitude, lng: longitude }"
      :zoom="7"
      map-type-id="terrain"
    >
      <GMapCircle
        :options="circleOptions"
        :radius="radius*1000"
        :center="{ lat: latitude, lng: longitude }"
      />
    </GMapMap>
  </div>
  <div class="field-wrapper mt-3">
    <label class="block mb-2">
      {{ t('automatedScenarios.fields.address') }}
    </label>
    <GMapAutocomplete
      :value="address"
      placeholder="Indiquez un lieu"
      class="p-inputtext p-component p-filled input-text"
      @place_changed="setPlace"
    />
  </div>
  <div class="field-wrapper">
    <InputNumber
      v-model="radius"
      title="automatedScenarios.fields.radius"
      suffix=" km"
      :min="1"
      :max="1500"
    />
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  PropType, ref, SetupContext, watch,
} from 'vue';
import { useI18n } from 'vue-i18n';
import InputNumber from '@/components/automated-scenarios/fields/InputNumber.vue';
import { Location } from '@/components/automated-scenarios/metadata/filtres/FiltreCustomerMetadata';
import { asFloat, asInt } from '@/helpers';

export default defineComponent({
  name: 'Location',

  components: {
    InputNumber,
  },

  props: {
    modelValue: {
      type: Object as PropType<Location[]>,
      required: true,
    },

  },

  emits: ['update:modelValue'],

  setup(props: { modelValue: Location[] }, { emit }: SetupContext) {
    const { t } = useI18n();
    const radius = ref(asInt(props.modelValue[0].radius ?? 50));
    const latitude = ref(asFloat(props.modelValue[0].lat));
    const longitude = ref(asFloat(props.modelValue[0].long));
    const address = ref(props.modelValue[0].address);
    const circleOptions = {
      strokeColor: '#629d03',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#629d03',
      fillOpacity: 0.35,
    };

    const setPlace = (place: { geometry: { location: { lat: () => number; lng: () => number } }; formatted_address: string }) => {
      latitude.value = place.geometry.location.lat();
      longitude.value = place.geometry.location.lng();
      address.value = place.formatted_address;
    };

    watch([radius, latitude, longitude], () => {
      emit('update:modelValue', {
        radius: radius.value,
        lat: latitude.value,
        long: longitude.value,
        address: address.value,
      });
    });

    return {
      t,
      circleOptions,
      latitude,
      longitude,
      radius,
      address,
      setPlace,
    };
  },
});
</script>

<style lang="scss" scoped>
.vue-map-container {
  height: 30rem;
}
.input-text {
  font-size: 1.25rem !important;
  padding: 0.625rem 0.625rem !important;
}
</style>

<style>
.pac-container {
  z-index: 9999;
}
</style>
