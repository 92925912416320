import axios from 'axios';
import { Configuration, OperatorType } from '@/types/generated-types/graphql';
import { List } from '@/composables/GraphQL';
import { UserState } from '@/composables/User';

import { SHOPIMIND_SHA1_SALT } from '@/configs';
import CryptoJS from 'crypto-js';

export async function uploadFileToMedia(data_file: any, idShop: number): Promise<any|null> {
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  const result = await createNewFileInMedia([data_file], idShop, '');
  // Assure une retroactivité avec l'anncienne méthode
  if (result && result.data && Array.isArray(result.data) && result.data.length && result.data[0].path) {
    // eslint-disable-next-line no-param-reassign
    result.data = `https://media.shopimind.io/clients/${result.data[0].path}`;
    // eslint-disable-next-line no-param-reassign
    result.status = 200;
  }
  return result;
}

function createAxiosInstanceWithHash(params: string) {
  const hash = CryptoJS.SHA256(`${SHOPIMIND_SHA1_SALT}${params}`).toString(CryptoJS.enc.Hex);
  return axios.create({
    baseURL: '/listing',
    auth: {
      username: process.env.VUE_APP_MEDIA_USERNAME ?? '',
      password: process.env.VUE_APP_MEDIA_PASSWORD ?? '',
    },
    headers: {
      'X-Request-Hash': hash,
    },
  });
}

export async function deleteMedia(idShop: number, file: string): Promise<any|null> {
  const params = {
    idShop,
    file,
  };
  const axiosInstance = createAxiosInstanceWithHash(`delete-file-${idShop}-${file}`);
  return axiosInstance.post<any>('/delete-file.php', params);
}

export async function getFilesFromPath(file: any, idShop: number, lastFile?: string): Promise<any|null> {
  const params = {
    path: file,
    idShop,
    lastFile,
  };
  const axiosInstance = createAxiosInstanceWithHash(`${params.path}-${params.idShop}`);
  return axiosInstance.post<any>('/listing.php', params);
}

export async function createFolderInMedia(newPath: any, idShop: number): Promise<any|null> {
  const params = {
    newPath,
    idShop,
  };
  const axiosInstance = createAxiosInstanceWithHash(`${params.newPath}-${params.idShop}`);
  return axiosInstance.post<any>('/create-folder.php', params);
}

export async function createNewFileInMedia(dataFile: any[], idShop: number, path: string): Promise<any|null> {
  const params = {
    dataFile,
    idShop,
    path,
    timestamp: new Date().getTime(),
  };
  const axiosInstance = createAxiosInstanceWithHash(`create-files-${params.idShop}`);
  return axiosInstance.post<any>('/create-file.php', params);
}

export async function listFolders(idShop: number): Promise<any|null> {
  const axiosInstance = createAxiosInstanceWithHash(`list-folder-${idShop}`);
  return axiosInstance.post<any>('/list-folder.php', { idShop });
}

export async function moveFile(idShop: number, oldFile: string, newPath: string): Promise<any|null> {
  const params = {
    idShop,
    oldFile,
    newPath,
  };
  const axiosInstance = createAxiosInstanceWithHash(`move-file-${idShop}-${oldFile}`);
  return axiosInstance.post<any>('/move-file.php', params);
}

/**
 * Get global configuration value by key
 * @param key
 */
export const getConfigurationKey = async (key: string): Promise<Configuration|null> => {
  const filters = [
    {
      field: 'key',
      operator: OperatorType.Equals,
      value: key,
    },
  ];

  const { items, err } = await List<Configuration>({
    name: 'Configuration',
    settings: {
      filter: filters,
      order: [],
      limit: 1,
      offset: 0,
    },
    fields: ['value'],
  });

  if (err === '' && items.length > 0) {
    return items[0];
  }
  return null;
};
