
import { useI18n } from 'vue-i18n';
import {
  defineComponent, reactive, Ref, ref, watch,
} from 'vue';
import ButtonGroup from '@/components/mes-lists/fields/ButtonGroup.vue';
import { UserState } from '@/composables/User';
import { SegmentationListImportSettingsData } from '@/types/lists';
import { store } from '@/store';
import BaseTextInput from '@/components/fields/BaseTextInput.vue';

export default defineComponent({
  name: 'SettingsPanelSegmentations',
  components: { BaseTextInput, ButtonGroup },

  props: {
    settingPanelValidationErrors: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
  },

  setup() {
    const { t } = useI18n();
    const idShop = ref(UserState.activeShop ? UserState.activeShop.id : 0);

    /* Fields options */
    const keepListUpdatedOptions = [
      {
        label: t('yes'),
        value: true,
      },
      {
        label: t('no'),
        value: false,
      },
    ];

    /* Fields values */
    const listName: Ref<string> = ref(store.getters['listImportEditor/getListName']);
    const settingsFields: SegmentationListImportSettingsData = reactive(store.getters['listImportEditor/getSettingsData']);
    const listId: Ref<string> = ref(store.getters['listImportEditor/getIdShopList']);

    watch([listName], () => {
      store.commit('listImportEditor/setListName', listName.value);
    });

    return {
      listName,
      settingsFields,
      keepListUpdatedOptions,
      listId,
    };
  },
});
