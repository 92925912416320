
import { defineComponent, ref, computed } from 'vue';
import { store } from '@/store';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import { ListType } from '@/types';

export default defineComponent({
  name: 'ImportListCustomers',

  components: {
    Dialog,
    Button,
  },

  props: {
    idList: {
      type: Number,
      required: true,
    },

    name: {
      type: String,
      required: true,
    },

    translation: {
      type: Function,
      required: true,
    },

    type: {
      type: String,
      required: true,
    }
  },

  emits: ['close-modal'],

  setup(props, { emit }) {
    const displayDialog = ref(true);

    const showSegmentation = computed(() => props.type === ListType.SEGMENT || props.type === ListType.NOT_DEFINED);

    const showImportCustomers = async (idShopList: number, name: string, typeImport: string, listType: string) => {
      await store.dispatch('listImportEditor/importCustomersAction', {
        idShopList,
        typeImport,
        listName: name,
        listType
      });
      displayDialog.value = false;
    };

    const closeModal = () => {
      displayDialog.value = false;
      emit('close-modal');
    };

    return {
      showImportCustomers,
      displayDialog,
      closeModal,
      showSegmentation,
    };
  },
});
