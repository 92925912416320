
import {
  defineComponent,
  ref,
  Ref,
  PropType,
  onMounted,
  computed,
} from 'vue';

import Button from 'primevue/button';
import ConfirmPopup from 'primevue/confirmpopup';
import Dialog from 'primevue/dialog';
import ProgressSpinner from 'primevue/progressspinner';
import Skeleton from 'primevue/skeleton';

import { useToast } from 'primevue/usetoast';
import { useConfirm } from 'primevue/useconfirm';
// eslint-disable-next-line import/extensions,import/no-unresolved
import { MenuItem } from 'primevue/menuitem';
import Image from 'primevue/image';

import SpmOverlayPanel from '@/components/spm-primevue/SpmOverlayPanel.vue';
import SpmPanelMenu from '@/components/spm-primevue/SpmPanelMenu.vue';
import ChooseDirectoryModal from '@/components/file-manager/ChooseDirectoryModal.vue';

import { UserState } from '@/composables/User';
import { deleteMedia, moveFile } from '@/composables/configs/configuration';

import { isImage } from '@/helpers/Files';

import { iconsByType } from '@/configs/file-manager';

import { File } from '@/types';

import { useI18n } from 'vue-i18n';

import { showToastSuccess, showToastError } from '@/helpers';

export default defineComponent({
  name: 'FileItem',

  components: {
    Button,
    ConfirmPopup,
    Dialog,
    ProgressSpinner,
    SpmOverlayPanel,
    SpmPanelMenu,
    Skeleton,
    Image,
    ChooseDirectoryModal,
  },

  props: {
    file: {
      type: Object as PropType<File>,
      required: true,
    },

    layout: {
      type: String,
      required: false,
      default: 'grid',
    },

    addMoveButton: {
      type: Boolean,
      required: false,
      default: false,
    }
  },

  emits: ['on-choose-file', 'on-delete-item', 'on-move-item'],

  setup(props, context) {
    const { t } = useI18n();
    const selectedImage: Ref<File | undefined> = ref();
    const idShop: number = UserState.activeShop ? UserState.activeShop.id : 0;
    const confirm = useConfirm();
    const toast = useToast();

    const displayImagePreview = ref(false);
    const displaySpinner = ref(false);
    const displayChooseFile = ref(false);
    const displayPreviewOption = ref(false);
    const loading = ref(false);
    const displayChooseDirectoryModal = ref(false);

    const chooseImageLabel = computed(() => (props.file.source ? 'fileManager.actions.download' : 'fileManager.actions.useImage'));

    const previewUrl = computed(() => props.file.previewUrl || `https://media.shopimind.io/resize/index.php?src=${props.file.url}&h=120&w=150`);

    const handleChooseFile = () => {
      if (props.file.source) {
        displaySpinner.value = true;
      }
      context.emit('on-choose-file', { file: props.file, action: 'download' }, () => {
        displaySpinner.value = false;
      });
    };

    const handlePreviewImage = () => {
      if (displayPreviewOption.value) {
        selectedImage.value = props.file;
        displayImagePreview.value = true;
      }
    };

    const editWithAI = () => {

      context.emit('on-choose-file', { file: props.file, action: 'editWithAi' });
    };

    const handleDelete = async (event: any) => {
      if (!event) return;
      confirm.require({
        target: event.currentTarget,
        message: t('templateBuilder.panels.fileManager.body'),
        icon: 'fa-solid fa-triangle-exclamation',
        acceptLabel: t('yes'),
        acceptClass: 'p-button-primary',
        rejectLabel: t('no'),
        rejectClass: 'p-button-secondary',
        accept: async () => {
          displaySpinner.value = true;
          const result = await deleteMedia(idShop, props.file.base_url ?? '');
          if ((Array.isArray(result.data) && result.data.length) || (result.data)) {
            context.emit('on-delete-item', props.file.id);
            displaySpinner.value = false;
          } else {
            toast.add({
              severity: 'error', summary: t('error'), detail: t('templateBuilder.panels.fileManager.error'), life: 3000,
            });
            displaySpinner.value = false;
          }
        },

      });
    };

    const items: Ref<MenuItem[]> = ref([
      {
        label: t('fileManager.actions.delete'),
        icon: 'far fa-trash',
        command: (event) => { handleDelete(event.originalEvent); },
      },
    ]);

    const handleImageLoaded = () => {
      loading.value = false;
    };

    const handleMoveFile = () => {
      displayChooseDirectoryModal.value = true;
    };

    const onCloseChooseDirectoryModal = () => {
      displayChooseDirectoryModal.value = false;
    };


    const onChooseFolder = async (folderPath: string, callback: Function) => {
      try {
        const result = await moveFile(idShop, props.file.base_url ?? '', folderPath);
        context.emit('on-move-item', result.data.sameFile, props.file.id);
        showToastSuccess(t('fileManager.fileMoved'));
        displayChooseDirectoryModal.value = false;
      } catch (error) {
        showToastError(t('errorMessages.GENERIC_ERROR'));
      } finally {
        callback();
      }
    };

    onMounted(async () => {
      if (props.file.source) {
        loading.value = true;
        items.value = [];
        items.value.push(
          {
            label: t(chooseImageLabel.value),
            icon: 'fa-thin fa-file-arrow-down',
            command: handleChooseFile,
          },
        );
        if (props.file.sourceType === 'images') {
          items.value.push({
            label: t('fileManager.actions.editWithAI'),
            icon: 'fa-light fa-microchip-ai',
            command: editWithAI,
          })
        }
        displayPreviewOption.value = true;
      } else {
        loading.value = true;
        const isFileImage = await isImage(props.file.url);
        if (isFileImage) {
          items.value.push(
            {
              label: t('fileManager.actions.editWithAI'),
              icon: 'fa-light fa-microchip-ai',
              command: editWithAI,
            },
          );
          displayChooseFile.value = true;
          displayPreviewOption.value = true;
        }
        loading.value = false;
      }
      if (props.addMoveButton) {
        items.value.push(
          {
            label: t('fileManager.actions.moveToFolder'),
            icon: 'fa-light fa-file-export',
            command: handleMoveFile,
          },
        );
      }
    });

    return {
      t,
      displayImagePreview,
      selectedImage,
      displaySpinner,
      items,
      displayChooseFile,
      loading,
      previewUrl,
      chooseImageLabel,
      iconsByType,
      displayPreviewOption,
      displayChooseDirectoryModal,

      handleImageLoaded,
      handleChooseFile,
      handlePreviewImage,
      handleMoveFile,
      onCloseChooseDirectoryModal,
      onChooseFolder,
    };
  },
});
