import {
  Property,
} from '@/types';

// eslint-disable-next-line import/no-cycle
import {
  getTemplateIframeDocument,
} from '../utils/helpers';

// eslint-disable-next-line import/no-cycle
import { sanitizeTranslationMarkup, setTranslationInState } from '../utils/translate';

const setContent = (selector: string, property: Property) => {
  const template = getTemplateIframeDocument();
  const element: HTMLElement|null = template?.querySelector(selector);
  if (element && undefined !== property.value) {
    sanitizeTranslationMarkup(element).innerHTML = property.value;
    setTranslationInState(element, 'markup', '', true);
  }
};

export default setContent;
