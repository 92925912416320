import { createApp } from 'vue';
import { defineRule } from 'vee-validate';
import { required } from '@vee-validate/rules';
import urql from '@urql/vue';
import PrimeVue from 'primevue/config';
import BadgeDirective from 'primevue/badgedirective';
import ToastService from 'primevue/toastservice';
import ConfirmationService from 'primevue/confirmationservice';
// import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import graphqlClient from '@/api/graphql/graphql-client';
import VueGoogleMaps from '@fawmi/vue-google-maps';
import heatmapjsVue from 'heatmapjs-vue';
// Prototypes
import '@/prototypes';

import router from './router';
import { i18n } from './i18n';
import { store, key } from './store';
import Root from './App.vue';
import './assets/scss/index.scss';

(async () => {
  const app = createApp(Root);

  // plugins
  app.use(router);

  app.use(urql, graphqlClient);

  app.use(PrimeVue);

  // app.component('FontAwesomeIcon', FontAwesomeIcon);

  app.use(ToastService);
  app.use(ConfirmationService);

  app.use(await i18n);

  app.use(store, key);

  app.use(VueGoogleMaps, {
    load: {
      key: 'AIzaSyApHuhGEnbSfZhuDeKYDXa5IiYdeLmzTA4',
    },
  });

  app.use(heatmapjsVue);

  // define global validators
  defineRule('required', required);

  // define global directives
  app.directive('badge', BadgeDirective);

  app.mount('#app');
})();
