<template>
  <label
    v-if="title"
    class="block mb-2"
    :class="customClassTitle"
  >
    {{ t(title) }}
    <i
      v-if="tooltipDesc"
      v-tooltip="{ value: t(tooltipDesc), class: 'tooltip-icons-outside' }"
      class="fas fa-question-circle"
      data-html="true"
      data-toggle="tooltip"
      aria-hidden="true"
    />
  </label>
  <BaseButtonGroup
    v-model="data"
    :options="optionsValues"
    :multiple="multiple"
    :class="customClass"
    :allow-empty="allowEmpty"
  />
</template>

<script lang="ts">
import {
  defineComponent, onMounted,
  PropType,
  ref,
  SetupContext,
  watch,
} from 'vue';
import { useI18n } from 'vue-i18n';
import BaseButtonGroup from '@/components/fields/BaseButtonGroup.vue';
import { StringMap } from '@/types';
import Tooltip from 'primevue/tooltip';

export default defineComponent({
  name: 'ButtonGroup',

  components: {
    BaseButtonGroup,
  },

  directives: {
    tooltip: Tooltip,
  },

  props: {
    modelValue: {
      type: [Array, String, Number] as PropType<string[] | string | number>,
      required: true,
    },

    options: {
      type: Object as PropType<StringMap>,
      required: true,
      default() {
        return {};
      },
    },

    title: {
      type: String,
      required: false,
      default: '',
    },

    multiple: {
      type: Boolean,
      default: false,
    },

    tooltipDesc: {
      type: String,
      required: false,
      default: '',
    },

    customClass: {
      type: String,
      required: false,
      default: '',
    },

    customClassTitle: {
      type: String,
      required: false,
      default: '',
    },

    mustTranslateLabel: {
      type: Boolean,
      required: false,
      default: false,
    },

    allowEmpty: {
      type: Boolean,
      default: true,
    },
  },

  emits: ['update:modelValue'],

  setup(props: { modelValue: number|string|string[]; options: any; mustTranslateLabel: boolean}, { emit }: SetupContext) {
    const { t } = useI18n();

    const data = ref(props.modelValue);
    const optionsValues = ref(props.options);

    watch([data], () => emit('update:modelValue', data.value));

    onMounted(() => {
      if (props.mustTranslateLabel) {
        optionsValues.value = optionsValues.value.map((item: any) => ({
          ...item,
          label: t(item.label),
        }));
      }
    });

    return {
      t,
      optionsValues,
      data,
    };
  },
});
</script>
