<template>
  <div class="flex flex-column gap-1">
    <div class="flex gap-2">
      <div class="flex flex-grow-1 gap-2">
        <InputText
          v-model="query"
          id="keyword"
          aria-describedby="search-by-keyword"
          :placeholder="t('fileManager.library.toolbar.searchByKeyword')"
          class="flex-grow-1"
          :disabled="disableFilter"
          @keyup.enter="handleFilter"
        />
        <Dropdown
          v-if="selectedSource === 'images'"
          v-model="selectedColor"
          :options="colorOptions"
          option-label="label"
          option-value="value"
          :placeholder="t('fileManager.library.toolbar.dominantColor')"
          :disabled="disableFilter"
          showClear
          @update:model-value="handleFilter"
        />
      </div>
      <div class="flex gap-2 justify-content-end">
        <SelectButton
          v-model="selectedSource"
          :options="sourceOptions"
          :allow-empty="false"
          :unselectable="false"
          :disabled="disableFilter"
          option-label="label"
          option-value="value"
        />
        <SpmButton
          :label="t('fileManager.library.toolbar.searchBtn')"
          icon="fa-regular fa-magnifying-glass"
          class-style="p-button p-button-primary"
          @click="handleFilter"
        />
      </div>
    </div>
    <div class="flex justify-content-center">
      <Message
        v-if="!files.length && !loading"
        class="text-justify"
        :closable="false"
      >
        {{ t('fileManager.library.messageInfo') }}
      </Message>
    </div>
    <div class="flex flex-column gap-2 library-list">
      <div
        v-if="loading"
        class="justify-content-center align-items-center"
      >
        <ProgressSpinner
          class="progress-spinner"
          style="width: 25px; height: 25px; margin: auto"
          stroke-width="8"
          fill="var(--surface-ground)"
          animation-duration="1.0s"
          aria-label="Custom ProgressSpinner"
        />
      </div>
      <div
        v-if="!loading"
        class="flex flex-column gap-1"
      >
        <div
          class="grid"
          style="text-align: center"
        >
          <div
            v-if="displaySpinner"
            class="col-12 md:col-6 lg:col-2 mb-3"
          >
            <ProgressSpinner
              class="progress-spinner"
              style="width: 25px; height: 25px; margin: auto"
              stroke-width="8"
              fill="var(--surface-ground)"
              animation-duration="1.0s"
              aria-label="Custom ProgressSpinner"
            />
          </div>
          <div
            v-for="(file) of files"
            :key="`${file.source}-${file.id}`"
            class="col-12 md:col-6 lg:col-2 mb-3"
          >
            <FileItem
              :file="file"
              @on-choose-file="handleChooseFile"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="justify-content-center align-items-center">
      <ProgressSpinner
        v-if="!loading && loadingNextPage"
        class="progress-spinner"
        style="width: 25px; height: 25px; margin: auto"
        stroke-width="8"
        fill="var(--surface-ground)"
        animation-duration="1.0s"
        aria-label="Custom ProgressSpinner"
      />
    </div>
  </div>
  <ChooseDirectoryModal
    v-if="displayChooseDirectoryModal"
    :display-modal="displayChooseDirectoryModal"
    @on-close-dialog="onCloseChooseDirectoryModal"
    @on-choose-folder="onChooseFolder"
  />
</template>

<script lang="ts">
import {
  defineComponent,
  ref,
  Ref,
  computed,
  watch, onMounted,
} from 'vue';

import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';
import InputText from 'primevue/inputtext';
import Toolbar from 'primevue/toolbar';
import MultiSelect from 'primevue/multiselect';
import SelectButton from 'primevue/selectbutton';
import Message from 'primevue/message';

import FileItem from '@/components/file-manager/FileItem.vue';
import SpmButton from '@/components/spm-primevue/SpmButton.vue';
import ChooseDirectoryModal from '@/components/file-manager/ChooseDirectoryModal.vue';
import { getBase64FromUrl } from '@/components/template-builder/utils/helpers';

import { UserState } from '@/composables/User';
import { createNewFileInMedia } from '@/composables/configs/configuration';

import ProgressSpinner from 'primevue/progressspinner';

import colors from '@/configs/file-manager';

import { showToastSuccess, showToastError } from '@/helpers';

import {
  File,
} from '@/types';

import axios from 'axios';
import { useI18n } from 'vue-i18n';
import {addUrlParameter} from "@/helpers/helpers";

export default defineComponent({
  name: 'Library',

  components: {
    Button,
    ProgressSpinner,
    FileItem,
    Toolbar,
    MultiSelect,
    SelectButton,
    InputText,
    Dropdown,
    SpmButton,
    Message,
    ChooseDirectoryModal
  },
  emits: ['on-choose-file'],

  setup(props, context) {
    const { t } = useI18n();
    const files: Ref<File[]> = ref([]);
    const idShop: number = UserState.activeShop ? UserState.activeShop.id : 0;

    const loading = ref(false);
    const loadingNextPage = ref(false);

    const displaySpinner = ref(false);
    const disableFilter = ref(false);
    const checkNextPage = ref(false);

    const selectedSource = ref('images');
    const sourceOptions = ref([
      {
        label: t('fileManager.library.toolbar.images'),
        value: 'images'
      },
      {
        label: t('fileManager.library.toolbar.icons'),
        value: 'icons'
      }
    ]);

    const selectedColor = ref();
    const colorOptions = ref(colors(t));

    const query = ref('');

    const currentPage = ref(1);
    const nextPageToken = ref();

    const displayChooseDirectoryModal = ref(false);

    const fileCallback = ref();
    const fileBase64 = ref();

    const sourceApiUrl = computed(() => {
      return selectedSource.value === 'images' ? process.env.VUE_APP_IMAGES_API_URL ?? '' : process.env.VUE_APP_ICON_API_URL ?? ''
    });

    const handleChooseFile = async (file: any, callback: Function) => {
      if (file.file.url && file.action === 'editWithAi') {
        context.emit('on-choose-file', file);
      } else {
        try {
          const base64 = await getBase64FromUrl(file.file.url);
          displayChooseDirectoryModal.value = true;
          fileBase64.value = base64;

          fileCallback.value = callback;
          callback();
        } catch (error) {
          await showToastError(t('errorMessages.GENERIC_ERROR'));
          callback();
        }
      }
    };

    const fetchData = async (nextPage = false) => {
      if (query.value.trim()) {
        if (!nextPage) {
          loading.value = true;
          files.value = [];
        } else {
          currentPage.value += 1;
          loadingNextPage.value = true;
        }

        disableFilter.value = true;

        // Parameters
        const params: Record<string, any> = {};
        params.query = query.value;
        // Color
        if (selectedColor.value && selectedSource.value === 'images') {
          params.color = selectedColor.value
        }
        // Pagination
        if (nextPage) {
          params.page = currentPage.value;
          if (nextPageToken.value) {
            params.next_page = nextPageToken.value;
          }
        }

        try {
          const { data } = await axios.get(sourceApiUrl.value, {
            params
          });
          files.value.push(...data.files);

          if (data.nextPage) {
            nextPageToken.value = data.nextPage
          }

          loading.value = false;
          loadingNextPage.value = false;
          disableFilter.value = false;
          checkNextPage.value = true;
          if (data.noResult) {
            checkNextPage.value = false;
          }
        } catch (err) {
          showToastError(t('errorMessages.GENERIC_ERROR'));
          loading.value = false;
          loadingNextPage.value = false;
          disableFilter.value = false;
          checkNextPage.value = false;
        }
      }
    };

    const handleFilter = async () => {
      currentPage.value = 1;
      await fetchData();
    };

    const handleScroll = async () => {
      if (!loadingNextPage.value && checkNextPage.value) {
        await fetchData(true);
      }
    };

    const onCloseChooseDirectoryModal = () => {
      displayChooseDirectoryModal.value = false;
      fileBase64.value = '';
      fileCallback.value = null;
    };

    const onChooseFolder = async (folderPath: string, callback: Function) => {
      try {
        if (fileBase64.value) {
          await createNewFileInMedia([fileBase64.value], idShop, folderPath);
          if (fileCallback.value) {
            fileCallback.value();
          }
          showToastSuccess(t('fileManager.imageDownloaded'));
          fileBase64.value = '';
          fileCallback.value = null;
          displayChooseDirectoryModal.value = false;
          callback();
        }
      } catch (error) {
        showToastError(t('errorMessages.GENERIC_ERROR'));
        callback();
      }
    };

    watch(selectedSource, async () => {
      if (query.value.trim()) {
        currentPage.value = 1;
        await fetchData();
      }
    });

    onMounted(() => {
      // Ajouter un paramètre à l'URL afin de pouvoir charger gleap via url
      addUrlParameter('cp', 'imagelibrary');
    });

    return {
      t,
      files,
      displaySpinner,
      loading,
      loadingNextPage,
      selectedSource,
      sourceOptions,
      selectedColor,
      colorOptions,
      query,
      disableFilter,
      displayChooseDirectoryModal,

      handleChooseFile,
      handleFilter,
      fetchData,
      handleScroll,
      onCloseChooseDirectoryModal,
      onChooseFolder,
    };
  },
});
</script>

<style lang="scss" scoped>
.progress-spinner {
  transform: translateY(130%);
}
</style>
