<!-- eslint-disable no-lonely-if -->
<template>
  <div class="w-full">
    <FileUpload
      accept=".zip,.html,.txt"
      :max-file-size="70000000"
      :auto="true"
      :multiple="false"
      :custom-upload="true"
      @select="(event) => onSelectedFiles(event)"
      @uploader="(event) => onTemplatedUpload(event)"
    >
      <template #header="{ chooseCallback }">
        <div class="flex flex-wrap justify-content-between align-items-center flex-1 gap-2">
          <div class="flex gap-2">
            <Button
              ref="chooseFileBtn"
              :label="t('templateSelector.import.uploadFile')"
              class="p-button-secondary"
              icon="fa-light fa-cloud-arrow-up"
              @click="chooseCallback"
            />
            <Button
              v-if="files"
              :label="t('Supprimer le fichier')"
              class="p-button-danger"
              icon="fad fa-times"
              @click="onRemoveTemplatingFile"
            />
          </div>
          <div
            v-if="files && showUploadExternalImageBtn"
            class="field flex align-items-center gap-2"
          >
            <div>
              <label>{{ t(`templateSelector.import.uploadExternalImages`) }}</label>
            </div>
            <SelectButton
              v-model="uploadExternalImage"
              :options="yesno"
              :unselectable="false"
              option-label="name"
              option-value="value"
              class="ml-auto"
              @update:model-value="handleChange"
            />
          </div>
        </div>
      </template>
      <template #content>
        <div
          v-if="files"
          class="flex align-items-center justify-content-center flex-column"
        >
          <div
            class="flex flex-wrap"
            style="width: 100%;"
          >
            <div
              class="flex flex-column field-wrapper align-items-center"
              style="width: 100%;"
            >
              <Tree
                :value="structure"
                class="w-full md:w-30rem"
              />
            </div>
          </div>
        </div>
        <div
          v-else
          @click="chooseFileToUpload"
        >
          <div class="flex align-items-center justify-content-center flex-column cursor-pointer">
            <i class="fa-light fa-cloud-arrow-up border-2 border-circle p-5 text-8xl text-400 border-400" />
            <p class="mt-4 mb-0">
              {{ t('templateSelector.import.emptyFile') }}
            </p>
          </div>
        </div>
      </template>
    </FileUpload>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  ref,
  Ref,
} from 'vue';

import Button from 'primevue/button';
import FileUpload from 'primevue/fileupload';
import SelectButton from 'primevue/selectbutton';
import Tree from 'primevue/tree';
import { useToast } from 'primevue/usetoast';

import {
  ImagePath,
  CssFile,
} from '@/types/zip-file-uploader-types';

import * as cheerio from 'cheerio';

import { useI18n } from 'vue-i18n';
import JSZip, { JSZipObject } from 'jszip';

interface TreeNode {
    key: string;
    label: string;
    icon: string;
    children?: TreeNode[];
}

export default defineComponent({
  name: 'ZipFileUploader',

  components: {
    Button,
    FileUpload,
    Tree,
    SelectButton,
  },

  props: {
    modelValue: {
      type: Number,
      required: true,
      default: 0,
    },
  },

  emits: ['on-file-uploaded', 'on-file-removed', 'update:modelValue'],

  setup(props, { emit }) {
    const { t } = useI18n();
    const toast = useToast();

    const chooseFileBtn = ref();

    const files: Ref<File | undefined> = ref();
    const htmlContent = ref('');
    const images: Ref<ImagePath[]> = ref([]);
    const cssFiles: Ref<CssFile[]> = ref([]);

    const structure: Ref<TreeNode[]> = ref([]);

    const showUploadExternalImageBtn = ref(false);

    const uploadExternalImage = ref(props.modelValue);

    const onRemoveTemplatingFile = () => {
      files.value = undefined;
      structure.value = [];
      images.value = [];
      cssFiles.value = [];
      htmlContent.value = '';
      showUploadExternalImageBtn.value = false;
      uploadExternalImage.value = 0;

      emit('on-file-removed');
    };

    const onSelectedFiles = (event: any) => {
      if (!event.files.length) {
        toast.add({
          severity: 'info',
          summary: 'Success',
          detail: t('myLists.importContacts.errors.maxSizeError', ['700000000']),
          life: 3000,
        });
      }
    };

    const isStringValidHtml = (html: string, mimeType: DOMParserSupportedType = 'text/html'): boolean => {
      const $ = cheerio.load(html);
      const domParser: DOMParser = new DOMParser();
      const doc: Document = domParser.parseFromString($.html(), mimeType);
      const parseError: Element | null = doc.documentElement.querySelector('parsererror');

      if (parseError !== null && parseError.nodeType === Node.ELEMENT_NODE) {
        return false;
      }
      return true;
    };

    const isImageFile = (fileName: string): boolean => {
      const extension = fileName.split('.').pop();
      if (extension) {
        return ['jpg', 'jpeg', 'png', 'gif', 'bmp'].includes(extension);
      }
      return false;
    };

    const isHtmlFile = (fileName: string): boolean => {
      const extension = fileName.split('.').pop();
      if (extension) {
        return ['html', 'htm', 'txt'].includes(extension);
      }
      return false;
    };

    const isCssFile = (fileName: string): boolean => {
      const extension = fileName.split('.').pop();
      if (extension) {
        return ['css'].includes(extension);
      }
      return false;
    };

    const getMimeTypeFromFileName = (fileName: string): string | null => {
      const extension = fileName.split('.').pop()?.toLowerCase();
      if (!extension) return 'image/jpeg';

      switch (extension) {
        case 'jpg':
        case 'jpeg':
          return 'image/jpeg';
        case 'png':
          return 'image/png';
        case 'gif':
          return 'image/gif';
        case 'bmp':
          return 'image/bmp';
        default:
          return 'image/jpeg';
      }
    };

    const getStructure = (obj: Record<string, JSZipObject>): TreeNode[] => {
      const treeNodes: TreeNode[] = [];

      // eslint-disable-next-line guard-for-in, no-restricted-syntax
      for (const path in obj) {
        const file = obj[path];
        const parts = path.split('/');
        let parentNode = treeNodes;

        for (let i = 0; i < parts.length; i++) {
          const part = parts[i];
          const isLast = i === parts.length - 1;

          // eslint-disable-next-line no-continue
          if (part.trim() === '') continue;

          let treeNode = parentNode.find((node) => node.label === part);
          if (!treeNode) {
            treeNode = {
              key: Math.random().toString(36).substring(2, 9),
              label: part,
              icon: file.dir ? 'fal fa-folder' : 'fal fa-file',
            };
            if (file.dir) {
              treeNode.children = [];
              parentNode.push(treeNode);
              parentNode.sort((a, b) => {
                if (a.icon === 'fal fa-folder' && b.icon !== 'fal fa-folder') return -1;
                if (a.icon !== 'fal fa-folder' && b.icon === 'fal fa-folder') return 1;
                return a.label.localeCompare(b.label);
              });
            } else {
              parentNode.push(treeNode);
              parentNode.sort((a, b) => {
                if (a.icon === 'fal fa-folder' && b.icon !== 'fal fa-folder') return -1;
                if (a.icon !== 'fal fa-folder' && b.icon === 'fal fa-folder') return 1;
                return a.label.localeCompare(b.label);
              });
            }
          }
          if (isLast && !file.dir) {
            delete treeNode.children;
          }
          parentNode = treeNode.children || [];
        }
      }

      return treeNodes;
    };

    const readFileAsText = (file: File): Promise<string> => new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.result) {
          resolve(reader.result as string);
        } else {
          reject(new Error('Error reading file'));
        }
      };
      reader.onerror = () => reject(new Error('Error reading file'));
      reader.readAsText(file);
    });

    const onTemplatedUpload = async (event: any) => {
      if (!event.files.length) {
        toast.add({
          severity: 'info',
          summary: 'Success',
          detail: 'Upload error',
          life: 3000,
        });
      }

      let htmlFiles = 0;

      if (event.files[0].name.split('.').pop()?.toLowerCase() === 'zip') {
        const zip = await JSZip.loadAsync(event.files[0], { createFolders: true });
        structure.value = getStructure(zip.files);

        // eslint-disable-next-line no-restricted-syntax
        for (const key in zip.files) {
          if (Object.prototype.hasOwnProperty.call(zip.files, key)) {
            const { name, dir } = zip.files[key];
            if (!dir) {
              const content = zip.file(name);
              if (content) {
                // Check for htmlFile
                // eslint-disable-next-line no-await-in-loop
                const stringContent = await content.async('string');
                if (isHtmlFile(name) && isStringValidHtml(stringContent)) {
                  htmlFiles += 1;
                  if (htmlFiles > 1) {
                    break;
                  }
                  const $ = cheerio.load(stringContent);
                  htmlContent.value = $.html();
                }
                // Get all images file to be uploaded
                if (isImageFile(name)) {
                  // eslint-disable-next-line no-await-in-loop
                  const imageBase64 = await content.async('base64');
                  const mimeType = getMimeTypeFromFileName(name);
                  const nameParts = name.split('/');
                  images.value.push({
                    originalPath: nameParts[nameParts.length - 1],
                    base64String: `data:${mimeType};base64,${imageBase64}`,
                  });
                }
                if (isCssFile(name)) {
                  // eslint-disable-next-line no-await-in-loop
                  const cssString = await content.async('string');
                  const cssParts = name.split('/');
                  cssFiles.value.push({
                    originalPath: cssParts[cssParts.length - 1],
                    cssString,
                  });
                }
              }
            }
          }
        }
      } else {
        const html = await readFileAsText(event.files[0]);
        if (isStringValidHtml(html)) {
          htmlFiles += 1;
          const $ = cheerio.load(html);
          htmlContent.value = $.html();
          structure.value = [{
            icon: 'fal fa-file',
            key: '1',
            label: event.files[0].name,
          }];
          images.value = [];
          cssFiles.value = [];
        }
      }
      if (htmlFiles === 1) {
        // eslint-disable-next-line prefer-destructuring
        files.value = event.files[0];
        if (htmlContent.value.match(/(https?:\/\/[^"')]+\.(?:png|jpg|jpeg|gif))/gm)) {
          showUploadExternalImageBtn.value = true;
        }
        emit('on-file-uploaded', {
          name: event.files[0].name.lastIndexOf('.') > 0 ? event.files[0].name.substr(0, event.files[0].name.lastIndexOf('.')) : event.files[0].name,
          html: htmlContent.value,
          images: images.value,
          cssFiles: cssFiles.value,
        });
      } else {
        toast.add({
          severity: 'info',
          summary: 'Success',
          detail: t('templateSelector.import.noHtmlFileFound'),
          life: 3000,
        });
      }
    };

    const chooseFileToUpload = () => {
      if (chooseFileBtn.value) {
        chooseFileBtn.value.$el.click();
      }
    };

    const yesno = [
      { name: t('yes'), value: 1 },
      { name: t('no'), value: 0 },
    ];

    const handleChange = () => {
      emit('update:modelValue', uploadExternalImage.value);
    };

    return {
      t,
      files,
      structure,
      chooseFileBtn,
      yesno,
      uploadExternalImage,
      showUploadExternalImageBtn,
      onRemoveTemplatingFile,
      onSelectedFiles,
      onTemplatedUpload,
      chooseFileToUpload,
      handleChange,
    };
  },
});
</script>
