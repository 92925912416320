
import {
  computed,
  defineComponent, onMounted, PropType, Ref, ref, watch,
} from 'vue';
import Dropdown from 'primevue/dropdown';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import { useI18n } from 'vue-i18n';
import {
  DropdownOption,
  FieldConfig,
  ActionTypeEnum,
  ParserFieldObject,
  Property, LinkTypeEnum,
} from '@/types';
import InputVariables from '@/components/template-builder/fields/InputVariables.vue';
import TriggerButton from '@/components/template-builder/fields/TriggerButton.vue';
import WysiwygEditor from '@/components/template-builder/fields/WysiwygEditor.vue';
import Code from '@/components/template-builder/fields/Code.vue';
import ChooseTemplateModal from '@/components/modals/ChooseTemplateModal.vue';
import { Templates } from '@/types/generated-types/graphql';
import { Get } from '@/composables/GraphQL';
import { store } from '@/store';
import TemplateSelector from '@/components/automated-scenarios/fields/TemplateSelector.vue';
import {
  activate as activateTemplate,
  setRefreshTemplatesList,
  TemplateEditorState,
  updateTemplateConfiguration,
} from '@/composables/template-editor/TemplateEditor';
import CryptoJS from 'crypto-js';
import { loadImageUntilSuccess } from '@/composables/loadImage';

export default defineComponent({
  name: 'ActionType',

  components: {
    TemplateSelector,
    ChooseTemplateModal,
    Dropdown,
    Button,
    Dialog,
    InputVariables,
    TriggerButton,
    WysiwygEditor,
    Code,
  },

  props: {
    configs: {
      type: Object as PropType<FieldConfig>,
      required: true,
    },

    parserValues: {
      type: Object as PropType<ParserFieldObject>,
      required: true,
    },

    originCall: {
      type: String,
      required: false,
      default: null,
    },

    idCampaign: {
      type: Number,
      required: false,
      default: null,
    },
  },

  emits: {
    'on-change-properties': Object,
  },

  setup(props, context) {
    const imageUrl = ref('');
    const baseUrl = `${process.env.VUE_APP_URL_MEDIAL_IMAGE_PREFIX_WITH_RESIZE}`;
    const { t } = useI18n();
    const displayTemplateModal = ref(false);
    const selectedTemplate: Ref<Templates | undefined> = ref();
    const displayTemplatePreview = ref(false);
    const originCall: Ref<string | null> = ref(props.originCall);

    const properties: Ref<Array<Property>> = ref(props.parserValues.properties);
    const classProperty: Ref<Property> = ref(properties.value.filter((property: Property) => property.name === 'spm_action_')[0]);
    const dataRedirectProperty: Ref<Property> = ref(properties.value.filter((property: Property) => property.name === 'data-redirect')[0]);

    const displayMessageProperty: Ref<Property> = ref(properties.value.filter((property: Property) => property.name === 'displayMessage')[0]);
    const displayMessageParserValues = {
      selector: props.parserValues.selector,
      properties: [displayMessageProperty.value],
    };
    const dataRedirectParserValues = {
      selector: props.parserValues.selector,
      properties: [dataRedirectProperty.value],
    };
    const actionTypeOptions: DropdownOption[] = Object.values(ActionTypeEnum)
      .map((key: string) => ({ value: key, label: t(`templateBuilder.fields.actionOptions.${key}`) }));

    const getActionType = () => Object.values(ActionTypeEnum)
      .filter((action: string) => action === classProperty.value.value)[0] ?? ActionTypeEnum.DISPLAY_MESSAGE;

    const actionType: Ref<ActionTypeEnum> = ref(getActionType());

    // eslint-disable-next-line no-shadow
    const handleChange = ({ selector, properties }: { selector: string; properties: Array<Property> }) => {
      context.emit('on-change-properties', {
        selector,
        properties,
      });
    };

    const handleChangeActionType = () => {
      classProperty.value.value = actionType.value;
      dataRedirectProperty.value.value = '';
      handleChange({ selector: props.parserValues.selector, properties: [classProperty.value, dataRedirectProperty.value] });
    };

    // eslint-disable-next-line no-shadow
    const handleChangeDataRedirect = ({ selector, properties }: { selector: string; properties: Array<Property> }) => {
      const property = { ...properties[0] };
      property.value = btoa(property.value); // Encode value to base64
      handleChange({ selector, properties: [property] });
    };

    const selectTemplate = async (idTemplate: number, label: string, type: string) => {
      const { item } = await Get<Templates>({
        name: 'Templates',
        id: idTemplate,
        keyName: 'id_template',
        fields: ['id_template', 'label', 'type'],
      });

      if (item) {
        selectedTemplate.value = item;
        dataRedirectProperty.value.value = `#redirect_${btoa(idTemplate as unknown as string)}`;
        handleChange({ selector: props.parserValues.selector, properties: [dataRedirectProperty.value] });
        // handleChange();

        if (originCall.value === 'templateBuilder') {
          setRefreshTemplatesList(true);
        }
      }

      displayTemplateModal.value = false;
      store.commit('general/hideTheSpinner');
    };
    const refreshImagePreview = async () => {
      if (actionType.value === ActionTypeEnum.DISPLAY_TEMPLATE) {
        let encodedDisplayedTemplate = '';
        let decodedIdTemplate = dataRedirectProperty.value.value;
        if (actionType.value) {
          const matchResult = dataRedirectProperty.value.value.match(/#redirect_(.+)/);
          encodedDisplayedTemplate = matchResult && matchResult[1] ? matchResult[1] : '';

          if (encodedDisplayedTemplate !== '') {
            decodedIdTemplate = Number(atob(encodedDisplayedTemplate));
          }
        }
        if (decodedIdTemplate > 0) {
          imageUrl.value = '';
          const cryptedImageKey = CryptoJS.SHA1(`salt-${decodedIdTemplate}`).toString();

          const potentialImageUrl = `${baseUrl + cryptedImageKey}-isdisplay.png&w=400&${new Date().getTime()}`;
          try {
            await loadImageUntilSuccess(potentialImageUrl);
            imageUrl.value = potentialImageUrl;
          } catch (error) {
            console.error("Erreur lors du chargement de l'image", error);
          }
        }
      }
    };

    watch(async () => dataRedirectProperty.value.value, async () => {
      await refreshImagePreview();
    }, { deep: true });

    const editTemplateRedirection = async (id: number, name: string, typeTemplate: string) => {
      await updateTemplateConfiguration();
      await activateTemplate(id, name, typeTemplate);
    };

    const baseLinkData: any = computed(() => {
      const namePrimaryTemplate = TemplateEditorState?.template?.informations.name;
      const translationsPrimaryTemplate = TemplateEditorState?.template?.translations ?? [];
      const uniqueRedirects = new Set();

      translationsPrimaryTemplate.forEach((translation) => {
        const { value } = translation;

        const match = value.match(new RegExp('#redirect_'));
        if (match) {
          const idRedirect = window.atob(value.replace('#redirect_', ''));
          if (!idRedirect.match(new RegExp('[a-zA-Z]'))) {
            uniqueRedirects.add(idRedirect);
          }
        }
      });

      const countRedirectTemplate = uniqueRedirects.size;

      return {
        namePrimaryTemplate,
        countRedirectTemplate,
      };
    });

    watch(() => props.parserValues, (values, oldValues) => {
      if (values !== oldValues) {
        if (classProperty.value) {
          actionType.value = getActionType();
        }
      }
    }, { immediate: true });

    watch(() => actionType.value, async (values, oldValues) => {
      if (values !== oldValues) {
        if (actionType.value === ActionTypeEnum.DISPLAY_TEMPLATE) {
          // Retrieve template informations
          const { item, err } = await Get<Templates>({
            name: 'Templates',
            id: dataRedirectProperty.value.value,
            keyName: 'id_template',
            fields: ['id_template', 'label', 'type'],
          });
          if (item) {
            selectedTemplate.value = item;
          }
        }
      }
    }, { immediate: true });

    onMounted(async () => {
      await refreshImagePreview();
    });

    return {
      t,
      actionType,
      actionTypeOptions,
      displayTemplateModal,
      selectedTemplate,
      displayTemplatePreview,
      ActionTypeEnum,
      displayMessageParserValues,
      dataRedirectParserValues,
      selectTemplate,
      handleChange,
      handleChangeActionType,
      handleChangeDataRedirect,
      baseLinkData,
      editTemplateRedirection,
      imageUrl,
    };
  },
});
