import { numberLogicalOperators, stringLogicalOperators } from '@/composables/segmentations/logicalOperators';

/**
 * Get the product categories properties segments
 */
export const getProductCategoriesPropertiesSegments = async () => [
  {
    id: 'id_category',
    label: 'segmentation.theme.contacts.groupProperties.hasDone.list.placedOrder.subProperties.orderProperties.subProperties.productsInOrder.subProperties.categories.subProperties.idCategory',
    logical_operators: numberLogicalOperators('id_category'),
  },
  {
    id: 'name',
    label: 'segmentation.theme.contacts.groupProperties.hasDone.list.placedOrder.subProperties.orderProperties.subProperties.productsInOrder.subProperties.categories.subProperties.name',
    logical_operators: stringLogicalOperators('name'),
  },
];

/**
 * Get the product manufacturers properties segments
 */
const getProductManufacturersPropertiesSegments = async () => [
  {
    id: 'id_manufacturer',
    label: 'segmentation.theme.contacts.groupProperties.hasDone.list.placedOrder.subProperties.orderProperties.subProperties.productsInOrder.subProperties.manufacturers.subProperties.idManufacturer',
    logical_operators: [
      {
        type: 'string',
        data: stringLogicalOperators('id_manufacturer'),
      },
      {
        type: 'number',
        data: numberLogicalOperators('id_manufacturer'),
      },
    ],
  },
  {
    id: 'name',
    label: 'segmentation.theme.contacts.groupProperties.hasDone.list.placedOrder.subProperties.orderProperties.subProperties.productsInOrder.subProperties.manufacturers.subProperties.name',
    logical_operators: stringLogicalOperators('name'),
  },
];

/**
 * Get the product properties segments
 */
export const getProductPropertiesSegments = async () => {
  const productCategories = await getProductCategoriesPropertiesSegments();
  const productManufacturers = await getProductManufacturersPropertiesSegments();
  return [
    {
      id: 'name',
      label: 'segmentation.theme.contacts.groupProperties.hasDone.list.placedOrder.subProperties.orderProperties.subProperties.productsInOrder.subProperties.productName',
      logical_operators: stringLogicalOperators('name'),
    },
    {
      id: 'id_product',
      label: 'segmentation.theme.contacts.groupProperties.hasDone.list.placedOrder.subProperties.orderProperties.subProperties.productsInOrder.subProperties.idProduct',
      logical_operators: [
        {
          type: 'string',
          data: stringLogicalOperators('id_product'),
        },
        {
          type: 'number',
          data: numberLogicalOperators('id_product'),
        },
      ],
    },
    {
      id: 'price',
      label: 'segmentation.theme.contacts.groupProperties.hasDone.list.placedOrder.subProperties.orderProperties.subProperties.productsInOrder.subProperties.price',
      logical_operators: numberLogicalOperators('price'),
      hasCurrency: true,
    },
    {
      id: 'price_discount',
      label: 'segmentation.theme.contacts.groupProperties.hasDone.list.placedOrder.subProperties.orderProperties.subProperties.productsInOrder.subProperties.priceDiscount',
      logical_operators: numberLogicalOperators('price_discount'),
      hasCurrency: true,
    },
    {
      id: 'link',
      label: 'segmentation.theme.contacts.groupProperties.hasDone.list.placedOrder.subProperties.orderProperties.subProperties.productsInOrder.subProperties.url',
      logical_operators: stringLogicalOperators('link'),
    },
    {
      id: 'quantity',
      label: 'segmentation.theme.contacts.groupProperties.hasDone.list.placedOrder.subProperties.orderProperties.subProperties.productsInOrder.subProperties.quantity',
      logical_operators: numberLogicalOperators('quantity'),
    },
    {
      id: 'products_categories',
      label: 'segmentation.theme.contacts.groupProperties.hasDone.list.placedOrder.subProperties.orderProperties.subProperties.productsInOrder.subProperties.categories.title',
      sub_properties: productCategories,
    },
    {
      id: 'manufacturers',
      label: 'segmentation.theme.contacts.groupProperties.hasDone.list.placedOrder.subProperties.orderProperties.subProperties.productsInOrder.subProperties.manufacturers.title',
      sub_properties: productManufacturers,
    },
  ];
};

/**
 * Get the product orders properties segments
 */
export const getProductOrdersPropertiesSegments = async () => {
  const productCategories = await getProductCategoriesPropertiesSegments();
  const productManufacturers = await getProductManufacturersPropertiesSegments();
  return [
    {
      id: 'name',
      label: 'segmentation.theme.contacts.groupProperties.hasDone.list.placedOrder.subProperties.orderProperties.subProperties.productsInOrder.subProperties.productName',
      logical_operators: stringLogicalOperators('name'),
    },
    {
      id: 'id_product',
      label: 'segmentation.theme.contacts.groupProperties.hasDone.list.placedOrder.subProperties.orderProperties.subProperties.productsInOrder.subProperties.idProduct',
      logical_operators: [
        {
          type: 'string',
          data: stringLogicalOperators('id_product'),
        },
        {
          type: 'number',
          data: numberLogicalOperators('id_product'),
        },
      ],
    },
    {
      id: 'price',
      label: 'segmentation.theme.contacts.groupProperties.hasDone.list.placedOrder.subProperties.orderProperties.subProperties.productsInOrder.subProperties.price',
      logical_operators: numberLogicalOperators('price'),
      hasCurrency: true,
    },
    // {
    //   id: 'price_discount',
    //   label: 'segmentation.theme.contacts.groupProperties.hasDone.list.placedOrder.subProperties.orderProperties.subProperties.productsInOrder.subProperties.priceDiscount',
    //   logical_operators: numberLogicalOperators('price_discount'),
    //   hasCurrency: true,
    // },
    {
      id: 'qty',
      label: 'segmentation.theme.contacts.groupProperties.hasDone.list.placedOrder.subProperties.orderProperties.subProperties.productsInOrder.subProperties.quantity',
      logical_operators: numberLogicalOperators('qty'),
    },
    {
      id: 'products_categories',
      label: 'segmentation.theme.contacts.groupProperties.hasDone.list.placedOrder.subProperties.orderProperties.subProperties.productsInOrder.subProperties.categories.title',
      sub_properties: productCategories,
    },
    {
      id: 'manufacturers',
      label: 'segmentation.theme.contacts.groupProperties.hasDone.list.placedOrder.subProperties.orderProperties.subProperties.productsInOrder.subProperties.manufacturers.title',
      sub_properties: productManufacturers,
    },
  ];
};
