
import { useI18n } from 'vue-i18n';
import {
  computed,
  defineComponent,
  PropType,
  ref,
  watch,
} from 'vue';
import Dropdown from 'primevue/dropdown';
import InlineMessage from 'primevue/inlinemessage';
import {
  CountryAndStateConfig,
  CountryAndStateErrors,
  CountryAndStateStyles,
  CountryAndStateValues,
} from '@/types/country-state-types';
import getCountries, { countriesPhonesCodes } from '@/configs/countries';
import Flag from '@/components/table/renderers/Flag.vue';

export default defineComponent({
  name: 'CountryDropdown',
  components: {
    Flag,
    Dropdown,
    InlineMessage,
  },

  props: {
    modelValue: {
      type: Object as PropType<CountryAndStateValues>,
      required: false,
      default: (): CountryAndStateValues => ({
        country: '',
        state: '',
      }),
    },

    error: {
      type: Object as PropType<CountryAndStateErrors>,
      required: false,
      default: (): CountryAndStateErrors => ({
        country: false,
        state: false,
      }),
    },

    styles: {
      type: Object as PropType<CountryAndStateStyles>,
      required: false,
      default: (): CountryAndStateStyles => ({
        component: 'width:100%',
        country: 'width:100%',
        state: 'width:100%',
      }),
    },

    configuration: {
      type: Object as PropType<CountryAndStateConfig>,
      required: false,
      default: (): CountryAndStateConfig => ({
        showLabels: true,
        showStates: true,
        showFlags: false,
        showPhonesCodes: false,
        showOnlyPhoneCodeForSelection: false,
      }),
    },
  },

  emits: ['update:modelValue'],

  setup(props, context) {
    const { t } = useI18n();
    const keyFlagValue = ref(0);

    const country = ref(props.modelValue.country);
    const state = ref(props.modelValue.state);

    const countries = ref(getCountries(t));
    const phonesCodes = countriesPhonesCodes;

    const states = computed(() => {
      switch (country.value) {
        case 'AR':
          return [
            { value: 'B', label: 'Buenos Aires' },
            { value: 'K', label: 'Catamarca' },
            { value: 'H', label: 'Chaco' },
            { value: 'U', label: 'Chubut' },
            { value: 'C', label: 'Ciudad de Buenos Aires' },
            { value: 'X', label: 'Córdoba' },
            { value: 'W', label: 'Corrientes' },
            { value: 'E', label: 'Entre Ríos' },
            { value: 'P', label: 'Formosa' },
            { value: 'Y', label: 'Jujuy' },
            { value: 'L', label: 'La Pampa' },
            { value: 'F', label: 'La Rioja' },
            { value: 'M', label: 'Mendoza' },
            { value: 'N', label: 'Misiones' },
            { value: 'Q', label: 'Neuquén' },
            { value: 'R', label: 'Río Negro' },
            { value: 'A', label: 'Salta' },
            { value: 'J', label: 'San Juan' },
            { value: 'D', label: 'San Luis' },
            { value: 'Z', label: 'Santa Cruz' },
            { value: 'S', label: 'Santa Fe' },
            { value: 'G', label: 'Santiago del Estero' },
            { value: 'V', label: 'Tierra del Fuego' },
            { value: 'T', label: 'Tucumán' },
          ];

        case 'CA':
          return [
            { value: 'ON', label: 'Ontario' },
            { value: 'QC', label: 'Quebec' },
            { value: 'BC', label: 'British Columbia' },
            { value: 'AB', label: 'Alberta' },
            { value: 'MB', label: 'Manitoba' },
            { value: 'SK', label: 'Saskatchewan' },
            { value: 'NS', label: 'Nova Scotia' },
            { value: 'NB', label: 'New Brunswick' },
            { value: 'NL', label: 'Newfoundland and Labrador' },
            { value: 'PE', label: 'Prince Edward Island' },
            { value: 'NT', label: 'Northwest Territories' },
            { value: 'YT', label: 'Yukon' },
            { value: 'NU', label: 'Nunavut' },
          ];

        case 'ID':
          return [
            { value: 'AC', label: 'Aceh' },
            { value: 'BA', label: 'Bali' },
            { value: 'BB', label: 'Bangka' },
            { value: 'BT', label: 'Banten' },
            { value: 'BE', label: 'Bengkulu' },
            { value: 'JT', label: 'Central Java' },
            { value: 'KT', label: 'Central Kalimantan' },
            { value: 'ST', label: 'Central Sulawesi' },
            { value: 'JI', label: 'Coat of arms of East Java' },
            { value: 'KI', label: 'East kalimantan' },
            { value: 'NT', label: 'East Nusa Tenggara' },
            { value: 'GO', label: 'Lambang propinsi' },
            { value: 'JK', label: 'Jakarta' },
            { value: 'JA', label: 'Jambi' },
            { value: 'LA', label: 'Lampung' },
            { value: 'MA', label: 'Maluku' },
            { value: 'MU', label: 'North Maluku' },
            { value: 'SA', label: 'North Sulawesi' },
            { value: 'SU', label: 'North Sumatra' },
            { value: 'PA', label: 'Papua' },
            { value: 'RI', label: 'Riau' },
            { value: 'KR', label: 'Lambang Riau' },
            { value: 'SG', label: 'Southeast Sulawesi' },
            { value: 'KS', label: 'South Kalimantan' },
            { value: 'SN', label: 'South Sulawesi' },
            { value: 'SS', label: 'South Sumatra' },
            { value: 'JB', label: 'West Java' },
            { value: 'KB', label: 'West Kalimantan' },
            { value: 'NB', label: 'West Nusa Tenggara' },
            { value: 'PB', label: 'Lambang Provinsi Papua Barat' },
            { value: 'SR', label: 'West Sulawesi' },
            { value: 'SB', label: 'West Sumatra' },
            { value: 'YO', label: 'Yogyakarta' },
          ];

        case 'IT':
          return [
            { value: 'AG', label: 'Agrigento' },
            { value: 'AL', label: 'Alessandria' },
            { value: 'AN', label: 'Ancona' },
            { value: 'AO', label: 'Aosta' },
            { value: 'AR', label: 'Arezzo' },
            { value: 'AP', label: 'Ascoli Piceno' },
            { value: 'AT', label: 'Asti' },
            { value: 'AV', label: 'Avellino' },
            { value: 'BA', label: 'Bari' },
            { value: 'BT', label: 'Barletta-Andria-Trani' },
            { value: 'BL', label: 'Belluno' },
            { value: 'BN', label: 'Benevento' },
            { value: 'BG', label: 'Bergamo' },
            { value: 'BI', label: 'Biella' },
            { value: 'BO', label: 'Bologna' },
            { value: 'BZ', label: 'Bolzano' },
            { value: 'BS', label: 'Brescia' },
            { value: 'BR', label: 'Brindisi' },
            { value: 'CA', label: 'Cagliari' },
            { value: 'CL', label: 'Caltanissetta' },
            { value: 'CB', label: 'Campobasso' },
            { value: 'CI', label: 'Carbonia-Iglesias' },
            { value: 'CE', label: 'Caserta' },
            { value: 'CT', label: 'Catania' },
            { value: 'CZ', label: 'Catanzaro' },
            { value: 'CH', label: 'Chieti' },
            { value: 'CO', label: 'Como' },
            { value: 'CS', label: 'Cosenza' },
            { value: 'CR', label: 'Cremona' },
            { value: 'KR', label: 'Crotone' },
            { value: 'CN', label: 'Cuneo' },
            { value: 'EN', label: 'Enna' },
            { value: 'FM', label: 'Fermo' },
            { value: 'FE', label: 'Ferrara' },
            { value: 'FI', label: 'Firenze' },
            { value: 'FG', label: 'Foggia' },
            { value: 'FC', label: 'Forlì-Cesena' },
            { value: 'FR', label: 'Frosinone' },
            { value: 'GE', label: 'Genova' },
            { value: 'GO', label: 'Gorizia' },
            { value: 'GR', label: 'Grosseto' },
            { value: 'IM', label: 'Imperia' },
            { value: 'IS', label: 'Isernia' },
            { value: 'AQ', label: 'L\'Aquila' },
            { value: 'SP', label: 'La Spezia' },
            { value: 'LT', label: 'Latina' },
            { value: 'LE', label: 'Lecce' },
            { value: 'LC', label: 'Lecco' },
            { value: 'LI', label: 'Livorno' },
            { value: 'LO', label: 'Lodi' },
            { value: 'LU', label: 'Lucca' },
            { value: 'MC', label: 'Macerata' },
            { value: 'MN', label: 'Mantova' },
            { value: 'MS', label: 'Massa' },
            { value: 'MT', label: 'Matera' },
            { value: 'VS', label: 'Medio Campidano' },
            { value: 'ME', label: 'Messina' },
            { value: 'MI', label: 'Milano' },
            { value: 'MO', label: 'Modena' },
            { value: 'MB', label: 'Monza e della Brianza' },
            { value: 'NA', label: 'Napoli' },
            { value: 'NO', label: 'Novara' },
            { value: 'NU', label: 'Nuoro' },
            { value: 'OG', label: 'Ogliastra' },
            { value: 'OT', label: 'Olbia-Tempio' },
            { value: 'OR', label: 'Oristano' },
            { value: 'PD', label: 'Padova' },
            { value: 'PA', label: 'Palermo' },
            { value: 'PR', label: 'Parma' },
            { value: 'PV', label: 'Pavia' },
            { value: 'PG', label: 'Perugia' },
            { value: 'PU', label: 'Pesaro-Urbino' },
            { value: 'PE', label: 'Pescara' },
            { value: 'PC', label: 'Piacenza' },
            { value: 'PI', label: 'Pisa' },
            { value: 'PT', label: 'Pistoia' },
            { value: 'PN', label: 'Pordenone' },
            { value: 'PZ', label: 'Potenza' },
            { value: 'PO', label: 'Prato' },
            { value: 'RG', label: 'Ragusa' },
            { value: 'RA', label: 'Ravenna' },
            { value: 'RC', label: 'Reggio Calabria' },
            { value: 'RE', label: 'Reggio Emilia' },
            { value: 'RI', label: 'Rieti' },
            { value: 'RN', label: 'Rimini' },
            { value: 'RM', label: 'Roma' },
            { value: 'RO', label: 'Rovigo' },
            { value: 'SA', label: 'Salerno' },
            { value: 'SS', label: 'Sassari' },
            { value: 'SV', label: 'Savona' },
            { value: 'SI', label: 'Siena' },
            { value: 'SR', label: 'Siracusa' },
            { value: 'SO', label: 'Sondrio' },
            { value: 'TA', label: 'Taranto' },
            { value: 'TE', label: 'Teramo' },
            { value: 'TR', label: 'Terni' },
            { value: 'TO', label: 'Torino' },
            { value: 'TP', label: 'Trapani' },
            { value: 'TN', label: 'Trento' },
            { value: 'TV', label: 'Treviso' },
            { value: 'TS', label: 'Trieste' },
            { value: 'UD', label: 'Udine' },
            { value: 'VA', label: 'Varese' },
            { value: 'VE', label: 'Venezia' },
            { value: 'VB', label: 'Verbano-Cusio-Ossola' },
            { value: 'VC', label: 'Vercelli' },
            { value: 'VR', label: 'Verona' },
            { value: 'VV', label: 'Vibo Valentia' },
            { value: 'VI', label: 'Vicenza' },
            { value: 'VT', label: 'Viterbo' },
          ];

        case 'JP':
          return [
            { value: '23', label: 'Aichi' },
            { value: '05', label: 'Akita' },
            { value: '02', label: 'Aomori' },
            { value: '12', label: 'Chiba' },
            { value: '38', label: 'Ehime' },
            { value: '18', label: 'Fukui' },
            { value: '40', label: 'Fukuoka' },
            { value: '07', label: 'Fukushima' },
            { value: '21', label: 'Gifu' },
            { value: '10', label: 'Gunma' },
            { value: '34', label: 'Hiroshima' },
            { value: '01', label: 'Hokkaido' },
            { value: '28', label: 'Hyogo' },
            { value: '08', label: 'Ibaraki' },
            { value: '17', label: 'Ishikawa' },
            { value: '03', label: 'Iwate' },
            { value: '37', label: 'Kagawa' },
            { value: '46', label: 'Kagoshima' },
            { value: '14', label: 'Kanagawa' },
            { value: '39', label: 'Kochi' },
            { value: '43', label: 'Kumamoto' },
            { value: '26', label: 'Kyoto' },
            { value: '24', label: 'Mie' },
            { value: '04', label: 'Miyagi' },
            { value: '45', label: 'Miyazaki' },
            { value: '20', label: 'Nagano' },
            { value: '42', label: 'Nagasaki' },
            { value: '29', label: 'Nara' },
            { value: '15', label: 'Niigata' },
            { value: '44', label: 'Oita' },
            { value: '33', label: 'Okayama' },
            { value: '47', label: 'Okinawa' },
            { value: '27', label: 'Osaka' },
            { value: '41', label: 'Saga' },
            { value: '11', label: 'Saitama' },
            { value: '25', label: 'Shiga' },
            { value: '32', label: 'Shimane' },
            { value: '22', label: 'Shizuoka' },
            { value: '09', label: 'Tochigi' },
            { value: '36', label: 'Tokushima' },
            { value: '13', label: 'Tokyo' },
            { value: '31', label: 'Tottori' },
            { value: '16', label: 'Toyama' },
            { value: '30', label: 'Wakayama' },
            { value: '06', label: 'Yamagata' },
            { value: '35', label: 'Yamaguchi' },
            { value: '19', label: 'Yamanashi' },
          ];

        case 'MX':
          return [
            { value: 'AGS', label: 'Aguascalientes' },
            { value: 'BCN', label: 'Baja California' },
            { value: 'BCS', label: 'Baja California Sur' },
            { value: 'CAM', label: 'Campeche' },
            { value: 'CHP', label: 'Chiapas' },
            { value: 'CHH', label: 'Chihuahua' },
            { value: 'COA', label: 'Coahuila' },
            { value: 'COL', label: 'Colima' },
            { value: 'DIF', label: 'Distrito Federal' },
            { value: 'DUR', label: 'Durango' },
            { value: 'GUA', label: 'Guanajuato' },
            { value: 'GRO', label: 'Guerrero' },
            { value: 'HID', label: 'Hidalgo' },
            { value: 'JAL', label: 'Jalisco' },
            { value: 'MEX', label: 'Estado de México' },
            { value: 'MIC', label: 'Michoacán' },
            { value: 'MOR', label: 'Morelos' },
            { value: 'NAY', label: 'Nayarit' },
            { value: 'NLE', label: 'Nuevo León' },
            { value: 'OAX', label: 'Oaxaca' },
            { value: 'PUE', label: 'Puebla' },
            { value: 'QUE', label: 'Querétaro' },
            { value: 'ROO', label: 'Quintana Roo' },
            { value: 'SLP', label: 'San Luis Potosí' },
            { value: 'SIN', label: 'Sinaloa' },
            { value: 'SON', label: 'Sonora' },
            { value: 'TAB', label: 'Tabasco' },
            { value: 'TAM', label: 'Tamaulipas' },
            { value: 'TLA', label: 'Tlaxcala' },
            { value: 'VER', label: 'Veracruz' },
            { value: 'YUC', label: 'Yucatán' },
            { value: 'ZAC', label: 'Zacatecas' },
          ];

        case 'US':
          return [
            { value: 'AL', label: 'Alabama' },
            { value: 'AK', label: 'Alaska' },
            { value: 'AZ', label: 'Arizona' },
            { value: 'AR', label: 'Arkansas' },
            { value: 'CA', label: 'California' },
            { value: 'CO', label: 'Colorado' },
            { value: 'CT', label: 'Connecticut' },
            { value: 'DE', label: 'Delaware' },
            { value: 'FL', label: 'Florida' },
            { value: 'GA', label: 'Georgia' },
            { value: 'HI', label: 'Hawaii' },
            { value: 'ID', label: 'Idaho' },
            { value: 'IL', label: 'Illinois' },
            { value: 'IN', label: 'Indiana' },
            { value: 'IA', label: 'Iowa' },
            { value: 'KS', label: 'Kansas' },
            { value: 'KY', label: 'Kentucky' },
            { value: 'LA', label: 'Louisiana' },
            { value: 'ME', label: 'Maine' },
            { value: 'MD', label: 'Maryland' },
            { value: 'MA', label: 'Massachusetts' },
            { value: 'MI', label: 'Michigan' },
            { value: 'MN', label: 'Minnesota' },
            { value: 'MS', label: 'Mississippi' },
            { value: 'MO', label: 'Missouri' },
            { value: 'MT', label: 'Montana' },
            { value: 'NE', label: 'Nebraska' },
            { value: 'NV', label: 'Nevada' },
            { value: 'NH', label: 'New Hampshire' },
            { value: 'NJ', label: 'New Jersey' },
            { value: 'NM', label: 'New Mexico' },
            { value: 'NY', label: 'New York' },
            { value: 'NC', label: 'North Carolina' },
            { value: 'ND', label: 'North Dakota' },
            { value: 'OH', label: 'Ohio' },
            { value: 'OK', label: 'Oklahoma' },
            { value: 'OR', label: 'Oregon' },
            { value: 'PA', label: 'Pennsylvania' },
            { value: 'RI', label: 'Rhode Island' },
            { value: 'SC', label: 'South Carolina' },
            { value: 'SD', label: 'South Dakota' },
            { value: 'TN', label: 'Tennessee' },
            { value: 'TX', label: 'Texas' },
            { value: 'UT', label: 'Utah' },
            { value: 'VT', label: 'Vermont' },
            { value: 'VA', label: 'Virginia' },
            { value: 'WA', label: 'Washington' },
            { value: 'WV', label: 'West Virginia' },
            { value: 'WI', label: 'Wisconsin' },
            { value: 'WY', label: 'Wyoming' },
            { value: 'PR', label: 'Puerto Rico' },
            { value: 'VI', label: 'US Virgin Islands' },
            { value: 'DC', label: 'District of Columbia' },
          ];

        default:
          return [];
      }
    });

    const onCountryChanged = (event: { value: string }) => {
      const updatedValue: CountryAndStateValues = {
        country: event.value,
        state: state.value,
      };

      context.emit('update:modelValue', updatedValue);
    };
    const onStateChanged = (event: { value: string }) => {
      const updatedValue: CountryAndStateValues = {
        country: country.value,
        state: event.value,
      };

      context.emit('update:modelValue', updatedValue);
    };

    watch(() => props.modelValue, () => {
      keyFlagValue.value += 1;
    }, { deep: true });

    return {
      t,
      keyFlagValue,
      // listing
      countries,
      states,
      phonesCodes,
      // selected values
      country,
      state,
      // events (change triggered)
      onCountryChanged,
      onStateChanged,
    };
  },
});
