<template>
  <Dialog
    ref="fileManagerDialogRef"
    v-model:visible="displayModal"
    :header="t('templateBuilder.fields.fileManager')"
    class="file-manager p-dialog-maximized"
    :style="{ background: colorClass }"
    @update:visible="handleClose"
    @show="handleDialogShown"
    @hide="handleDialogHide"
  >
    <TabView
      v-model:activeIndex="activeTab"
      lazy
    >
      <TabPanel
        :header="t('fileManager.filesList.header')"
      >
        <FilesList
          ref="filesListRef"
          :extensions="extensions"
          @on-choose-file="handleChooseFile"
          @on-close-dialog="handleClose"
        />
      </TabPanel>
      <TabPanel
        :header="t('fileManager.library.header')"
      >
        <Library
          ref="libraryRef"
          @on-choose-file="handleChooseFile"
        />
      </TabPanel>
      <TabPanel
        :header="t('fileManager.generativeAI.header')"
      >
        <AIInterface
          :ai-interface-params="AIInterfaceParams"
        />
      </TabPanel>
    </TabView>
  </Dialog>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  watch,
  ref,
  onMounted,
  onUnmounted,
} from 'vue';

import Dialog from 'primevue/dialog';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';

import FilesList from '@/components/file-manager/FilesList.vue';
import Library from '@/components/file-manager/Library.vue';

import { useI18n } from 'vue-i18n';
import { File } from '@/types';
import AIInterface from '@/components/spm-ai/AIInterface.vue';
import { addUrlParameter } from '@/helpers/helpers';

export default defineComponent({
  name: 'FileManager',

  components: {
    AIInterface,
    Dialog,
    TabView,
    TabPanel,
    FilesList,
    Library,
  },

  props: {
    displayDialog: {
      type: Boolean,
      required: true,
    },

    transparent: {
      type: String,
      required: false,
      default: '',
    },

    extensions: {
      type: String,
      required: false,
      default: '',
    },
  },

  emits: {
    'on-close-dialog': null,
    'on-choose-file': Object,
  },

  setup(props, context) {
    const { t } = useI18n();
    const displayModal = computed(() => props.displayDialog);
    const colorClass = ref(props.transparent);
    const activeTab = ref(0);
    const AIInterfaceParams = ref({
      AIContentType: 4,
      AIContextContent: '',
      AIContextContentText: '',
      AIContextFullContentText: '',
      AIContextContentHTML: '',
      AIApplyContentParams: { imageWidth: 1024, imageHeight: 1024 },
    });
    const fileManagerDialogRef = ref();
    const filesListRef = ref();
    const libraryRef = ref();

    const handleClose = () => {
      context.emit('on-close-dialog');
    };

    const handleChooseFile = async (params: any) => {
      if (params.action === 'download') {
        context.emit('on-choose-file', params.file);
      } else if (params.action === 'editWithAi') {
        const img = new Image();
        img.onload = () => {
          AIInterfaceParams.value = {
            AIContentType: 5,
            AIContextContent: params.file.url,
            AIContextContentText: '',
            AIContextFullContentText: '',
            AIContextContentHTML: '',
            AIApplyContentParams: { imageWidth: img.width, imageHeight: img.height },
          };
        };
        img.src = params.file.url;
        AIInterfaceParams.value = {
          AIContentType: 5,
          AIContextContent: params.file.url,
          AIContextContentText: '',
          AIContextFullContentText: '',
          AIContextContentHTML: '',
          AIApplyContentParams: { imageWidth: 1024, imageHeight: 1024 },
        };

        activeTab.value = 2;
      }
    };

    watch(activeTab, async () => {
      if (activeTab.value !== 2) {
        AIInterfaceParams.value = {
          AIContentType: 4,
          AIContextContent: '',
          AIContextContentText: '',
          AIContextFullContentText: '',
          AIContextContentHTML: '',
          AIApplyContentParams: { imageWidth: 1024, imageHeight: 1024 },
        };
      }
    });

    const handleScroll = async () => {
      const containerBottom = fileManagerDialogRef.value.content.scrollTop + fileManagerDialogRef.value.content.clientHeight;
      const threshold = 100;
      if (fileManagerDialogRef.value.content.scrollHeight - containerBottom <= threshold) {
        if (activeTab.value === 0 && filesListRef.value) {
          await filesListRef.value.handleScroll();
        }
        if (activeTab.value === 1 && libraryRef.value) {
          await libraryRef.value.handleScroll();
        }
      }
    };

    const handleDialogShown = () => {
      if (fileManagerDialogRef.value && fileManagerDialogRef.value.content) {
        fileManagerDialogRef.value.content.addEventListener('scroll', handleScroll);
      }
    };

    const handleDialogHide = () => {
      if (fileManagerDialogRef.value && fileManagerDialogRef.value.content) {
        fileManagerDialogRef.value.content.removeEventListener('scroll', handleScroll);
      }
    };

    onUnmounted(() => {
      if (fileManagerDialogRef.value && fileManagerDialogRef.value.content) {
        fileManagerDialogRef.value.content.removeEventListener('scroll', handleScroll);
      }
    });

    onMounted(() => {
      // Ajouter un paramètre à l'URL afin de pouvoir charger gleap via url
      addUrlParameter('cp', 'filemanager');
    });

    return {
      t,
      displayModal,
      colorClass,
      AIInterfaceParams,
      activeTab,
      fileManagerDialogRef,
      filesListRef,
      libraryRef,

      handleChooseFile,
      handleClose,
      handleDialogShown,
      handleDialogHide,
    };
  },
});
</script>

<style scoped lang="scss">
.file-manager {
  .dialog-footer {
    text-align: center !important;
    :deep() .menu-icon {
      font-size: 2rem !important;
      color: $brand-color-primary;
    }
  }
}
</style>
