
import {
  computed,
  ComputedRef,
  defineComponent,
  reactive,
  Ref,
  ref,
  watch,
} from 'vue';
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import { useI18n } from 'vue-i18n';
import { required } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import FieldErrors from '@/components/fields/partials/FieldErrors.vue';

export default defineComponent({
  name: 'InputTextModal',

  components: {
    Dialog,
    InputText,
    Button,
    FieldErrors,
  },

  props: {
    position: {
      type: String,
      required: false,
      default: 'center',
    },

    displayDialog: {
      type: Boolean,
      required: true,
    },

    displayCancelButton: {
      type: Boolean,
      required: false,
      default: false,
    },

    closeOnValidate: {
      type: Boolean,
      required: false,
      default: true,
    },

    header: {
      type: String,
      required: true,
    },

    cancelButtonText: {
      type: String,
      required: false,
      default: 'templateBuilder.modals.cancel',
    },

    validateButtonText: {
      type: String,
      required: false,
      default: 'templateBuilder.modals.validate',
    },

    inputValue: {
      type: String,
      required: false,
      default: '',
    },

    showLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  emits: ['on-close-dialog', 'on-validate'],

  setup(props, context) {
    const { t } = useI18n();
    const displayModal: ComputedRef<boolean> = computed(() => props.displayDialog);
    const fieldValue: Ref<string> = ref(props.inputValue);

    const loading = ref(false);

    const state = reactive({
      fieldValue,
    });

    const rules = {
      fieldValue: { required },
    };

    const v$ = useVuelidate(rules, state);

    watch(() => props.inputValue, (newValue, oldValue) => {
      if (newValue !== oldValue) {
        fieldValue.value = newValue;
      }
    });

    const handleClose = () => {
      context.emit('on-close-dialog');
      fieldValue.value = props.inputValue;
    };

    const handleValidation = async () => {
      if (!props.showLoading) {
        context.emit('on-validate', fieldValue.value);

        if (props.closeOnValidate) {
          handleClose();
        }
      } else {
        loading.value = true;
        context.emit('on-validate', fieldValue.value, () => {
          loading.value = false;
          handleClose();
        });
      }
    };

    return {
      t,
      v$,
      displayModal,
      loading,
      handleClose,
      handleValidation,
    };
  },
});
