import {
  List,
} from '@/composables/GraphQL';
import {
  SegmentationsEditorState, State,
} from '@/types/store-types';
import { ActionContext } from 'vuex';
import getThemeContactsDefinition from '@/composables/segmentations/themes/contacts';
import { i18n } from '@/i18n';
import {
  insertShopsListsImport,
  updateShopsListsImport,
} from '@/composables/mes-listes/ImportCustomers';

import {
  ShopsListsImportInputItem,
  ShopsListsImportUpdateInputItem,
  ShopsListsImport,
  OperatorType,
} from '@/types/generated-types/graphql';

import { queueState } from '@/types';

import { listManageKey, myListMod, myListsState } from '@/composables/mes-listes/ListsManage';

let translation: any;
(async () => {
  translation = await i18n;
})();

export default {
  namespaced: true,
  state: (): SegmentationsEditorState => ({
    initialized: false,
    originCall: '',
    refreshComponents: 0,
    segmentationsDefinition: {},
    configuration:
    [
      [
        {},
      ],
    ],
    errors: {
      properties: [],
      segments: [],
    },
    hasError: false,
    currentSegmentConfig: null,
    refreshContactsNumber: false,
    listImportId: 0
  }),

  getters: {
    getConfiguration: (state: SegmentationsEditorState) => state.configuration,
    getRefreshComponents: (state: SegmentationsEditorState) => state.refreshComponents,
    getInitialized: (state: SegmentationsEditorState) => state.initialized,
    getGroupProperties: (state: SegmentationsEditorState) => () => state.segmentationsDefinition.group_properties.reduce((acc: any, group: any) => {
      acc[group.id] = group.list.map((item: any) => ({ id: item.id, label: item.label }));
      return acc;
    }, {}),
    getPropertyDefinition: (state: SegmentationsEditorState) => (group: string, property: string) => {
      const groupProperties = state.segmentationsDefinition.group_properties.find((item: any) => item.id === group).list;
      return groupProperties.find((item: any) => item.id === property);
    },
    getErrors: (state: SegmentationsEditorState) => state.errors,
    getCurrentSegmentConfig: (state: SegmentationsEditorState) => state.currentSegmentConfig,
    getRefreshContactsNumber: (state: SegmentationsEditorState) => state.refreshContactsNumber,
    getHasError: (state: SegmentationsEditorState) => state.hasError,
  },

  mutations: {
    initConfiguration: (state: SegmentationsEditorState, initialized: boolean) => {
      state.configuration = [[{}]];
      state.errors.properties = [];
      state.errors.segments = [];
      state.hasError = false;
    },
    setConfiguration: (state: SegmentationsEditorState, configuration: any) => {
      state.configuration = configuration;
    },
    setInitialized: (state: SegmentationsEditorState, initialized: boolean) => {
      state.initialized = initialized;
    },
    setOriginCall: (state: SegmentationsEditorState, originCall: string) => {
      state.originCall = originCall;
    },
    setSegmentationsDefinition: (state: SegmentationsEditorState, segmentationsDefinition: any) => {
      state.segmentationsDefinition = segmentationsDefinition;
    },
    addBlockAND: (state: SegmentationsEditorState) => {
      state.configuration.push([{}]);
      state.refreshComponents += 1;
    },
    addBlockOR: (state: SegmentationsEditorState, indexBlockAND: number) => {
      state.configuration[indexBlockAND].push({});
      state.refreshComponents += 1;
    },
    removeBlockOR: (state: SegmentationsEditorState, payload: { indexBlockAND: number; indexBlockOR: number }) => {
      const { indexBlockAND, indexBlockOR } = payload;
      const blockOr = state.configuration[indexBlockAND][indexBlockOR];
      if ((blockOr.data !== undefined && blockOr.data !== null && blockOr.data !== '') || (blockOr.sub_properties && blockOr.sub_properties.length && blockOr.sub_properties[0].id)) {
        state.refreshContactsNumber = true;
      }
      if (state.currentSegmentConfig && blockOr.segmentId === state.currentSegmentConfig.configProperty.segmentId) {
        state.currentSegmentConfig = null;
      }
      if (state.currentSegmentConfig && blockOr.sub_properties) {
        const checkCurrentSegmentConfig = (properties: any[]) => {
          if (state.currentSegmentConfig) {
            properties.forEach((property) => {
              if (property.sub_properties) {
                checkCurrentSegmentConfig(property.sub_properties);
              } else if (property.segmentId === state.currentSegmentConfig.configProperty.segmentId) {
                state.currentSegmentConfig = null;
              }
            });
          }
        };
        checkCurrentSegmentConfig(blockOr.sub_properties);
      }
      state.configuration[indexBlockAND].splice(indexBlockOR, 1);
      // if block AND is empty, remove it
      if (state.configuration[indexBlockAND].length === 0) {
        state.configuration.splice(indexBlockAND, 1);
      }
      state.refreshComponents += 1;
    },
    updateSegmentProperty: (state: SegmentationsEditorState, payload: { indexBlockAND: number; indexBlockOR: number; segmentData: any }) => {
      const { indexBlockAND, indexBlockOR, segmentData } = payload;
      state.configuration[indexBlockAND].splice(indexBlockOR, 1, segmentData);
    },
    resetErrors: (state: SegmentationsEditorState) => {
      state.errors = {
        properties: [],
        segments: [],
      };
      state.hasError = false;
    },
    pushError: (state: SegmentationsEditorState, payload: { type: string; value: any }) => {
      const { type, value } = payload;
      state.errors[type].push(value);
      state.hasError = true;
    },
    removeError: (state: SegmentationsEditorState, payload: { type: string; id: string; value: any }) => {
      const { type, id, value } = payload;
      state.errors[type] = state.errors[type].filter((error: any) => error[id] !== value);
    },
    removePropertyError: (state: SegmentationsEditorState, payload: string) => {
      state.errors.properties = state.errors.properties.filter((error: string) => error !== payload);
    },
    setCurrentSegmentConfig: (state: SegmentationsEditorState, payload: any) => {
      state.currentSegmentConfig = payload;
    },
    setRefreshContactsNumber: (state: SegmentationsEditorState, payload: boolean) => {
      state.refreshContactsNumber = payload;
    },
    resetState: (state: SegmentationsEditorState) => {
      state.errors = {
        properties: [],
        segments: [],
      };
      state.hasError = false;
      state.configuration = [
        [
          {},
        ],
      ];
      state.listImportId = 0;
      state.currentSegmentConfig = null;
      state.refreshContactsNumber = false;
    },
  },

  actions: {
    initialization: async ({ state, commit }: ActionContext<SegmentationsEditorState, State>,
      { originCall }: { originCall: string }) => {
      if (state.initialized) return;
      commit('setOriginCall', originCall);
      const contactDefinition = await getThemeContactsDefinition(translation.global.t);
      commit('setSegmentationsDefinition', {
        label: 'segmentation.theme.contacts.title',
        available_on: ['bulk_campaign', 'automation_campaign', 'list'],
        group_properties: contactDefinition,
      });
      commit('setInitialized', true);
    },
    validate: async ({ state, commit, getters }: ActionContext<SegmentationsEditorState, State>) => {
      commit('resetErrors');
      const config = getters.getConfiguration;
      const definition = state.segmentationsDefinition;
      const validateSimpleProperty = async (segmentDefinition: any, property: any) => {
        const operators = segmentDefinition?.logical_operators;
        if (operators) {
          const operatorDefinition = Array.isArray(operators)
            ? operators.find((item: any) => item.type === property.groupOperator).data[property.operator]
            : operators[property.operator];

          if (operatorDefinition?.validate) {
            const validationObject = await operatorDefinition.validate({ [property.id]: property.data });
            if (!validationObject.success) {
              commit('pushError', { type: 'segments', value: { segmentId: property.segmentId, value: validationObject.validate.value } });
            }
          }
        }
      };

      const findProperty = (obj: any, path: string[]) => {
        let currentProperty: any = obj;

        for (const id of path) {
          if (!currentProperty.sub_properties) {
            return undefined;
          }

          const nextProperty = currentProperty.sub_properties.find((prop: any) => prop.id === id);
          if (!nextProperty) {
            return undefined;
          }

          currentProperty = nextProperty;
        }

        return currentProperty;
      };

      const processProperties = async (originalSegmentDefinition: any, properties: any, isSimpleProperty: boolean, followingPropertiesID: any = []) => {
        for (const property of properties) {
          const subProperties = property?.sub_properties;
          if (subProperties && Array.isArray(subProperties)) {
            followingPropertiesID.push(property.id);
            await processProperties(originalSegmentDefinition, subProperties, isSimpleProperty, followingPropertiesID);
            followingPropertiesID.pop();
          } else {
            followingPropertiesID.push(property.id);
            const segmentDefinition = isSimpleProperty ? originalSegmentDefinition : findProperty(originalSegmentDefinition, followingPropertiesID);
            await validateSimpleProperty(segmentDefinition, property);
            followingPropertiesID.pop();
          }
        }
      };

      for (const [indexBlockAND, blockAND] of config.entries()) {
        for (const [indexBlockOR, blockOR] of blockAND.entries()) {
          if (blockOR.id) {
            const segmentGroupDefinition = definition.group_properties.find((item: any) => item.id === blockOR.groupProperty);
            const segmentDefinition = segmentGroupDefinition.list.find((item: any) => item.id === blockOR.id);
            await processProperties(segmentDefinition, blockOR.sub_properties ?? [blockOR], !blockOR.sub_properties);
          } else {
            commit('pushError', { type: 'properties', value: `${indexBlockAND}-${indexBlockOR}` });
          }
        }
      }
    },
    save: async ({ state, commit }: ActionContext<SegmentationsEditorState, State>,
      // eslint-disable-next-line consistent-return
      {
        idShop, idShopList, stayUpdated, listName,
      }: { idShop: number; idShopList: number; stayUpdated: boolean; listName: string }) => {
      const refreshList = () => {
        listManageKey.value += 1;
        myListMod.value.ID = idShopList;
        myListMod.value.Name = listName;
        myListMod.value.HardBounce = 0;
        myListMod.value.NonSubscribers = 0;
        myListMod.value.PercentHardBounce = '';
        myListMod.value.PercentNonSubscribers = '';
        myListMod.value.PercentSoftBounce = '';
        myListMod.value.PercentSpams = '';
        myListMod.value.PercentSubscribers = '';
        myListMod.value.PercentUnSubscribers = '';
        myListMod.value.SoftBounce = 0;
        myListMod.value.Spams = 0;
        myListMod.value.Subscribers = 0;
        myListMod.value.UnSubscribers = 0;
        myListMod.value.TotalContacts = 0;
        myListMod.value.TotalSent = 0;
        myListMod.value.TotalUniqueViews = 0;
        myListMod.value.PercentUniqueViews = '0';
        myListMod.value.TotalUniqueClicks = 0;
        myListMod.value.PercentUniqueClicks = '0%';

        myListsState.refreshComponent = idShopList;
      };

      if (state.listImportId) {
        const updateInput: ShopsListsImportUpdateInputItem = {
          id_shop_list_import: state.listImportId,
          segments_config: JSON.stringify(state.configuration),
          stay_updated: stayUpdated,
          state: queueState.QUEUED,
        };

        const result = await updateShopsListsImport([updateInput]);
        if (result.err === '') {
          refreshList();
        }
        return result;
      }
      const inputInsert: ShopsListsImportInputItem = {
        id_shop: idShop,
        id_shop_list: idShopList,
        params: 'segmentationV2',
        file: '',
        segments_config: JSON.stringify(state.configuration),
        force_update: 1,
        newsletter: 1,
        double_optin: true,
        stay_updated: stayUpdated,
        nb_customers: 0,
        nb_customers_checked: 0,
        nb_customers_valid: 0,
        nb_imported_customers: 0,
        nb_non_imported_customers: 0,
        nb_partially_imported_customers: 0,
        path_report_folder: '',
        state: queueState.QUEUED,
      };

      const result = await insertShopsListsImport([inputInsert]);
      if (result.err === '') {
        refreshList();
      }
      return result;
    },
    getConfiguration: async ({ state, commit }: ActionContext<SegmentationsEditorState, State>,
      // eslint-disable-next-line consistent-return
      { idShop, idShopList }: { idShop: number; idShopList: number }) => {
      const result = await List<ShopsListsImport>({
        name: 'ShopsListsImport',
        settings: {
          offset: 0,
          limit: 1,
          order: [],
          filter: [
            { field: 'id_shop', value: idShop, operator: OperatorType.Equals },
            { field: 'id_shop_list', value: idShopList, operator: OperatorType.Equals },
          ],
        },
        fields: ['id_shop_list_import', 'stay_updated', 'segments_config'],
      });
      if (!result.err && result.items.length) {
        state.configuration = JSON.parse(result.items[0].segments_config);
        state.listImportId = result.items[0].id_shop_list_import;
        return result.items[0];
      }
      return null;
    },
  },
};
