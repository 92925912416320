<template>
  <div class="mt-2 shops-bills">
    <h3
      v-if="displayTitle"
      class="mb-4"
    >
      {{ t('shop.bills.title') }}
    </h3>
    <div class="grid">
      <div class="col-12">
        <SpmTable
          :key="spmTableKey"
          name="ShopsBills"
          index="id_ps_bundle"
          :persistent-filters="persistentFilters"
          :table-columns="columns"
          :initial-sorting="initialSorting"
        >
          <template #payment_error="slotProps">
            <Tag
              v-if="slotProps.data.payment_error === 1"
              :value="t('shop.bills.status.processing')"
              severity="info"
              icon="far fa-ellipsis-h"
              style="background-color:#9b9b9b"
            />
            <Tag
              v-else-if="slotProps.data.payment_error === 2"
              :value="t('shop.bills.status.unpaid')"
              severity="danger"
              icon="far fa-times"
            />
            <Tag
              v-else
              :value="t('shop.bills.status.paid')"
              icon="far fa-check"
              severity="success"
              style="background-color:#94c840;"
            />
          </template>
          <template #products="slotProps">
            <span v-html="listProducts(slotProps.data.products, slotProps.data.id_bundle)" />
          </template>
          <template #amount_ht="slotProps">
            {{ slotProps.data.amount_ht }} €
          </template>
          <template #amount_ttc="slotProps">
            {{ slotProps.data.amount_ttc }} €
          </template>
          <template #id_cart="slotProps">
            <Button
              v-if="slotProps.data.payment_error === 2 && slotProps.data.id_cart !== undefined && findPermission('my_bills.pay_bill')"
              icon="fas fa-file-invoice"
              :label="t('shop.bills.pay_button')"
              class="p-button-sm p-button-danger"
              @click="payInvoice(slotProps.data.id_ps_bundle)"
            />
            <Button
              v-else
              icon="far fa-file-pdf"
              :label="slotProps.data.id_ps_bundle"
              class="p-button-sm p-button-secondary"
              type="button"
              :style="findPermission('my_bills.download')? '': 'pointer-events: none'"
              @click="downloadInvoice(slotProps.data.id_ps_bundle)"
            />
          </template>
        </SpmTable>
      </div>
    </div>
  </div>
  <Dialog
    v-model:visible="showPaymentModal"
    modal
    :closable="true"
    :style="{ width: '50vw' }"
    :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
  >
    <template #header>
      <h3>{{ t('offers.paymentModal.title') }}</h3>
    </template>
    <section class="contents">
      <div class="text-left mb-3  ">
        <span class="font-bold">{{ t('shop.bills.header.invoice') }} :</span> {{ idPsBundleToPay }} -
        <span class="font-bold">{{ t('shop.bills.header.amount_ttc') }} :</span> {{ amountToPay }}
      </div>
      <div
        id="dropInComponent"
        ref="dropInComponent"
      />
    </section>
    <template #footer>
      <Button
        :label="t('close')"
        icon="far fa-times"
        class="p-button-secondary"
        @click="showPaymentModal = false"
      />
    </template>
  </Dialog>
</template>

<script lang="ts">
import { useI18n } from 'vue-i18n';
import { defineComponent, ref, watch } from 'vue';
import Tag from 'primevue/tag';
import Button from 'primevue/button';
import {
  Bundles, Maybe, OperatorType, Scalars, ShopsBills,
} from '@/types/generated-types/graphql';
import {
  SpmTableColumns, SpmTableFilter, SpmTableSort, SpmTableState,
} from '@/types';
import SpmTable from '@/components/table/SpmTable.vue';
import { findPermission, UserState } from '@/composables/User';
import { List } from '@/composables/GraphQL';
import GetPaymentMethods, { adyenConfig, MakeDetailsCall, PayUnpaidBill } from '@/composables/user/AdyenConfig';
import AdyenCheckout from '@adyen/adyen-web';
import Dialog from 'primevue/dialog';
import { getShopsBill } from '@/composables/shop/ShopsBills';
import { formatNumberToCurrency } from '@/helpers/Number';

export default defineComponent({
  name: 'ShopBills',
  components: {
    Tag,
    Button,
    SpmTable,
    Dialog,
  },

  props: {
    displayTitle: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  setup() {
    const { t } = useI18n();
    const spmTableKey = ref(0);
    const showPaymentModal = ref(false);
    const locale = UserState.user.lang;
    const countryCode = locale.toLocaleUpperCase();
    const idPsBundleToPay = ref();
    const amountToPay = ref();

    // Adyen configuration
    const adyenLocale = `${locale}_${countryCode}`;
    const adyenEnvironment: string = process.env.VUE_APP_ADYEN_ENVIRONMENT ?? 'live';

    const shopId = UserState?.activeShop?.id ?? 0; // todo: Do something for shopId 0

    const columns: SpmTableColumns[] = [
      {
        field: 'date',
        header: t('shop.bills.header.date'),
        sortable: false,
        filterable: false,
        editable: false,
        style: 'width:10%;',
        type: 'text',
      },
      {
        field: 'id_ps_bundle',
        header: '',
        sortable: false,
        filterable: false,
        editable: false,
        style: '',
        type: '',
        hidden: true,
      },
      {
        field: 'id_bundle',
        header: '',
        sortable: false,
        filterable: false,
        editable: false,
        style: '',
        type: '',
        hidden: true,
      },
      {
        field: 'payment_error',
        header: t('shop.bills.header.status'),
        sortable: false,
        filterable: false,
        editable: false,
        style: 'width:10%; text-align:center !important;',
        type: '',
      },
      {
        field: 'products',
        header: t('shop.bills.header.offer'),
        sortable: false,
        filterable: false,
        editable: false,
        style: '',
        type: 'text',
      },
      {
        field: 'amount_ht',
        header: t('shop.bills.header.amount_ht'),
        sortable: false,
        filterable: false,
        editable: false,
        style: 'width:10%;text-align:right !important;',
        type: 'text',
      },
      {
        field: 'amount_ttc',
        header: t('shop.bills.header.amount_ttc'),
        sortable: false,
        filterable: false,
        editable: false,
        style: 'width:10%;text-align: right !important;',
        type: 'text',
      },
      {
        field: 'id_cart',
        header: t('shop.bills.header.invoice'),
        sortable: false,
        filterable: false,
        editable: false,
        style: 'width:350px;text-align:left !important;',
        type: 'text',
      },
    ];
    const persistentFilters: SpmTableFilter[] = [
      { field: 'id_shop', value: shopId, operator: OperatorType.Equals },
      { field: 'valid', value: 1, operator: OperatorType.Equals },
    ];

    const initialSorting: SpmTableSort[] = [
      {
        field: 'date',
        type: 'DESC',
      },
    ];

    const tableData = ref<SpmTableState>({
      items: [],
      total: 0,
      error: '',
      selectedItems: [],
    });

    const bundles = ref<Record<string, Bundles>>({ });
    watch(tableData, async () => {
      const bills: ShopsBills[] = tableData.value.items as ShopsBills[];
      const bundleIds: number[] = bills.map((bill) => bill.id_bundle);

      const { items, err } = await List<Bundles>({
        name: 'Bundles',
        settings: {
          offset: 0,
          limit: 1000,
          order: [],
          filter: [
            { field: 'id_bundle', value: bundleIds.join(','), operator: OperatorType.In },
          ],
        },
        fields: ['id_bundle', 'label'],
      });

      if (err === '') {
        items.forEach((bundle) => {
          bundles.value[bundle.id_bundle.toString()] = bundle;
        });
      }
    });

    const listProducts = (products: Maybe<Scalars['String']> | undefined, BundleID: number): string => (products
      ? '- '.concat(JSON.parse(products).join('<br />- '))
      : bundles.value[BundleID.toString()]?.label ?? '');

    const payInvoice = async (billId: string) => {
      // Get bill information
      const bill = await getShopsBill(billId, ['id_ps_bundle', 'amount_ht', 'amount_ttc']);

      if (bill && bill.items.length > 0) {
        idPsBundleToPay.value = bill.items[0].id_ps_bundle;
        amountToPay.value = formatNumberToCurrency(bill.items[0].amount_ttc, 'EUR');

        const dataQuery = {
          idShop: UserState.activeShop?.id ?? 0,
          idUser: UserState.user.id,
          idPsBundle: billId,
          paymentDetails: null,
        };

        showPaymentModal.value = true;
        const paymentMethods = await GetPaymentMethods({
          id_user: UserState.user.id, currency: 'EUR', amount: bill.items[0].amount_ttc, country_code: countryCode, locale: adyenLocale,
        });

        const configuration = {
          translations: {
            'fr-FR': {
              payButton: t('offers.pay'),
            },
          },

          paymentMethodsResponse: paymentMethods.data,
          allowPaymentMethods: ['scheme', 'paypal'],
          clientKey: adyenConfig[adyenEnvironment]['client-key'],
          locale: adyenLocale,
          environment: adyenConfig[adyenEnvironment].environment,
          onSubmit: async (state: any, dropin: any) => {
            dropin.setStatus('loading');
            dataQuery.paymentDetails = state.data;

            // Your function calling your server to make the `/payments` request
            PayUnpaidBill(JSON.stringify(dataQuery)).then(async (response) => {
              if (response.data.action) {
                // Drop-in handles the action object from the /payments response
                dropin.handleAction(response.data.action);
              } else if (response.data.resultCode === 'Authorised' || response.data.resultCode === 'Received') {
                // Your function to show the final result to the shopper
                dropin.setStatus('success', { message: t('offers.billPaymentAcceptedWithDelay') });

                setTimeout(() => {
                  spmTableKey.value += 1;
                }, 5000);
                idPsBundleToPay.value = '';
                amountToPay.value = '';
              } else {
                dropin.setStatus('error', { message: response.data.message });

                idPsBundleToPay.value = '';
                amountToPay.value = '';
              }
            }).catch((reason: any) => {
              dropin.setStatus('error', { message: reason.response.data.refusalReason });
            });
          },

          onAdditionalDetails: (state: any, dropin: any) => {
            // Your function calling your server to make a `/payments/details` request
            MakeDetailsCall(JSON.stringify(state.data))
              .then((response) => {
                if (response.data.action) {
                  // Drop-in handles the action object from the /payments response
                  dropin.handleAction(response.data.action);
                } else if (response.data.resultCode === 'Authorised') {
                  // Your function to show the final result to the shopper
                  dropin.setStatus('success', { message: t('offers.billPaymentAcceptedWithDelay') });

                  setTimeout(() => {
                    spmTableKey.value += 1;
                  }, 5000);
                  idPsBundleToPay.value = '';
                  amountToPay.value = '';
                } else {
                  dropin.setStatus('error', { message: response.message });

                  idPsBundleToPay.value = '';
                  amountToPay.value = '';
                }

                idPsBundleToPay.value = '';
                amountToPay.value = '';
              })
              .catch((error) => {
                throw Error(error);
              });
          },

          onPaymentCompleted: (result: any, component: any) => {
            console.info(result, component);
          },

          onError: (error: any, component: any) => {
            console.error(error.name, error.message);
          },

          paymentMethodsConfiguration: {
            card: {
              hasHolderName: true,
              holderNameRequired: true,
              hideCVC: false,
            },

            threeDS2: {
              challengeWindowSize: '05',
            },
          },
        };

        const checkout = await AdyenCheckout(configuration);
        checkout.create('dropin', { showStoredPaymentMethods: true }).mount('#dropInComponent').setComponentRef('dropInComponent');
      }
    };

    const downloadInvoice = (reference: string) => {
      const url = `https://gestion.shopimind.com/pdf-invoice.php?reference=${reference}`;
      window.open(url);
    };

    return {
      t,
      spmTableKey,
      showPaymentModal,
      idPsBundleToPay,
      amountToPay,
      columns,
      persistentFilters,
      initialSorting,
      listProducts,
      payInvoice,
      findPermission,
      downloadInvoice,
    };
  },
});
</script>

<style lang="scss">
</style>
