
import {
  defineComponent,
  computed,
  ComputedRef,
  Ref,
  ref,
  onUpdated,
} from 'vue';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import { hideSectionWidgetModal, TemplateEditorState as state } from '@/composables/template-editor/TemplateEditor';
import LoadSection from '@/components/template-builder/LoadSection.vue';
import { useI18n } from 'vue-i18n';
import { LoadSectionPayload } from '@/types';
import { getTemplateIframeDocument } from '@/components/template-builder/utils/helpers';

export default defineComponent({
  name: 'SectionWidgetModal',

  components: {
    LoadSection,
    Dialog,
    Button,
  },

  setup() {
    const { t } = useI18n();
    const displayModal: ComputedRef<boolean> = computed(() => state.isSectionWidgetModalVisible);
    const payload: Ref<LoadSectionPayload | null> = ref({ position: 'top', selector: '#spm_body .spm_section:first-child' });

    const handleClose = () => {
      hideSectionWidgetModal();
    };

    onUpdated(() => {
      // We search for placeholder in the template
      const template = getTemplateIframeDocument();
      const placeholder = template?.querySelector('.sortable-group-section.sortable-placeholder') as HTMLElement;

      if (placeholder) {
        // We define the new payload
        payload.value = { position: 'bottom', selector: '.sortable-group-section.sortable-placeholder' };
      }
    });

    return {
      t,
      displayModal,
      payload,
      handleClose,
    };
  },
});
