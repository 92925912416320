<template>
  <div class="flex justify-content-between flex-wrap mb-3">
    <div class="flex align-items-center justify-content-center">
      <h3>
        {{ t("stats.pages.title") }}
      </h3>
    </div>
    <div class="flex align-items-center justify-content-center">
      <router-link
        :to="{ name: 'user.profile', params: { tab: 'stats' } }"
      >
        <SpmButton
          :label="t('stats.actions.subscribe')"
          icon="far fa-file-chart-column"
          class-style="mr-2 p-button p-button-secondary"
          @click="showStatsModelSelectorHandler"
        />
      </router-link>
      <SecureSpmButton
        v-if="findPermission('stats_report.create')"
        :label="t('stats.actions.create')"
        icon="far fa-plus-circle"
        class-style="p-button p-button-success"
        :on-authorized-click="showStatsModelSelectorHandler"
        required-feature="stats_report.create"
      />
    </div>
  </div>

  <ConfirmDialog
    :breakpoints="{ '640px': '75vw', '380px': '100vw' }"
    :style="{ width: '40vw' }"
  />
  <TabView
    @tab-change="tabChange"
  >
    <TabPanel
      :key="StatsType.STATS"
      :header="t('stats.pages.tabs.stats')"
    >
      <SpmTable
        :key="statsTableKey"
        ref="statsComponentRef"
        name="UsersPagesConfigurationWithPriorities"
        :table-columns="statsColumns"
        :persistent-filters="statsFilters"
        :id-shop="idShop"
        :reorderable-rows="true"
        responsive-layout="scroll"
        index="stats"
        :paginator="false"
        :limit="0"
        :exclude-name="excludeName"
        :exclude-field="excludeField"
        :exclude-filters="excludeStatsFilters"
        @row-reorder="onRowReorder"
        @cell-edit-complete="onColumnValueEdit"
        @cell-edit-init="renderData"
      >
        <template #page_name="slotProps">
          <div
            class="mr-5"
            style="float:left"
          >
            <span class="font-bold capitalize">
              {{ getLanguageValue(slotProps.data[slotProps.column.props.field]) }}
            </span>
            <span class="mt-2 last-update hidden block">
              {{ t('campaigns.automation.list.lastUpdateOn') }} {{ toLocaleDateString(slotProps.data.date_modification) }}
            </span>
          </div>
        </template>
      </SpmTable>
    </TabPanel>
    <TabPanel
      :key="StatsType.EMAIL"
      :header="t('stats.pages.tabs.email')"
    >
      <SpmTable
        :key="statsTableKey"
        ref="emailComponentRef"
        name="UsersPagesConfigurationWithPriorities"
        index="email"
        :table-columns="emailColumns"
        :persistent-filters="emailFilters"
        :id-shop="idShop"
        :paginator="false"
        :limit="0"
        @cell-edit-complete="onColumnValueEdit"
        @cell-edit-init="renderData"
      >
        <template #page_name="slotProps">
          <div
            class="mr-5"
            style="float:left"
          >
            <span class="font-bold capitalize">
              {{ getLanguageValue(slotProps.data[slotProps.column.props.field]) }}
            </span>
            <span class="mt-2 last-update hidden block">
              {{ t('campaigns.automation.list.lastUpdateOn') }} {{ toLocaleDateString(slotProps.data.date_modification) }}
            </span>
          </div>
        </template>
      </SpmTable>
    </TabPanel>
  </TabView>

  <StatsModelSelector
    v-if="showStatsModelSelector"
    stats-model-type="none"
    @on-choose-template="selectStatsModel"
    @on-close-dialog="closeStatsModelSelector"
  />
  <OverlayPanel
    ref="renameOverlayRef"
  >
    <div class="flex flex-row gap-3">
      <div style="padding:1rem">
        <span class="block mb-2"> {{ t('stats.actions.rename.message') }}</span>
        <LocalizedTextInput
          id="StatsSettingsPanelSettingName"
          v-model="statsModelName"
          class="input-text mb-3"
          style="width: 100%;"
          :default-languages="defaultLanguages"
        />
        <div
          class="flex justify-content-end"
        >
          <SpmButton
            :label="t('close')"
            class="p-button mr-1 p-button-secondary"
            @click="closeRenameOverlay"
          />
          <SpmButton
            :label="t('save')"
            class="p-button p-button-success"
            @click="renameStatsModel"
          />
        </div>
      </div>
    </div>
  </OverlayPanel>
  <SendTestModal
    @on-validate="(emitParameters) => sendTestReport(emitParameters)"
  />
</template>

<script lang="ts">
import SpmTable from '@/components/table/SpmTable.vue';
import {
  SpmTableAction, SpmTableColumns, SpmTableFilter, StatsType, UserTypeEnum,
} from '@/types';
import {
  defineComponent, onBeforeMount, onMounted, Ref, ref, toRefs, watch,
} from 'vue';

import ConfirmDialog from 'primevue/confirmdialog';
import { useConfirm } from 'primevue/useconfirm';
import { useI18n } from 'vue-i18n';
import { showToastError, showToastSuccess } from '@/helpers';
import {
  OperatorType,
  UsersPagesConfiguration,
  UsersPagesConfigurationInputItem,
  UsersPagesConfigurationUpdateInputItem,
  UsersPagesSubscriptions,
  UsersPagesSubscriptionsUpdateInputItem,
} from '@/types/generated-types/graphql';
import { findPermission, UserState, hasAccessToFeatures } from '@/composables/User';
import {
  DuplicateUsersPagesConfiguration,
  GetUsersPagesConfiguration,
  UpdateUsersPagesConfiguration,
} from '@/composables/Statistics/usersPagesConfiguration';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import StatsModelSelector from '@/components/stats/StatsModelSelector.vue';
import { useStore } from '@/store';
import SpmButton from '@/components/spm-primevue/SpmButton.vue';
import SecureSpmButton from '@/components/spm-primevue/SecureSpmButton.vue';
import { PlanName } from '@/types/enums';
import {
  getUserStatsPages,
  UpdateUsersPagesSubscriptions,
} from '@/composables/Statistics/usersPagesSubscriptions';
// eslint-disable-next-line import/no-cycle
import router from '@/router';
import moment from 'moment';
import useDateFormat from '@/composables/useDateFormat';
import deepcopy from 'deepcopy';
import OverlayPanel from 'primevue/overlaypanel';
import LocalizedTextInput from '@/components/fields/LocalizedTextInput.vue';
import defaultLanguages from '@/configs/languages';
import SendTestModal, { Suggestion } from '@/components/modals/SendTestModal.vue';
import { nestPost } from '@/composables/nestApi';

export default defineComponent({
  name: 'StatsList',

  components: {
    SendTestModal,
    LocalizedTextInput,
    StatsModelSelector,
    SpmTable,
    ConfirmDialog,
    TabView,
    TabPanel,
    SpmButton,
    SecureSpmButton,
    OverlayPanel,
  },

  setup() {
    const { t } = useI18n();
    const confirm = useConfirm();
    const store = useStore();
    const idShop = ref(UserState.activeShop ? UserState.activeShop.id : 0);
    const userLang = ref(UserState.user.lang);
    const statsTableKey = ref(0);
    const emailComponentRef = ref();
    const statsComponentRef = ref();
    const activeTab = ref(StatsType.STATS);
    const showStatsModelSelector = ref(false);
    const currentRowDataBeingUpdated = ref();
    const { toLocaleDateString } = useDateFormat();
    const renameOverlayRef = ref();
    const statsModelName = ref();
    const currentRowData = ref();
    const selectedReport = ref();

    const pagesSubscriptions: Ref<UsersPagesSubscriptions[]> = ref([]);
    const pagesSubscriptionsById: Ref<number[]> = ref([]);

    const statsFilters: SpmTableFilter[] = [
      {
        field: 'type',
        value: StatsType.STATS,
        operator: OperatorType.Equals,
      },
      {
        field: 'deleted',
        value: 0,
        operator: OperatorType.Equals,
      },
      {
        field: 'id_user',
        value: UserState.user.id,
        operator: OperatorType.Equals,
      },
      {
        field: 'user_type',
        value: UserState.user.userType,
        operator: OperatorType.Equals,
      },
    ];

    const emailFilters: SpmTableFilter[] = [
      {
        field: 'type',
        value: StatsType.EMAIL,
        operator: OperatorType.Equals,
      },
      {
        field: 'deleted',
        value: 0,
        operator: OperatorType.Equals,
      },
      {
        field: 'id_user',
        value: UserState.user.id,
        operator: OperatorType.Equals,
      },
      {
        field: 'user_type',
        value: UserState.user.userType,
        operator: OperatorType.Equals,
      },
    ];

    const excludeStatsFilters: Ref<SpmTableFilter[]> = ref([]);
    const excludeName = ref('');
    const excludeField = ref('');

    const isJsonString = (str: string) => str.trim().startsWith('{') && str.trim().endsWith('}');

    const showRenameOverlay = (usersPagesConfiguration: UsersPagesConfigurationUpdateInputItem, event: any) => {

      if (usersPagesConfiguration.page_name) {
        if (isJsonString(usersPagesConfiguration.page_name)) {
          statsModelName.value = JSON.parse(usersPagesConfiguration.page_name);
        } else {
          statsModelName.value = usersPagesConfiguration.page_name;
        }
      }
      currentRowData.value = usersPagesConfiguration;
      renameOverlayRef.value.toggle(event);
    };

    const UpdateRows = (usersPagesConfig: UsersPagesConfigurationUpdateInputItem[], successMsg: string, errorMsg: string) => {
      UpdateUsersPagesConfiguration(usersPagesConfig).then((result) => {
        if (result.status && result.id) {
          showToastSuccess(successMsg);
          store.commit('statsEditor/setRefreshNavigationBar', true);
        } else {
          showToastError(errorMsg);
        }
      }).catch(() => {
        showToastError(errorMsg);
      });
    };

    const getPagesSubscriptionsForUser = async () => {
      // Get users pages subscriptions
      const result = await getUserStatsPages(UserState.user.id, UserState.user.userType, null, UserState.activeShop?.idUser);

      if (result && result.items && result.items.length) {
        pagesSubscriptions.value = result.items;
        pagesSubscriptionsById.value = result.items.map((subscription: UsersPagesSubscriptions) => subscription.id_users_pages_configuration);
      }
    };

    const deleteConfirmationDialog = (usersPagesConfig: UsersPagesConfigurationUpdateInputItem) => {
      confirm.require({
        message: t('stats.pages.delete.body'),
        header: t('stats.pages.delete.title'),
        icon: 'far fa-exclamation-triangle',
        acceptLabel: t('stats.pages.delete.validate'),
        rejectLabel: t('stats.pages.delete.cancel'),
        accept: () => {
          const usersPagesConfInput: UsersPagesConfigurationUpdateInputItem = {
            id_users_pages_configuration: usersPagesConfig.id_users_pages_configuration,
            deleted: true,
            date_modification: new Date().toLocaleString('en-US', { timeZone: 'Europe/Paris' }),
          };

          UpdateRows([usersPagesConfInput], t('stats.pages.delete.success'), t('stats.pages.delete.error'));
          store.commit('statsEditor/setRefreshComponents', true);
        },
      });
    };

    const duplicateConfirmationDialog = (usersPagesConfig: UsersPagesConfiguration) => {
      confirm.require({
        message: t('stats.pages.duplicate.body'),
        header: t('stats.pages.duplicate.title'),
        icon: 'far fa-exclamation-triangle',
        acceptLabel: t('stats.pages.duplicate.validate'),
        rejectLabel: t('stats.pages.duplicate.cancel'),
        accept: async () => {
          const record = await GetUsersPagesConfiguration(usersPagesConfig.id_users_pages_configuration,
            ['page_name', 'configuration', 'extra_data', 'type']);
          if (record.err || record.item == null) {
            return showToastError(t('stats.pages.duplicate.error'));
          }

          let adminUserId = null;
          if (UserState.user.userType === UserTypeEnum.ADMIN) {
            adminUserId = UserState.user.id;
          } else if (UserState.activeShop) {
            adminUserId = UserState.activeShop?.idUser;
          }

          const usersPagesConfInput: UsersPagesConfigurationInputItem = {
            owner: 'user',
            page_name: record.item.page_name,
            navigation_name: record.item.navigation_name ?? record.item.page_name,
            admin_user_id: adminUserId,
            created_by_user_id: UserState.user.id,
            created_by_user_type: UserState.user.userType,
            configuration: record.item.configuration,
            extra_data: record.item.extra_data,
            type: record.item.type,
            date_creation: new Date().toLocaleString('en-US', { timeZone: 'Europe/Paris' }),
            date_modification: new Date().toLocaleString('en-US', { timeZone: 'Europe/Paris' }),
          };

          return DuplicateUsersPagesConfiguration([usersPagesConfInput]).then((result) => {
            if (result.status && result.id) {
              showToastSuccess(t('stats.pages.duplicate.success'));
              store.commit('statsEditor/setRefreshComponents', true);
              store.commit('statsEditor/setRefreshNavigationBar', true);
            } else {
              showToastError(t('stats.pages.duplicate.error'));
            }
          }).catch(() => {
            showToastError(t('stats.pages.duplicate.error'));
          });
        },
      });
    };

    const tabChange = (tabIndex: any) => {
      switch (tabIndex.index) {
        case 0:
          activeTab.value = StatsType.STATS;
          break;
        case 1:
          activeTab.value = StatsType.EMAIL;
          break;
        default:
          activeTab.value = StatsType.STATS;
          break;
      }
    };

    const featurePermissionEdit = hasAccessToFeatures('stats_report.edit');
    const featurePermissionView = hasAccessToFeatures('stats_report.view');

    const actions: SpmTableAction[] = [
      {
        icon: 'far fa-eye',
        label: t('stats.actions.view'),
        callback: (usersPagesConfig: UsersPagesConfiguration) => {
          const viewStats = () => {
            router.push({ name: 'custom_stats', params: { pageId: usersPagesConfig.id_users_pages_configuration } });
          };

          if (usersPagesConfig.owner === 'user') {
            if (featurePermissionEdit && !featurePermissionEdit.access) {
              store.commit('general/setIsFeatureUnavailableModalVisible', true);
              store.commit('general/setFeatureUnavailableMinPlan', featurePermissionEdit.minimumPlan);
              store.commit('general/setOnAuthorizedClickFunction', viewStats);
            } else {
              viewStats();
            }
          } else if (usersPagesConfig.owner === 'system') {
            viewStats();
          }
        },

        show: () => activeTab.value !== StatsType.EMAIL,
        minimumPlan: featurePermissionView && featurePermissionView.minimumPlan !== PlanName.STANDARD ? featurePermissionView.minimumPlan : null,
      },
      {
        icon: 'far fa-paper-plane',
        label: t('stats.actions.sendTest'),
        callback: (usersPagesConfig: UsersPagesConfiguration) => {
          // Mark current report as selected
          selectedReport.value = usersPagesConfig.id_users_pages_configuration;

          // Open modal to select mail addresses to send the reports
          store.commit('general/showSendTestModal');
        },

        show: () => activeTab.value === StatsType.EMAIL,
      },
      {
        icon: 'far fa-edit',
        label: t('stats.actions.edit'),
        callback: async (usersPagesConfig: UsersPagesConfiguration) => {
          if (usersPagesConfig.id_users_pages_configuration) {
            const editStats = async () => {
              await store.dispatch('statsEditor/editPage',
                { pageId: usersPagesConfig.id_users_pages_configuration });
            };

            if (usersPagesConfig.owner === 'user') {
              if (featurePermissionEdit && !featurePermissionEdit.access) {
                store.commit('general/setIsFeatureUnavailableModalVisible', true);
                store.commit('general/setFeatureUnavailableMinPlan', featurePermissionEdit.minimumPlan);
                store.commit('general/setOnAuthorizedClickFunction', editStats);
              } else {
                await editStats();
              }
            } else if (usersPagesConfig.owner === 'system') {
              editStats();
            }
          }
        },

        show: () => findPermission('stats_report.edit'),
        minimumPlan: featurePermissionEdit && featurePermissionEdit.minimumPlan !== PlanName.STANDARD ? featurePermissionEdit.minimumPlan : null,
      },
      {
        icon: 'far fa-clone',
        label: t('stats.actions.duplicate'),
        callback: (usersPagesConfig: UsersPagesConfiguration) => duplicateConfirmationDialog(usersPagesConfig),
        show: () => findPermission('stats_report.clone'),
      },
      {
        icon: 'fa-light fa-input-text',
        label: t('stats.actions.rename.title'),
        callback: (usersPagesConfig: UsersPagesConfigurationUpdateInputItem, event: any) => (event && usersPagesConfig ? showRenameOverlay(usersPagesConfig, event) : false),
        show: () => findPermission('stats_report.rename'),
      },
      {
        icon: 'far fa-trash',
        label: t('stats.actions.delete'),
        callback: (usersPagesConfig: UsersPagesConfigurationUpdateInputItem) => deleteConfirmationDialog(usersPagesConfig),
        show: (usersPagesConfig: UsersPagesConfiguration) => {
          if (usersPagesConfig.owner === 'system') {
            return false;
          }
          return findPermission('stats_report.delete');
        },
      },
    ];

    const closeRenameOverlay = () => {
      renameOverlayRef.value.hide();
    };

    const emailColumns: SpmTableColumns[] = [
      {
        field: 'id_users_pages_configuration',
        header: '',
        sortable: false,
        filterable: false,
        editable: false,
        style: '',
        type: 'text',
        hidden: true,
      },
      {
        field: 'type',
        header: '',
        sortable: false,
        filterable: false,
        editable: false,
        style: '',
        type: 'text',
        hidden: true,
      },
      {
        field: 'page_name',
        header: t('stats.pages.headers.pageName'),
        sortable: true,
        filterable: true,
        editable: true,
        style: '',
        type: 'text',
      },
      {
        field: 'date_creation',
        header: t('stats.pages.headers.dateCreated'),
        sortable: true,
        filterable: true,
        editable: false,
        style: '',
        type: 'text',
        filterSettings: { type: 'date', hideFilterMenu: true },
      },
      {
        field: 'date_modification',
        header: '',
        sortable: false,
        filterable: false,
        editable: false,
        style: '',
        type: 'text',
        hidden: true,
      },
      {
        field: 'owner',
        header: '',
        sortable: false,
        filterable: false,
        editable: false,
        style: '',
        type: 'text',
        hidden: true,
      },
      {
        field: '',
        header: t('actions'),
        sortable: false,
        filterable: false,
        editable: false,
        style: 'width:15%',
        type: 'action',
        actions,
      },
    ];

    const statsColumns: SpmTableColumns[] = [
      {
        field: 'id_users_pages_configuration',
        header: '',
        sortable: false,
        filterable: false,
        editable: false,
        style: '',
        type: 'text',
        hidden: true,
      },
      {
        field: 'type',
        header: '',
        sortable: false,
        filterable: false,
        editable: false,
        style: '',
        type: 'text',
        hidden: true,
      },
      {
        field: 'page_name',
        header: t('stats.pages.headers.pageName'),
        sortable: true,
        filterable: true,
        editable: true,
        style: '',
        type: 'text',
      },
      {
        field: 'date_creation',
        header: t('stats.pages.headers.dateCreated'),
        sortable: true,
        filterable: true,
        editable: false,
        style: '',
        type: 'text',
        filterSettings: { type: 'date', hideFilterMenu: true },
      },
      {
        field: 'date_modification',
        header: '',
        sortable: false,
        filterable: false,
        editable: false,
        style: '',
        type: 'text',
        hidden: true,
      },
      {
        field: 'owner',
        header: '',
        sortable: false,
        filterable: false,
        editable: false,
        style: '',
        type: 'text',
        hidden: true,
      },
      {
        field: '',
        header: t('actions'),
        sortable: false,
        filterable: false,
        editable: false,
        style: 'width:15%',
        type: 'action',
        actions,
      },
    ];

    const onRowReorder = (usersPagesConfig: UsersPagesConfigurationUpdateInputItem[]) => {
      const updateArr: UsersPagesSubscriptionsUpdateInputItem[] = [];

      if (pagesSubscriptions.value) {
        usersPagesConfig.forEach((record, index) => {
          const subscription = pagesSubscriptions.value.find((page: UsersPagesSubscriptions) => page.id_users_pages_configuration === record.id_users_pages_configuration);

          if (subscription) {
            const newUpdateRecord: UsersPagesSubscriptionsUpdateInputItem = {
              id_users_pages_subscription: subscription.id_users_pages_subscription,
              priority: index + 1,
            };

            updateArr.push(newUpdateRecord);
          }
        });
      }

      if (updateArr.length) {
        UpdateUsersPagesSubscriptions(updateArr).then((result) => {
          if (result && result.status) {
            store.commit('statsEditor/setRefreshNavigationBar', true);
            store.commit('statsEditor/setRefreshComponents', true);
          }
        });
      }
    };

    const closeStatsModelSelector = () => {
      showStatsModelSelector.value = false;
    };

    const selectStatsModel = async (pageId: number) => {
      await store.dispatch('statsEditor/editPage',
        { pageId });
      closeStatsModelSelector();
      store.commit('general/hideTheSpinner');
    };

    const showStatsModelSelectorHandler = () => {
      showStatsModelSelector.value = true;
    };

    function isJSONObject(variable: any) {
      try {
        JSON.parse(variable);
        return true;
      } catch (e) {
        return false;
      }
    }

    const updateStatsModel = async (field: string, data: any, jsonData: any, originalJsonObject: any) => {
      const modifiedData = originalJsonObject;

      if (!isJSONObject(jsonData)) {
        modifiedData[userLang.value] = data[field];
      }

      const usersPagesConfig: UsersPagesConfigurationUpdateInputItem = {
        id_users_pages_configuration: data.id_users_pages_configuration,
        [field]: JSON.stringify(modifiedData),
        date_modification: moment().format('YYYY-MM-DD HH:mm:ss'),
      };

      UpdateUsersPagesConfiguration([usersPagesConfig]).then((result) => {
        if (result.status && result.id) {
          showToastSuccess(t('stats.actions.rename.nameUpdated'));
          store.commit('statsEditor/setRefreshNavigationBar', true);
          store.commit('statsEditor/setRefreshComponents', true);
        } else {
          showToastError(t('errorMessages.updateFailed'));
        }
      }).catch((err: Error) => {
        showToastError(err.message);
      });
    };

    const onColumnValueEdit = async (field: string, data: any) => {
      const jsonData = JSON.parse(currentRowDataBeingUpdated.value.page_name);
      await updateStatsModel(field, data, jsonData, jsonData);
    };

    const renameStatsModel = () => {
      updateStatsModel('page_name', currentRowData.value, JSON.stringify(toRefs(statsModelName.value)), statsModelName.value);
      closeRenameOverlay();
    };

    watch(() => store.getters['statsEditor/getRefreshComponents'], () => {
      if (store.getters['statsEditor/getRefreshComponents']) {
        statsTableKey.value += 1;
        store.commit('statsEditor/setRefreshComponents', false);
      }
    });

    onBeforeMount(() => {
      getPagesSubscriptionsForUser();
      if (UserState.activeShop && UserState.user && UserState.user.id !== UserState.activeShop.idUser) {
        excludeStatsFilters.value.push({ field: 'admin_user_id', value: UserState.activeShop.idUser, operator: OperatorType.In });
        excludeName.value = 'UsersPagesConfiguration';
        excludeField.value = 'id_users_pages_configuration';
      }
    });

    onMounted(() => {
      store.commit('statsEditor/setRefreshComponents', false);
    });

    const getLanguageValue = (columnValue: string) => {
      if (isJsonString(columnValue)) {
        const result = JSON.parse(columnValue);
        return result[userLang.value];
      }
      return columnValue;
    };

    const renderData = (field: string, data: any, event: any) => {
      if (field === 'page_name') {
        currentRowDataBeingUpdated.value = deepcopy(event.data);
        // eslint-disable-next-line no-param-reassign
        event.data[event.field] = getLanguageValue(data[field]);
      }
    };

    /**
     * Send test report by e-mail
     * @param params
     */
    const sendTestReport = async (params: Suggestion[]) => {
      nestPost('esr', '/esr/send-test', {}, {
        data: {
          idShop: UserState.activeShop?.id ?? 0,
          idReport: selectedReport.value,
          addresses: params,
        },
      }).then(async (result) => {
        if (result && result.success) {
          await showToastSuccess('emailReportSent');
        } else {
          await showToastError('GENERIC_ERROR');
        }

        selectedReport.value = null;
      });
    };

    return {
      t,
      emailColumns,
      statsColumns,
      emailFilters,
      idShop,
      statsFilters,
      onRowReorder,
      statsTableKey,
      emailComponentRef,
      statsComponentRef,
      tabChange,
      closeStatsModelSelector,
      selectStatsModel,
      showStatsModelSelectorHandler,
      showStatsModelSelector,
      StatsType,
      findPermission,
      onColumnValueEdit,
      toLocaleDateString,
      getLanguageValue,
      renderData,
      statsModelName,
      renameOverlayRef,
      renameStatsModel,
      closeRenameOverlay,
      defaultLanguages,
      excludeStatsFilters,
      excludeName,
      excludeField,
      sendTestReport,
    };
  },
});
</script>

<style scoped>

</style>
