import {
  Delete, List, Request, RequestResult, Upsert,
} from '@/composables/GraphQL';
import { OperatorType, ShopsConfiguration, ShopsLangUpdateInputItem } from '@/types/generated-types/graphql';
import { UserState } from '@/composables/User';
import serviceParameters, { servicesRequiredFieldsPerActions, servicesPerActions } from '@/configs/services-parameters';
import { MultiLangInput, ServiceParameter } from '@/types/store-parameters-types';
import axios from 'axios';
import fetchShopInformation from '@/composables/shop/Shops';
import { unserialize } from 'php-serialize';

export const fetchStoreParamsConfigurations = async (idShop: number): Promise<RequestResult<ShopsConfiguration[]>> => {
  const query = 'query( $idShop: Int)'
    + '{ GetShopsParameters(id_shop: $idShop){id_shop_configuration, key, lang, value}}';

  const variables = {
    idShop,
  };

  return Request<ShopsConfiguration[]>({
    name: 'GetShopsParameters',
    query,
    variables,
  });
};

export async function fetchShopConfigurations({ shopId = UserState.activeShop?.id }, keyLike = ''): Promise<Array<ShopsConfiguration>|null> {
  const { items, total, err } = await List<ShopsConfiguration>({
    name: 'ShopsConfiguration',
    settings: {
      filter: [
        {
          field: 'id_shop',
          operator: OperatorType.Equals,
          value: shopId ?? 0,
        },
        {
          field: 'key',
          operator: OperatorType.Like,
          value: keyLike !== '' ? `%${keyLike}%` : '%shopRequiredData%',
        },
      ],
      order: [
        { field: 'id_shop', type: 'ASC' },
      ],
      limit: 0,
      offset: 0,
    },
    fields: ['lang', 'value', 'key', 'id_shop_configuration'],
  });

  if (err === '') {
    return items;
  }
  return null;
}

export async function generateUserExtendedToken(user_token: any): Promise<any|null> {
  return axios.create().get<any>('https://graph.facebook.com/oauth/access_token', {
    params: {
      grant_type: 'fb_exchange_token',
      client_id: '1951290961830126',
      client_secret: '1507bd8f917c8b2b682b124fae453b15',
      fb_exchange_token: user_token,
    },
  });
}

export async function saveFacebookUser(idShop: number, user: any): Promise<any|null> {
  return axios.create({ baseURL: '/api' }).post<any>('/user/facebook/save', {
    idShop,
    user,
  });
}

export async function saveFacebookPage(idShop: number, pageId: string, pageToken: string, shopUrl: string): Promise<any|null> {
  return axios.create({ baseURL: '/api' }).post<any>('/page/facebook', {
    idShop,
    pageId,
    pageToken,
    url: shopUrl,
  });
}

export async function deleteFacebookTokens(idShop: number, keysToDelete: string): Promise<any|null> {
  return axios.create({ baseURL: '/api' }).post<any>('/user/facebook/delete', {
    idShop,
    keysToDelete,
  });
}

export const saveNewLang = async (shopsLangItem: ShopsLangUpdateInputItem) => Upsert<ShopsLangUpdateInputItem>({
  name: 'ShopsLang',
  input: shopsLangItem,
  type: 'ShopsLangUpdateInputItem',
});

export const deleteLanguage = async (shopsLangItem: ShopsLangUpdateInputItem[]) => Delete<ShopsLangUpdateInputItem>({
  name: 'ShopsLang',
  input: shopsLangItem,
  type: 'ShopsLangUpdateInput',
});

export async function uploadFile(data_file: any, idShop: number, type: string): Promise<any|null> {
  return axios.create({ baseURL: '/api' }).post<any>('/upload-image', {
    data_file,
    id_shop: idShop,
    type,
  });
}

export async function synchronizeLanguages(idShop: number): Promise<any|null> {
  return axios.create({ baseURL: '/api' }).post<any>('/shop/shops-langs/sync', {
    id_shop: idShop,
  });
}

export const retrieveServicesData = async (idShop: number, field: any) => {
  const {
    data,
    err,
  } = await fetchStoreParamsConfigurations(idShop);
  const defaultValuesKeys = Object.keys(field);
  const exclusions = ['shopRequiredDataSocialNetworks_facebook',
    'shopRequiredDataSocialNetworks_googleplus',
    'shopRequiredDataSocialNetworks_instagram',
    'shopRequiredDataSocialNetworks_pinterest',
    'shopRequiredDataSocialNetworks_snapchat',
    'shopRequiredDataSocialNetworks_tumblr',
    'shopRequiredDataSocialNetworks_twitter',
    'shopRequiredDataSocialNetworks_whatsapp',
    'shopRequiredDataSocialNetworks_youtube'];

  if (data === null || data.length === 0) {
    return;
  }

  for (let i = 0; i < data.length; i++) {
    const record = data[i];
    for (let j = 0; j < defaultValuesKeys.length; j++) {
      if (record.key === defaultValuesKeys[j]) {
        if (record.lang && typeof field[defaultValuesKeys[j]] === 'object') {
          let objectValue: any = {};
          objectValue = field[defaultValuesKeys[j]];
          objectValue[record.lang] = record.value;
          // eslint-disable-next-line no-param-reassign
          field[defaultValuesKeys[j]] = objectValue;
        } else if (!exclusions.includes(record.key)) {
          // eslint-disable-next-line no-param-reassign
          field[defaultValuesKeys[j]] = record.value;
        }
      }
    }
  }
};

export const checkIfServiceNeedToBeVerified = (actionId: string) => {
  const actionIds = ['boxsendemail', 'boxsendsms', 'boxsendpushnotification'];
  return actionIds.includes(actionId);
};

function isServiceConfigured(field: Record<string, any>, conditions: any[]) {
  // eslint-disable-next-line array-callback-return, consistent-return
  return conditions.every((condition) => {
    if (typeof condition === 'string') {
      return field[condition]
        && Object.keys(field[condition]).length
        && Object.values(field[condition]).every((value) => value !== null && value !== undefined && value !== '');
    } if (typeof condition === 'object' && condition.property) {
      const { property } = condition;
      const standardVerification = field[property]
          && Object.keys(field[property]).length
          && Object.values(field[property]).every((value) => value !== null && value !== undefined && value !== '');

      if (condition.customVerification && typeof condition.customVerification === 'function') {
        return standardVerification && condition.customVerification(field[property]);
      }
      return standardVerification;
    }
  });
}

export const getNotConfiguredServices = async (idShop: number, actionIds: string[]) => {
  let services: string[] = [];
  let field: Record<string, any> = {};

  actionIds.forEach((actionId) => {
    const serviceField = servicesRequiredFieldsPerActions[actionId];
    if (serviceField) {
      field = { ...field, ...serviceField };
      services = [...services, ...servicesPerActions[actionId]];
    }
  });

  await retrieveServicesData(idShop, field);

  const shopUrlField: Record<string, any> = {
    url: {},
    url_cart: {},
  };
  let urlInput: MultiLangInput = {};
  let urlCart: MultiLangInput = {};
  const info = await fetchShopInformation({ shopId: idShop });
  if (info && info.langs) {
    urlInput = info.langs.reduce((p: any, current: { lang: string; url: string }) => ({
      ...p,
      [current.lang]: current.url,
    }), {});
    urlCart = info.langs.reduce((p: any, current: { lang: string; urlCart: string }) => ({
      ...p,
      [current.lang]: current.urlCart,
    }), {});

    shopUrlField.url = Object.assign(shopUrlField.url, urlInput);
    shopUrlField.url_cart = Object.assign(shopUrlField.url_cart, urlCart);
  }

  const notConfiguredServices: ServiceParameter[] = [];

  services.forEach((service) => {
    if (service === 'ImportantLinksServiceParameter') {
      const isImportantLinksServiceConfigured = isServiceConfigured(field, ['shopRequiredDataRgpd_privacyPolicyUrl']) && isServiceConfigured(shopUrlField, ['url', 'url_cart']);
      if (!isImportantLinksServiceConfigured) {
        const importantLinksServiceParameter = serviceParameters.find((serviceParameter) => serviceParameter.component === 'ImportantLinksServiceParameter');
        if (importantLinksServiceParameter) {
          notConfiguredServices.push(importantLinksServiceParameter);
        }
      }
    }
    if (service === 'EmailServiceParameter') {
      const isEmailServiceConfigured = isServiceConfigured(field, ['shopRequiredDataEmail_senderEmail', 'shopRequiredDataEmail_senderName']);
      if (!isEmailServiceConfigured) {
        const emailServiceParameter = serviceParameters.find((serviceParameter) => serviceParameter.component === 'EmailServiceParameter');
        const socialNetworkServiceParameter = serviceParameters.find((serviceParameter) => serviceParameter.component === 'SocialNetworkServiceParameter');
        if (emailServiceParameter && socialNetworkServiceParameter) {
          notConfiguredServices.push(emailServiceParameter);
          notConfiguredServices.push(socialNetworkServiceParameter);
        }
      }
    }
    if (service === 'SmsServiceParameter') {
      const isSmsServiceConfigured = isServiceConfigured(field, ['shopRequiredDataSms_senderName']);
      if (!isSmsServiceConfigured) {
        const smsServiceParameter = serviceParameters.find((serviceParameter) => serviceParameter.component === 'SmsServiceParameter');
        if (smsServiceParameter) {
          notConfiguredServices.push(smsServiceParameter);
        }
      }
    }
    if (service === 'PushNotificationServiceParameter') {
      const isPushNotificationServiceConfigured = isServiceConfigured(
        field,
        [
          'shopRequiredDataPushNotification_textNotif',
          'shopRequiredDataPushNotification_fullTextButtonAccept',
          'shopRequiredDataPushNotification_fullTextButtonRefuse',
        ],
      );
      if (!isPushNotificationServiceConfigured) {
        const pushNotificationServiceParameter = serviceParameters.find((serviceParameter) => serviceParameter.component === 'PushNotificationServiceParameter');
        if (pushNotificationServiceParameter) {
          notConfiguredServices.push(pushNotificationServiceParameter);
        }
      }
    }
    if (service === 'FacebookServiceParameter') {
      const isFacebookServiceConfigured = field.fbLoggedUser !== '' && field.fbLinkedPage !== '';
      if (!isFacebookServiceConfigured) {
        const facebookServiceParameter = serviceParameters.find((serviceParameter) => serviceParameter.component === 'FacebookServiceParameter');
        if (facebookServiceParameter) {
          notConfiguredServices.push(facebookServiceParameter);
        }
      }
    }
  });

  return notConfiguredServices;
};

export const getMandatoryConfiguredServices = async (idShop: number) => {
  const field = {
    shopRequiredDataEmail_senderEmail: {},
    shopRequiredDataEmail_senderName: {},
    shopRequiredDataRgpd_dataManagerEmail: {},
    shopRequiredDataSms_senderName: {},
    shopRequiredDataPushNotification_textNotif: {},
    shopRequiredDataPushNotification_fullTextButtonAccept: {},
    shopRequiredDataPushNotification_fullTextButtonRefuse: {},
    fbLoggedUser: '',
    fbLinkedPage: '',
    shopRequiredDataRgpd_privacyPolicyUrl: {},
    shopRequiredDataCouponing_max_percentage_of_reduction: 0,
    shopRequiredDataCouponing_min_purchase_amount_for_discount: 0,
    shopRequiredDataSocialNetworks_socialNetwork: '',
  };

  await retrieveServicesData(idShop, field);

  const shopUrlField: Record<string, any> = {
    url: {},
    url_cart: {},
  };
  let urlInput: MultiLangInput = {};
  let urlCart: MultiLangInput = {};
  const info = await fetchShopInformation({ shopId: idShop });
  if (info && info.langs) {
    urlInput = info.langs.reduce((p: any, current: { lang: string; url: string }) => ({
      ...p,
      [current.lang]: current.url,
    }), {});
    urlCart = info.langs.reduce((p: any, current: { lang: string; urlCart: string }) => ({
      ...p,
      [current.lang]: current.urlCart,
    }), {});

    shopUrlField.url = Object.assign(shopUrlField.url, urlInput);
    shopUrlField.url_cart = Object.assign(shopUrlField.url_cart, urlCart);
  }

  const isImportantLinksServiceConfigured = isServiceConfigured(field, ['shopRequiredDataRgpd_privacyPolicyUrl']) && isServiceConfigured(shopUrlField, ['url', 'url_cart']);
  const isEmailServiceConfigured = isServiceConfigured(field, ['shopRequiredDataEmail_senderEmail', 'shopRequiredDataEmail_senderName']);
  const isSmsServiceConfigured = isServiceConfigured(field, ['shopRequiredDataSms_senderName']);
  const isPushNotificationServiceConfigured = isServiceConfigured(
    field,
    [
      'shopRequiredDataPushNotification_textNotif',
      'shopRequiredDataPushNotification_fullTextButtonAccept',
      'shopRequiredDataPushNotification_fullTextButtonRefuse',
    ],
  );
  const isVoucherServiceConfigured = isServiceConfigured(
    field,
    [
      {
        property: 'shopRequiredDataCouponing_max_percentage_of_reduction',
        customVerification: (value: any) => parseInt(value, 10) > 0,
      },
      {
        property: 'shopRequiredDataCouponing_min_purchase_amount_for_discount',
        customVerification: (value: any) => parseInt(value, 10) > 0,
      },
    ],
  );

  let isSocialNetworkServiceConfigured = false;
  if (field.shopRequiredDataSocialNetworks_socialNetwork !== null
      && field.shopRequiredDataSocialNetworks_socialNetwork !== undefined
      && field.shopRequiredDataSocialNetworks_socialNetwork !== ''
  ) {
    const socialNetworkArray = unserialize(field.shopRequiredDataSocialNetworks_socialNetwork);
    isSocialNetworkServiceConfigured = socialNetworkArray.filter((network: string) => network !== 'googleplus').length;
  }

  const configuredServices = [];

  if (isImportantLinksServiceConfigured) {
    configuredServices.push('ImportantLinksServiceParameter');
  }
  if (isEmailServiceConfigured) {
    configuredServices.push('EmailServiceParameter');
  }
  if (isSmsServiceConfigured) {
    configuredServices.push('SmsServiceParameter');
  }
  if (isPushNotificationServiceConfigured) {
    configuredServices.push('PushNotificationServiceParameter');
  }
  if (isVoucherServiceConfigured) {
    configuredServices.push('VoucherServiceParameter');
  }
  if (isSocialNetworkServiceConfigured) {
    configuredServices.push('SocialNetworkServiceParameter');
  }

  return configuredServices;
};

export const getAIServiceParameter = () => {
  const aiServiceParameter = serviceParameters.find((serviceParameter) => serviceParameter.component === 'AIServiceParameter');
  if (aiServiceParameter) {
    return [aiServiceParameter];
  }
  return [];
};
