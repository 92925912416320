<template>
  <Dialog
    :visible="isChooseZipFileModalVisible"
    modal
    :header="t('templateBuilder.widgets.importZip')"
    :style="{ width: '35rem' }"
    :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
    :draggable="false"
    @update:visible="handleHideDialog"
  >
    <div class="flex flex-column justify-content-start gap-3 mx-2">
      <ZipFileUploader
        v-model="uploadExternalImage"
        @on-file-uploaded="handleZipFileUploaded"
        @on-file-removed="handleZipFileRemoved"
      />
      <div
        v-if="zipTemplate"
        class="flex justify-content-center"
      >
        <Button
          :label="t('templateSelector.buttons.chooseTemplate')"
          class="p-button-sm p-button-success"
          @click="handleChooseZipTemplate"
        />
      </div>
    </div>
  </Dialog>
</template>

<script lang="ts">
import {
  defineComponent,
  computed,
  ref,
  Ref,
} from 'vue';

import Button from 'primevue/button';
import Dialog from 'primevue/dialog';

import ZipFileUploader from '@/components/template-builder/fields/ZipFileUploader.vue';

import { useStore } from '@/store';

import { ZipFile } from '@/types/zip-file-uploader-types';

import { useI18n } from 'vue-i18n';

import { resetListeners } from '@/components/template-builder/utils/listeners';
import { createHistory } from '@/composables/template-editor/History';
import {
  resetActiveSection,
  updateSectionsInState,
  createHtmlTemplateFromZipFile,
  updateSectionCss,
  TemplateEditorState as state,
  htmlHasSmartlist,
} from '@/composables/template-editor/TemplateEditor';
import { UserState } from '@/composables/User';

import { refreshSmartProductList } from '@/components/template-builder/callbacks';

import { showToastError } from '@/helpers';

import { HistoryType } from '@/types';

export default defineComponent({
  name: 'ChooseZipFileModal',
  components: {
    Dialog,
    Button,
    ZipFileUploader,
  },

  setup() {
    const { t } = useI18n();
    const store = useStore();

    const idShop = ref(UserState.activeShop ? UserState.activeShop.id : 0);

    const uploadExternalImage = ref(0);

    const zipTemplate: Ref<ZipFile | null> = ref(null);
    const templateType = ref(state.template?.type);

    const isChooseZipFileModalVisible = computed(() => store.getters['liveEditor/getIsChooseZipFileModalVisible']);
    const getSelectedWidget = computed(() => store.getters['liveEditor/getSelectedWidget']);
    const selectedWidgetId = computed(() => store.getters['liveEditor/getSelectedWidgetId']);

    const handleHideDialog = async () => {
      getSelectedWidget.value('');

      // Update state
      updateSectionsInState();

      // Reset active section
      resetActiveSection();

      // Reset all listeners on template elements
      resetListeners();

      store.commit('liveEditor/setIsChooseZipFileModalVisible', false);
      store.commit('liveEditor/setSelectedWidget', null);
      store.commit('liveEditor/setSelectedWidgetId', '');
    };

    const handleZipFileUploaded = (zipFile: ZipFile) => {
      zipTemplate.value = zipFile;
    };

    const handleZipFileRemoved = () => {
      zipTemplate.value = null;
    };

    const handleChooseZipTemplate = async () => {
      if (zipTemplate.value) {
        try {
          store.commit('general/showTheSpinner');
          const { html, css } = await createHtmlTemplateFromZipFile(
            idShop.value,
            zipTemplate.value,
            templateType.value as string,
            uploadExternalImage.value,
            true,
            selectedWidgetId.value,
          );

          getSelectedWidget.value(html);

          updateSectionCss(css, selectedWidgetId.value);

          // Update state
          updateSectionsInState();

          // Create history entry
          createHistory(HistoryType.ADD_WIDGET);

          // Reset active section
          resetActiveSection();

          // Reset all listeners on template elements
          resetListeners();

          if (htmlHasSmartlist(html)) {
            // Refresh smart product list
            refreshSmartProductList(selectedWidgetId.value);
          }

          store.commit('liveEditor/setIsChooseZipFileModalVisible', false);
          store.commit('liveEditor/setSelectedWidget', null);
          store.commit('liveEditor/setSelectedWidgetId', '');
          store.commit('general/hideTheSpinner');
        } catch (error) {
          showToastError(t('templateBuilder.panels.fileManager.uploadError'));
          store.commit('general/hideTheSpinner');
        }
      }
    };

    return {
      t,
      zipTemplate,
      isChooseZipFileModalVisible,
      uploadExternalImage,

      handleHideDialog,
      handleZipFileUploaded,
      handleZipFileRemoved,
      handleChooseZipTemplate,
    };
  },
});
</script>
