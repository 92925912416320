<template>
  <div class="flex justify-content-between flex-wrap mb-3">
    <div class="flex align-items-center justify-content-center">
      <h3>
        {{ t('myShopData.customers.title') }}
      </h3>
    </div>
    <div class="flex flex-row-reverse">
      <div
        v-if="findPermission('my_data.synchro.execute')"
        class="flex align-items-center justify-content-center"
      >
        <Synchronization
          synchro-type="customers"
          @synchro-launched="synchroAction(true)"
        />
      </div>
      <div class="mr-2">
        <ShopsSyncsInProgress
          v-if="synchroData"
          :id-shop="idShop"
          :table-data="synchroData"
          :launch-fetch="launchSynchro"
          sync-type="customers,all"
        />
      </div>
      <div class="mr-2">
        <router-link
          :to="{ name: 'shop.data' }"
        >
          <Button class="p-button p-button-secondary">
            {{ t('return') }}
          </Button>
        </router-link>
      </div>
    </div>
  </div>

  <div class="grid">
    <div class="col-12 col-fixed customers">
      <SpmTable
        name="ShopsCustomers"
        index="id_shop_customer"
        :persistent-filters="persistentFilters"
        :table-columns="columns"
        :id-shop="idShop"
        :custom-selector="true"
        :show-export-menu-item="findPermission('my_data.export')"
        grouped-actions-key="id_shop_customer"
        :export-file-name="t('myShopData.customers.title')"
      >
        <template #id_shop_customer="slotsProp">
          <Button
            :key="slotsProp.data.id_shop_customer"
            class="p-button-outlined p-button-rounded p-button-sm"
            icon="far fa-ellipsis-v"
            @click="anonymize(slotsProp.data)"
          />
        </template>
        <template #first_name="slotProps">
          <div v-if="!slotProps.data.deleted">
            {{ slotProps.data.first_name }}
          </div>
          <div v-else>
            <i
              v-tooltip="{ value: t('myShopData.orders.anonUserInfo'),
                           class: 'tooltip-icons-outside' }"
              class="fa-regular fa-mask"
            />
          </div>
        </template>
        <template #last_name="slotProps">
          <div v-if="!slotProps.data.deleted">
            {{ slotProps.data.last_name }}
          </div>
          <div v-else>
            <i
              v-tooltip="{ value: t('myShopData.orders.anonUserInfo'),
                           class: 'tooltip-icons-outside' }"
              class="fa-regular fa-mask"
            />
          </div>
        </template>
        <template #email="slotProps">
          <div v-if="!slotProps.data.deleted">
            <DataExplorerLink
              :value="slotProps.data.email"
              :id="slotProps.data.id_shop_customer"
              :show-icon="true"
            />
          </div>
          <div v-else>
            <i
              v-tooltip="{ value: t('myShopData.orders.anonUserInfo'),
                           class: 'tooltip-icons-outside' }"
              class="fa-regular fa-mask"
            />
          </div>
        </template>
        <template #gender="props">
          <div v-if="props.data.gender==='0'">
            <i class="fa-solid fa-question" />
          </div>
          <div v-else-if="props.data.gender==='1'">
            M
          </div>
          <div v-else>
            F
          </div>
        </template>
        <template #birthday="props">
          {{ props.data.birthday ? props.data.birthday.split(' ')[0] : '' }}
        </template>
        <template #newsletter="slotProps">
          <div v-if="slotProps.data.newsletter === '2'">
            <i
              class="far fa-check newsletter-2"
            />
          </div>
          <div v-else-if="slotProps.data.newsletter === '1'">
            <i
              class="far fa-check newsletter-1"
            />
          </div>
          <div v-else>
            <i
              class="far fa-ban"
            />
          </div>
        </template>
      </SpmTable>
    </div>
  </div>
</template>

<script lang="ts">
import {
  computed, defineComponent, onMounted, ref,
} from 'vue';
import { useI18n } from 'vue-i18n';
import SpmTable from '@/components/table/SpmTable.vue';
import {
  IntervalDateEnum,
  SpmTableColumns, SpmTableFilter, SpmTableFilterOption, SpmTableState,
} from '@/types';
import { OperatorType, ShopsCustomers, ShopsSyncs } from '@/types/generated-types/graphql';
import { findPermission, UserState } from '@/composables/User';
import Synchronization from '@/components/header/Synchronization.vue';
import ShopsSyncsInProgress from '@/components/header/ShopsSyncsInProgress.vue';
import { ListResult } from '@/composables/GraphQL';
import ShopsSyncsList from '@/composables/shop/ShopSyncs';
import Button from 'primevue/button';
import { newsletterTypeEnum } from '@/composables/shop/ShopsLists';
import { getShopCustomerLanguages } from '@/types/country-language-options';
import { FilterMatchMode } from 'primevue/api';
import Tooltip from 'primevue/tooltip';
import DataExplorerLink from '@/components/data-explorer/fields/DataExplorerLink.vue';

export default defineComponent({
  name: 'Customers',
  components: {
    Synchronization,
    SpmTable,
    ShopsSyncsInProgress,
    Button,
    DataExplorerLink,
  },

  directives: {
    tooltip: Tooltip,
  },

  setup() {
    const { t } = useI18n();
    const synchroData = ref<ListResult<ShopsSyncs>>();
    const idShop = UserState.activeShop?.id ?? 0;
    const launchSynchro = ref(false);

    const newsletterOptions: SpmTableFilterOption[] = Object.values(newsletterTypeEnum)
      .map((key: string) => ({ value: key, label: t(`myLists.manage.customers.optin.${key}`) }));

    const persistentFilters = ref<SpmTableFilter[]>([
      {
        field: 'sc.id_shop',
        value: idShop,
        operator: OperatorType.Equals,
      },
    ]);

    const genderList = [
      { value: '0', label: t('gender.unknown') },
      { value: '1', label: t('gender.male') },
      { value: '2', label: t('gender.female') },
    ];

    const columns: SpmTableColumns[] = [
      {
        field: 'id_customer_shop',
        header: t('id'),
        sortable: true,
        filterable: true,
        editable: false,
        style: '',
        type: 'text',
        prefix: 'sc',
      },
      {
        field: 'first_name',
        header: t('myShopData.customers.headers.first_name'),
        sortable: true,
        filterable: true,
        editable: false,
        style: '',
        type: 'text',
        prefix: 'sc',
      },
      {
        field: 'last_name',
        header: t('myShopData.customers.headers.last_name'),
        sortable: true,
        filterable: true,
        editable: false,
        style: '',
        type: 'text',
        prefix: 'sc',
      },
      {
        field: 'gender',
        header: t('myShopData.customers.headers.gender'),
        sortable: true,
        filterable: true,
        editable: false,
        style: '',
        type: 'text',
        filterSettings: {
          type: 'multiSelect',
          options: genderList,
          defaultMatchMode: FilterMatchMode.EQUALS,
          hideFilterMenu: true,
          showFilterInput: false,
        },

        prefix: 'sc',
      },
      {
        field: 'email',
        header: t('myShopData.customers.headers.email'),
        sortable: true,
        filterable: true,
        editable: false,
        style: '',
        type: 'text',
        prefix: 'sc',
      },
      {
        field: 'birthday',
        header: t('myShopData.customers.headers.birthday'),
        sortable: true,
        filterable: true,
        editable: false,
        style: '',
        type: 'text',
        filterSettings: { type: 'date', hideFilterMenu: true, customOptionsItems: [IntervalDateEnum.CUSTOM_DATE_RANGE] },
        prefix: 'sc',
      },
      {
        field: 'date_modification',
        header: t('myShopData.customers.headers.date_modification'),
        sortable: true,
        filterable: true,
        editable: false,
        style: '',
        type: 'text',
        filterSettings: { type: 'date', hideFilterMenu: true },
        prefix: 'sc',
      },
      {
        field: 'newsletter',
        header: t('myShopData.customers.headers.newsletter'),
        sortable: true,
        filterable: true,
        editable: false,
        style: '',
        type: 'text',
        filterSettings: {
          type: 'multiSelect', options: newsletterOptions, hideFilterMenu: true, showFilterInput: false,
        },

        prefix: 'sc',
      },
      {
        field: 'country',
        header: t('myShopData.customers.headers.country'),
        sortable: true,
        filterable: true,
        editable: false,
        style: 'width:5%',
        type: 'flag',
        filterSettings: { type: 'country', hideFilterMenu: true },
        prefix: 'sc',
      },
      {
        field: 'lang',
        header: t('myShopData.customers.headers.lang'),
        sortable: true,
        filterable: true,
        editable: false,
        style: 'width:5%',
        type: 'language',
        filterSettings: { type: 'language', options: getShopCustomerLanguages, hideFilterMenu: true },
        prefix: 'sc',
      },
      {
        field: 'active',
        header: t('myShopData.customers.headers.active'),
        sortable: true,
        filterable: true,
        editable: false,
        style: 'width:5%',
        type: 'active-status-icon',
        filterSettings: {
          type: 'multiSelect',
          options: [{ value: 1, label: t('active') }, { value: 0, label: t('inactive') }],
          hideFilterMenu: true,
          showFilterInput: false,
        },

        prefix: 'sc',
      },
      {
        field: 'id_shop_customer',
        header: '',
        sortable: false,
        filterable: false,
        editable: false,
        style: '',
        type: 'text',
        prefix: 'sc',
        hidden: true,
      },

      {
        field: 'deleted',
        header: '',
        sortable: false,
        filterable: false,
        editable: true,
        hidden: true,
        style: '',
        type: 'text',
      },
    ];

    const anonymize = (data: ShopsCustomers) => {
      //console.log({ anonymize: data });
    };

    onMounted(async () => {
      synchroData.value = await ShopsSyncsList('customers,all', idShop, 'user');
    });

    const synchroAction = (launch: boolean) => {
      launchSynchro.value = launch;
    };

    return {
      t,
      idShop,
      columns,
      anonymize,
      findPermission,
      synchroData,
      launchSynchro,
      synchroAction,
      persistentFilters,
    };
  },
});
</script>
