// eslint-disable-next-line import/no-cycle
import { getTemplateIframeDocument } from '../utils/helpers';

/* eslint-disable import/prefer-default-export */
export const getLinkType = (selector: string): string => {
  const template = getTemplateIframeDocument();
  const element: HTMLElement|null = template?.querySelector(selector)?.firstElementChild as HTMLElement;
  if (element && element.tagName === 'A') {
    return element.getAttribute('href') ?? '';
  }
  if (element && ['H1', 'H2', 'H3', 'H4'].includes(element.tagName)) {
    const element2: HTMLElement|null = element?.firstElementChild as HTMLElement;
    if (element2 && element2.tagName === 'A') {
      return element2.getAttribute('href') ?? '';
    }
  }
  return '';
};
