import { AxiosError } from 'axios';
import ToastEventBus from 'primevue/toasteventbus';

// eslint-disable-next-line import/no-cycle
import { ToastOptions } from '@/types';
import { i18n } from '@/i18n';
import sha1 from 'sha1';
import { SHOPIMIND_SHA1_SALT } from '@/configs';

export function getErrorMessage(error: AxiosError): string {
  if (error.response) {
    return error.response.data.message;
  }

  if (error.request) {
    return 'GENERIC_ERROR';
  }

  return error.message;
}

export const ToastService = {
  add: (options: ToastOptions): void => {
    ToastEventBus.emit('add', options);
  },
};

export async function showToastError(errorMessage: string, life = 3000): Promise<void> {
  const { global: { t } } = await i18n;

  ToastService.add({
    severity: 'error',
    summary: t('errorToastSummary'),
    detail: t(`errorMessages.${errorMessage}`, errorMessage),
    life,
  });
}

export async function showToastSuccess(successMessage: string, life = 3000): Promise<void> {
  const { global: { t } } = await i18n;

  ToastService.add({
    severity: 'success',
    detail: t(`successMessages.${successMessage}`, successMessage),
    life,
  });
}

export async function showToast(severity: string, message: string, life = 3000): Promise<void> {
  const { global: { t } } = await i18n;

  ToastService.add({
    severity: severity as 'info' | 'success' | 'warn' | 'error',
    detail: t(message),
    life,
  });
}

export function getEnumKeyByStringValue<T extends {[index: string]: string}>(theEnum: T, stringValue: string): keyof T {
  const keys = Object.keys(theEnum).filter((x) => theEnum[x] === stringValue);
  return keys.length > 0 ? keys[0] : '';
}

export function asInt(value: string | number, def = 0): number {
  let returnValue;

  try {
    if (typeof value === 'number') {
      returnValue = value;
    } else {
      returnValue = value && value !== '' ? parseInt(value, 10) : def;
    }
  } catch (e) {
    returnValue = def;
  }

  return returnValue;
}

export function asFloat(value: string | number, def = 0): number {
  let returnValue;

  try {
    if (typeof value === 'number') {
      returnValue = value;
    } else {
      returnValue = value && value !== '' ? parseFloat(value) : def;
    }
  } catch (e) {
    returnValue = def;
  }

  return returnValue;
}

export function fmod(a: number, b: number) {
  return Number((a - (Math.floor(a / b) * b)).toPrecision(8));
}

export const nl2br = (str: string, is_xhtml = false) => {
  const breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
  return (`${str}`).replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, `$1${breakTag}$2`);
};

export const br2nl = (str: string) => str.replace(/<br\s*\/?>\s/mg, '\n');

export const generateSha1Hash = (key: any): string => sha1(`${SHOPIMIND_SHA1_SALT}${key.toString()}`);

export const countCharactersInUtf8String = (str: string): number => str.replace(/[\u0080-\u10FFFF]/g, 'x').length;

export const htmlEntityDecode = (html: string): string => {
  const txt = document.createElement('textarea');
  txt.innerHTML = html;
  return txt.value;
};

/**
 * Create DOM element from HTML string
 * @param html
 */
export const createDomFromString = (html: string): Document => (new DOMParser()).parseFromString(html, 'text/html');

/**
 * Clean empty values of an object
 * @param obj
 */
export const deleteEmptyPropsOfObject = (obj: any) => Object.keys(obj).forEach((k) => {
  if (!obj[k] || obj[k] === undefined || (Array.isArray(obj[k]) && obj[k].length === 0)) {
    // eslint-disable-next-line no-param-reassign
    delete obj[k];
  } else if (Array.isArray(obj[k])) {
    obj[k].forEach((k2: any) => deleteEmptyPropsOfObject(k2));
  }
});

export const getParents = (elem: any, selector: string | null = null) => {
  const parents = [];
  const isClassNameRegExp = new RegExp('^\\.');
  while (elem.parentNode && elem.parentNode.nodeName.toLowerCase() !== 'body') {
    if (
      !selector
      || (!isClassNameRegExp.test(selector) && elem.parentNode.nodeName.toLowerCase() === selector)
      || (isClassNameRegExp.test(selector) && elem.parentNode.classList.contains(selector.replace(isClassNameRegExp, '')))
    ) {
      parents.push(elem.parentNode);
    }

    // eslint-disable-next-line no-param-reassign
    elem = elem.parentNode;
  }

  return parents;
};

export const getFirstParent = (elem: any, selector: string | null = null) => {
  let parent;
  const isClassNameRegExp = new RegExp('^\\.');
  while (elem.parentNode && elem.parentNode.nodeName.toLowerCase() !== 'body') {
    if (
      !selector
      || (!isClassNameRegExp.test(selector) && elem.parentNode.nodeName.toLowerCase() === selector)
      || (isClassNameRegExp.test(selector) && elem.parentNode.classList.contains(selector.replace(isClassNameRegExp, '')))
    ) {
      parent = elem.parentNode;
      break;
    }

    // eslint-disable-next-line no-param-reassign
    elem = elem.parentNode;
  }

  return parent;
};

export const setActiveMenubarItem = (el: any) => {
  if (el.classList.contains('p-menuitem-text') || el.classList.contains('p-menuitem-link')) {
    // We get the current active element to remove the class
    const menubar = getParents(el, '.menubar-container')[0];
    const activeItems: HTMLElement[] = menubar.querySelectorAll('.current-active-menu, [active-item="true"]');

    // Active class and property removal on previous active item
    Array.from(activeItems ?? []).forEach((activeItem) => {
      activeItem.classList.remove('current-active-menu');
      activeItem.removeAttribute('active-item');
    });

    // We find the highest LI parent
    const parents = getParents(el, '.p-menuitem');
    const highestParent = parents[parents.length - 1];
    highestParent.setAttribute('active-item', 'true');
  }
};

/**
 * Convert rgb(a) string to hex
 * @param rgb
 */
export const rgbToHex = (rgb: string): string => {
  const colorCodes = (new RegExp('^rgba?\\(([0-9]+),\\s?([0-9]+),\\s?([0-9]+)')).exec(rgb);

  if (colorCodes) {
    return `#${[colorCodes[1], colorCodes[2], colorCodes[3]].map((x) => {
      const y = parseInt(x, 10);
      const hex = y.toString(16);
      return hex.length === 1 ? `0${hex}` : hex;
    }).join('')}`;
  }

  return '';
};

/**
 * Remove placeholder class
 * @param htmlElement Html Element
 */
export function removePlaceholderClass(htmlElement: HTMLElement) {
  const elements = document.querySelectorAll('.emptyColumnSlot');
  elements.forEach((element) => {
    element.classList.remove('chosenSlot');
    element.classList.remove('notCompatibleSlot');
  });
  htmlElement.classList.remove('placeholder-on-add-widget', 'placeholder-on-add-widget--disallowed', 'm-4');
  htmlElement.classList.remove('placeholder-on-add-widget', 'placeholder-on-add-widget--allowed', 'm-4');
}

export function trimExcessSpaces(str: string) {
  let result = str.replace(/\u00A0/g, '\0');

  result = result.trim();
  return result.replace(/\0/g, '\u00A0');
}
