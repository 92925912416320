
// eslint-disable-next-line max-classes-per-file
import {
  defineComponent,
  onMounted,
  onUpdated,
  PropType,
  Ref,
  ref,
  SetupContext,
} from 'vue';

import { useI18n } from 'vue-i18n';

import Editor from 'primevue/editor';
import EmojisButton from '@/components/fields/partials/EmojisButton.vue';
import VariablesButton from '@/components/fields/partials/VariablesButton.vue';
import Quill from 'quill';
import CustomColorPicker from '@/components/fields/partials/CustomColorPicker.vue';
import OverlayPanel from 'primevue/overlaypanel';

import { ColorFormatEnum, Translation } from '@/types';

// eslint-disable-next-line import/no-cycle
import {
  getTranslationLabel,
} from '@/components/template-builder/utils/helpers';

import { Variable } from '../template-builder/utils/variables-list';

export default defineComponent({
  name: 'BaseWysiwygEditor',
  components: {
    OverlayPanel,
    CustomColorPicker,
    VariablesButton,
    EmojisButton,
    Editor,
  },

  props: {
    modelValue: {
      type: String,
      required: true,
    },

    variablesList: {
      type: Array as PropType<Variable[]>,
      required: false,
      default: () => [],
    },

    translation: {
      type: Object as PropType<Translation>,
      required: false,
      default: null,
    },

  },

  emits: {
    'update:modelValue': String,
    'activate-group': String,
    'deactivate-group': String,
  },

  setup(props, { emit }: SetupContext) {
    const { t } = useI18n();

    // this part is mandatory to use inline styles instead of Quill classes (problems could occur if we use classes in emails)
    const DirectionAttribute = Quill.import('attributors/attribute/direction');
    Quill.register(DirectionAttribute, true);
    const AlignStyle = Quill.import('attributors/style/align');
    Quill.register(AlignStyle, true);
    const BackgroundStyle = Quill.import('attributors/style/background');
    Quill.register(BackgroundStyle, true);
    const ColorStyle = Quill.import('attributors/style/color');
    Quill.register(ColorStyle, true);
    const DirectionStyle = Quill.import('attributors/style/direction');
    Quill.register(DirectionStyle, true);
    const FontStyle = Quill.import('attributors/style/font');
    Quill.register(FontStyle, true);
    const SizeStyle = Quill.import('attributors/style/size');
    Quill.register(SizeStyle, true);

    // Prevent Quill from updating <br> to ''
    const Parchment = Quill.import('parchment');
    const Break = Quill.import('blots/break');
    class FixedBreak extends Break {
      insertInto(parent: any, reference: any) {
        Parchment.Embed.prototype.insertInto.call(this, parent, reference);
      }

      // eslint-disable-next-line class-methods-use-this
      length() {
        return 1;
      }

      // eslint-disable-next-line class-methods-use-this
      value() {
        return '\n';
      }
    }
    Quill.register(FixedBreak, true);

    // Keep all attributes in block element like "p"
    const Block = Quill.import('blots/block');
    class FixedBlock extends Block {
      static create(value: any) {
        const node = super.create(value);
        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        for (const key in value) {
          node.setAttribute(key, value[key]);
        }
        return node;
      }

      static formats(domNode: any) {
        const attributes: Record<string, any> = {};
        // eslint-disable-next-line no-restricted-syntax
        for (const attr of domNode.attributes) {
          attributes[attr.name] = attr.value;
        }
        return attributes;
      }
    }
    FixedBlock.allowedChildren.push(FixedBreak);

    FixedBlock.blotName = 'fixed_block';
    Quill.register(FixedBlock, true);

    // Keep all attributes in inline element like "a"
    const Inline = Quill.import('blots/inline');
    class FixedInline extends Inline {
      static create(value: any) {
        const node = super.create(value);
        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        for (const key in value) {
          node.setAttribute(key, value[key]);
        }
        return node;
      }

      static formats(domNode: any) {
        const attributes: Record<string, any> = {};
        // eslint-disable-next-line no-restricted-syntax
        for (const attr of domNode.attributes) {
          attributes[attr.name] = attr.value;
        }
        return attributes;
      }
    }

    FixedInline.blotName = 'fixed_inline';
    Quill.register(FixedInline, true);

    const inputValue: Ref<string> = ref('');
    const editorRef = ref();
    const overlayPanelRef = ref();
    const overlayPanelBackgroundRef = ref();
    const colorFormat: Ref<string> = ref(ColorFormatEnum.HEX);

    const handleValueChange = () => {
      inputValue.value = editorRef.value.quill.root.innerHTML;
      emit('update:modelValue', inputValue.value);
    };

    const insertContent = (value: string | number | undefined) => {
      if (editorRef.value && editorRef.value.quill) {
        editorRef.value.quill.insertText(editorRef.value.quill.selection.savedRange.index, value);
        handleValueChange();
      }
    };

    const toggleColorPicker = (event: MouseEvent) => {
      overlayPanelRef.value.toggle(event);
    };

    const toggleColorPickerBackground = (event: MouseEvent) => {
      overlayPanelBackgroundRef.value.toggle(event);
    };

    const changeColor = (color: string) => {
      editorRef.value.quill.format('color', color);
      handleValueChange();
    };

    const changeColorBackground = (color: string) => {
      editorRef.value.quill.format('background-color', color);
      handleValueChange();
    };

    onMounted(() => {
      if (editorRef.value && editorRef.value.quill) {
        editorRef.value.quill.disable();
      }

      inputValue.value = props.modelValue;
    });

    onUpdated(() => {
      if (editorRef.value && editorRef.value.quill) {
        editorRef.value.quill.enable();
      }
    });

    const Delta = Quill.import('delta');
    const lineBreakMatcher = () => {
      const newDelta = new Delta();
      newDelta.insert({ break: '' });
      return newDelta;
    };

    // Update Quill behavior when using 'enter' key
    const handleEnter = (range: any, context: any) => {
      const nextCharacter = editorRef.value.quill.getText(range.index + 1, 1);
      editorRef.value.quill.insertEmbed(range.index, 'break', true, 'user');
      if (nextCharacter.length === 0) {
        editorRef.value.quill.insertEmbed(range.index, 'break', true, 'user');
      }
      editorRef.value.quill.setSelection(range.index + 1, Quill.sources.SILENT);
    };

    const quillModules = ref({
      clipboard: {
        matchVisual: false,
        matchers: [
          ['BR', lineBreakMatcher], // Quill use clipboard when initializing the first value
        ],
      },
      keyboard: {
        bindings: {
          handleEnter: {
            key: 13,
            handler: handleEnter,
          },
          linebreak: {
            key: 13,
            shiftKey: true,
            handler: handleEnter,
          },
        },
      },
    });

    const focus = () => {
      if (editorRef.value && editorRef.value.quill) {
        editorRef.value.quill.blur();
        editorRef.value.quill.focus();
      }
    };

    const handleEditorLoaded = () => {
      editorRef.value.quill.root.addEventListener('blur', () => {
        if (props.translation) {
          emit('deactivate-group', props.translation.groupKey);
        }
      });
      editorRef.value.quill.root.addEventListener('focus', () => {
        if (props.translation) {
          emit('activate-group', props.translation.groupKey);
        }
      });
    };

    return {
      t,
      inputValue,
      editorRef,
      overlayPanelRef,
      overlayPanelBackgroundRef,
      handleValueChange,
      insertContent,
      toggleColorPicker,
      toggleColorPickerBackground,
      changeColor,
      changeColorBackground,
      colorFormat,
      quillModules,
      focus,
      getTranslationLabel,
      handleEditorLoaded,
    };
  },
});
