
import {
  computed,
  ComputedRef,
  defineComponent,
  PropType,
  ref,
  watch,
} from 'vue';
import { useI18n } from 'vue-i18n';

import {
  ConfigurationPanelStructure,
  HistoryType,
  ParserObject,
  TabItem,
  TemplateStructureEnum,
} from '@/types';
import {
  addActiveItemElementDecorations,
  setActiveItemData,
} from '@/components/template-builder/utils/active-item-builder';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Panel from 'primevue/panel';
import {
  setChangeInLeftToolbar,
  TemplateEditorState,
  hideLeftToolbar, updateSectionsInState,
} from '@/composables/template-editor/TemplateEditor';
import { store } from '@/store';
import { checkIfSavePointNeeded, createHistory } from '@/composables/template-editor/History';
import ConfigurationPanelTabContent from './ConfigurationPanelTabContent.vue';

export default defineComponent({
  name: 'ConfigurationPanel',

  components: {
    ConfigurationPanelTabContent,
    TabView,
    TabPanel,
    Panel,
  },

  props: {
    structureConfig: {
      type: Object as PropType<ConfigurationPanelStructure>,
      required: true,
    },

    parserValues: {
      type: Object as PropType<ParserObject>,
      required: true,
    },

    isClosable: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  setup(props) {
    const { t } = useI18n();
    const tabItems = computed(() => props.structureConfig.items);
    const activeTabItemIndex = ref(0);
    const activeTabItem: ComputedRef<TabItem> = computed(() => tabItems.value[activeTabItemIndex.value] || tabItems.value[0]);
    const leftToolbarHasChanged = computed(() => TemplateEditorState.leftToolbar.hasChanged);
    const currentActiveItemData = computed(() => store.getters['liveEditor/getActiveItemData']);
    checkIfSavePointNeeded();
    const changeLiveEditorActiveItem = async () => {
      // Create history point if changes made in left panel before switching to other tab
      if (leftToolbarHasChanged.value) {
        if (currentActiveItemData.value) {
          let historyType: HistoryType | null;
          switch (currentActiveItemData.value.type) {
            case TemplateStructureEnum.WIDGET:
              historyType = HistoryType.WIDGET_CHANGE;
              break;
            case TemplateStructureEnum.COLUMN:
              historyType = HistoryType.COLUMN_CHANGE;
              break;
            case TemplateStructureEnum.LINE:
              historyType = HistoryType.ROW_CHANGE;
              break;
            case TemplateStructureEnum.SECTION:
              historyType = HistoryType.SECTION_CHANGE;
              break;
            default:
              historyType = null;
              break;
          }

          if (historyType) {
            // Update state
            updateSectionsInState();

            createHistory(historyType);
          }
        }

        setChangeInLeftToolbar(false);
      }

      const activeItemData = {
        selector: props.parserValues[activeTabItem.value.label].selector || '',
        type: activeTabItem.value.tabItemType,
      };

      setActiveItemData(activeItemData);

      if (activeItemData.type === TemplateStructureEnum.SECTION || activeItemData.type === TemplateStructureEnum.WIDGET) {
        addActiveItemElementDecorations(activeItemData);
      }
    };

    watch([
      () => props.parserValues,
      () => props.structureConfig,
    ],
    () => {
      if (activeTabItemIndex.value !== 0) {
        activeTabItemIndex.value = 0;
      }
      changeLiveEditorActiveItem();
    });

    watch(activeTabItemIndex, () => {
      changeLiveEditorActiveItem();
    }, { immediate: true });

    return {
      t,
      tabItems,
      activeTabItemIndex,
      activeTabItem,
      hideLeftToolbar,
    };
  },
});
