// eslint-disable-next-line import/no-cycle
import { List } from '@/composables/GraphQL';
// eslint-disable-next-line import/no-cycle
import {
  OperatorType, ShopsBills,
} from '@/types/generated-types/graphql';
// eslint-disable-next-line import/no-cycle
import { SpmTableFilter } from '@/types';

export async function getLastUnpaidBill(idShop: number) {
  const filters: SpmTableFilter[] = [
    { field: 'id_shop', value: idShop, operator: OperatorType.Equals },
    { field: 'valid', value: 1, operator: OperatorType.Equals },
    { field: 'payment_error', value: 2, operator: OperatorType.Equals },
  ];

  return List<ShopsBills>({
    name: 'ShopsBills',
    settings: {
      limit: 1,
      offset: 0,
      order: [],
      filter: filters,
    },
    fields: [
      'id_ps_bundle',
    ],
  });
}

export async function getShopsBill(idPsBundle: string, fields: string[]) {
  const filters: SpmTableFilter[] = [
    { field: 'id_ps_bundle', value: idPsBundle, operator: OperatorType.Equals },
  ];

  return List<ShopsBills>({
    name: 'ShopsBills',
    settings: {
      limit: 1,
      offset: 0,
      order: [],
      filter: filters,
    },
    fields,
  });
}
