// eslint-disable-next-line import/no-cycle
import {
  StringMap,
  TemplateParentTypeEnum,
} from '@/types';

export const DEFAULT_CURRENCY = 'EUR';

export const TEMPLATE_SECTION_CONTENT_ID_SUFFIX = '_content';
export const ACTIONS_BUTTON_GROUP = 'actions-buttons-group';
export const TEMPLATE_SECTION_IDENTIFIER_PREFIX = 'spm_section_';
export const TEMPLATE_SECTION_IDENTIFIER = `[id^=${TEMPLATE_SECTION_IDENTIFIER_PREFIX}][class^=spm_section]`;
export const TEMPLATE_SECTION_CLASS = 'spm_section';
export const TEMPLATE_SECTION_CONTENT_IDENTIFIER = `[id^=${TEMPLATE_SECTION_IDENTIFIER_PREFIX}][id$=${TEMPLATE_SECTION_CONTENT_ID_SUFFIX}]`;
export const TEMPLATE_LINE_IDENTIFIER_PREFIX = 'spm_row_';
export const TEMPLATE_LINE_IDENTIFIER = `[id^=${TEMPLATE_LINE_IDENTIFIER_PREFIX}]`;
export const TEMPLATE_LINE_CLASS = '.spm_draggable_row';
export const TEMPLATE_COLUMN_IDENTIFIER_PREFIX = 'spm_column';
// multiple selectors here (some columns does not have ids)
export const TEMPLATE_COLUMN_IDENTIFIER = `[id^=${TEMPLATE_COLUMN_IDENTIFIER_PREFIX}], .${TEMPLATE_COLUMN_IDENTIFIER_PREFIX}`;
export const TEMPLATE_WIDGET_IDENTIFIER_PREFIX = 'spm_widget_';
export const TEMPLATE_WIDGET_IDENTIFIER = `[id^=${TEMPLATE_WIDGET_IDENTIFIER_PREFIX}]`;
export const TEMPLATE_PAGE_IDENTIFIER = 'spm_body';
export const TEMPLATE_CONTENT_IDENTIFIER = '#spm_content';
export const TEMPLATE_TRANSLATE_SPECIFIC = 'spm_translate_specific';
export const TEMPLATE_TRANSLATE_SPECIFIC_ITEMS = 'spm_translate_specific_items';
export const TEMPLATE_TRANSLATE_SPECIFIC_ONLY_ATTRIBUTE = 'spm_translate_specific_only_attribute';
export const TEMPLATE_TRANSLATE_SPECIFIC_ONLY_CONTENT = 'spm_translate_specific_only_content';
export const TEMPLATE_TRANSLATE_SPECIFIC_HTML_CONTENT = 'spm_translate_specific_html_content';
export const COLUMN_WITH_PLACEHOLDER_CLASS = 'spm_column_with_placeholder';
export const WIDGETS_PLACEHOLDER_IDENTIFIER = 'spm_draggable_widget_placeholder';
export const WIDGET_IMPORT_ZIP_PLACEHOLDER_IDENTIFIER = '{spm_widget_import_zipplaceholder}';
export const MAX_COLUMN_COUNT = 5;
export const MAX_LINES_COUNT = 10;
export const MAX_SECTIONS_COUNT = 10;
export const NEW_SECTION_ID_PREFIX = 'SECTION_ADD_';
export const CUSTOM_WIDGET_CSS_PREFIX = 'custom_widget_';

export const TEMPLATE_STRUCTURE_MAP: StringMap = {
  section: TEMPLATE_SECTION_IDENTIFIER,
  sectionContent: TEMPLATE_SECTION_CONTENT_IDENTIFIER,
  line: TEMPLATE_LINE_IDENTIFIER,
  column: TEMPLATE_COLUMN_IDENTIFIER,
  widget: TEMPLATE_WIDGET_IDENTIFIER,
};

export const TEMPLATE_ITEM_IDENTIFIER_PREFIX_MAP: StringMap = {
  section: TEMPLATE_SECTION_IDENTIFIER_PREFIX,
  sectionContent: TEMPLATE_SECTION_IDENTIFIER_PREFIX,
  line: TEMPLATE_LINE_IDENTIFIER_PREFIX,
  column: TEMPLATE_COLUMN_IDENTIFIER_PREFIX,
  widget: TEMPLATE_WIDGET_IDENTIFIER_PREFIX,
};

export const SAVED_SECTIONS_KEY = 'spm.saved-sections';
export const COLOR_PICKER_KEY = 'spm.color-picker';

export const SAVE_BUTTON_IDENTIFIER = 'save-button-identifier';
export const DUPLICATE_BUTTON_IDENTIFIER = 'duplicate-button-identifier';
export const REMOVE_BUTTON_IDENTIFIER = 'remove-button-identifier';

export const AI_BUTTON_IDENTIFIER = 'ai-button-identifier';

export const ANIMATION_SPEED = 0;

export const LINE_DROPPABLE_CLASS = '.spm_droppable_row';
export const COLUMN_DROPPABLE_CLASS = '.columns';
export const WIDGET_DROPPABLE_CLASS = '.spm_droppable_widget';
export const WIDGET_DROPPABLE_IDENTIFIER = 'spm_droppable_widget';
export const WIDGET_DRAGGABLE_CLASS = '.spm_draggable_widget';
export const WIDGET_DRAGGABLE_IDENTIFIER = 'spm_draggable_widget';
export const BUILDER_ELEMENT_HOVER_CLASS = 'spm_hover';
export const BUILDER_ELEMENT_ACTIVE_CLASS = 'spm_active_element';
export const BUILDER_SORTABLE_DRAG_CLASS = 'spm_sortable_drag';
export const BUILDER_SORTABLE_CHOSEN_CLASS = 'spm_sortable_chosen';
export const BUILDER_SORTABLE_GHOST_CLASS = 'spm_sortable_ghost';
export const BUILDER_SORTABLE_PLACEHOLDER_HOVER_CLASS = 'spm_sortable_hover';
export const TRANSLATION_MARKUP = 'spmtranslation';
export const TRANSLATION_ATTRIBUTE = 'data-spmtranslationid';

export const PERCENTAGE_WIDTH_ATTRIBUTE = 'data-percentagewidth';
export const BUILDER_EMBED_TEMPLATE_CONTAINERS = 'body, header, footer, div, span, ul, li, ol, p, table, td, blockquote, a, section';
export const BUILDER_EMBED_CONTAINER_HOVER_CLASS = 'spm_hover_element';
export const BUILDER_EMBED_REPLACED_ELEMENT_CLASS = 'spm_replace';
export const BUILDER_SELECTOR_DISPLAY_DELETABLE = '#acb-banner, .gdprModal';
export const POPIN_AUTO_CLOSE_CLASS = 'spm_auto_close';
export const POPIN_AUTO_CLOSE_ATTRIBUTE = 'data-spm_auto_close';

export const MEDIA_URL_PART_THUMB_GENERATION = 'https://media.shopimind.io/resize/index.php?src=http://media.shopimind.io/img/templates_preview_v3/part_{SHA1_KEY}.png&w=410';
export const MEDIA_URL_PART_RELOAD_CACHE = 'https://media.shopimind.io/img/templates_preview_v3/part_{SHA1_KEY}-reloadcache.png';

interface ProductsListsClasses {
  [key: string]: string;
}

export const PRODUCTS_LISTS_CLASSES: ProductsListsClasses = {
  [TemplateParentTypeEnum.EMAIL]: 'spm_smart_products_list',
  [TemplateParentTypeEnum.POPUP]: 'spm_smart_products_list',
  [TemplateParentTypeEnum.EMBED]: 'spm_smart_products_list',
  [TemplateParentTypeEnum.FACEBOOKMESSENGER]: 'spm_fb_smartlist',
};

export const BORDERS_PROPERTIES_TO_REMOVE_IF_ZERO = [
  'border-color',
  'border-style',
  'border-bottom-style',
  'border-bottom-color',
  'border-bottom-width',
  'border-top-style',
  'border-top-color',
  'border-top-width',
  'border-left-style',
  'border-left-color',
  'border-left-width',
  'border-right-style',
  'border-right-color',
  'border-right-width',
];

export const LINE_HEIGHT_POSSIBLE_VALUES = [
  { label: 'templateBuilder.fields.lineHeight.normal', value: '1' },
  { label: '1.1', value: '1.1' },
  { label: '1.3', value: '1.3' },
  { label: '1.5', value: '1.5' },
  { label: '1.75', value: '1.75' },
  { label: 'templateBuilder.fields.lineHeight.double', value: '2' },
  { label: 'templateBuilder.fields.lineHeight.triple', value: '3' },
];

export const VARIABLES_V3_TO_V4 = [
  {
    oldVariable: '{SITE_URL_W_HTTP}',
    newVariable: '{var=shop.url https=false}',
  },
  {
    oldVariable: '{PRIVACY_POLICY_URL}',
    newVariable: '{var=shop.privacy_policy_url}',
  },
  {
    oldVariable: '{DATA_MANAGER_EMAIL}',
    newVariable: '{var=shop.data_manager_email}',
  },
  {
    oldVariable: '{SITE_NAME}',
    newVariable: '{var=shop.name}',
  },
  {
    oldVariable: '{SITE_URL}',
    newVariable: '{var=shop.url}',
  },
  {
    oldVariable: '{SHOP_ADDRESS}',
    newVariable: '{var=shop.address}',
  },
  {
    oldVariable: '{CART_ID}',
    newVariable: '{var=cart.id_cart}',
  },
  {
    oldVariable: '{CART_URL}',
    newVariable: '{var=cart.url}',
  },
  {
    oldVariable: '{TOTAL_AMOUNT}',
    newVariable: '{var=total_amount}',
  },
  {
    oldVariable: '{LAST_CATEGORY_VIEW}',
    newVariable: '{var=visitor.last_category_view}',
  },
  {
    oldVariable: '{LAST_PRODUCT_VIEW}',
    newVariable: '{var=visitor.last_product_view}',
  },
  {
    oldVariable: '{BEST_CATEGORY_CONTEXT}',
    newVariable: '{var=visitor.best_category_context}',
  },
  {
    oldVariable: '{BEST_PRODUCT_CONTEXT}',
    newVariable: '{var=visitor.best_product_context}',
  },
  {
    oldVariable: '{LAST_PRODUCT_VIEW_URL}',
    newVariable: '{var=visitor.last_product_view_url}',
  },
  {
    oldVariable: '{LINK_DOUBLE_OPTIN}',
    newVariable: '{var=shop.link_double_optin}',
  },
  {
    oldVariable: '{CUSTOMER_SENIORITY}',
    newVariable: '{var=contact.seniority}',
  },
  {
    oldVariable: '{CUSTOMER_LASTORDER_TIME}',
    newVariable: '{var=contact.lastorder_time}',
  },
  {
    oldVariable: '{MAIL_SUBJECT}',
    newVariable: '{var=template.subject}',
  },
  {
    oldVariable: '{ORDER_ID}',
    newVariable: '{var=order.id_order}',
  },
  {
    oldVariable: '{TOTAL_AMOUNT}',
    newVariable: '{var=order.amount}',
  },
  {
    oldVariable: '{CAMPAIGN_USER_ID}',
    newVariable: '{var=contact.id_customer_shop}',
  },
  {
    oldVariable: '{CUSTOM_1}',
    newVariable: '{var=contact.custom_1}',
  },
  {
    oldVariable: '{CUSTOM_2}',
    newVariable: '{var=contact.custom_2}',
  },
  {
    oldVariable: '{CUSTOM_3}',
    newVariable: '{var=contact.custom_3}',
  },
  {
    oldVariable: '{CUSTOM_4}',
    newVariable: '{var=contact.custom_4}',
  },
  {
    oldVariable: '{PRODUCT_ID}',
    newVariable: '{var=product.id_product}',
  },
  {
    oldVariable: '{PRODUCT_COMBINATION}',
    newVariable: '{var=product.id_combination}',
  },
  {
    oldVariable: '{PRODUCT_TITLE}',
    newVariable: '{var=product.name}',
  },
  {
    oldVariable: '{PRODUCT_URL}',
    newVariable: '{var=product.url}',
  },
  {
    oldVariable: '{PRODUCT_IMAGE_URL_BASE}',
    newVariable: '{var=product.image_url resize=400x400 resizeType=fill-to-fit}',
  },
  {
    oldVariable: '{PRODUCT_IMAGE_URL}',
    newVariable: '{var=product.image_url resize=400x400 resizeType=fill-to-fit}',
  },
  {
    oldVariable: '{PRODUCT_DESCRIPTION}',
    newVariable: '{var=product.description_short}',
  },
  {
    oldVariable: '{PRODUCT_MANUFACTURER}',
    newVariable: '{var=product.manufacturer.name}',
  },
  {
    oldVariable: '{PRODUCT_PRICE_STRIKE}',
    newVariable: '{var=product.price_strike}',
  },
  {
    oldVariable: '{PRODUCT_PRICE}',
    newVariable: '{var=product.price}',
  },
  {
    oldVariable: '{ID_TEMPLATE}',
    newVariable: '{var=template.id_template}',
  },
  {
    oldVariable: '{ID_STEP}',
    newVariable: '{var=id_step}',
  },
  {
    oldVariable: '{ID_SEND}',
    newVariable: '{var=id_send}',
  },
  {
    oldVariable: '{ROOT_URL}',
    newVariable: '{var=shop.root_url}',
  },
  {
    oldVariable: '{FB_PAGEID}',
    newVariable: '{var=shop.fb_pageid}',
  },
  {
    oldVariable: '{FB_USERREF}',
    newVariable: '{var=visitor.fb_userref}',
  },
  {
    oldVariable: '{PRIVATE_ARCHIVE_URL}',
    newVariable: '{var=template.private_archive_url}',
  },
  {
    oldVariable: '{VIEW_IN_BROWSER_URL}',
    newVariable: '{var=template.view_in_browser_url}',
  },
  {
    oldVariable: '{UNSUBSCRIBE_URL}',
    newVariable: '{var=template.unsubscribe_url}',
  },
  {
    oldVariable: '{FIRSTNAME}',
    newVariable: '{var=contact.first_name}',
  },
  {
    oldVariable: '{LASTNAME}',
    newVariable: '{var=contact.last_name}',
  },
  {
    oldVariable: '{CODE_NBR}',
    newVariable: '{var=voucher.code}',
  },
  {
    oldVariable: '{VOUCHER_BUTTON}',
    newVariable: '{var=voucher.button}',
  },
  {
    oldVariable: '{CODE_DESC}',
    newVariable: '{var=voucher.description}',
  },
  {
    oldVariable: '{CODE_AMT}',
    newVariable: '{var=voucher.amount}',
  },
  {
    oldVariable: '{CODE_MIN_AMT}',
    newVariable: '{var=voucher.minimum_order}',
  },
  {
    oldVariable: '{CODE_EXP_DATE}',
    newVariable: '{var=voucher.date_end}',
  },
  {
    oldVariable: '{CODE_EXP_DAY}',
    newVariable: '{var=voucher.nb_day_validate}',
  },
  {
    oldVariable: '{SHOP_LOGO_URL}',
    newVariable: '{var=shop.logo_url}',
  },
  {
    oldVariable: '{GENDER}',
    newVariable: '{var=contact.gender}',
  },
  {
    oldVariable: '{CUSTOMER_EMAIL}',
    newVariable: '{var=contact.email}',
  },
  {
    oldVariable: '{CURRENT_ORDER_STATUS}',
    newVariable: '{var=order.status}',
  },
  {
    oldVariable: '{CURRENT_ORDER_REF}',
    newVariable: '{var=order.reference}',
  },
  {
    oldVariable: '{FACEBOOK_URL}',
    newVariable: '{var=social.facebook_url}',
  },
  {
    oldVariable: '{TWITTER_URL}',
    newVariable: '{var=social.twitter_url}',
  },
  {
    oldVariable: '{PINTEREST_URL}',
    newVariable: '{var=social.pinterest_url}',
  },
  {
    oldVariable: '{TUMBLR_URL}',
    newVariable: '{var=social.tumblr_url}',
  },
  {
    oldVariable: '{INSTAGRAM_URL}',
    newVariable: '{var=social.instagram_url}',
  },
  {
    oldVariable: '{CONTACTPAGE_URL}',
    newVariable: '{var=social.contact_url}',
  },
  {
    oldVariable: '{YOUTUBE_URL}',
    newVariable: '{var=social.youtube_url}',
  },
  {
    oldVariable: '{WHATSAPP_URL}',
    newVariable: '{var=social.whatsapp_url}',
  },
  {
    oldVariable: '{SNAPCHAT_URL}',
    newVariable: '{var=social.snapchat_url}',
  },
  {
    oldVariable: '{SUPPORT_TEL}',
    newVariable: '{var=social.phone}',
  },
  {
    oldVariable: '{SUPPORT_MAIL}',
    newVariable: '{var=social.email}',
  },
  {
    oldVariable: '{CUSTOMER_CUSTOM}',
    newVariable: '{var=contact.custom}',
  },
  {
    oldVariable: '{PRODUCT_CUSTOM}',
    newVariable: '{var=product.custom}',
  },
  {
    oldVariable: '{ORDER_CUSTOM}',
    newVariable: '{var=order.custom}',
  },
];

// Maximum width of AI images
export const AI_IMAGE_MAX_WIDTH = 1024;

// Maximum height of AI images
export const AI_IMAGE_MAX_HEIGHT = 1024;

export const TRANSLATION_EXCLUDE_IDS = ['display-time', 'display-date', 'notification-time'];
