import { ref } from 'vue';
import { PlanName, GleapCustomActionName } from '@/types/enums';

const permissionsArray = {
  value: 'root',
  label: 'root',
  type: 'root',
  group: '',
  hidden: false,
  nodes: [
    {
      value: 'notifications',
      label: 'user.subUser.permissions.notifications_title',
      type: 'title',
      group: 'root',
      hidden: false,
      nodes: [
        {
          value: 'notifications.notifications',
          label: '',
          type: 'sub-title',
          group: 'notifications',
          hidden: true,
          nodes: [
            {
              value: 'notifications.view',
              label: 'user.subUser.permissions.view',
              type: 'checkbox',
              group: 'notifications',
            },
          ],
        },
      ],
    },
    {
      value: 'shop_menu',
      label: 'user.subUser.permissions.shop_menu.title',
      type: 'title',
      group: 'root',
      hidden: false,
      nodes: [
        {
          value: 'store_params.view',
          label: 'user.subUser.permissions.view',
          type: 'checkbox',
          group: 'shop_menu',
          hidden: false,
        },
        {
          value: 'shop_menu.store_params',
          label: 'user.subUser.permissions.shop_menu.store_params.title',
          type: 'sub-title',
          group: 'shop_menu',
          hidden: false,
          nodes: [
            {
              value: 'store_params.edit',
              label: 'user.subUser.permissions.edit',
              type: 'checkbox',
              group: 'shop_menu.store_params',
              hidden: false,
            },
          ],
        },
        {
          value: 'shop_menu.my_domains',
          label: 'user.subUser.permissions.shop_menu.my_domains_title',
          type: 'sub-title',
          group: 'shop_menu',
          hidden: false,
          nodes: [
            {
              value: 'my_domains.view',
              label: 'user.subUser.permissions.view',
              type: 'checkbox',
              group: 'shop_menu.my_domains',
              hidden: false,
            },
            {
              value: 'my_domains.create',
              label: 'user.subUser.permissions.create',
              type: 'checkbox',
              group: 'shop_menu.my_domains',
              hidden: false,
            },
            {
              value: 'my_domains.verify',
              label: 'user.subUser.permissions.verify',
              type: 'checkbox',
              group: 'shop_menu.my_domains',
              hidden: false,
            },
            {
              value: 'my_domains.delete',
              label: 'user.subUser.permissions.delete',
              type: 'checkbox',
              group: 'shop_menu.my_domains',
              hidden: false,
            },
          ],
        },
        {
          value: 'shop_menu.my_offer',
          label: 'user.subUser.permissions.shop_menu.my_offer_title',
          type: 'sub-title',
          group: 'shop_menu',
          hidden: false,
          nodes: [
            {
              value: 'my_offer.view',
              label: 'user.subUser.permissions.view',
              type: 'checkbox',
              group: 'shop_menu.my_offer',
              hidden: false,
            },
            {
              value: 'my_offer.pay',
              label: 'user.subUser.permissions.shop_menu.my_offer_pay',
              type: 'checkbox',
              group: 'shop_menu.my_offer',
              hidden: false,
            },
          ],
        },
        {
          value: 'shop_menu.my_bills',
          label: 'user.subUser.permissions.shop_menu.my_bills.title',
          type: 'sub-title',
          group: 'shop_menu',
          hidden: false,
          nodes: [
            {
              value: 'my_bills.view',
              label: 'user.subUser.permissions.view',
              type: 'checkbox',
              group: 'shop_menu.my_bills',
              hidden: false,
            },
            {
              value: 'my_bills.download',
              label: 'user.subUser.permissions.shop_menu.my_bills.download',
              type: 'checkbox',
              group: 'shop_menu.my_bills',
              hidden: false,
            },
            {
              value: 'my_bills.pay_bill',
              label: 'user.subUser.permissions.shop_menu.my_bills.pay_bills',
              type: 'checkbox',
              group: 'shop_menu.my_bills',
              hidden: false,
            },
          ],
        },
        {
          value: 'shop_menu.gdpr',
          label: 'user.subUser.permissions.shop_menu.gdprTitle',
          type: 'checkbox',
          group: 'shop_menu',
          hidden: false,
          nodes: [
            {
              value: 'my_bills.gdpr.view',
              label: 'user.subUser.permissions.download',
              type: 'checkbox',
              group: 'shop_menu.my_bills',
              hidden: false,
            },
          ],
        },
      ],
    },
    {
      value: 'main-functions',
      label: 'user.subUser.permissions.mainFunctions.title',
      type: 'title',
      group: 'root',
      hidden: false,
      nodes: [
        {
          value: 'main-functions.automated_scenarios',
          label: 'user.subUser.permissions.automated_scenarios.title',
          type: 'sub-title',
          group: 'main-functions',
          hidden: false,
          nodes: [
            {
              value: 'automated_scenarios.create',
              label: 'user.subUser.permissions.create',
              type: 'checkbox',
              group: 'main-functions.automated_scenarios',
              hidden: false,
            },
            {
              value: 'automated_scenarios.edit',
              label: 'user.subUser.permissions.edit',
              type: 'checkbox',
              group: 'main-functions.automated_scenarios',
              hidden: false,
            },
            {
              value: 'automated_scenarios.view',
              label: 'user.subUser.permissions.view',
              type: 'checkbox',
              group: 'main-functions.automated_scenarios',
              hidden: false,
            },
            {
              value: 'automated_scenarios.delete',
              label: 'user.subUser.permissions.delete',
              type: 'checkbox',
              group: 'main-functions.automated_scenarios',
              hidden: false,
            },
            // {
            //   value: 'automated_scenarios.export',
            //   label: 'user.subUser.permissions.export',
            //   type: 'checkbox',
            //   group: 'main-functions.automated_scenarios',
            //   hidden: false,
            // },
            {
              value: 'automated_scenarios.duplicate',
              label: 'user.subUser.permissions.duplicate',
              type: 'checkbox',
              group: 'main-functions.automated_scenarios',
              hidden: false,
            },
            {
              value: 'automated_scenarios.activate',
              label: 'user.subUser.permissions.activate',
              type: 'checkbox',
              group: 'main-functions.automated_scenarios',
              hidden: false,
            },
            {
              value: 'automated_scenarios.marketing_pressure.view',
              label: 'user.subUser.permissions.automated_scenarios.marketing_pressure.view',
              type: 'checkbox',
              group: 'main-functions.automated_scenarios',
              hidden: false,
            },
          ],
        },
        {
          value: 'main-functions.bulk_campaigns',
          label: 'user.subUser.permissions.bulk_campaigns.title',
          type: 'sub-title',
          group: 'main-functions',
          hidden: false,
          nodes: [
            {
              value: 'bulk_campaigns.create',
              label: 'user.subUser.permissions.create',
              type: 'checkbox',
              group: 'bulk_campaigns',
              hidden: false,
            },
            {
              value: 'bulk_campaigns.stop_send',
              label: 'user.subUser.permissions.bulk_campaigns.stop_send',
              type: 'checkbox',
              group: 'bulk_campaigns',
              hidden: false,
            },
            {
              value: 'bulk_campaigns.edit',
              label: 'user.subUser.permissions.bulk_campaigns.makeDefault',
              type: 'checkbox',
              group: 'bulk_campaigns',
              hidden: false,
            },
            {
              value: 'bulk_campaigns.view',
              label: 'user.subUser.permissions.view',
              type: 'checkbox',
              group: 'bulk_campaigns',
              hidden: false,
            },
            {
              value: 'bulk_campaigns.delete',
              label: 'user.subUser.permissions.delete',
              type: 'checkbox',
              group: 'bulk_campaigns',
              hidden: false,
            },
            // {
            //   value: 'bulk_campaigns.export',
            //   label: 'user.subUser.permissions.export',
            //   type: 'checkbox',
            //   group: 'bulk_campaigns',
            //   hidden: false,
            // },
            {
              value: 'bulk_campaigns.duplicate',
              label: 'user.subUser.permissions.duplicate',
              type: 'checkbox',
              group: 'bulk_campaigns',
              hidden: false,
            },
            {
              value: 'bulk_campaigns.activate',
              label: 'user.subUser.permissions.activate',
              type: 'checkbox',
              group: 'bulk_campaigns',
              hidden: false,
            },
            {
              value: 'bulk_campaigns.archive',
              label: 'user.subUser.permissions.archive',
              type: 'checkbox',
              group: 'bulk_campaigns',
              hidden: false,
            },
          ],
        },
        {
          value: 'main-functions.my_templates',
          label: 'user.subUser.permissions.my_templates.title',
          type: 'sub-title',
          group: 'main-functions',
          hidden: false,
          nodes: [
            {
              value: 'my_templates.create',
              label: 'user.subUser.permissions.create',
              type: 'checkbox',
              group: 'main-functions.my_templates',
              hidden: false,
            },
            {
              value: 'my_templates.view',
              label: 'user.subUser.permissions.view',
              type: 'checkbox',
              group: 'main-functions.my_templates',
              hidden: false,
            },
            {
              value: 'my_templates.edit',
              label: 'user.subUser.permissions.edit',
              type: 'checkbox',
              group: 'main-functions.my_templates',
              hidden: false,
            },
            {
              value: 'my_templates.delete',
              label: 'user.subUser.permissions.delete',
              type: 'checkbox',
              group: 'main-functions.my_templates',
              hidden: false,
            },
            {
              value: 'my_templates.export',
              label: 'user.subUser.permissions.export',
              type: 'checkbox',
              group: 'main-functions.my_templates',
              hidden: false,
            },
            {
              value: 'my_templates.duplicate',
              label: 'user.subUser.permissions.duplicate',
              type: 'checkbox',
              group: 'main-functions.my_templates',
              hidden: false,
            },
          ],
        },
        {
          value: 'main-functions.my_data',
          label: 'user.subUser.permissions.mainFunctions.my_data.title',
          type: 'sub-title',
          group: 'main-functions',
          hidden: false,
          nodes: [
            {
              value: 'my_data.view',
              label: 'user.subUser.permissions.mainFunctions.my_data.view',
              type: 'checkbox',
              group: 'main-functions.my_data',
              hidden: false,
            },
            {
              value: 'my_data.export',
              label: 'user.subUser.permissions.export',
              type: 'checkbox',
              group: 'main-functions.my_data',
              hidden: false,
            },
            {
              value: 'my_data.synchro.execute',
              label: 'user.subUser.permissions.mainFunctions.my_data.synchro_execute',
              type: 'checkbox',
              group: 'main-functions.my_data',
              hidden: false,
            },
          ],
        },
        {
          value: 'main-functions.lists-segments',
          label: 'user.subUser.permissions.listsAndSegments.title',
          type: 'sub-title',
          group: 'main-functions',
          hidden: false,
          nodes: [
            {
              value: 'lists-segments.mailing_lists_view',
              label: 'user.subUser.permissions.listsAndSegments.mailing_lists_view',
              type: 'checkbox',
              group: 'main-functions.lists-segments',
              hidden: false,
            },
            {
              value: 'lists-segments.manage_lists',
              label: 'user.subUser.permissions.listsAndSegments.manage_lists',
              type: 'checkbox',
              group: 'main-functions.lists-segments',
              hidden: false,
            },
            {
              value: 'lists-segments.rename',
              label: 'user.subUser.permissions.rename',
              type: 'checkbox',
              group: 'main-functions.lists-segments',
              hidden: false,
            },
            {
              value: 'lists-segments.delete',
              label: 'user.subUser.permissions.delete',
              type: 'checkbox',
              group: 'main-functions.lists-segments',
              hidden: false,
            },
            {
              value: 'lists-segments.delete_all',
              label: 'user.subUser.permissions.delete_all',
              type: 'checkbox',
              group: 'main-functions.lists-segments',
              hidden: false,
            },
            {
              value: 'lists-segments.clone',
              label: 'user.subUser.permissions.duplicate',
              type: 'checkbox',
              group: 'main-functions.lists-segments',
              hidden: false,
            },
            {
              value: 'lists-segments.copy',
              label: 'user.subUser.permissions.copy',
              type: 'checkbox',
              group: 'main-functions.lists-segments',
              hidden: false,
            },
            {
              value: 'lists-segments.move',
              label: 'user.subUser.permissions.move',
              type: 'checkbox',
              group: 'main-functions.lists-segments',
              hidden: false,
            },
            {
              value: 'lists-segments.unsub',
              label: 'user.subUser.permissions.listsAndSegments.client_unsub',
              type: 'checkbox',
              group: 'main-functions.lists-segments',
              hidden: false,
            },
            {
              value: 'lists-segments.export',
              label: 'user.subUser.permissions.export',
              type: 'checkbox',
              group: 'main-functions.lists-segments',
              hidden: false,
            },
            {
              value: 'lists-segments.import_clients',
              label: 'user.subUser.permissions.listsAndSegments.import_clients',
              type: 'checkbox',
              group: 'main-functions.lists-segments',
              hidden: false,
            },
            {
              value: 'lists-segments.modify_clients',
              label: 'user.subUser.permissions.listsAndSegments.modify_clients',
              type: 'checkbox',
              group: 'main-functions.lists-segments',
              hidden: false,
            },
            {
              value: 'lists-segments.create',
              label: 'user.subUser.permissions.create',
              type: 'checkbox',
              group: 'main-functions.lists-segments',
              hidden: false,
            },
            {
              value: 'lists-segments.import-reports',
              label: 'user.subUser.permissions.my_lists.actions.import_reports',
              type: 'checkbox',
              group: 'main-functions.lists-segments',
              hidden: false,
            },
            {
              value: 'lists-segments.unsubscribers_import',
              label: 'user.subUser.permissions.my_lists.actions.unsubscribers_import',
              type: 'checkbox',
              group: 'main-functions.lists-segments',
              hidden: false,
            },
          ],
        },
        {
          value: 'main-functions.my_push_notif',
          label: 'user.subUser.permissions.my_push_notif.title',
          type: 'sub-title',
          group: 'main-functions',
          hidden: false,
          nodes: [
            {
              value: 'my_push_notif.view',
              label: 'user.subUser.permissions.my_push_notif.view',
              type: 'checkbox',
              group: 'main-functions.my_push_notif',
              hidden: false,
            },
            {
              value: 'my_push_notif.export',
              label: 'user.subUser.permissions.export',
              type: 'checkbox',
              group: 'main-functions.my_push_notif',
              hidden: false,
            },
          ],
        },
        {
          value: 'main-functions.my_unsubscribed',
          label: 'user.subUser.permissions.my_unsubscribed.title',
          type: 'sub-title',
          group: 'main-functions',
          hidden: false,
          nodes: [
            {
              value: 'my_unsubscribed.view',
              label: 'user.subUser.permissions.my_unsubscribed.view',
              type: 'checkbox',
              group: 'main-functions.my_unsubscribed',
              hidden: false,
            },
            {
              value: 'my_unsubscribed.export',
              label: 'user.subUser.permissions.export',
              type: 'checkbox',
              group: 'main-functions.my_unsubscribed',
              hidden: false,
            },
            {
              value: 'my_unsubscribed.import',
              label: 'user.subUser.permissions.import',
              type: 'checkbox',
              group: 'main-functions.my_unsubscribed',
              hidden: false,
            },
          ],
        },
        {
          value: 'main-functions.my_bounces',
          label: 'user.subUser.permissions.my_bounces.title',
          type: 'sub-title',
          group: 'main-functions',
          hidden: false,
          nodes: [
            {
              value: 'my_bounces.view',
              label: 'user.subUser.permissions.my_bounces.view',
              type: 'checkbox',
              group: 'main-functions.my_bounces',
              hidden: false,
            },
            {
              value: 'my_bounces.export',
              label: 'user.subUser.permissions.export',
              type: 'checkbox',
              group: 'main-functions.my_bounces',
              hidden: false,
            },
          ],
        },
        {
          value: 'main-functions.my_spams',
          label: 'user.subUser.permissions.my_spams.title',
          type: 'sub-title',
          group: 'main-functions',
          hidden: false,
          nodes: [
            {
              value: 'my_spams.view',
              label: 'user.subUser.permissions.my_spams.view',
              type: 'checkbox',
              group: 'main-functions.my_spams',
              hidden: false,
            },
            {
              value: 'my_spams.export',
              label: 'user.subUser.permissions.export',
              type: 'checkbox',
              group: 'main-functions.my_spams',
              hidden: false,
            },
          ],
        },
      ],
    },
    {
      value: 'user_options',
      label: 'user.subUser.permissions.user_options.title',
      type: 'title',
      group: 'root',
      hidden: false,
      nodes: [
        {
          value: 'user_options.payment_method',
          label: 'user.subUser.permissions.user_options.payment_method.title',
          type: 'sub-title',
          group: 'user_options',
          hidden: false,
          nodes: [
            {
              value: 'payment_method.create',
              label: 'user.subUser.permissions.create',
              type: 'checkbox',
              group: 'user_options.payment_method',
              hidden: false,
            },
            {
              value: 'payment_method.makeDefault',
              label: 'user.subUser.permissions.user_options.payment_method.makeDefault',
              type: 'checkbox',
              group: 'user_options.payment_method',
              hidden: false,
            },
            {
              value: 'payment_method.view',
              label: 'user.subUser.permissions.view',
              type: 'checkbox',
              group: 'user_options.payment_method',
              hidden: false,
            },
            {
              value: 'payment_method.delete',
              label: 'user.subUser.permissions.delete',
              type: 'checkbox',
              group: 'user_options.payment_method',
              hidden: false,
            },

          ],
        },
        {
          value: 'user_options.stats_report',
          label: 'user.subUser.permissions.stats_report_title',
          type: 'sub-title',
          group: 'user_options',
          hidden: false,
          nodes: [
            {
              value: 'stats_report.create',
              label: 'user.subUser.permissions.create',
              type: 'checkbox',
              group: 'user_options.stats_report',
              hidden: false,
            },
            {
              value: 'stats_report.edit',
              label: 'user.subUser.permissions.edit',
              type: 'checkbox',
              group: 'user_options.stats_report',
              hidden: false,
            },
            {
              value: 'stats_report.duplicate',
              label: 'user.subUser.permissions.duplicate',
              type: 'checkbox',
              group: 'user_options.stats_report',
              hidden: false,
            },
            {
              value: 'stats_report.view',
              label: 'user.subUser.permissions.preview',
              type: 'checkbox',
              group: 'user_options.stats_report',
              hidden: false,
            },
            {
              value: 'stats_report.export',
              label: 'user.subUser.permissions.export',
              type: 'checkbox',
              group: 'user_options.stats_report',
              hidden: false,
            },
            {
              value: 'stats_report.delete',
              label: 'user.subUser.permissions.delete',
              type: 'checkbox',
              group: 'user_options.stats_report',
              hidden: false,
            },

          ],
        },
      ],
    },
  ],
};

export const permissionsArr = ref<string[]>([]);

export const groupElementArr = ref<string[]>([]);

export const subGroupElementArr = ref<string[]>([]);

export const featurePermissions: Record<string, any> = {
  'bulk.deliverability.bulk-bounces-categories': {
    access: [PlanName.PREMIUM, PlanName.ULTIMATE],
  },
  'bulk.deliverability.bulk-emails-providers': {
    access: [PlanName.PREMIUM, PlanName.ULTIMATE],
  },
  'stats_report.create': {
    access: [PlanName.PREMIUM, PlanName.ULTIMATE],
  },
  'stats_report.edit': {
    access: [PlanName.PREMIUM, PlanName.ULTIMATE],
  },
  'stats_report.view': {
    access: [PlanName.PREMIUM, PlanName.ULTIMATE],
  },
  'user.manage-sub-users': {
    access: [PlanName.PREMIUM, PlanName.ULTIMATE],
  },
  'data_csv.export': {
    access: [PlanName.PREMIUM, PlanName.ULTIMATE],
  },
  'template.import': {
    access: [PlanName.PREMIUM, PlanName.ULTIMATE],
  },
  [GleapCustomActionName.GLEAP_RDV_CUSTOMER_SUCCESS]: {
    access: [PlanName.PREMIUM, PlanName.ULTIMATE],
  },
  [GleapCustomActionName.GLEAP_TELEPHONE_SUPPORT]: {
    access: [PlanName.PREMIUM, PlanName.ULTIMATE],
  },
  boxsendpushnotification: {
    access: [PlanName.PREMIUM, PlanName.ULTIMATE],
  },
};

export default permissionsArray;
