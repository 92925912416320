
import {
  defineComponent, ref, SetupContext, watch,
} from 'vue';
import Calendar from 'primevue/calendar';

export default defineComponent({
  name: 'SegmentDateInput',

  components: { Calendar },

  props: {
    modelValue: {
      type: Date,
      required: true,
    },
  },

  emits: ['update:modelValue'],

  setup(props: { modelValue: Date }, { emit }: SetupContext) {
    const value = ref(props.modelValue);

    watch(value, () => {
      emit('update:modelValue', value.value);
    });

    return {
      value,
    };
  },
});
