// eslint-disable-next-line import/no-cycle
import {
  OperatorType,
  UsersPagesConfiguration,
  UsersPagesConfigurationInputItem,
  UsersPagesConfigurationUpdateInputItem,
} from '@/types/generated-types/graphql';
// eslint-disable-next-line import/no-cycle
import {
  CustomMutation,
  Get,
  GetResult,
  Insert,
  List,
  Update,
} from '@/composables/GraphQL';
// eslint-disable-next-line import/no-cycle
import {
  DateFrequencyData,
  GlobalDashboardSettingsData,
  GlobalEmailSettingsData,
  GlobalStatsSettingsData,
} from '@/types/stats-editor-types';
// eslint-disable-next-line import/no-cycle
import { SpmTableFilter, StatsType } from '@/types';
// eslint-disable-next-line import/no-cycle
import { ErrorConfigForm } from '@/types/automated-scenarios';
import { computed } from 'vue';
import {
  helpers, maxLength, minLength, required,
} from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import { i18n } from '@/i18n';

let translation: any;
(async () => {
  translation = await i18n;
})();

export enum TypeStatsEmailSendingFrequency {
  RECURRENT = 'recurrent',
  TRIGGER_BULK_CAMPAIGN = 'triggerBulkCampaign',
}

// eslint-disable-next-line max-len
export const GetUsersPagesConfiguration = async (id_users_pages_configuration: number, fields: string[]): Promise<GetResult<UsersPagesConfiguration>> => Get<UsersPagesConfiguration>({
  id: id_users_pages_configuration,
  name: 'UsersPagesConfiguration',
  keyName: 'id_users_pages_configuration',
  fields,
});

export const InsertUsersPagesConfiguration = async (usersPagesConfInput: UsersPagesConfigurationInputItem[]) => Insert<UsersPagesConfigurationInputItem>({
  name: 'UsersPagesConfiguration',
  input: usersPagesConfInput,
  type: 'UsersPagesConfigurationInput',
});

export const DuplicateUsersPagesConfiguration = async (usersPagesConfInput: UsersPagesConfigurationInputItem[]) => CustomMutation({
  name: 'DuplicateUsersPagesConfiguration',
  input: usersPagesConfInput,
  type: 'UsersPagesConfigurationInput',
});

export const UpdateUsersPagesConfiguration = async (usersPagesConfUpdateInput: UsersPagesConfigurationUpdateInputItem[]) => Update<UsersPagesConfigurationUpdateInputItem>({
  name: 'UsersPagesConfiguration',
  input: usersPagesConfUpdateInput,
  type: 'UsersPagesConfigurationUpdateInput',
});

export const pageSettingsValidate = async (
  data: GlobalStatsSettingsData | GlobalDashboardSettingsData | GlobalEmailSettingsData,
  type: StatsType,
  localizedTextInputValidator: any,
): Promise<ErrorConfigForm> => {
  const rules = computed(() => {
    if (type === StatsType.EMAIL) {
      return {
        name: {
          required,
          localizedTextInputValidator: localizedTextInputValidator('text'),
        },
        mailing_frequencies: {
          mailingFrequenciesValidator: helpers.withMessage(
            () => translation.global.t('statsEditor.sidebarPanel.settings.email.mailingFrequenciesError'),
            (value: DateFrequencyData): boolean => {
              if ([TypeStatsEmailSendingFrequency.RECURRENT, TypeStatsEmailSendingFrequency.TRIGGER_BULK_CAMPAIGN].includes(value.type)) {
                if (value.type === TypeStatsEmailSendingFrequency.RECURRENT) {
                  if (value.way && value.way !== '') {
                    if (value.way === 'selectedDaysOfWeek' || value.way === 'selectedDaysOfMonth') {
                      return Boolean(value.selectedDays && value.selectedDays.length > 0);
                    }
                    return true;
                  }
                } else if (value.type === TypeStatsEmailSendingFrequency.TRIGGER_BULK_CAMPAIGN
                  && value.selectedDays && value.selectedDays.length > 0) {
                  return true;
                }
              }

              return false;
            },
          ),
        },
        mail_subject: {
          required,
          localizedTextInputValidator: localizedTextInputValidator('text'),
        },
      };
    }
    return {
      name: {
        localizedTextInputValidator: localizedTextInputValidator('text'),
      },
      navigationName: {
        localizedTextInputValidator: localizedTextInputValidator('text'),
      },
    };
  });

  const v$ = useVuelidate(rules, data);
  const success = await v$.value.$validate();
  return {
    success,
    validate: v$,
  };
};

// Get user's custom stats pages from database
export const getUsersPagesConfiguration = async (type: StatsType, adminUserId: string) => {
  const filter: SpmTableFilter[] = [
    { field: 'type', value: type, operator: OperatorType.Equals },
    { field: 'deleted', value: 0, operator: OperatorType.Equals },
    { field: 'admin_user_id', value: adminUserId, operator: OperatorType.In },
  ];

  return List<UsersPagesConfiguration>({
    name: 'UsersPagesConfiguration',
    settings: {
      limit: 0,
      offset: 0,
      order: [
        {
          field: 'page_name',
          type: 'ASC',
        },
      ],
      filter,
    },
    fields: ['id_users_pages_configuration', 'type', 'page_name', 'navigation_name', 'date_creation', 'date_modification'],
  });
};

// eslint-disable-next-line max-len
export const getUsersPagesConfigurationMain = async (id_users_pages_configuration: number, fields: string[]): Promise<GetResult<UsersPagesConfiguration>> => Get<UsersPagesConfiguration>({
  id: id_users_pages_configuration,
  name: 'UsersPagesConfiguration',
  keyName: 'id_users_pages_configuration',
  fields,
});

export const fetchUsersPagesConfMainList = async (type: string, input: string, limit: number) => {
  const filters = [
    {
      field: 'created_by_user_id',
      value: '',
      operator: OperatorType.IsNull,
    },
    {
      field: 'type',
      value: type,
      operator: OperatorType.Equals,
    },
    {
      field: 'deleted',
      value: 0,
      operator: OperatorType.Equals,
    },
  ];

  if (input !== '') {
    filters.push({
      field: 'page_name',
      value: `%${input}%`,
      operator: OperatorType.Like,
    });
  }

  return List<UsersPagesConfiguration>({
    name: 'UsersPagesConfiguration',
    settings: {
      limit,
      offset: 0,
      order: [],
      filter: filters,
    },
    fields: [
      'id_users_pages_configuration', 'page_name', 'navigation_name', 'extra_data', 'configuration',
    ],
  });
};
