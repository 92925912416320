
import {
  defineComponent,
  onMounted, PropType, ref, SetupContext, watch,
} from 'vue';
import { useI18n } from 'vue-i18n';
import BaseInputNumber from '@/components/fields/partials/BaseInputNumber.vue';
import { DropdownOption } from '@/types';
import { Period } from '@/types/automated-scenarios';
import Dropdown from 'primevue/dropdown';

export default defineComponent({
  name: 'PeriodSelector',

  components: {
    BaseInputNumber,
    Dropdown,
  },

  props: {
    modelValue: {
      type: Object as PropType<Period>,
      default: () => ({
        unit: 'MINUTE',
        value: 1,
      }),
    },

    units: {
      type: Array as PropType<DropdownOption[]>,
      required: true,
    },

    title: {
      type: String,
      required: false,
      default: 'Duration',
    },

    minValue: {
      type: Number,
      required: false,
      default: 1,
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    selectorGrouped: {
      type: String,
      required: false,
      default: 'period-selector-grouped',
    },

    inputWidth: {
      type: String,
      required: false,
      default: ''
    }
  },

  emits: ['update:modelValue'],

  setup(props: { modelValue: Period; units: DropdownOption[] }, { emit }: SetupContext) {
    const { t } = useI18n();
    const value = ref(props.modelValue ? props.modelValue.value : 1);
    const unit = ref(
      props.modelValue && props.units.find(u => u.value === props.modelValue.unit)
        ? props.modelValue.unit
        : props.units[0].value
    );

    const update = () => emit('update:modelValue', {
      unit: unit.value,
      value: value.value,
    });

    watch([value, unit], update);

    watch(props, () => {
      unit.value = props.modelValue.unit;
      value.value = props.modelValue.value;
    });

    // emit event if user don't change default values
    onMounted(update);

    return {
      t,
      value,
      unit,
    };
  },
});
