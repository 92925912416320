// eslint-disable-next-line import/no-cycle
import { ComponentData, ComponentMetadata, ErrorConfigForm } from '@/types/automated-scenarios';
// eslint-disable-next-line import/no-cycle
import { Lang, UserState } from '@/composables/User';
// eslint-disable-next-line import/no-cycle
import { StringMap, TypeCampaignEnum } from '@/types';
// eslint-disable-next-line import/no-cycle
import { getLinkedOperators } from '@/composables/AutomatedScenarios';
import { i18n } from '@/i18n';
// eslint-disable-next-line import/no-cycle
import { asInt } from '@/helpers';
// eslint-disable-next-line import/no-cycle
import {
  checkIfTemplateOrRedirectContainsVoucher,
  getTemplateAndRedirect, reusePreviousCouponValues, voucherMethodsValues, voucherTypeValues,
} from '@/composables/automated-scenarios/AutomatedScenariosSendingChannel';
import {
  between, integer, maxLength, minLength, requiredIf,
} from '@vuelidate/validators';
// eslint-disable-next-line import/no-cycle
import { localizedTextInputValidator } from '@/helpers/CustomValidator';
import useVuelidate from '@vuelidate/core';
// eslint-disable-next-line import/no-cycle
import { GetShopsConfigurationList, SaveShopsConfiguration } from '@/composables/shop/ShopsConfiguration';

let translation: any;
(async () => {
  translation = await i18n;
})();

export interface ActionSendSmsData {
  template_display_selector_id: number | null;
  reuse_a_previous_coupon: number;
  display_popup_on_site: number;
  template_display_reduction_method: number;
  template_display_reduction_descript: StringMap;
  template_display_reduction_type: number;
  template_display_reduction_identifiant: string;
  template_display_reduction_amount: number;
  template_display_reduction_duplicate: string;
  template_display_reduction_minorderamount: number;
  template_display_reduction_validtime: number;
  template_display_reduction_prefix: string;
  template_display_message_langs: string;
  template_message_expname: StringMap;
  [key: string]: any;
}

const ActionSendSmsMetadata: ComponentMetadata<ActionSendSmsData> = {
  Meta: {
    kind: 'action',
    id: 'boxsendsms',
    label: 'Envoyer un SMS',
    icon: 'fa-comment-alt-dots',
    hasConfiguration: true,
    incompatibilities: {
      automation: {
        next_operators: [
          'boxabandonpanier', 'boxsitepasstime', 'boxquitsite',
          'boxperiodiclaunching', 'boxscrollpage', 'boxstatuscommand',
          'boxcustomjs', 'registeronsite', 'subscribetonewsletter',
          'boxsendsms', 'boxdisplayfreepopup', 'boxdisplayfreemodel',
          'boxvisitpage',
        ],
      },
      bulk: {
        next_operators: [
          'boxabandonpanier', 'boxsitepasstime', 'boxquitsite',
          'boxperiodiclaunching', 'boxscrollpage', 'boxstatuscommand',
          'boxcustomjs', 'registeronsite', 'subscribetonewsletter',
          'boxsendsms', 'boxdisplayfreepopup', 'boxdisplayfreemodel',
          'boxvisitpage', 'boxchoosebulklist',
        ],
      },
    },
    component: 'ActionSendSms',
    availableInCampaign: [TypeCampaignEnum.AUTOMATION, TypeCampaignEnum.BULK],
  },
  Create(data: ActionSendSmsData = {
    template_display_selector_id: null,
    reuse_a_previous_coupon: 0,
    display_popup_on_site: 0,
    template_display_reduction_method: 3,
    template_display_reduction_descript: {},
    template_display_reduction_type: 1,
    template_display_reduction_identifiant: '',
    template_display_reduction_amount: 0,
    template_display_reduction_duplicate: '',
    template_display_reduction_minorderamount: 0,
    template_display_reduction_validtime: 7,
    template_display_reduction_prefix: '',
    template_display_message_langs: '',
    template_message_expname: {},
  }): ComponentData<ActionSendSmsData> {
    const formattedData = data;
    const languages: string[] = UserState.activeShop?.langs.map((language: Lang) => language.id) ?? [];
    formattedData.template_display_message_langs = JSON.stringify(languages);

    return {
      id: 'boxsendsms',
      form_name: 'boxsendsms',
      logo_box: 'fa-comment',
      ...formattedData,
    };
  },
  async Validate(data: ActionSendSmsData): Promise<ErrorConfigForm> {
    //         required: voucherTemplateValidator(data, 'method'),
    let mustConfigVoucher = false;
    if (data.template_display_selector_id && data.template_display_selector_id > 0) {
      const idTemplate = asInt(data.template_display_selector_id);
      let templatesContainsVoucher = false;
      const listTemplate = await getTemplateAndRedirect(idTemplate);
      if (listTemplate !== null) {
        templatesContainsVoucher = checkIfTemplateOrRedirectContainsVoucher(listTemplate);
      }
      if (templatesContainsVoucher && data.reuse_a_previous_coupon === reusePreviousCouponValues.CONFIGURE_NEW_VOUCHER) {
        mustConfigVoucher = true;
      }
    }

    const rules = {
      template_display_reduction_method: {
        required: mustConfigVoucher,
      },
      template_display_reduction_descript: {
        required: false,
      },
      template_display_reduction_type: {
        required: mustConfigVoucher,
      },
      template_display_reduction_identifiant: {
        required: requiredIf(() => mustConfigVoucher && data.template_display_reduction_method === voucherMethodsValues.MANUAL),
        minLength: minLength(2),
        maxLength: maxLength(45),
      },
      template_display_reduction_amount: {
        required: requiredIf(() => mustConfigVoucher && [voucherTypeValues.PERCENTAGE, voucherTypeValues.AMOUNT].includes(data.template_display_reduction_type)),
        integer,
      },
      template_display_reduction_duplicate: {
        // eslint-disable-next-line max-len
        required: requiredIf(() => mustConfigVoucher && data.template_display_reduction_method === voucherMethodsValues.AUTOMATIC && data.template_display_reduction_type === voucherTypeValues.DUPLICATED),
        minLength: minLength(2),
        maxLength: maxLength(45),
      },
      template_display_reduction_minorderamount: {
        required: requiredIf(() => mustConfigVoucher && data.template_display_reduction_method === voucherMethodsValues.AUTOMATIC),
        between: between(0, 100000),
      },
      template_display_reduction_validtime: {
        required: requiredIf(() => mustConfigVoucher && data.template_display_reduction_method === voucherMethodsValues.AUTOMATIC),
        between: between(1, 126),
      },
      template_display_reduction_prefix: {
        minLength: minLength(2),
        maxLength: maxLength(32),
      },
      template_message_expname: {
        localizedTextInputValidator: localizedTextInputValidator('text'),
      },
    };

    const v$ = useVuelidate(rules, data);
    const success = await v$.value.$validate();

    return {
      success,
      validate: v$,
    };
  },
  Outputs(data: ActionSendSmsData): number {
    return 1;
  },
  CanBeDeleted(operatorId: string): boolean | string {
    const canI = getLinkedOperators(operatorId);
    if (canI.length) {
      const listBoxes = canI.join(', ');
      return translation.global.t('automatedScenarios.box_dialog.delete_operator.warning_linked_box', { boxes: listBoxes });
    }
    return true;
  },
  Label(data: ActionSendSmsData): string {
    return 'Envoyer un SMS';
  },
  async Callback(data: ActionSendSmsData): Promise<void> {
    // Load sender names from database
    const senderNames = await GetShopsConfigurationList({
      shopId: UserState.activeShop?.id ?? 0,
      key: 'select2_list_sms_from_name',
      fields: 'id_shop_configuration, key, value',
      limit: 0,
      offset: 0,
    });

    const suggestions: string[] = [];
    if (senderNames && senderNames.length && senderNames[0].value !== '') {
      const json = JSON.parse(senderNames[0].value);
      if (json) {
        json.forEach((element: string) => {
          suggestions.push(element);
        });
      }
    }

    // Add new entries if not in array
    let saveConfig = false;
    Object.keys(data.template_message_expname).forEach((key: string) => {
      if (!suggestions.includes(data.template_message_expname[key])) {
        suggestions.push(data.template_message_expname[key]);
        saveConfig = true;
      }
    });

    // Save
    if (saveConfig) {
      await SaveShopsConfiguration({
        id_shop: UserState.activeShop?.id ?? 0,
        configs: [
          {
            key: 'select2_list_sms_from_name',
            lang: '',
            value: JSON.stringify(suggestions),
          },
        ],
      });
    }
  },
};

export default ActionSendSmsMetadata;
