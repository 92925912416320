
import { useI18n } from 'vue-i18n';
import {
  defineComponent, onBeforeMount, onMounted, reactive, Ref, ref, watch,
} from 'vue';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import ToggleButton from 'primevue/togglebutton';
import Dropdown from 'primevue/dropdown';
import InlineMessage from 'primevue/inlinemessage';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import MultiSelect from 'primevue/multiselect';
import { findPermission, UserState, setUserLang } from '@/composables/User';
import CountryDropdown from '@/components/fields/CountryDropdown.vue';
import {
  getSubUserProfile,
  getUserProfile,
  MyProfileInterface,
  ProfileErrorsInterface,
  saveMyProfile,
} from '@/composables/user/Profile';
import { showToastError, showToastSuccess } from '@/helpers';
import { CountryAndStateValues } from '@/types/country-state-types';
import { isValidEmail } from '@/helpers/Email';
import { DropdownOption, StatsType } from '@/types';
import { getUsersPagesConfiguration } from '@/composables/Statistics/usersPagesConfiguration';
import {
  DeleteUsersPagesSubscriptions,
  getUserStatsPages,
  InsertUsersPagesSubscriptions,
} from '@/composables/Statistics/usersPagesSubscriptions';
import { List } from '@/composables/GraphQL';
import {
  OperatorType,
  UsersPagesConfiguration,
  UsersPagesSubscriptionsDeleteInput,
  UsersPagesSubscriptionsInputItem,
  ShopsUsersUpdateInputItem,
} from '@/types/generated-types/graphql';
import { useStore } from '@/store';
import MyOffer from '@/views/shop/MyOffer.vue';
import Loader from '@/components/layout/Loader.vue';
import { UpdateShopsUsers } from '@/composables/user/ShopsUsers';
import moment from 'moment';

export default defineComponent({
  name: 'Profile',
  components: {
    Button,
    InputText,
    ToggleButton,
    Dropdown,
    InlineMessage,
    CountryDropdown,
    TabView,
    TabPanel,
    MultiSelect,
    MyOffer,
    Loader,
  },

  props: {
    tab: {
      type: String,
      required: false,
      default: 'profile',
    },
  },

  setup(props: {tab: string }) {
    const {
      t, locale, setLocaleMessage,
    } = useI18n();
    const store = useStore();

    const dashboard: Ref<number> = ref(0);
    const statsPages: Ref<DropdownOption[]> = ref([]);
    const emailsPages: Ref<DropdownOption[]> = ref([]);
    const dashboardsList: Ref<DropdownOption[]> = ref([{ label: t('user.subUser.reports.none'), value: '' }]);
    const allStatsPagesList: Ref<DropdownOption[]> = ref([]);
    const statsPagesList: Ref<DropdownOption[]> = ref([]);
    const emailsPagesList: Ref<DropdownOption[]> = ref([]);
    const activeTab = ref(0);
    const { userType } = UserState.user;
    const isLoading = ref(false);
    const originalLocale = ref();

    const checkUserState = ref(false);

    const loadingSave = ref(false);

    const tabsArr = [
      'profile',
      'stats',
      'offer',
      'bills',
      'payment_method',
    ];

    const reloadTranslations = async (newLocale: string) => {
      locale.value = newLocale;

      try {
        // eslint-disable-next-line import/no-dynamic-require,global-require
        const msg = await require(`../../i18n/${newLocale}.json`);
        setLocaleMessage(newLocale, msg);
        setUserLang(newLocale);
      } catch (error) {
        console.error(`Failed to load translations for ${newLocale}: ${error}`);
      }
    };

    const setTabIndex = () => {
      const index = tabsArr.findIndex((element) => element === props.tab);

      if (index !== -1) {
        activeTab.value = index;
      }
    };

    const saveStatisticsReports = async () => {
      loadingSave.value = true;

      let otherDashboards: any[] = [];
      let otherStatsPages: any[] = [];
      let otherEmailsPages: any[] = [];

      if (userType !== 'admin') {
        const resDashboards = await getUserStatsPages(UserState.user.id, UserState.user.userType, StatsType.DASHBOARD, UserState.activeShop?.idUser, true);
        if (resDashboards && resDashboards.err === '' && resDashboards.items.length) {
          otherDashboards = resDashboards.items;
        }
        const resStatsPages = await getUserStatsPages(UserState.user.id, UserState.user.userType, StatsType.STATS, UserState.activeShop?.idUser, true);
        if (resStatsPages && resStatsPages.err === '' && resStatsPages.items.length) {
          otherStatsPages = resStatsPages.items;
        }
        const resEmailsPages = await getUserStatsPages(UserState.user.id, UserState.user.userType, StatsType.EMAIL, UserState.activeShop?.idUser, true);
        if (resEmailsPages && resEmailsPages.err === '' && resEmailsPages.items.length) {
          otherEmailsPages = resEmailsPages.items;
        }
      }

      // We delete old pages links
      const usersPagesSubscriptionsDelete: UsersPagesSubscriptionsDeleteInput[] = [
        {
          id_user: UserState.user.id,
          user_type: UserState.user.userType,
        },
      ];
      await DeleteUsersPagesSubscriptions(usersPagesSubscriptionsDelete);

      // Add dashboard and stats pages
      const pagesToLink: UsersPagesSubscriptionsInputItem[] = [];

      let priority = 0;
      // Add other dashboard of different admin shops if user is a sub user
      otherDashboards.forEach((otherDashboard: any) => {
        const input: UsersPagesSubscriptionsInputItem = {
          id_users_pages_configuration: otherDashboard.id_users_pages_configuration,
          id_user: UserState.user.id,
          user_type: UserState.user.userType,
          type: StatsType.DASHBOARD,
          priority,
        };
        pagesToLink.push(input);
        priority += 1;
      });

      if (dashboard.value && dashboard.value !== 0) {
        const shopsUsersPageInput: UsersPagesSubscriptionsInputItem = {
          id_users_pages_configuration: dashboard.value,
          id_user: UserState.user.id,
          user_type: UserState.user.userType,
          type: StatsType.DASHBOARD,
          priority,
        };

        pagesToLink.push(shopsUsersPageInput);
      }

      // Add other stats pages of different admin shops if user is a sub user
      otherStatsPages.forEach((otherStatsPage: any) => {
        const input: UsersPagesSubscriptionsInputItem = {
          id_users_pages_configuration: otherStatsPage.id_users_pages_configuration,
          id_user: UserState.user.id,
          user_type: UserState.user.userType,
          type: StatsType.STATS,
          priority,
        };
        pagesToLink.push(input);
        priority += 1;
      });

      if (statsPages.value && statsPages.value.length) {
        statsPages.value.forEach((item: DropdownOption) => {
          priority += 1;

          const shopsUsersPageInput: UsersPagesSubscriptionsInputItem = {
            id_users_pages_configuration: parseInt(item.value.toString(), 10),
            id_user: UserState.user.id,
            user_type: UserState.user.userType,
            type: StatsType.STATS,
            priority,
          };

          pagesToLink.push(shopsUsersPageInput);
        });
      }

      // Add other emails pages of different admin shops if user is a sub user
      otherEmailsPages.forEach((otherEmailsPage: any) => {
        const input: UsersPagesSubscriptionsInputItem = {
          id_users_pages_configuration: otherEmailsPage.id_users_pages_configuration,
          id_user: UserState.user.id,
          user_type: UserState.user.userType,
          type: StatsType.EMAIL,
          priority,
        };
        pagesToLink.push(input);
        priority += 1;
      });

      if (emailsPages.value && emailsPages.value.length) {
        emailsPages.value.forEach((item: DropdownOption) => {
          const shopsUsersPageInput: UsersPagesSubscriptionsInputItem = {
            id_users_pages_configuration: parseInt(item.value.toString(), 10),
            id_user: UserState.user.id,
            user_type: UserState.user.userType,
            type: StatsType.EMAIL,
            priority: 0,
          };

          pagesToLink.push(shopsUsersPageInput);
        });
      }

      if (pagesToLink.length) {
        const result = await InsertUsersPagesSubscriptions(pagesToLink);

        loadingSave.value = false;

        if (result && result.status) {
          await showToastSuccess('savedSuccessful');
          store.commit('statsEditor/setRefreshComponents', true);
          store.commit('statsEditor/setRefreshNavigationBar', true);
        } else {
          await showToastError(t('errorMessages.GENERIC_ERROR'));
        }
      }
    };

    const pErrors = reactive<ProfileErrorsInterface>({
      email: false,
      password: false,
      country: false,
      state: false,
      lang: false,
      advisorEmail: false,
    });

    const passwordConfirm = ref('');
    const availableLanguage = ref([
      { value: 'fr', label: 'Français' },
      { value: 'en', label: 'English' },
    ]);

    const profile = reactive<MyProfileInterface>({
      id_user: 0,
      lang: 'fr',
      last_name: '',
      first_name: '',
      email: '',
      password: '',
      societe: '',
      vat_number: '',
      phone_mobile: '',
      phone_fixe: '',
      complement: '',
      rue: '',
      bp: '',
      zip_code: '',
      city: '',
      country: '',
      state: ''
    });

    const saveProfile = () => {
      isLoading.value = true;
      // Email must be provided and valid
      pErrors.email = profile.email === '' || (profile.email !== '' && !isValidEmail(profile.email));
      // Passwords should match
      pErrors.password = ((profile.password !== '' || passwordConfirm.value !== '') && profile.password !== passwordConfirm.value);
      // Lang must not be empty
      pErrors.lang = profile.lang === '';

      if (userType === 'admin') {
        // Country must not be empty
        pErrors.country = profile.country === '';
        // For countries AR, CA, ID, IT, JP, MX, and US, state must not be empty
        pErrors.state = ['AR', 'CA', 'ID', 'IT', 'JP', 'MX', 'US'].includes(profile.country) && profile.state === '';
      }

      let atLeastOneError = false;
      Object.keys(pErrors).forEach((key) => {
        // For the linter, there is a possibility for errors[key] to be undefined, hence "?? false"
        atLeastOneError = atLeastOneError || (pErrors[key as keyof ProfileErrorsInterface] ?? false);
      });

      if (!atLeastOneError) {
        if (userType === 'admin') {
          saveMyProfile(profile).then(() => {
            if (locale.value !== profile.lang) {
              reloadTranslations(profile.lang);
            }
            isLoading.value = false;
            showToastSuccess('savedSuccessful');
          }).catch((error: any) => {
            isLoading.value = false;
            showToastError('GENERIC_ERROR');
          });
        } else {
          const request: ShopsUsersUpdateInputItem = {
            id_shops_user: profile.id_user,
            first_name: profile.first_name,
            last_name: profile.last_name,
            lang: profile.lang,
            email: profile.email,
            password: profile.password,
            date_modification: moment()
              .format('YYYY-MM-DD, HH:mm:ss'),

            shops: [],
          };
          if (!profile.password || profile.password === '' || profile.password.trim() === '') {
            delete request.password;
          }
          UpdateShopsUsers([request]).then(() => {
            if (locale.value !== profile.lang) {
              reloadTranslations(profile.lang);
            }
            isLoading.value = false;
            showToastSuccess('savedSuccessful');
          }).catch((error: any) => {
            isLoading.value = false;
            showToastError('GENERIC_ERROR');
          });
        }
      }
    };

    const countryAndState = ref<CountryAndStateValues>({
      country: '',
      state: '',
    });

    watch(countryAndState, () => {
      profile.country = countryAndState.value.country;
      profile.state = countryAndState.value.state;
    });

    const assignUserData = async () => {
      const { item, err } = await getUserProfile(UserState.user.id);

      if (err) {
        await showToastError('GENERIC_ERROR');
        return;
      }

      profile.id_user = item?.id_user ?? 0;
      profile.last_name = item?.last_name ?? '';
      profile.first_name = item?.first_name ?? '';
      profile.email = item?.email ?? '';
      profile.societe = item?.societe ?? '';
      profile.vat_number = item?.vat_number ?? '';
      profile.phone_mobile = item?.phone_mobile ?? '';
      profile.phone_fixe = item?.phone_fixe ?? '';
      profile.complement = item?.complement ?? '';
      profile.rue = item?.rue ?? '';
      profile.bp = item?.bp ?? '';
      profile.zip_code = item?.zip_code ?? '';
      profile.city = item?.city ?? '';
      profile.country = item?.country ?? '';
      profile.state = item?.state ?? '';
      profile.lang = item?.lang ?? '';
      originalLocale.value = profile.lang;

      countryAndState.value = {
        country: item?.country ?? '',
        state: item?.state ?? '',
      };
    };

    const assignSubUserData = async () => {
      const { item, err } = await getSubUserProfile(UserState.user.id);

      if (err) {
        await showToastError('GENERIC_ERROR');
        return;
      }

      profile.id_user = item?.id_shops_user ?? 0;
      profile.last_name = item?.last_name ?? '';
      profile.first_name = item?.first_name ?? '';
      profile.email = item?.email ?? '';
      profile.lang = item?.lang ?? '';
    };

    onBeforeMount(async () => {
      let userStatsPages;
      let userEmailsPages;

      if (userType === 'admin') {
        await assignUserData();

        // Get all stats pages
        userStatsPages = await getUsersPagesConfiguration(StatsType.STATS, UserState.user.id.toString());
        if (userStatsPages && userStatsPages.items && userStatsPages.items.length) {
          userStatsPages.items.forEach((userStatsPage) => {
            let pageName = userStatsPage.page_name ?? '';
            try {
              const parsedPageName = JSON.parse(pageName);
              if (locale.value in parsedPageName) {
                pageName = parsedPageName[locale.value];
              } else {
                pageName = userStatsPage.page_name ?? '';
              }
            } catch (error) {
              pageName = userStatsPage.page_name ?? '';
            }
            const page: DropdownOption = { label: pageName, value: userStatsPage.id_users_pages_configuration };
            dashboardsList.value.push(page);
            allStatsPagesList.value.push(page);
            statsPagesList.value.push(page);
          });
        }

        // Get all emails pages
        userEmailsPages = await getUsersPagesConfiguration(StatsType.EMAIL, UserState.user.id.toString());
        if (userEmailsPages && userEmailsPages.items && userEmailsPages.items.length) {
          userEmailsPages.items.forEach((userEmailsPage) => {
            let pageName = userEmailsPage.page_name ?? '';
            try {
              const parsedPageName = JSON.parse(pageName);
              if (locale.value in parsedPageName) {
                pageName = parsedPageName[locale.value];
              } else {
                pageName = userEmailsPage.page_name ?? '';
              }
            } catch (error) {
              pageName = userEmailsPage.page_name ?? '';
            }
            const page: DropdownOption = { label: pageName, value: userEmailsPage.id_users_pages_configuration };
            emailsPagesList.value.push(page);
          });
        }
      } else {
        // if sub user get data from shops_users
        await assignSubUserData();

        let idAdminUser = '';
        if (UserState.activeShop) {
          idAdminUser = UserState.activeShop.idUser.toString();
        }

        // Get all stats pages
        userStatsPages = await getUsersPagesConfiguration(StatsType.STATS, idAdminUser);
        if (userStatsPages && userStatsPages.items && userStatsPages.items.length) {
          userStatsPages.items.forEach((userStatsPage) => {
            let pageName = userStatsPage.page_name ?? '';
            try {
              const parsedPageName = JSON.parse(pageName);
              if (locale.value in parsedPageName) {
                pageName = parsedPageName[locale.value];
              } else {
                pageName = userStatsPage.page_name ?? '';
              }
            } catch (error) {
              pageName = userStatsPage.page_name ?? '';
            }
            const page: DropdownOption = { label: pageName, value: userStatsPage.id_users_pages_configuration };
            dashboardsList.value.push(page);
            allStatsPagesList.value.push(page);
            statsPagesList.value.push(page);
          });
        }

        // Get all emails pages
        userEmailsPages = await getUsersPagesConfiguration(StatsType.EMAIL, idAdminUser);
        if (userEmailsPages && userEmailsPages.items && userEmailsPages.items.length) {
          userEmailsPages.items.forEach((userEmailsPage) => {
            let pageName = userEmailsPage.page_name ?? '';
            try {
              const parsedPageName = JSON.parse(pageName);
              if (locale.value in parsedPageName) {
                pageName = parsedPageName[locale.value];
              } else {
                pageName = userEmailsPage.page_name ?? '';
              }
            } catch (error) {
              pageName = userEmailsPage.page_name ?? '';
            }
            const page: DropdownOption = { label: pageName, value: userEmailsPage.id_users_pages_configuration };
            emailsPagesList.value.push(page);
          });
        }
      }

      // We get the current dashboard and stats pages for current user
      const dashboards = await getUserStatsPages(UserState.user.id, UserState.user.userType, StatsType.DASHBOARD, UserState.activeShop?.idUser);

      if (dashboards && dashboards.items) {
        dashboard.value = dashboards.items[0].id_users_pages_configuration;
      }

      const oldStatsPages = await getUserStatsPages(UserState.user.id, UserState.user.userType, StatsType.STATS, UserState.activeShop?.idUser);

      if (oldStatsPages && oldStatsPages.items && oldStatsPages.items.length) {
        const oldPagesIds: number[] = [];
        oldStatsPages.items.forEach((page) => {
          oldPagesIds.push(page.id_users_pages_configuration);
        });

        if (oldPagesIds.length) {
          // Get all pages
          const { items } = await List<UsersPagesConfiguration>({
            name: 'UsersPagesConfiguration',
            settings: {
              filter: [
                {
                  field: 'id_users_pages_configuration',
                  operator: OperatorType.In,
                  value: oldPagesIds.join(','),
                },
              ],
              order: [
                {
                  field: 'id_users_pages_configuration',
                  type: 'ASC',
                },
              ],
              limit: 0,
              offset: 0,
            },
            fields: ['id_users_pages_configuration', 'page_name'],
          });

          if (items && items.length) {
            items.forEach((page) => {
              let pageName = page.page_name;
              try {
                const parsedPageName = JSON.parse(pageName);
                if (locale.value in parsedPageName) {
                  pageName = parsedPageName[locale.value];
                } else {
                  pageName = page.page_name;
                }
              } catch (error) {
                pageName = page.page_name;
              }
              const currentStatsPageOption: DropdownOption = { label: pageName, value: page.id_users_pages_configuration };
              statsPages.value.push(currentStatsPageOption);
            });
          }
        }
      }

      const oldEmailsPages = await getUserStatsPages(UserState.user.id, UserState.user.userType, StatsType.EMAIL, UserState.activeShop?.idUser);

      if (oldEmailsPages && oldEmailsPages.items && oldEmailsPages.items.length) {
        const oldEmailsPagesIds: number[] = [];
        oldEmailsPages.items.forEach((page) => {
          oldEmailsPagesIds.push(page.id_users_pages_configuration);
        });

        if (oldEmailsPagesIds.length) {
          // Get all pages
          const { items } = await List<UsersPagesConfiguration>({
            name: 'UsersPagesConfiguration',
            settings: {
              filter: [
                {
                  field: 'id_users_pages_configuration',
                  operator: OperatorType.In,
                  value: oldEmailsPagesIds.join(','),
                },
              ],
              order: [
                {
                  field: 'id_users_pages_configuration',
                  type: 'ASC',
                },
              ],
              limit: 0,
              offset: 0,
            },
            fields: ['id_users_pages_configuration', 'page_name'],
          });

          if (items && items.length) {
            items.forEach((page) => {
              let pageName = page.page_name;
              try {
                const parsedPageName = JSON.parse(pageName);
                if (locale.value in parsedPageName) {
                  pageName = parsedPageName[locale.value];
                } else {
                  pageName = page.page_name;
                }
              } catch (error) {
                pageName = page.page_name;
              }
              const currentEmailsPageOption: DropdownOption = { label: pageName, value: page.id_users_pages_configuration };
              emailsPages.value.push(currentEmailsPageOption);
            });
          }
        }
      }
    });

    const setCheckUserState = () => {
      const index = tabsArr.findIndex((element) => element === 'offer');

      checkUserState.value = index === activeTab.value;
    };

    onMounted(() => {
      setCheckUserState();
      setTabIndex();
    });

    watch(() => props.tab, () => {
      const index = tabsArr.findIndex((element) => element === props.tab);

      if (index !== -1) {
        activeTab.value = index;
      }
    });

    watch(() => activeTab.value, () => {
      setCheckUserState();
    });

    return {
      t,
      dashboard,
      dashboardsList,
      statsPages,
      statsPagesList,
      emailsPages,
      emailsPagesList,
      pErrors,
      profile,
      passwordConfirm,
      availableLanguage,
      countryAndState,
      saveProfile,
      saveStatisticsReports,
      findPermission,
      activeTab,
      userType,
      isLoading,
      checkUserState,
      loadingSave,
    };
  },
});
