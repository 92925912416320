<template>
  <div class="block p-buttonset">
    <template v-if="!isRemoved">
      <label
        class="block my-3"
      >
        {{ t('templateBuilder.fields.imageUrl') }}
      </label>
      <div class="p-fluid flex mb-2">
        <InputText
          v-model="selectedImage"
          class="input-text"
          :id="inputUniqueId"
          data-test-id="image-input"
          :placeholder="t('templateBuilder.fields.imageUrl')"
          @change="handleChange"
        />
      </div>
      <div class="text-center">
        <div
          v-if="selectedImage"
          class="mt-2 p-2 selected-image"
        >
          <div class="preview-container">
            <img
              :src="selectedImage"
              alt="alt"
              class="product-image"
            >
            <div class="action-buttons">
              <Button
                :title="t('templateBuilder.fields.chooseImage')"
                icon="far fa-exchange"
                class="mt-2 mr-2"
                data-test-id="image-button"
                @click="displayImageModal=true"
              />
              <Button
                :title="t('templateBuilder.fields.editImage')"
                icon="far fa-pencil"
                class="mt-2 mr-2"
                @click="editImageModal=true"
              />
              <AIButton
                type="button"
                class="mt-2 mr-2 input-button-ai"
                icon="far fa-light fa-microchip-ai"
                :ai-selector="parserValues.selector"
                :ai-params="{ target: 'template', type: 'attr', 'attr': 'src', 'updateFieldWithValue': { 'selector': '#' + inputUniqueId } }"
                ai-content-type="3"
              />
              <Button
                v-if="configs.options.remove"
                :title="t('templateBuilder.fields.removeImage')"
                icon="far fa-trash"
                class="mt-2"
                @click="handleRemove"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <label
        class="block my-3"
        data-test-id="image-label"
      >
        {{ t(configs.label) }}
      </label>
      <div class="text-center mb-2">
        <Button
          :label="t('templateBuilder.fields.chooseImage')"
          class="mb-2"
          data-test-id="image-button"
          @click="displayImageModal=true"
        />
      </div>
    </template>
    <div
      v-if="configs.options.description"
      class="mt-2"
    >
      <label class="block my-3">
        {{ t('templateBuilder.fields.imageDescription') }}
      </label>
      <div class="p-fluid flex mb-1">
        <InputText
          v-model="description"
          class="input-text"
          @input="handleChangeDescription"
        />
      </div>
    </div>
    <FileManager
      v-if="displayImageModal"
      :display-dialog="displayImageModal"
      extensions="image/*"
      @on-close-dialog="displayImageModal=false"
      @on-choose-file="onChooseImage"
    />
    <EditImageModal
      :display-dialog="editImageModal"
      :image="selectedImage"
      @on-close-dialog="editImageModal=false"
      @on-save-image="onSaveImage"
    />
  </div>
</template>

<script lang="ts">
import {
  defineComponent, PropType, Ref, ref,
} from 'vue';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import FileManager from '@/components/file-manager/FileManager.vue';
import EditImageModal from '@/components/modals/EditImageModal.vue';
import {
  FieldConfig,
  File,
  ParserFieldObject,
  Property,
} from '@/types';
import { useI18n } from 'vue-i18n';
import { removeImage, showImage } from '../callbacks';
import AIButton from "@/components/fields/AIButton.vue";

export default defineComponent({
  name: 'Image',

  components: {
    FileManager,
    AIButton,
    EditImageModal,
    Button,
    InputText,
  },

  props: {
    configs: {
      type: Object as PropType<FieldConfig>,
      required: true,
    },

    parserValues: {
      type: Object as PropType<ParserFieldObject>,
      required: true,
    },
  },

  emits: {
    'on-change-properties': Object,
  },

  setup(props, context) {
    const { t } = useI18n();
    const displayImageModal = ref(false);
    const editImageModal = ref(false);
    const srcProperty: Ref<Property> = ref(props.parserValues.properties && props.parserValues.properties.filter((property) => property.name === 'src')[0]);
    const selectedImage: Ref<string> = ref(srcProperty.value.value ?? props.configs.options.defaultImageUrl);
    const altProperty: Ref<Property> = ref(props.parserValues.properties && props.parserValues.properties.filter((property) => property.name === 'alt')[0]);
    const description: Ref<string> = ref((altProperty.value && altProperty.value.value) ?? '');
    const isRemoved: Ref<boolean> = ref(false);

    const handleChange = () => {
      srcProperty.value.value = selectedImage.value;
      context.emit('on-change-properties', {
        selector: props.parserValues.selector,
        properties: [srcProperty.value],
      });
    };

    const handleChangeDescription = () => {
      altProperty.value.value = description.value;
      context.emit('on-change-properties', {
        selector: props.parserValues.selector,
        properties: [altProperty.value],
      });
    };

    const handleRemove = () => {
      selectedImage.value = '';
      removeImage(props.parserValues.selector);
      isRemoved.value = true;
      handleChange();
    };

    const onChooseImage = (image: File) => {
      selectedImage.value = image.url;
      handleChange();
      if (props.configs.options.remove) {
        isRemoved.value = false;
        showImage(props.parserValues.selector);
      }
    };

    const onSaveImage = (imageUrl: string) => {
      selectedImage.value = imageUrl;
      handleChange();
    };
    const createUniqueString = () => Math.random().toString(36).substring(2, 15) + Date.now().toString(36);
    return {
      t,
      displayImageModal,
      editImageModal,
      isRemoved,
      description,
      onChooseImage,
      handleChangeDescription,
      onSaveImage,
      handleChange,
      handleRemove,
      selectedImage,
      inputUniqueId: `unique-input-${createUniqueString()}`,
    };
  },
});
</script>

<style scoped lang="scss">
$img-size: 11rem;
.selected-image {
  width: 100%;
  margin: auto;
  border: 1px solid $solitude !important;
  text-align: center;

  .preview-container {
    position: relative;
    text-align: center;

    .product-image {
      width: $img-size;
      object-fit: cover;
      object-position: bottom;
      margin: auto;
    }

    .action-buttons {
      display: block;

      & .p-button {
        border: solid 1px $solitude;
        background: $white;
        color: $heather;
        border-radius: 3px;
        box-shadow: none !important;
      }
    }
  }
}
.input-text {
  font-size: 1.25rem !important;
  padding: 0.625rem 0.625rem !important;
}

.input-button-ai {
  width:2.357rem!important;
  padding:3px 0!important;
  height:auto!important;
}
</style>
