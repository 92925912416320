<template>
  <ReloadNotice :display-dialog="showReloadNotice" />
  <div class="app flex flex-auto">
    <AppSidebar
      :key="refreshKey"
      :is-mobile="isMobile"
    />
    <div class="main flex flex-column flex-auto min-h-screen min-w-0 relative w-full">
      <Header
        :key="refreshKey"
        :is-mobile="isMobile"
      />
      <div class="content h-full flex flex-auto flex-column">
        <main class="h-full flex flex-auto flex-column py-4">
          <router-view />
        </main>
        <Footer />
      </div>
    </div>
  </div>
  <TemplateEditor v-if="TemplateEditorState.isTemplateEditorVisible" />
  <AutomatedScenarioEditor v-if="AutomatedScenarioState.isEditorVisible" />
  <StatsEditor v-if="statsEditorPageId > 0" />
  <ImportCustomers
    v-if="isListImportEditorVisible"
  />
  <DataViewer v-if="isDataViewerVisible" />
  <ChoosePlanV14 v-if="isChoosePlanVisible" />
  <OpenAccessToSupportModal />
  <ServicesParametersModal v-if="isServicesParametersModalVisible" />
  <DataExplorer v-if="isDataExplorerVisible" />
</template>

<script lang="ts">
import Header from '@/components/layout/Header.vue';
import Footer from '@/components/layout/Footer.vue';
import TemplateEditor from '@/components/template-builder/TemplateEditor.vue';
import AutomatedScenarioEditor from '@/components/automated-scenarios/AutomatedScenarioEditor.vue';
import { AutomatedScenarioState } from '@/composables/AutomatedScenarios';
import { TemplateEditorState } from '@/composables/template-editor/TemplateEditor';
import StatsEditor from '@/views/stats/StatsEditor.vue';
import {
  computed, ComputedRef, defineComponent, onBeforeMount, onMounted, ref, Ref, watch,
} from 'vue';
import { useStore } from '@/store';
import AppSidebar from '@/components/layout/AppSidebar.vue';
import { UserState, hasAccessToFeatures } from '@/composables/User';
import { Request } from '@/composables/GraphQL';
import ImportCustomers from '@/views/mes-listes/customers/ImportCustomers/ImportCustomers.vue';
import Gleap from 'gleap';
import DataViewer from '@/components/data-viewer/DataViewer.vue';
import ChoosePlanV14 from '@/components/my-offer/MyOffer14/ChoosePlanV14.vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { checkIfDbCreated } from '@/configs/complete';
import { GetShopsConfigurationList } from '@/composables/shop/ShopsConfiguration';
import gleapActionForm from '@/configs/gleap-actions';
import getShopsDomains from '@/composables/shop/ShopsSendDomains';
import { GleapCustomActionName, GleapChecklistEvent } from '@/types/enums';
import OpenAccessToSupportModal from '@/components/modals/OpenAccessToSupportModal.vue';
import { showToastError } from '@/helpers';
import { getShopPendingRecurringCharges, getTagInstallerLinkRedirect } from '@/composables/user/ShopifyConfig';
import ServicesParametersModal from '@/components/modals/ServicesParametersModal.vue';
import { getMandatoryConfiguredServices } from '@/composables/shop/MyShopParameters';
import { servicesPerGleapChecklist } from '@/configs/services-parameters';
import { getLocalStorageElement } from '@/helpers/LocalStorage';
import ReloadNotice from '@/views/layout/ReloadNotice.vue';
import DataExplorer from '@/components/data-explorer/DataExplorer.vue';

export default defineComponent({
  name: 'LayoutMain',
  components: {
    DataViewer,
    ImportCustomers,
    StatsEditor,
    Footer,
    TemplateEditor,
    AutomatedScenarioEditor,
    AppSidebar,
    Header,
    ChoosePlanV14,
    OpenAccessToSupportModal,
    ServicesParametersModal,
    ReloadNotice,
    DataExplorer,
  },

  setup() {
    // Initialize Gleap chat plugin
    Gleap.setLanguage(UserState.user.lang ?? 'en');
    Gleap.initialize(process.env.VUE_APP_GLEAP_API_KEY || '');
    Gleap.setMaxNetworkRequests(50);
    const {
      locale,
    } = useI18n();
    const router = useRouter();

    const store = useStore();
    const statsEditorPageId: Ref<number> = computed(() => store.getters['statsEditor/getPageId']);
    const isListImportEditorVisible: Ref<number> = computed(() => store.getters['listImportEditor/getIsEditorVisible']);
    const isDataViewerVisible: Ref<boolean> = computed(() => store.getters['dataViewer/getIsVisible']);
    const isChoosePlanVisible: Ref<boolean> = computed(() => store.getters['choosePlan/getIsVisible']);
    const isServicesParametersModalVisible: Ref<boolean> = computed(() => store.getters['general/getIsServicesParametersModalVisible']);
    const isDataExplorerVisible: Ref<boolean> = computed(() => store.getters['dataExplorer/getIsDataExplorerVisible']);
    const showReloadNotice = ref(false);

    const urlShop: Ref<string | null> = ref(UserState.activeShop?.url ?? null);
    const cachedWebsiteContents: ComputedRef<Record<string, string>> = computed(() => store.getters['liveEditor/getWebsiteContents']);
    const refreshKey = ref(0);

    const isMobile = ref(false);
    // eslint-disable-next-line no-restricted-globals
    isMobile.value = screen.width <= 768;

    // Check gleap action
    Gleap.registerCustomAction(async (customAction) => {
      if (customAction.name === 'redirect_shopify_recurring_charge') {
        let shopPendingRecurringCharges = await getShopPendingRecurringCharges(UserState.activeShop?.id ?? 0);
        shopPendingRecurringCharges = shopPendingRecurringCharges && shopPendingRecurringCharges.success ? shopPendingRecurringCharges.charge.urlConfirmation : '';

        if (shopPendingRecurringCharges !== '') {
          window.open(shopPendingRecurringCharges, '_blank');
        } else {
          await showToastError('GENERIC_ERROR');
        }
        return;
      }
      if (customAction.name === 'install_shopify_tag') {
        let tagInstallerLinkRedirect = await getTagInstallerLinkRedirect(UserState.activeShop?.id ?? 0);
        tagInstallerLinkRedirect = tagInstallerLinkRedirect && tagInstallerLinkRedirect.success ? tagInstallerLinkRedirect.url : '';
        if (tagInstallerLinkRedirect !== '') {
          window.open(tagInstallerLinkRedirect, '_blank');
        } else {
          await showToastError('GENERIC_ERROR');
        }
        return;
      }
      if (customAction.name === 'redirect_user_to_bills') {
        await router.push({ name: 'shop.MyOffer' });
        return;
      }

      const featurePermission = hasAccessToFeatures(customAction.name);
      const isOfferV3 = UserState.hasOfferV3;

      const doGleapCustomAction = () => {
        if (customAction.name === GleapCustomActionName.GLEAP_OPEN_ACCESS_TO_SUPPORT) {
          store.commit('general/setIsOpenAccessToSupportModalVisible', true);
        } else {
          const gleapAction = gleapActionForm[customAction.name];
          if (gleapAction.isForm) {
            Gleap.startClassicForm(gleapAction.value, true);
          } else if (gleapAction.isLink) {
            window.open(gleapAction.value, '_blank');
          } else if (gleapAction.isPhone) {
            window.open(`tel:${gleapAction.value}`);
          }
        }
        store.commit('general/setGleapAction', null);
      };

      if (isOfferV3) {
        doGleapCustomAction();
      } else if (featurePermission && !featurePermission.access) {
        store.commit('general/setIsFeatureUnavailableModalVisible', true);
        store.commit('general/setFeatureUnavailableMinPlan', featurePermission.minimumPlan);
        store.commit('general/setGleapAction', customAction.name);
      } else if (!featurePermission || (featurePermission && featurePermission.access)) {
        doGleapCustomAction();
      }
    });

    Gleap.on('open', async () => {
      const shopDomains = await getShopsDomains(1, [], ['domain_verified', 'authentication_verified', 'alignment_verified', 'tracking_domain_verified']);
      const configuredServices = await getMandatoryConfiguredServices(UserState.activeShop?.id ?? 0);
      if (shopDomains && shopDomains.length) {
        const domain = shopDomains[0];
        if (domain.domain_verified) {
          Gleap.trackEvent(GleapChecklistEvent.GLEAP_CHECKLIST_VERIFY_DOMAIN);
        }
        if (domain.authentication_verified) {
          Gleap.trackEvent(GleapChecklistEvent.GLEAP_CHECKLIST_AUTHENTICATE_DOMAIN);
        }
        if (domain.alignment_verified) {
          Gleap.trackEvent(GleapChecklistEvent.GLEAP_CHECKLIST_ALIGN_DOMAIN);
        }
        if (domain.tracking_domain_verified) {
          Gleap.trackEvent(GleapChecklistEvent.GLEAP_CHECKLIST_ALIGN_REDIRECTION_LINKS);
        }
      }
      configuredServices.forEach((configuredService) => {
        Gleap.trackEvent(servicesPerGleapChecklist[configuredService]);
      });
    });

    const checkIfSameShopInUse = () => {
      const activeShopIdFromLocalStorage = getLocalStorageElement('spm_auth_current_shop_id', false, false);

      if ((!UserState.activeShop || UserState.activeShop.id.toString() !== activeShopIdFromLocalStorage) && !UserState.changingShop) {
        showReloadNotice.value = true;
      }
    };

    onMounted(() => {
      if (urlShop.value && !Object.prototype.hasOwnProperty.call(cachedWebsiteContents.value, urlShop.value)) {
        // Cache homepage of shop's website for using in templateEditor
        const query = 'query ( $url: String) { TemplatesShopWebsiteContent(shopUrl: $url) }';
        const variables = { url: urlShop.value };

        Request<string>({
          name: 'TemplatesShopWebsiteContent',
          query,
          variables,
        }).then((result) => {
          if (result.data) {
            store.commit('liveEditor/setWebsiteContents', { url: urlShop.value, content: result.data });
          }
        });
      }

      // Check for changes every 5 seconds
      setInterval(checkIfSameShopInUse, 5000);
    });

    onBeforeMount(async () => {
      store.commit('general/showTheSpinner');
      const dbCreated = await checkIfDbCreated();
      if (!dbCreated) {
        await router.push('/complete');
      }
      store.commit('general/hideTheSpinner');
    });

    watch(locale, () => {
      refreshKey.value += 1;
    });

    return {
      TemplateEditorState,
      AutomatedScenarioState,
      statsEditorPageId,
      isListImportEditorVisible,
      isDataViewerVisible,
      isMobile,
      refreshKey,
      isChoosePlanVisible,
      isServicesParametersModalVisible,
      showReloadNotice,
      isDataExplorerVisible,
    };
  },
});
</script>

<style scoped lang="scss">
.app {
  display: flex;
  height: 100%;
  .main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    .content {
      padding: 0 2rem;
      height: 100%;
      background-color: #fff;
    }
  }
}

.open-modal-btn {
  position: fixed;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}
</style>
