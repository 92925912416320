<template>
  <Sidebar
    :visible="true"
    :show-close-icon="false"
    position="full"
    class="modal-container flex flex-column"
    @hide="hide"
    @show="show"
  >
    <Toolbar class="toolbar-top">
      <template #start>
        <img
          src="@/assets/images/logo-sm.svg"
          alt="Shopimind"
          class="logo mr-3"
        >
        <h1>{{ title }}</h1>
      </template>

      <template #end>
        <div
          v-if="showCloseButton"
          class="toolbar-items-container"
        >
          <Button
            class="toolbar-button-item"
            icon="far fa-fw fa-times-circle"
            @click="closeModal"
          />
        </div>
        <div v-else>
          <ToolbarButtons
            v-if="toolbarRightButtons && toolbarRightButtons.length"
            :buttons="toolbarRightButtons"
          />
        </div>
      </template>
    </Toolbar>
    <Toolbar
      v-if="(contentHeaderToolbarStart && contentHeaderToolbarStart.length) ||
        (contentHeaderToolbarEnd && contentHeaderToolbarEnd.length)"
      class="content-header-toolbar-v2"
    >
      <template
        v-if="(contentHeaderToolbarStart && contentHeaderToolbarStart.length)"
        #start
      >
        <ToolbarButtons :buttons="contentHeaderToolbarStart" />
      </template>
      <template
        v-if="(contentHeaderToolbarEnd && contentHeaderToolbarEnd.length)"
        #end
      >
        <ToolbarButtons :buttons="contentHeaderToolbarEnd" />
      </template>
    </Toolbar>

    <div class="content-wrapper flex">
      <div
        v-if="!!$slots.sidebar"
        class="content-sidebar"
      >
        <slot name="sidebar" />
      </div>
      <div
        v-if="!!$slots.content"
        class="content flex flex-column bg-white"
      >
        <slot name="content" />
      </div>
    </div>

    <Toolbar
      v-if="(tabs && tabs.length)"
      class="toolbar-bottom overflow-x-scroll"
    >
      <template #start>
        <ToolbarButtons
          :buttons="tabs"
          :no-shrink="true"
        />
      </template>
    </Toolbar>
  </Sidebar>
</template>

<script lang="ts">
import {
  defineComponent, onMounted, onUnmounted, PropType, SetupContext,
} from 'vue';
import { PrimvueMenuModel, ToolbarItemsModel } from '@/types';
import Sidebar from 'primevue/sidebar';
import Toolbar from 'primevue/toolbar';
import ToolbarButtons from '@/components/ToolbarButtons.vue';
import Button from 'primevue/button';

export default defineComponent({
  name: 'EditorModal',

  components: {
    Sidebar,
    Toolbar,
    ToolbarButtons,
    Button,
  },

  props: {
    title: {
      type: String,
      required: false,
      default: () => '',
    },

    toolbarRightButtons: {
      type: Array as PropType<PrimvueMenuModel[]>,
      required: false,
      default: () => [],
    },

    contentHeaderToolbarStart: {
      type: Array as PropType<ToolbarItemsModel[]>,
      required: false,
      default: () => [],
    },

    contentHeaderToolbarEnd: {
      type: Array as PropType<ToolbarItemsModel[]>,
      required: false,
      default: () => [],
    },

    tabs: {
      type: Array as PropType<PrimvueMenuModel[]>,
      required: false,
      default: () => [],
    },

    showCloseButton: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  emits: ['on-close-modal'],

  setup(props: { }, context: SetupContext) {
    const hide = () => document.body.classList.remove('p-overflow-hidden');
    const show = () => document.body.classList.add('p-overflow-hidden');

    const closeModal = () => {
      context.emit('on-close-modal');
    };

    onMounted(show);
    onUnmounted(hide);

    return {
      hide, show, closeModal,
    };
  },

});
</script>

<style lang="scss">
.modal-container.p-sidebar .p-sidebar-header {
  display: none;
  padding: 0;
}

.modal-container.p-sidebar .p-sidebar-content {
  padding: 0;
  border: none;
}

.content-sidebar {
  position: relative;

  .configuration-wrapper {
    .field-wrapper {
      margin-bottom: 1rem;
      label {
        font-weight: normal;
        color: #6c757d;
        font-size: 0.95rem;
        font-family: "Source Sans Pro", sans-serif;
      }
    }
  }
}

.toolbar-items-container {
  .toolbar-button-item {
    background-color: white;
    color: $tuna;
    border: 1px solid $light-grey;
    box-shadow: none !important;

    &:hover {
      color: $brand-color-primary;
      border: 1px solid $light-grey;
    }

    &:active:not(.disabled) {
      border-color: $light-grey;
      color: $brand-color-primary;
    }

    &:focus {
      border-color: $light-grey;
    }

    &.active {
      border-color: $light-grey;
      color: $brand-color-primary;
    }

    &.disabled {
      border-color: $light-grey;
      color: $heather;
    }
  }
}
</style>

<style scoped lang="scss">
.modal-container {
  overflow: hidden;
}

.content-header {
  padding: 5px;
}

.content-header-toolbar-v2 {
  padding: 5px;
  background-color: $white;
}

.toolbar-top {
  background: $tuna;
  padding: 10px;
  border: none;
  border-radius: 0;

  & h1 {
    color: $white;
    font-size: 1.4rem;
    margin: 0;
    border-bottom: none;
  }
}

.content-wrapper {
  flex: 10;
  overflow: auto;
}

.content {
  flex: 10;
  position: relative;
}

.toolbar-bottom {
  padding: 10px 0;
  z-index: 1;
  overflow-x: auto;
}

.logo {
  height: 2.25rem;
}
</style>
