<template>
  <SpmOverlayPanel
    class-content="flex align-items-center justify-content-center spm-overlay-panel-content"
    :close-panel="closeDeletePanel"
    @onclose-panel="resetVisibility"
  >
    <template #trigger>
      <SpmButton
        v-if="type === 'blockOR'"
        icon="fa-light fa-trash"
        class="p-button-secondary input-height"
        style="height: 2.5rem;"
      />
      <SpmButton
        v-else
        icon="fa-light fa-xmark"
        class="p-button-secondary input-height"
        style="height: 2.5rem;"
      />
    </template>
    <div class="flex">
      <div style="padding: 0.5rem">
        {{ title }}
      </div>
    </div>
    <template #footer>
      <div
        class="flex flex-row-reverse flex-wrap"
        style="padding: 0.5rem 0 0.5rem 0"
      >
        <SpmButton
          :label="t('no')"
          class="p-button-secondary mx-2 input-height"
          @click="closeDeletionPanel"
        />
        <SpmButton
          :label="t('yes')"
          class="p-button-danger ml-2 input-height"
          @click="removeAction"
        />
      </div>
    </template>
  </SpmOverlayPanel>
</template>

<script lang="ts">
import { defineComponent, ref, SetupContext } from 'vue';
import SpmButton from '@/components/spm-primevue/SpmButton.vue';
import SpmOverlayPanel from '@/components/spm-primevue/SpmOverlayPanel.vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'PropertyDeletePanel',

  components: {
    SpmButton,
    SpmOverlayPanel,
  },

  props: {
    title: {
      type: String,
      required: true,
    },

    type: {
      type: String,
      default: '',
      required: false,
    },
  },

  emits: ['remove-callback'],

  setup(props, { emit }: SetupContext) {
    const { t } = useI18n();
    const closeDeletePanel = ref(false);

    const resetVisibility = () => {
      closeDeletePanel.value = false;
    };

    const closeDeletionPanel = () => {
      closeDeletePanel.value = true;
    };

    const removeAction = () => {
      closeDeletionPanel();
      emit('remove-callback');
    };

    return {
      t,
      closeDeletePanel,
      resetVisibility,
      closeDeletionPanel,
      removeAction,
    };
  },
});
</script>
