import {
  WidgetTypeConfig,
  ConfigurationPanelStructureItemEnum,
  ListSelectionTypeFacebookMessengerEnum,
} from '@/types';
// eslint-disable-next-line import/no-cycle
import {
  setContent,
  setTruncateLimit,
  setSmartListProperty, setSmartListButtonTitle,
} from '@/components/template-builder/setters';
// eslint-disable-next-line import/no-cycle
import {
  getContent,
  getTruncateLimit,
  getSmartListProperty,
} from '@/components/template-builder/getters';
import variables from '@/components/template-builder/utils/variables-list';
// eslint-disable-next-line import/no-cycle
import { refreshSmartProductList } from '@/components/template-builder/callbacks';
import getInputVariablesFieldConfig from '@/components/template-builder/config/fields/input-variables-config';
import selectionTypeConfig from '@/components/template-builder/config/fields/selection-type-config';
import getItemListFieldConfig from '@/components/template-builder/config/fields/item-list-config';
import { OperatorType } from '@/types/generated-types/graphql';
import getStepperFieldConfig from '@/components/template-builder/config/fields/stepper-config';
import { UserState } from '@/composables/User';

const widgetSmartProductListConfig: WidgetTypeConfig = {
  tabPanel: {
    label: 'templateBuilder.widgetsHeaders.smartProductList',
    items: [
      {
        type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
        label: 'templateBuilder.configs.widgetConfiguration',
        selector: 'body #spm_body #{ID}',
        items: [
          {
            label: 'templateBuilder.fields.selectionType',
            selector: '{SELECTOR} .spm_product_list_element_container',
            properties: [
              {
                name: 'engine', getter: getSmartListProperty, setters: [setSmartListProperty], callbacks: [refreshSmartProductList], refreshWidgetFields: true,
              },
              {
                name: 'nb_weeks', getter: getSmartListProperty, setters: [setSmartListProperty], callbacks: [refreshSmartProductList], refreshWidgetFields: true,
              },
              {
                name: 'ids', getter: getSmartListProperty, setters: [setSmartListProperty], callbacks: [refreshSmartProductList], refreshWidgetFields: true,
              },
            ],
            ...selectionTypeConfig,
            options: {
              ...selectionTypeConfig.options,
              list: Object.values(ListSelectionTypeFacebookMessengerEnum).map((type) => ({ label: `templateBuilder.fields.selectionTypes.${type}`, value: type })),
              defaultValue: ListSelectionTypeFacebookMessengerEnum.CROSSSELLING,
            },
          },
          {
            label: 'templateBuilder.fields.productList',
            selector: '{SELECTOR} .spm_product_list_element_container',
            properties: [{
              name: 'excluded_ids', getter: getSmartListProperty, setters: [setSmartListProperty], callbacks: [refreshSmartProductList], refreshWidgetFields: true,
            }],
            ...getItemListFieldConfig(
              [],
              'ShopsProducts',
              { id: 'id_shop_product', label: 'name' },
              'templateBuilder.fields.excludeProducts',
              [{ field: 'lang', value: UserState.user.lang ?? 'fr', operator: OperatorType.Equals }, { field: 'active', value: true, operator: OperatorType.Equals }],
            ),
          },
          {
            label: 'templateBuilder.fields.categoryList',
            selector: '{SELECTOR} .spm_product_list_element_container',
            properties: [{
              name: 'excluded_categories', getter: getSmartListProperty, setters: [setSmartListProperty], callbacks: [refreshSmartProductList], refreshWidgetFields: true,
            }],
            ...getItemListFieldConfig(
              [],
              'ShopsProductsCategoriesList',
              { id: 'id_shop_product_category_list', label: 'name' },
              'templateBuilder.fields.excludeCategories',
              [{ field: 'lang', value: UserState.user.lang ?? 'fr', operator: OperatorType.Equals }],
            ),
          },
          {
            label: 'templateBuilder.fields.manufacturerList',
            selector: '{SELECTOR} .spm_product_list_element_container',
            properties: [{
              name: 'excluded_manufacturers',
              getter: getSmartListProperty,
              setters: [setSmartListProperty],
              callbacks: [refreshSmartProductList],
              refreshWidgetFields: true,
            }],
            ...getItemListFieldConfig(
              [],
              'ShopsManufacturers',
              { id: 'id_manufacturer', label: 'name' },
              'templateBuilder.fields.excludeManufacturers',
              [],
            ),
          },
          {
            label: 'templateBuilder.fields.maxDisplayedProducts',
            selector: '{SELECTOR} .spm_product_list_element_container',
            properties: [{
              name: 'nb', getter: getSmartListProperty, setters: [setSmartListProperty], callbacks: [refreshSmartProductList], refreshWidgetFields: true,
            }],
            ...getStepperFieldConfig(),
          },
          {
            label: 'templateBuilder.fields.buttonText',
            selector: '{SELECTOR} .spm_product_list_element_button a',
            properties: [{
              name: 'buttonText', getter: getContent, setters: [setSmartListButtonTitle], callbacks: [refreshSmartProductList], refreshWidgetFields: true,
            }],
            ...getInputVariablesFieldConfig('', variables.allVariables, false, false),
          },
          {
            label: 'templateBuilder.fields.maxProductTitleCharacters',
            selector: 'product.name',
            properties: [{
              name: 'truncate', getter: getTruncateLimit, setters: [setTruncateLimit], callbacks: [refreshSmartProductList], refreshWidgetFields: true,
            }],
            ...getStepperFieldConfig(),
          },
          {
            label: 'templateBuilder.fields.maxProductDescriptionCharacters',
            selector: 'product.description_short',
            properties: [{
              name: 'truncate', getter: getTruncateLimit, setters: [setTruncateLimit], callbacks: [refreshSmartProductList], refreshWidgetFields: true,
            }],
            ...getStepperFieldConfig(),
          },
        ],
      },
    ],
  },
};

export default widgetSmartProductListConfig;
