
import { useI18n } from 'vue-i18n';
import Dropdown from 'primevue/dropdown';
import Checkbox from 'primevue/checkbox';
import { defineComponent, reactive, ref } from 'vue';
import TermsAndConditions from '@/views/auth/TermsAndConditions.vue';
import SpmButton from '@/components/spm-primevue/SpmButton.vue';
import BaseTextInput from '@/components/fields/BaseTextInput.vue';
import FieldErrors from '@/components/fields/partials/FieldErrors.vue';
import { ErrorConfigForm } from '@/types/automated-scenarios';
import {
  helpers, maxLength, minLength, required, sameAs,
} from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import solutions from '@/configs/ecommerce-solutions';
import { emailFormatValidator, urlFormatValidator } from '@/helpers/CustomValidator';
import { nestPost } from '@/composables/nestApi';
import { useRouter } from 'vue-router';
import { showToastError, showToastSuccess } from '@/helpers';

export default defineComponent({
  name: 'SpeedRegistration',
  components: {
    FieldErrors,
    TermsAndConditions,
    Dropdown,
    SpmButton,
    Checkbox,
    BaseTextInput,
  },

  setup() {
    const { t } = useI18n();
    const router = useRouter();
    const isLoadingForm = ref(false);
    const formValidation = ref<any>({});
    const componentFieldErrorsKey = ref(0);
    const showTACModal = ref(false);

    const userInfo = reactive({
      email: '',
      password: '',
      confirmPassword: '',
      promoCode: '',
      shopUrl: '',
      solutionEcommerce: '',
      acceptTAC: false,
    });

    const validateRegisterFormData = async (): Promise<ErrorConfigForm> => {
      const rules = {
        email: {
          emailFormatValidator: emailFormatValidator(),
        },

        password: {
          required,
          minLength: minLength(8),
          maxLength: maxLength(40),
        },

        confirmPassword: {
          required,
          sameAsPassword: sameAs(userInfo.password),
        },

        shopUrl: {
          urlFormatValidator: urlFormatValidator(),
        },

        solutionEcommerce: {
          required,
        },

        acceptTAC: {
          isChecked: helpers.withMessage(t('customValidator.acceptTAC'), (value: any) => value),
        },
      };

      const v$ = useVuelidate(rules, userInfo);
      const success = await v$.value.$validate();

      return {
        success,
        validate: v$,
      };
    };

    const validateRegisterForm = async () => {
      isLoadingForm.value = true;
      const validation = await validateRegisterFormData();
      if (!validation.success) {
        formValidation.value = validation.validate;
        componentFieldErrorsKey.value += 1;
        isLoadingForm.value = false;
      } else {
        try {
          const result = await nestPost('v4', '/user/create', {}, {
            email: userInfo.email,
            password: userInfo.password,
            shopUrl: userInfo.shopUrl,
            solutionEcommerce: userInfo.solutionEcommerce,
            promoCode: userInfo.promoCode,
          });

          if (result && result.success) {
            await showToastSuccess(t('user.register.actionRegistration.success'));
            await router.push('/auth/login');
          } else {
            const reasonError = result.reason ?? '';
            switch (reasonError) {
              case 'data_incorrect':
                await showToastError(t('user.register.actionRegistration.dataIncorrect'));
                break;
              case 'email_already_exist':
                await showToastError(t('user.register.actionRegistration.emailAlreadyExist'));
                break;
              default:
                await showToastError(t('errorMessages.GENERIC_ERROR'));
            }
          }
        } catch (e) {
          await showToastError(t('errorMessages.GENERIC_ERROR'));
        }

        isLoadingForm.value = false;
      }
    };

    return {
      t,
      formValidation,
      componentFieldErrorsKey,
      userInfo,
      isLoadingForm,
      solutions,
      showTACModal,
      validateRegisterForm,
    };
  },
});
