
import {
  defineComponent,
  PropType,
  computed,
  ref,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { PricingPlan, SelectedPlanData, SmsPricing } from '@/types/offer-types';
import {
  yearlyPriceReduction,
} from '@/configs/offers';
import CountryDropdown from '@/components/fields/CountryDropdown.vue';
import SpmButton from '@/components/spm-primevue/SpmButton.vue';
import {
  CountryAndStateValues,
  CountryAndStateConfig,
} from '@/types/country-state-types';
import { formatNumberToCurrency } from '@/helpers/Number';
import { DEFAULT_CURRENCY } from '@/components/template-builder/utils/constants';
import { UserState } from '@/composables/User';

export default defineComponent({
  name: 'PlanFeature',

  components: {
    CountryDropdown,
    SpmButton,
  },

  props: {
    plan: {
      type: Object as PropType<PricingPlan>,
      required: true,
    },

    selectedPlan: {
      type: Object as PropType<SelectedPlanData>,
      required: true,
    },

    monthlyPagesViewed: {
      type: Number || null,
      required: true,
    },

    smsPricing: {
      type: Object as PropType<Record<string, any>>,
      required: true,
    },

    pricingPlans: {
      type: Object as PropType<Record<string, any>>,
      required: true,
    },

    discount: {
      type: Number || null,
      required: false,
      default: null,
    },

    modelValue: {
      type: Object as PropType<CountryAndStateValues>,
      required: true,
    },

    countryDropdownConfiguration: {
      type: Object as PropType<CountryAndStateConfig>,
      required: true,
    },

    planLevel: {
      type: Number,
      required: true,
    },

    showMostPopularBanner: {
      type: Boolean,
      default: false,
    },

    planSmsPricing: {
      type: Object as PropType<SmsPricing[]>,
      required: true,
    },
  },

  emits: ['update:modelValue'],

  setup(props, { emit }) {
    const { t } = useI18n();

    const currency = ref(UserState.activeShop?.currency ?? DEFAULT_CURRENCY);
    currency.value = UserState.activeShop?.solutionEcommerce === 'shopify' ? 'USD' : currency.value;

    const pricing = computed(() => {
      const plan = props.pricingPlans[props.plan.name];
      if (props.monthlyPagesViewed !== null) {
        const monthlyPagesViewedValue = props.monthlyPagesViewed;
        const exactPlan = plan.find((item: { monthlyPagesViewed: number }) => monthlyPagesViewedValue <= item.monthlyPagesViewed);
        if (exactPlan) {
          const priceOfPlan = props.discount && props.discount > 0 ? exactPlan.price - (exactPlan.price * (props.discount / 100)) : exactPlan.price;

          if (props.selectedPlan.isYearly) {
            const price = priceOfPlan * 12 * (1 - yearlyPriceReduction);
            return `${formatNumberToCurrency(Math.round(price * 100) / 100, currency.value)} ${t('shop.menu.myOffer.perYear')}`;
          }
          return `${formatNumberToCurrency(Math.round(priceOfPlan * 100) / 100, currency.value)} ${t('shop.menu.myOffer.perMonth')}`;
        }
      }
      return '';
    });

    const originalPricing = computed(() => {
      const plan = props.pricingPlans[props.plan.name];
      if (props.monthlyPagesViewed !== null) {
        const monthlyPagesViewedValue = props.monthlyPagesViewed;
        const exactPlan = plan.find((item: { monthlyPagesViewed: number }) => monthlyPagesViewedValue <= item.monthlyPagesViewed);
        if (exactPlan) {
          if (props.selectedPlan.isYearly) {
            const price = exactPlan.price * 12 * (1 - yearlyPriceReduction);
            return `${formatNumberToCurrency(Math.round(price * 100) / 100, currency.value)} ${t('shop.menu.myOffer.perYear')}`;
          }
          return `${formatNumberToCurrency(Math.round(exactPlan.price * 100) / 100, currency.value)} ${t('shop.menu.myOffer.perMonth')}`;
        }
      }
      return '';
    });

    const smsPrice = computed(() => {
      const price = props.smsPricing[props.plan.name].find((item: { value: string }) => item.value === props.modelValue.country);
      if (price) {
        return formatNumberToCurrency(price.smsPrice, currency.value, 3);
      }
      return '--';
    });

    const planSmsPrice = computed(() => {
      const price = props.planSmsPricing.find((item: { value: string }) => item.value === props.modelValue.country);
      if (price) {
        return formatNumberToCurrency(price.smsPrice, currency.value, 3);
      }
      return '--';
    });

    const selectedCountry = computed({
      get() {
        return props.modelValue;
      },
      set(newValue) {
        emit('update:modelValue', newValue);
      },
    });

    const formatNumber = (number: number) => formatNumberToCurrency(number, currency.value, 3);

    return {
      t,
      originalPricing,
      pricing,
      smsPrice,
      planSmsPrice,
      selectedCountry,

      formatNumber,
    };
  },
});
