import { Insert, List } from '@/composables/GraphQL';
import {
  OperatorType,
  OrderType,
  ShopsNotifications,
  ShopsNotificationsReadInputItem,
} from '@/types/generated-types/graphql';
import { TemplateEditorState } from '@/composables/template-editor/TemplateEditor';
import { AutomatedScenarioState } from '@/composables/AutomatedScenarios';
import { store } from '@/store';
import { computed, Ref } from 'vue';

interface NotificationArgs {
  shopId: number;
  paging?: number;
  limit?: number;
}

export const markNotificationsAsRead = async (notificationsNotRead: ShopsNotificationsReadInputItem[]) => {
  const {
    messages, status, err,
  } = await Insert<ShopsNotificationsReadInputItem>({
    name: 'ShopsNotificationsRead',
    input: notificationsNotRead,
    type: 'ShopsNotificationsReadInput',
  });

  if (err !== '' || !status) {
    // console.log(err);
  }
};

export const fetchNotifications = async ({ shopId, paging = 0, limit = 10 }: NotificationArgs): Promise<Array<any>|null> => {
  if (shopId <= 0) {
    return null;
  }

  // Check if statistic report is opened
  const statsEditorPageId: Ref<number> = computed(() => store.getters['statsEditor/getPageId']);

  if (TemplateEditorState.isTemplateEditorVisible || AutomatedScenarioState.isEditorVisible || statsEditorPageId.value > 0) {
    return null;
  }

  const { items, total, err } = await List<ShopsNotifications>({
    name: 'ShopsNotifications',
    settings: {
      limit,
      offset: paging * limit,
      order: [
        { field: 'date', type: OrderType.Desc },
      ],
      filter: [
        { field: 'id_shop', value: shopId, operator: OperatorType.Equals },
      ],
    },
    fields: ['id_shop_notification', 'title', 'message', 'type', 'date', 'shopsNotificationsRead{id_shop , id_shop_notification, date}'],
  });

  return items ?? [];
};
