<template>
  <div class="flex gap-2">
    <Dropdown
      ref="dropdownRef"
      v-model="subPropertyId"
      :options="subPropertiesOptions"
      option-label="label"
      option-value="value"
      :option-disabled="optionDisabled"
      :placeholder="t('segmentation.complex.propertyPlaceholder')"
      class="flex input-height"
      @change="resetPropertyInput"
      @before-show="onBeforeShow"
      @before-hide="onBeforeHide"
    />
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent, ref, SetupContext, Ref
} from 'vue';
import Dropdown from 'primevue/dropdown';
import { useI18n } from "vue-i18n";
import { store } from "@/store";
import validateSegmentProperty from '@/components/segmentations/utils/utils';

export default defineComponent({
  name: 'SegmentationComplexPropertyDropdown',

  components: { Dropdown },

  props: {
    propertyConfig: {
      type: Object,
      required: true,
    },

    propertyDefinition: {
      type: Object,
      required: true,
    },

    property: {
      type: String,
      required: true
    },

    index: {
      type: Number,
      required: true,
    }
  },

  emits: ['update:property', 'reset-property-input'],


  setup(props, { emit }: SetupContext) {
    const { t } = useI18n();
    const subPropertyId = ref(props.property);

    const optionDisabled = ref('');

    const subPropertiesOptions = computed(() => 
      Object.entries(props.propertyDefinition.sub_properties).map(([key, values]: [string, any]) => {
        const config = props.propertyConfig.sub_properties.filter((property: any) => property.id);
        const disabled = config.find((property: any) => property.id === values.id);
        return {
          label: t(values.label),
          value: values.id,
          disabled: disabled !== undefined && disabled.id !== subPropertyId.value,
        };
      })
    );

    const currentSegmentConfig = computed(() => store.getters['segmentationsEditor/getCurrentSegmentConfig']);

    const refreshComplexPropertyComponent = () => {
      emit('refreshComponent');
    }

    const resetPropertyInput = () => {
      emit('update:property', subPropertyId.value);
      emit('reset-property-input');
    }

    const dropdownRef = ref();
    const onBeforeShow = async () => {
      optionDisabled.value = 'disabled';
      if (currentSegmentConfig.value) {
        if (currentSegmentConfig.value.configProperty.segmentId !== props.propertyConfig.sub_properties[props.index].segmentId) {
          const { segmentId } = currentSegmentConfig.value.configProperty;
          const validation = await validateSegmentProperty(currentSegmentConfig.value.configProperty, currentSegmentConfig.value.propertyDefinition);
          if (validation && !validation.success) {
            store.commit('segmentationsEditor/pushError', { type: 'segments', value: { segmentId, value: validation.validate.value } });
            dropdownRef.value.hide();
            return false;
          }
          store.commit('segmentationsEditor/removeError', { type: 'segments', id: 'segmentId', value: segmentId });
          store.commit('segmentationsEditor/setCurrentSegmentConfig', null);
          store.commit('segmentationsEditor/setRefreshContactsNumber', true);
        }
      } else {
        store.commit('segmentationsEditor/setCurrentSegmentConfig', null);
      }
    };

    const onBeforeHide = () => {
      optionDisabled.value = '';
    }

    return {
      t,
      subPropertiesOptions,
      dropdownRef,
      subPropertyId,
      optionDisabled,
      resetPropertyInput,
      refreshComplexPropertyComponent,
      onBeforeShow,
      onBeforeHide,
    };
  },
});
</script>

