<template>
  <div class="block p-buttonset">
    <label
      class="block my-3"
    >
      {{ t('templateBuilder.fields.imageUrl') }}
    </label>
    <div class="p-fluid flex mb-2">
      <InputText
        ref="inputTextRef"
        v-model="selectedImage"
        class="input-text"
        data-test-id="image-input"
        :placeholder="t('templateBuilder.fields.imageUrl')"
        @change="handleChange"
        @blur="handleBlur"
        @focus="handleFocus"
      />
    </div>
    <div class="text-center">
      <div
        v-if="selectedImage"
        class="mt-2 p-2 selected-image"
      >
        <div class="preview-container">
          <img
            :src="selectedImage"
            alt="alt"
            class="product-image"
          >
          <div class="action-buttons">
            <Button
              :title="t('templateBuilder.fields.chooseImage')"
              icon="far fa-exchange"
              class="mt-2 mr-2"
              data-test-id="image-button"
              @click="displayImageModal=true"
            />
            <Button
              :title="t('templateBuilder.fields.editImage')"
              icon="far fa-pencil"
              class="mt-2 mr-2"
              @click="editImageModal=true"
            />
          </div>
        </div>
      </div>
      <div
        v-else
        class="text-center mb-2"
      >
        <Button
          :label="t('templateBuilder.fields.chooseImage')"
          class="mb-2"
          data-test-id="image-button"
          @click="displayImageModal=true"
        />
        <FileManager
          v-if="displayImageModal"
          :display-dialog="displayImageModal"
          extensions="image/*"
          @on-close-dialog="displayImageModal=false"
          @on-choose-file="onChooseImage"
        />
      </div>
      <FileManager
        v-if="displayImageModal"
        :display-dialog="displayImageModal"
        extensions="image/*"
        @on-close-dialog="displayImageModal=false"
        @on-choose-file="onChooseImage"
      />
      <EditImageModal
        :display-dialog="editImageModal"
        :image="selectedImage"
        @on-close-dialog="editImageModal=false"
        @on-save-image="onSaveImage"
      />
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent, Ref, ref, SetupContext, PropType,
} from 'vue';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import FileManager from '@/components/file-manager/FileManager.vue';
import EditImageModal from '@/components/modals/EditImageModal.vue';
import {
  File,
  Translation,
} from '@/types';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'BaseImage',

  components: {
    FileManager,
    EditImageModal,
    Button,
    InputText,
  },

  props: {
    modelValue: {
      type: String,
      required: true,
    },

    translation: {
      type: Object as PropType<Translation>,
      required: false,
      default: null,
    },
  },

  emits: {
    'update:modelValue': String,
    'activate-group': String,
    'deactivate-group': String,
  },

  setup(props, { emit }: SetupContext) {
    const { t } = useI18n();
    const displayImageModal = ref(false);
    const editImageModal = ref(false);
    const selectedImage: Ref<string> = ref(props.modelValue);

    const handleChange = () => {
      emit('update:modelValue', selectedImage.value);
    };

    const onChooseImage = (image: File) => {
      selectedImage.value = image.url;
      handleChange();
    };

    const onSaveImage = (imageUrl: string) => {
      selectedImage.value = imageUrl;
      handleChange();
    };

    const inputTextRef = ref();

    const focus = () => {
      if (inputTextRef.value) {
        inputTextRef.value.$el.focus();
      }
    };

    const handleFocus = () => {
      if (props.translation) {
        emit('activate-group', props.translation.groupKey);
      }
    };

    const handleBlur = () => {
      if (props.translation) {
        emit('deactivate-group', props.translation.groupKey);
      }
    };

    return {
      t,
      displayImageModal,
      editImageModal,
      onChooseImage,
      onSaveImage,
      handleChange,
      selectedImage,
      inputTextRef,
      focus,
      handleFocus,
      handleBlur,
    };
  },
});
</script>

<style scoped lang="scss">
$img-size: 11rem;
.selected-image {
  width: 100%;
  margin: auto;
  border: 1px solid $solitude !important;
  text-align: center;

  .preview-container {
    position: relative;
    text-align: center;

    .product-image {
      width: $img-size;
      object-fit: cover;
      object-position: bottom;
      margin: auto;
    }

    .action-buttons {
      display: block;

      & .p-button {
        border: solid 1px $solitude;
        background: $white;
        color: $heather;
        border-radius: 3px;
        box-shadow: none !important;
      }
    }
  }
}
.input-text {
  font-size: 1.25rem !important;
  padding: 0.625rem 0.625rem !important;
}
</style>
