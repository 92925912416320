
import {
  computed,
  defineComponent, ref, SetupContext, watch, onMounted,
} from 'vue';
import BaseInputNumber from '@/components/fields/partials/BaseInputNumber.vue';
import FieldErrors from '@/components/fields/partials/FieldErrors.vue';
import { UserState } from '@/composables/User';
import { getCurrencySymbol } from '@/helpers/Number';
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: 'SegmentInputNumber',

  components: {
    BaseInputNumber,
    FieldErrors,
  },

  props: {
    modelValue: {
      type: Number,
      required: true,
    },

    min: {
      type: Number,
      required: true,
    },

    max: {
      type: Number,
      required: true,
    },

    suffix: {
      type: String,
      required: false,
      default: '',
    },

    errorObj: {
      type: Object,
      required: false,
      default: () => ({}),
    },

    fieldId: {
      type: String,
      required: true,
    },

    hasCurrency: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  emits: ['update:modelValue'],

  setup(props: { modelValue: number; errorObj: any; suffix: string; hasCurrency: boolean }, { emit }: SetupContext) {
    const { locale } = useI18n();
    const value = ref(props.modelValue);
    const errors = computed(() => props.errorObj.value?.error);
    const fieldKey = computed(() => props.errorObj.value?.id);

    const inputSuffix = ref(props.suffix);

    watch(value, () => {
      emit('update:modelValue', value.value);
    });

    onMounted(() => {
      if (props.hasCurrency) {
        inputSuffix.value = ` ${getCurrencySymbol(UserState.activeShop?.currency ?? 'EUR', locale.value)}`;
      }
    });

    return {
      value,
      errors,
      fieldKey,
      inputSuffix,
    };
  },
});
