// ADYEN_ENVIRONMENT="test"
// ADYEN_ENVIRONMENT_KEY="test_EO7FK2QT45E6BLLAFYXLFTPKD4OZIWHQ"
// ADYEN_MERCHANT_ACCOUNT="ShopymindECOM"
// ADYEN_X_API_KEY="AQEkhmfuXNWTK0Qc+iSDmmsovumSRo9Tu7HcqYKqbOEiQjuldd9EEMFdWw2+5HzctViMSCJMYAc=-Kk/mo1XhIO7VqDwJdyx3qwAHKLaIjEuc2MIBOEb4nHk=-C}fPWq6S28:pnj.m"
// ADYEN_HMAC_KEY="85DDD8C877E50C2EFC0F419763855B1F22F97714597C445013E2B90330FE0480"

// eslint-disable-next-line import/prefer-default-export
import {
  CustomSingleMutation,
} from '@/composables/GraphQL';
import {
  AdyenTokensUpdateInputItem,
} from '@/types/generated-types/graphql';
import axios from 'axios';
import { nestPost } from '@/composables/nestApi';

export const adyenConfig: Record<string, any> = {

  /**
   * Customer Area :: Account > Server communication
   * @see https://docs.adyen.com/development-resources/webhooks
   */
  HMAC_key: 'B56D349A803C7AE03232FBB50A6C97FE7D3B00793E63160A4A291AA6B1738DA2',

  /**
   * Customer Area :: Account > API credentials
   * @see https://docs.adyen.com/development-resources/api-credentials
   */
  live: {
    'x-api-key': 'AQEkhmfuXNWTK0Qc+iSDmmsovumSRo9Tu7HcqYKqbOEiQjuldd9EEMFdWw2+5HzctViMSCJMYAc=-derfqz0DSHNNaz6bhQIdnqXVcC6HyV7k/2k5ZCkX2Go=-Z}^h93peN%w=xBJT',
    'client-key': 'live_ZKYEUHRAU5GK5CH75N77TBQQGUANHNNV',
    timeout: 30,
    environment: 'live',
    environmentKey: 'f002527873857549-Shopymind',
    merchantAccount: 'ShopymindECOM',
  },
  test: {
    environment: 'test',
    'client-key': 'test_EO7FK2QT45E6BLLAFYXLFTPKD4OZIWHQ',
  },
};

interface PaymentMethodArgs {
  id_user: number;
  currency: string;
  amount: number;
  country_code: string;
  locale: string;
}

export default async function GetPaymentMethods({
  id_user, currency, amount, country_code, locale,
}: PaymentMethodArgs): Promise<any|null> {
  return axios.create({ baseURL: '/api' }).post<any>('/payment-methods', {
    id_user,
    amount,
    currency,
    country_code,
    locale,
  });
}

export async function AddPaymentMethod(idUser: number, data: any): Promise<any> {
  return axios.create({ baseURL: '/api' }).post<any>('/add-payment-method', { idUser, data });
}

export async function MakePaymentCall(data: any): Promise<any> {
  return nestPost('payments', '/make-payment', {}, { data });
}

export async function MakeDetailsCall(data: any): Promise<any> {
  return nestPost('payments', '/make-payment-details', {}, { data });
}

export async function PayUnpaidBill(data: any): Promise<any> {
  return nestPost('payments', '/pay-unpaid-bill', {}, { data });
}

export async function MarkUserTokenAsDelete(idUser: number, data: any): Promise<any> {
  return axios.create({ baseURL: '/api' }).post<any>('/delete-payment-method', { idUser, data });
}

export const MakeDefault = async (adyenTokenInput: AdyenTokensUpdateInputItem[]) => CustomSingleMutation<AdyenTokensUpdateInputItem[]>({
  name: 'MakeTokenAsDefault',
  input: adyenTokenInput,
  type: 'AdyenTokensUpdateInput',
});
