<template>
  <Dialog
    v-model:visible="dialogVisible"
    :modal="true"
    :draggable="false"
    :closable="true"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    style="width: 60vw"
    @update:visible="updateDialogVisibility"
  >
    <template #header>
      <div>
        <h3>{{ translation('storeParameters.services.productRecommendations.modalProductsTitle') }}</h3>
      </div>
    </template>
    <div class="container">
      <div class="p-fluid flex">
        <span class="p-input-icon-left">
          <i class="fa-thin fa-magnifying-glass" />
          <InputText
            v-model="product"
            placeholder="Search"
            @input="handleSearch"
          />
        </span>
      </div>
      <div class="flex pane-container">
        <div
          class="pane"
          style="width: 45%; overflow: hidden;"
        >
          <Listbox
            v-model="availableProducts"
            :options="productsAvailableList"
            :virtual-scroller-options="{ itemSize: 50, showLoader: true, delay: 150 }"
            list-style="height:200px"
            multiple
          >
            <template #option="slotProps">
              <div class="flex align-items-center">
                <img
                  alt=""
                  :src="slotProps.option.image_link"
                  class="mr-2"
                  style="height: 40px"
                >
                &nbsp;
                {{ `#(${slotProps.option.id_product})` }}
                &nbsp;
                {{ slotProps.option.name }}
              </div>
            </template>
            <template #loader="{ options }">
              <div
                :class="['flex items-center p-2', { 'bg-surface-100 dark:bg-surface-700': options.odd }]"
                style="height: 50px"
              >
                <Skeleton
                  :width="options.even ? '60%' : '50%'"
                  height="1.3rem"
                />
              </div>
            </template>
          </listbox>
        </div>
        <div
          class="mt-4"
          style="width: 5%"
        >
          <Button
            icon="fa-solid fa-chevrons-left"
            @click="moveItemsToAvailable"
          />
          <Button
            icon="fa-solid fa-chevrons-right"
            class="mb-3"
            @click="moveItemsToChosen"
          />
        </div>
        <div
          class="pane"
          style="width: 50%; overflow: hidden;"
        >
          <Listbox
            v-model="chosenProducts"
            :options="productsChosenList"
            :virtual-scroller-options="{ itemSize: 50, showLoader: true, delay: 150 }"
            list-style="height:200px"
            multiple
          >
            <template #option="slotProps">
              <div class="flex align-items-center">
                <img
                  alt=""
                  :src="slotProps.option.image_link"
                  class="mr-2"
                  style="height: 40px"
                >
                &nbsp;
                {{ `#(${slotProps.option.id_product})` }}
                &nbsp;
                {{ slotProps.option.name }}
              </div>
            </template>
            <template #loader="{ options }">
              <div
                :class="['flex items-center p-2', { 'bg-surface-100 dark:bg-surface-700': options.odd }]"
                style="height: 50px"
              >
                <Skeleton
                  :width="options.even ? '60%' : '50%'"
                  height="1.3rem"
                />
              </div>
            </template>
          </Listbox>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="flex justify-content-end">
        <Button @click="updateDialogVisibility">
          {{ translation('storeParameters.services.productRecommendations.validate') }}
        </Button>
      </div>
    </template>
  </Dialog>
</template>

<script lang="ts">
import {
  defineComponent, PropType, ref,
} from 'vue';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import { ShopsProducts } from '@/types/generated-types/graphql';
import Listbox from 'primevue/listbox';
import InputText from 'primevue/inputtext';
import ShopsProductsList from '@/composables/shop-products/Shop-products';
import Skeleton from 'primevue/skeleton';

export default defineComponent({
  name: 'ProductSelection',

  components: {
    Dialog,
    Button,
    Listbox,
    InputText,
    Skeleton,
  },

  props: {
    translation: {
      type: Function,
      require: true,
      default: () => ({}),
    },

    defaultLang: {
      type: String,
      required: true,
    },

    availableProductsArr: {
      type: Array as PropType<ShopsProducts[]>,
      required: true,
    },

    chosenProductsArr: {
      type: Array as PropType<ShopsProducts[]>,
      required: true,
    },

    productSearchStr: {
      type: String,
      required: true,
    },
  },

  emits: ['close-action-modal'],

  setup(props, context) {
    const dialogVisible = ref(true);
    const product = ref(props.productSearchStr);
    const productsAvailableList = ref<ShopsProducts[]>(props.availableProductsArr);
    const productsChosenList = ref<ShopsProducts[]>(props.chosenProductsArr);
    const chosenProducts = ref<ShopsProducts[]>([]);
    const availableProducts = ref<ShopsProducts[]>([]);
    let timer: any = null;

    const updateDialogVisibility = () => {
      dialogVisible.value = false;
      context.emit('close-action-modal', productsChosenList.value, productsAvailableList.value, product.value);
    };

    const handleSearch = async () => {
      clearTimeout(timer);
      timer = setTimeout(async () => {
        const result = await ShopsProductsList(product.value, props.defaultLang, 10000);
        if (result !== null) {
          availableProducts.value = [];
          productsAvailableList.value = result;
        }
      }, 1000);
    };

    const moveItemsToChosen = () => {
      productsChosenList.value = chosenProducts.value.concat(productsChosenList.value);

      productsChosenList.value.forEach((record: ShopsProducts) => {
        availableProducts.value = availableProducts.value.filter((chosenRecord: ShopsProducts) => chosenRecord.id_shop_product !== record.id_shop_product);
      });
      productsChosenList.value = productsChosenList.value.concat(availableProducts.value);

      productsChosenList.value.forEach((chosenProduct: ShopsProducts) => {
        productsAvailableList.value = productsAvailableList.value.filter((productAvailable: ShopsProducts) => productAvailable.id_shop_product !== chosenProduct.id_shop_product);
      });
      availableProducts.value = [];
      chosenProducts.value = [];
    };

    const moveItemsToAvailable = () => {
      productsAvailableList.value = productsAvailableList.value.concat(chosenProducts.value);

      chosenProducts.value.forEach((chosenProduct: ShopsProducts) => {
        productsChosenList.value = productsChosenList.value.filter((listProduct: ShopsProducts) => listProduct.id_shop_product !== chosenProduct.id_shop_product);
      });
      chosenProducts.value = [];
    };

    return {
      dialogVisible,
      updateDialogVisibility,
      product,
      handleSearch,
      productsAvailableList,
      productsChosenList,
      chosenProducts,
      availableProducts,
      moveItemsToChosen,
      moveItemsToAvailable,
    };
  },
});
</script>

<style scoped lang="scss">
.container{
  padding: 1rem;
}

.pane-container {
  height: 200px;
}

.pane {
  margin: 5px 5px 0 0;
  height: 100%;
  width:50%;
  overflow: auto;
  border: 1px solid #cccccc;
}
</style>
