
import { defineComponent, computed } from 'vue';
import {
  AutomatedScenarioState as state,
} from '@/composables/AutomatedScenarios';
import {
  hasAccessToFeatures,
} from '@/composables/User';
import { PlanName, TypeCampaignEnum } from '@/types/enums';

export default defineComponent({
  name: 'OperatorSelectionBox',

  props: {
    label: {
      type: String,
      required: true,
    },

    icon: {
      type: String,
      required: true,
    },

    kind: {
      type: String,
      required: true,
    },

    loading: {
      type: Boolean,
      required: false,
      default: false,
    },

    id: {
      type: String,
      required: false,
      default: '',
    },
  },

  setup(props: { icon: string; kind: string; id: string}) {

    const featurePermission = computed(() => hasAccessToFeatures(props.id));
    const minimumPlan = computed(() => {
      if (featurePermission.value 
        && featurePermission.value.minimumPlan !== PlanName.STANDARD
        && state.scenario.type === TypeCampaignEnum.BULK
      ) {
        return featurePermission.value.minimumPlan;
      }
      return null;
    });

    const iconClasses = `far ${props.icon}`;
    const containerClasses = {
      grid: true,
      'box-selection-display': true,
      'box-declencheur': props.kind === 'declencheur',
      'box-filtre': props.kind === 'filtre',
      'box-action': props.kind === 'action',
    };

    return {
      iconClasses,
      containerClasses,
      minimumPlan,
    };
  },
});
