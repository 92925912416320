<template>
  <div class="flex align-items-center">
    <div class="flex align-items-center justify-content-center">
      <Calendar
        v-model="range.from"
        date-format="dd/mm/yy"
        :hide-on-range-selection="true"
        :manual-input="false"
      />
    </div>
    <div
      class="flex align-items-center justify-content-center"
    >
      <label class="ml-2">
        {{ t('automatedScenarios.fields.and') }}
      </label>
      <Calendar
        v-model="range.to"
        date-format="dd/mm/yy"
        :hide-on-range-selection="true"
        :manual-input="false"
        :min-date="rulesMinMax.right.min"
        class="ml-2"
      />
    </div>
  </div>
</template>

<script lang="ts">
import {
  computed, defineComponent, onMounted, PropType, ref, Ref, SetupContext, watch,
} from 'vue';
import Calendar from 'primevue/calendar';
import { useI18n } from 'vue-i18n';
import moment from 'moment';

export default defineComponent({
  name: 'SegmentDateBetween',

  components: { Calendar },

  props: {
    modelValue: {
      type: Object as PropType<any>,
      required: true,
    },

    min: {
      type: Date,
      required: false,
      default: () => {
        moment().subtract(1, 'years').toDate();
      },
    },

    max: {
      type: Date,
      required: false,
      default: () => {
        moment().add(1, 'months').toDate();
      },
    },
  },

  emits: ['update:modelValue'],

  setup(props, { emit }: SetupContext) {
    const { t } = useI18n();

    const range: Ref<any> = ref({
      from: ((props.modelValue && props.modelValue.from) ?? moment().toDate()),
      to: ((props.modelValue && props.modelValue.to) ?? moment().add(1, 'months').toDate()),
    });

    const rulesMinMax = computed(() => ({
      left: {
        min: props.min ?? null,
        max: props.max ?? null,
      },
      right: {
        min: moment(range.value.from).add(1, 'days').toDate()
      },
    }));

    watch(range.value, () => {
      emit('update:modelValue', {
        from: range.value.from,
        to: range.value.to,
      });
    });

    onMounted(() => emit('update:modelValue', range.value));

    return {
      t,
      range,
      rulesMinMax,
    };
  },
});
</script>

<style scoped>

</style>
