export enum UserTypeEnum {
  ADMIN = 'admin',
  USER = 'user',
}

export enum SortOrderEnum {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum FilterOperatorsEnum {
  STARTS_WITH = 'startsWith',
  CONTAINS = 'contains',
  NOT_CONTAINS = 'notContains',
  ENDS_WITH = 'endsWith',
  EQUALS = 'equals',
  NOT_EQUALS = 'notEquals',
  LESS_THAN = 'lt',
  LESS_THAN_OR_EQUAL_TO = 'lte',
  GREATER_THAN = 'gt',
  GREATER_THAN_OR_EQUAL_TO = 'gte',
  IN = 'in',
  NOT_IN= 'notIn',
  BETWEEN = 'between',
  NOT_BETWEEN = 'notBetween',
  INTERVAL = 'interval'
}

export enum ConfigurationPanelStructureItemEnum {
  TAB_ITEM = 'tab-item',
  FIELDS_GROUP = 'fields-group',
  STRUCTURE_EDIT = 'structure-edit',
}

export enum StructureEditDirectionEnum {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical',
}

export enum TemplateStructureEnum {
  WIDGET = 'widget',
  COLUMN = 'column',
  LINE = 'line',
  SECTION = 'section',
  SECTION_CONTENT = 'sectionContent',
  PAGE = 'page',
  GROUP = 'group',
}

export enum TemplateStructurePositionsEnum {
  TOP = 'top',
  BOTTOM = 'bottom',
  LEFT = 'left',
  RIGHT = 'right',
}

export enum TemplateWidgetAddPositionsEnum {
  ABOVE = 'above',
  UNDER = 'under',
  INSIDE = 'inside',
}

export enum TemplateParentTypeEnum {
  NONE = '',
  EMAIL = 'email',
  DISPLAY = 'display',
  EMBED = 'embed',
  POPUP = 'popup',
  PUSHNOTIFICATIONS = 'pushnotifications',
  SMS = 'sms',
  FACEBOOKMESSENGER = 'facebookmessenger',
}

export enum TemplateParentTypeIntegerEnum {
  EMAIL = 1,
  SMS = 2,
  POPUP = 3,
  EMBED = 4,
  FACEBOOKMESSENGER = 5,
  PUSHNOTIFICATIONS = 6
}

// The value is the mapped component name
export enum ComponentNameEnum {
  BUTTON_GROUP = 'ButtonGroup',
  DRAGGABLE_BUTTON_GROUP = 'DraggableButtonGroup',
  COLOR = 'Color',
  STEPPER = 'Stepper',
  SPACES = 'Spaces',
  STEPPER_PX_PERCENT_SLIDER = 'StepperPxPercentSlider',
  FONT_FAMILY = 'FontFamily',
  LINE_HEIGHT = 'LineHeight',
  BORDERS = 'Borders',
  FONT = 'Font',
  INPUT_VARIABLES = 'InputVariables',
  TEXTAREA_VARIABLES = 'TextareaVariables',
  CODE = 'Code',
  WYSIWYG_EDITOR = 'WysiwygEditor',
  LINK = 'Link',
  DROPDOWN = 'Dropdown',
  MULTIDROPDOWN = 'MultiDropdown',
  BOX_SHADOW = 'BoxShadow',
  INFORMATION = 'Information',
  INPUT_GROUP = 'InputGroup',
  IMAGE = 'Image',
  SHARING_TYPE = 'SharingType',
  ITEM_LIST = 'ItemList',
  TRIGGER_BUTTON = 'TriggerButton',
  SELECTION_TYPE = 'SelectionType',
  ACTION_TYPE = 'ActionType',
  FACEBOOK_BUTTONS = 'FacebookButtons',
  SOCIAL_ICONS_STYLE = 'SocialIconsStyle',
  BACKGROUND_IMAGE = 'BackgroundImage',
  CUSTOM_HTML = 'CustomHtml',
}

export enum TextAlignValueEnum {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
  JUSTIFY = 'justify',
}

export enum AlignValueEnum {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
}

export enum VerticalAlignValueEnum {
  BOTTOM = 'bottom',
  MIDDLE = 'middle',
  TOP = 'top'
}

export enum ItemsVerticalAlignValueEnum {
  END = 'end',
  CENTER = 'center',
  START = 'start'
}

export enum CloseIconPositionValueEnum {
  TOP_LEFT = 'tl',
  TOP_RIGHT = 'tr',
}

export enum HeadingValueEnum {
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
}

export enum TextStylesValueEnum {
  BOLD = 'bold',
  ITALIC = 'italic',
  UNDERLINE = 'underline',
  STRIKETHROUGH = 'line-through',
  UPPERCASE = 'uppercase',
}

export enum ColorFormatEnum {
  HEX = 'hex',
  RGB = 'rgb',
  RGBA = 'rgba'
}

export enum BorderConfigurationEnum {
  BORDER_COLOR = 'border-color',
  BORDER_WIDTH = 'border-width',
  BORDER_TOP_WIDTH = 'border-top-width',
  BORDER_BOTTOM_WIDTH = 'border-bottom-width',
  BORDER_LEFT_WIDTH = 'border-left-width',
  BORDER_RIGHT_WIDTH = 'border-right-width',
  BORDER_STYLE = 'border-style',
  BORDER_TOP_STYLE = 'border-top-style',
  BORDER_BOTTOM_STYLE = 'border-bottom-style',
  BORDER_LEFT_STYLE = 'border-left-style',
  BORDER_RIGHT_STYLE = 'border-right-style'
}

export enum StepperSignEnum {
  NONE = '',
  PX = 'px',
  PERCENT = '%',
  REM = 'rem',
}

export enum SpacePropertyEnum {
  TOP = 'top',
  BOTTOM = 'bottom',
  RIGHT = 'right',
  LEFT = 'left'
}

export enum BorderRadiusPropertyEnum {
  TOP_LEFT = 'top-left',
  TOP_RIGHT = 'top-right',
  BOTTOM_LEFT = 'bottom-left',
  BOTTOM_RIGHT = 'bottom-right'
}

export enum WidgetTypeEnum {
  TITLE = 'widget-title',
  TEXT = 'widget-text',
  IMAGE = 'widget-image',
  SOCIAL_MEDIA = 'widget-social-media',
  SHARE_SOCIAL_MEDIA = 'widget-share-social-media',
  CODE = 'widget-code',
  BUTTON = 'widget-button',
  SEPARATOR = 'widget-separator',
  SMART_PRODUCT_LIST = 'widget-smart-product-list',
  GDPR_BUTTON = 'widget-gdpr-button',
  VOUCHER = 'widget-voucher',
  CREATE_ACCOUNT = 'create-account',
  NEWSLETTER_SUBSCRIPTION = 'newsletter-subscription',
  NEWSLETTER_UNSUBSCRIPTION = 'newsletter-unsubscription',
  FACEBOOK_MESSENGER = 'facebook-messenger',
  IMPORT_ZIP = 'widget-import-zip',

  SECTION = 'section',

  COLUMN = 'column',
}

export enum TypeCampaignEnum {
  AUTOMATION = 'automation',
  BULK = 'bulk',
  CUSTOMER_IMPORT = 'customer_import',
}

export enum MarketingWorkflowTypeEnum {
  MARKETING = 'marketing',
  TRANSACTIONAL = 'transactional',
}

export enum MarketingWorkflowStatusEnum {
  ACTIVE = 'active',
  DISABLED = 'disabled',
  DELETED = 'deleted',
  DRAFT = 'draft',
  ARCHIVED = 'archived'
}

export enum MarketingWorkflowsCategoryEnum {
  ALL = 'all',
  EMPTY = 'Empty',
  CONVERSION = 'Conversion',
  LOYALTY = 'Fidélisation',
  RETENTION = 'Rétention',
  ACQUISITION = 'Acquisition',
}

export enum CampaignsBulkStatusEnum {
  DRAFT = 'draft', // editer, dupliquer et supprimer
  BEING_EDITED = 'being_edited', // Editer, dupliquer et supprimer
  SCHEDULED = 'scheduled', // Editer, dupliquer et supprimer
  SENDING = 'sending', // Stop l'envoie et dupliquer
  SENT = 'sent', // Dupliquer et archiver
  SUSPENDED_BY_USER = 'suspended_by_user', // Dupliquer et archiver
  SUSPENDED = 'suspended', // Dupliquer et archiver
  ARCHIVED_BY_USER = 'archived_by_user', // Dupliquer
  ARCHIVED = 'archived',
  DELETED = 'deleted',
}
export enum LinkTypeEnum {
  NONE = 'none',
  WEB_ADDRESS = 'webAddress',
  ANCHOR = 'anchor',
  EMAIL_ADDRESS = 'emailAddress',
  DISPLAY_TEMPLATE = 'displayTemplate',
  CLOSE_POPIN = 'closePopin',
}

export enum ActionTypeEnum {
  DISPLAY_MESSAGE = 'displayMessage',
  REDIRECT = 'redirectToUrl',
  DISPLAY_TEMPLATE = 'displayTemplate',
  EXECUTE_JS = 'executeJS',
}

export enum SharingTypeEnum {
  ONLINE_VERSION = 'onlineVersion',
  CUSTOM_URL = 'customUrl',
}

export enum AppearEffectEnum {
  NONE = 'none',
  BOUNCE = 'bounce',
  FLASH = 'flash',
  PULSE = 'pulse',
  RUBBERBAND = 'rubberBand',
  SHAKE = 'shake',
  HEADSHAKE = 'headShake',
  SWING = 'swing',
  TADA = 'tada',
  WOBBLE = 'wobble',
  JELLO = 'jello',
  BOUNCEIN = 'bounceIn',
  BOUNCEINDOWN = 'bounceInDown',
  BOUNCEINLEFT = 'bounceInLeft',
  BOUNCEINRIGHT = 'bounceInRight',
  BOUNCEINUP = 'bounceInUp',
  FADEIN = 'fadeIn',
  FADEINDOWN = 'fadeInDown',
  FADEINDOWNBIG = 'fadeInDownBig',
  FADEINLEFT = 'fadeInLeft',
  FADEINLEFTBIG = 'fadeInLeftBig',
  FADEINRIGHT = 'fadeInRight',
  FADEINRIGHTBIG = 'fadeInRightBig',
  FADEINUP = 'fadeInUp',
  FADEINUPBIG = 'fadeInUpBig',
  FLIPINX = 'flipInX',
  FLIPINY = 'flipInY',
  LIGHTSPEEDIN = 'lightSpeedIn',
  ROTATEIN = 'rotateIn',
  ROTATEINDOWNLEFT = 'rotateInDownLeft',
  ROTATEINDOWNRIGHT = 'rotateInDownRight',
  ROTATEINUPLEFT = 'rotateInUpLeft',
  ROTATEINUPRIGHT = 'rotateInUpRight',
  JACKINTHEBOX = 'jackInTheBox',
  ROLLIN = 'rollIn',
  ZOOMIN = 'zoomIn',
  ZOOMINDOWN = 'zoomInDown',
  ZOOMINLEFT = 'zoomInLeft',
  ZOOMINRIGHT = 'zoomInRight',
  ZOOMINUP = 'zoomInUp',
  SLIDEINDOWN = 'slideInDown',
  SLIDEINLEFT = 'slideInLeft',
  SLIDEINRIGHT = 'slideInRight',
  SLIDEINUP = 'slideInUp',
  HEARTBEAT = 'heartBeat',
}

export enum ListSelectionTypeEnum {
  CROSSSELLING ='crossselling',
  PRODUCTS_CART ='products_cart',
  PRODUCTS_ORDER ='products_order',
  CATALOG_BESTSELLERS ='catalog_bestsellers',
  CATALOG_NEWEST ='catalog_newest',
  CATEGORIES_BESTSELLERS ='categories_bestsellers',
  CURRENT_CATEGORY_BESTSELLERS ='current_category_bestsellers',
  CATEGORIES_BESTSELLERS_LAST_CATEGORY_VISITED ='categories_bestsellers_last_category_visited',
  CATEGORIES_BESTSELLERS_LAST_PRODUCT_VISITED ='categories_bestsellers_last_product_visited',
  CATEGORIES_BESTSELLERS_BEST_CATEGORY_CONTEXT ='categories_bestsellers_best_category_context',
  CATEGORIES_BESTSELLERS_CATEGORY_BEST_PRODUCT_CONTEXT ='categories_bestsellers_category_best_product_context',
  CATEGORIES_NEWEST ='categories_newest',
  CATEGORIES_RANDOM ='categories_random',
  MANUFACTURERS_BESTSELLERS ='manufacturers_bestsellers',
  CURRENT_MANUFACTURER_BESTSELLERS ='current_manufacturer_bestsellers',
  MANUFACTURERS_NEWEST ='manufacturers_newest',
  MANUFACTURERS_RANDOM ='manufacturers_random',
  MANUAL_SELECTION ='manual_selection',
}

export enum ListSelectionTypeFacebookMessengerEnum {
  CROSSSELLING ='crossselling',
  PRODUCTS_CART ='products_cart',
  PRODUCTS_ORDER ='products_order',
  CATALOG_BESTSELLERS ='catalog_bestsellers',
  CATALOG_NEWEST ='catalog_newest',
  CATEGORIES_BESTSELLERS_LAST_CATEGORY_VISITED ='categories_bestsellers_last_category_visited',
  CATEGORIES_BESTSELLERS_LAST_PRODUCT_VISITED ='categories_bestsellers_last_product_visited',
  CATEGORIES_BESTSELLERS_BEST_CATEGORY_CONTEXT ='categories_bestsellers_best_category_context',
  CATEGORIES_BESTSELLERS_CATEGORY_BEST_PRODUCT_CONTEXT ='categories_bestsellers_category_best_product_context',
}

export enum ElementPositionEnum {
  TOP_LEFT = 'tl',
  TOP_CENTER = 'tc',
  TOP_RIGHT = 'tr',
  MIDDLE_LEFT = 'ml',
  MIDDLE_CENTER = 'mc',
  MIDDLE_RIGHT = 'mr',
  BOTTOM_LEFT = 'bl',
  BOTTOM_CENTER = 'bc',
  BOTTOM_RIGHT = 'br',
}

export enum BoxShadowEnum {
  NONE = 'none',
  CENTER = '0px 0px',
  UPPER_LEFT = '-20px -20px',
  UPPER_MIDDLE = '0px -20px',
  UPPER_RIGHT = '20px -20px',
  RIGHT = '20px 0px',
  LOWER_RIGHT = '20px 20px',
  LOWER_MIDDLE = '0px 20px',
  LOWER_LEFT = '-20px 20px',
  LEFT = '-20px 0px'
}

export enum SocialNetworksValueEnum {
  TWITTER = 'spm_twitter',
  FACEBOOK = 'spm_facebook',
  EMAIL = 'spm_email',
  PINTEREST = 'spm_pinterest',
  TUMBLR = 'spm_tumblr',
  INSTAGRAM = 'spm_instagram',
  YOUTUBE = 'spm_youtube',
  WHATSAPP = 'spm_whatsapp',
  SNAPCHAT = 'spm_snapchat',
}

export enum BoolOneZero {
  TRUE = '1',
  FALSE = '0',
}

export enum DaysOfWeekIndex {
  Monday = 0,
  Tuesday = 1,
  Wednesday = 2,
  Thursday = 3,
  Friday = 4,
  Saturday = 5,
  Sunday = 6,
}

export enum DaysOfWeek {
  Monday = 'Mon',
  Tuesday = 'Tue',
  Wednesday = 'Wed',
  Thursday = 'Thu',
  Friday = 'Fri',
  Saturday = 'Sat',
  Sunday = 'Sun',
}

export enum EmailRejectType {
  SPAM = 'spam',
  SOFT_BOUNCE = 'soft-bounce',
  HARD_BOUNCE = 'hard-bounce',
  UNSUB = 'unsub',
}

export enum CloseIconListEnum {
  CANCEL_1 = 'cancel-1',
  CANCEL_CIRCLED_1 = 'cancel-circled-1',
  CANCEL_SQUARED = 'cancel-squared',
  CANCEL_OUTLINE = 'cancel-outline',
  CANCEL_ALT = 'cancel-alt',
  CANCEL_ALT_FILLED = 'cancel-alt-filled',
  CANCEL_CIRCLED_OUTLINE = 'cancel-circled-outline',
  CANCEL_3 = 'cancel-3',
  CANCEL_5 = 'cancel-5',
  CANCEL_6 = 'cancel-6',
  CANCEL_CIRCLED_4 = 'cancel-circled-4',
  CANCEL_CIRCLED_3 = 'cancel-circled-3',
  CANCEL_CIRCLED2_1 = 'cancel-circled2-1',
  CANCEL_CIRCLE_2 = 'cancel-circle-2',
  WINDOW_CLOSE = 'window-close',
  WINDOW_CLOSE_O = 'window-close-o',
}

export enum VoucherIconListEnum {
  HIDDEN = 'hidden',
  GIFT = 'gift',
  GIFT_1 = 'gift-1',
  GIFT_2 = 'gift-2',
  SCISSORS_OUTLINE = 'scissors-outline',
  SCISSORS = 'scissors',
  STAR = 'star',
  STAR_1 = 'star-1',
  STAR_2 = 'star-2',
  EMO_BEER = 'emo-beer',
  EMO_SURPRISED = 'emo-surprised',
  EMO_TONGUE = 'emo-tongue',
  EMO_WINK2 = 'emo-wink2',
  EMO_SAINT = 'emo-saint',
  EMO_LAUGH = 'emo-laugh',
  EMO_HAPPY = 'emo-happy',
  TAGS = 'tags',
  TAGS_1 = 'tags-1',
  TAG = 'tag',
  ROCKET_1 = 'rocket-1',
  PAPER_PLANE_1 = 'paper-plane-1',
  BASKET = 'basket',
  BASKET_1 = 'basket-1',
  BASKET_2 = 'basket-2',
  THUMBS_UP = 'thumbs-up',
  THUMBS_UP_1 = 'thumbs-up-1',
  THUMBS_UP_2 = 'thumbs-up-2',
  THUMBS_UP_3 = 'thumbs-up-3',
  LEFT_HAND = 'left-hand',
  RIGHT_HAND = 'right-hand',
  MEGAPHONE = 'megaphone',
  MEGAPHONE_1 = 'megaphone-1',
  HEART = 'heart',
  HEART_1 = 'heart-1',
  SMILEY = 'smiley',
  MONEY = 'money',
  ROCKET = 'rocket',
  THUMBS_UP_ALT = 'thumbs-up-alt',
  PAPER_PLANE = 'paper-plane',
  HAND_SCISSORS_O = 'hand-scissors-o',
  HAND_PEACE_O = 'hand-peace-o',
  SHOPPING_BAG = 'shopping-bag',
  SHOPPING_BASKET = 'shopping-basket',
}

export enum SmartProductListLayoutEnum {
  HORIZONTAL_1 = 'horizontal_1',
  HORIZONTAL_2 = 'horizontal_2',
  HORIZONTAL_3 = 'horizontal_3',
  HORIZONTAL_4 = 'horizontal_4',
  VERTICAL_1 = 'vertical_1',
}

export enum SocialNetworkIconStyleEnum {
  STYLE_1 = '1',
  STYLE_2 = '2',
  STYLE_3 = '3',
  STYLE_4 = '4',
  STYLE_5 = '5',
  STYLE_6 = '6',
  STYLE_7 = '7',
  STYLE_8 = '8',
  STYLE_9 = '9',
  STYLE_10 = '10',
  STYLE_11 = '11',
  STYLE_12 = '12',
  STYLE_13 = '13',
  STYLE_14 = '14',
  STYLE_15 = '15',
  STYLE_16 = '16',
  STYLE_17 = '17',
  STYLE_18 = '18',
  STYLE_19 = '19',
  STYLE_20 = '20',
}

export enum TemplateWidth {
  DESKTOP = '100%',
  TABLET = '810px',
  MOBILE = '590px',
}

export enum HistoryType {
  POINT_OF_SAVE = 'point_of_save',
  LEGAL_MENTION_ADDED = 'legal_mention_added',
  ADD_WIDGET = 'add_widget',
  WIDGET_CHANGE = 'widget_change',
  WIDGET_SAVE = 'widget_save',
  DELETE_WIDGET = 'delete_widget',
  ADD_ROW = 'add_row',
  ROW_SAVE = 'row_save',
  ROW_CHANGE = 'row_change',
  ADD_COLUMN = 'add_column',
  COLUMN_SAVE = 'column_save',
  COLUMN_CHANGE = 'column_change',
  DELETE_COLUMN = 'delete_column',
  ADD_SECTION = 'add_section',
  SECTION_SAVE = 'section_save',
  SECTION_CHANGE = 'section_change',
  DELETE_SECTION = 'delete_section',
  DESIGN_CHANGE = 'design_change',
  IMAGE_INSERT = 'image_insert',
  TEXT_EDIT = 'text_edit',
  TRANSLATIONS_CHANGE = 'translations_change',
  EMBED_CHANGE_POSITION = 'embed_change_position',
  DYNAMIC_DESIGN_CSS_CHANGE = 'dynamic_design_css_change',
  ADVANCED_CSS_STATIC_CHANGE = 'advanced_css_static_change',
  ADVANCED_CSS_STATIC_MOBILE_CHANGE = 'advanced_css_static_mobile_change',
  DISPLAY_CHANGE_URL = 'display_change_url',
  WIDGET_ORDER_CHANGE = 'widget_order_change',
  ROW_ORDER_CHANGE = 'row_order_change',
  SECTIONS_ORDER_CHANGE = 'sections_order_change',
  DELETE_ROW = 'delete_row',
  DUPLICATE_SECTION = 'duplicate_section',
  DUPLICATE_ROW = 'duplicate_row',
  DUPLICATE_COLUMN = 'duplicate_column',
  DUPLICATE_WIDGET = 'duplicate_widget',
  INTERMEDIATE_POINT_OF_SAVE = 'intermediate_point_of_save'
}

export enum TimeUnits {
  SECOND = 'SECOND',
  MINUTE = 'MINUTE',
  HOUR = 'HOUR',
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  YEAR = 'YEAR',
}

export enum FacebookMessengerSkins {
  LIGHT = 'light',
  DARK = 'dark',
}

export enum FacebookMessengerSkinsUrlPreview {
  LIGHT = 'https://media.shopimind.io/img/facebook-checkbox-plugin/standard-light.png',
  DARK = 'https://media.shopimind.io/img/facebook-checkbox-plugin/standard-dark.png',
}

export enum SpmLanguageConditions {
  EQUALS = '=',
  NOT_EQUALS = '!=',
  LESS_THAN = '<',
  LESS_OR_EQUAL_THAN = '<=',
  MORE_THAN = '>',
  MORE_OR_EQUAL_THAN = '>=',
  HAS_ANY = 'has_any',
  IS_EMPTY = 'is_empty',
  IN = 'in',
  NOT_IN = 'not_in',
}

export enum StatsWidgets {
  ROW = 'StatsWidgetRow',
  COLUMN = 'StatsWidgetColumn',
  TABS = 'StatsWidgetTabs',
  MENU = 'StatsWidgetMenu',
  DATA = 'StatsWidgetData',
  TITLE = 'StatsWidgetTitle',
}

export enum StatsType {
  DASHBOARD = 'dashboard',
  STATS = 'stats',
  EMAIL = 'email',
}

/* Enum Stats Builder */
export enum StatsBuilderRowLayoutEnum {
  TWELVE = '12',
  SIX_SIX = '6-6',
  NINE_THREE = '9-3',
  THREE_NINE = '3-9',
  SIX_THREE_THREE = '6-3-3',
  THREE_SIX_THREE = '3-6-3',
  THREE_THREE_SIX = '3-3-6',
  FOUR_FOUR_FOUR = '4-4-4',
  THREE_THREE_THREE_THREE = '3-3-3-3'
}

export enum StatsBuilderColumnLayoutEnum {
  THREE = '3',
  FOUR = '4',
  SIX = '6',
  NINE = '9',
  TWELVE = '12',
}

export enum StatsEditorPanelEnum {
  EMPTY = 'EMPTY',
  WIDGET_EDIT_PANEL = 'WidgetEditPanel',
  ELEMENTS_LIST_PANEL = 'ElementsListPanel',
  SETTINGS_PANEL = 'SettingsPanel',
  FILTERS_PANEL = 'FiltersPanel',
}

export enum IntervalDateEnum {
  LAST_DAY = 'lastDay',
  LAST_3_DAYS = 'last3Days',
  LAST_7_DAYS = 'last7Days',
  LAST_14_DAYS = 'last14Days',
  LAST_30_DAYS = 'last30Days',
  LAST_3_MONTHS = 'last3Months',
  LAST_12_MONTHS = 'last12Months',
  CUSTOM_DATE_RANGE = 'customDateRange',
}

export enum IntervalNextDateEnum {
  NEXT_DAY = 'nextDay',
  NEXT_3_DAYS = 'next3Days',
  NEXT_7_DAYS = 'next7Days',
  NEXT_14_DAYS = 'next14Days',
  NEXT_30_DAYS = 'next30Days',
  NEXT_3_MONTHS = 'next3Months',
  NEXT_12_MONTHS = 'next12Months',
  CUSTOM_DATE_RANGE = 'customDateRange',
}

export enum queueState {
  QUEUED = 'queued',
  PENDING = 'pending',
  PROCESSED = 'processed',
}

export enum ButtonWidthValueEnum {
  AUTO = 'auto',
  FULL_WIDTH = '100%',
}

export enum PlanName {
  STANDARD = 'standard',
  PREMIUM = 'premium',
  ULTIMATE = 'ultimate'
}

export enum Feature {
  UNLIMITED_EMAILS_CONTACT_NUMBERS = 'offers.features.unlimitedEmailsAndContactNumbers',
  AUTOMATION_CAMPAIGN = 'offers.features.automationAndCampaigns',
  POPIN_SMART_CONTENT_FORMS = 'offers.features.popinAndSmartContentAndForms',
  SMS_AUTOMATION_AND_CAMPAIGN = 'offers.features.smsAutomationAndCampaign',
  PUSH_IN_AUTOMATION = 'offers.features.pushInAutomation',
  LIST_AND_SEGMENT_MANAGEMENT = 'offers.features.listAndSegmentManagement',
  MULTICHANNEL_PRODUCT_RECOMMENDATION = 'offers.features.multichannelProductRecommendation',
  STATISTICAL_DASHBOARD_AND_EMAIL_STATISTICAL_REPORTS = 'offers.features.statisticalDashboardAndEmailStatisticalReports',
  EMAIL_SUPPORT = 'offers.features.emailSupport',
  PUSH_IN_CAMPAIGNS = 'offers.features.pushInCampaigns',
  MULTI_ACCOUNT = 'offers.features.multiAccount',
  ADVANCED_EXPORT_ALL_DATATABLES = 'offers.features.advancedExportAllDatatables',
  API = 'offers.features.api',
  DELIVERABILITY_STATS = 'offers.features.deliverabilityStats',
  STAT_BUILDER = 'offers.features.statBuilder',
  APPOINTMENT_CUSTOMER_SUCCESS = 'offers.features.appointmentCustomerSuccess',
  TELEPHONE_SUPPORT = 'offers.features.telephoneSupport',
  CUSTOM_DATA = 'offers.features.customData',
  APPOINTMENT_DELIVERABILITY_EXPERT = 'offers.features.appointmentDeliverabilityExpert',
  CONDITIONAL_BLOCKS_BUILDER = 'offers.features.conditionalBlocksBuilder',
  PRICE_PER_SMS = 'offers.features.pricePerSms',
  SMS_PRICE_WITHIN_SUBSCRIPTION_NON_CARRYOVER = 'offers.features.smsPriceWithinSubscriptionNonCarryover'
}

export enum BuyableElementType {
  CREDITS = 'credits',
  PLAN_CREDITS = 'plan_credits',
  MONTHLY_PLAN = 'monthly_plan',
  YEARLY_PLAN = 'yearly_plan',
}

export enum GleapCustomActionName {
  GLEAP_RDV_CUSTOMER_SUCCESS = 'gleap.rdv-customer-success',
  GLEAP_TELEPHONE_SUPPORT = 'gleap.telephone-support',
  GLEAP_OPEN_ACCESS_TO_SUPPORT = 'gleap.open-access-to-support',
}

export enum GleapChecklistEvent {
  GLEAP_CHECKLIST_VERIFY_DOMAIN = 'verify domain',
  GLEAP_CHECKLIST_AUTHENTICATE_DOMAIN = 'authenticate domain',
  GLEAP_CHECKLIST_ALIGN_DOMAIN = 'align domain',
  GLEAP_CHECKLIST_ALIGN_REDIRECTION_LINKS = 'align redirection links',
  GLEAP_CHECKLIST_VERIFY_EMAIL_SERVICE_PARAMETER = 'verify email service parameter',
  GLEAP_CHECKLIST_EMAIL_SERVICE_CONFIGURED = 'email service configured',
  GLEAP_CHECKLIST_IMPORTANT_LINKS_SERVICE_CONFIGURED = 'important links service configured',
  GLEAP_CHECKLIST_SOCIAL_NETWORK_SERVICE_CONFIGURED = 'social network service configured',
  GLEAP_CHECKLIST_SMS_SERVICE_CONFIGURED = 'sms service configured',
  GLEAP_CHECKLIST_PUSH_NOTIFICATION_SERVICE_CONFIGURED = 'push notification service configured',
  GLEAP_CHECKLIST_VOUCHER_SERVICE_CONFIGURED = 'voucher service configured',
}

export enum AutomaticallyCloseEnum {
  NONE = '0',
  AFTER_5_SECONDS = '5',
  AFTER_10_SECONDS = '10',
  AFTER_15_SECONDS = '15',
  AFTER_20_SECONDS = '20',
  AFTER_25_SECONDS = '25',
  AFTER_30_SECONDS = '30',
}

export enum BackgroundImageOptionTypeEnum {
  SELECTOR = 'selector',
  FULL = 'full',
  NO_OPTION = 'no_option'
}

export enum DataExplorerDataType {
  CONTACT_FILE = 'contactFile',
  ADDRESS_FILE = 'addressFile',
  ORDER_FILE = 'orderFile',
  CART_FILE = 'cartFile',
}

export enum ListType {
  LIST = 'list',
  SEGMENT = 'segment',
  NOT_DEFINED = 'not_defined'
}
