
import {
  defineComponent, onMounted, onUnmounted, PropType, SetupContext,
} from 'vue';
import { PrimvueMenuModel, ToolbarItemsModel } from '@/types';
import Sidebar from 'primevue/sidebar';
import Toolbar from 'primevue/toolbar';
import ToolbarButtons from '@/components/ToolbarButtons.vue';
import Button from 'primevue/button';

export default defineComponent({
  name: 'EditorModal',

  components: {
    Sidebar,
    Toolbar,
    ToolbarButtons,
    Button,
  },

  props: {
    title: {
      type: String,
      required: false,
      default: () => '',
    },

    toolbarRightButtons: {
      type: Array as PropType<PrimvueMenuModel[]>,
      required: false,
      default: () => [],
    },

    contentHeaderToolbarStart: {
      type: Array as PropType<ToolbarItemsModel[]>,
      required: false,
      default: () => [],
    },

    contentHeaderToolbarEnd: {
      type: Array as PropType<ToolbarItemsModel[]>,
      required: false,
      default: () => [],
    },

    tabs: {
      type: Array as PropType<PrimvueMenuModel[]>,
      required: false,
      default: () => [],
    },

    showCloseButton: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  emits: ['on-close-modal'],

  setup(props: { }, context: SetupContext) {
    const hide = () => document.body.classList.remove('p-overflow-hidden');
    const show = () => document.body.classList.add('p-overflow-hidden');

    const closeModal = () => {
      context.emit('on-close-modal');
    };

    onMounted(show);
    onUnmounted(hide);

    return {
      hide, show, closeModal,
    };
  },

});
