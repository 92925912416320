
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  Ref,
  ref,
  watch,
} from 'vue';
import SpmGraph from '@/components/graphs/SpmGraph.vue';
import SpmTable from '@/components/table/SpmTable.vue';
import SpmKpi from '@/components/graphs/SpmKpi.vue';
import SpmButton from '@/components/spm-primevue/SpmButton.vue';
import Skeleton from 'primevue/skeleton';
import Checkbox from 'primevue/checkbox';
import TermsAndConditions from '@/views/auth/TermsAndConditions.vue';
// eslint-disable-next-line import/no-cycle
import { Request } from '@/composables/GraphQL';
// eslint-disable-next-line import/no-cycle
import {
  ChartType,
  StatsReqParams,
  StatsResponse,
  StatsWidgetType,
} from '@/types/generated-types/graphql';
// eslint-disable-next-line import/no-cycle
import {
  IntervalDateEnum,
  SpmTableColumns,
  SpmTableFilter,
  SpmTableState,
} from '@/types';
// eslint-disable-next-line import/no-cycle
import { deleteEmptyPropsOfObject } from '@/helpers';
// eslint-disable-next-line import/no-cycle
import {
  UserState, hasAccessToFeatures, tryPlan, findPermission,
} from '@/composables/User';
import { SpmKpiConfiguration } from '@/types/stats-types';
import { useI18n } from 'vue-i18n';
import { formatNumberToCurrency } from '@/helpers/Number';
// eslint-disable-next-line import/no-cycle
import { DEFAULT_CURRENCY } from '@/components/template-builder/utils/constants';
// eslint-disable-next-line import/no-cycle
import { useStore } from '@/store';
import deepcopy from 'deepcopy';
import moment, { DurationInputArg1 } from 'moment';
import DurationConstructor = moment.unitOfTime.DurationConstructor;

export interface StatsWidgetDataConfiguration {
  group: string;
  category: string;
  metric: string;
  channel: string[];
  widgetType: StatsWidgetType;
  isTimeline: boolean;
  charts?: { [key: string]: string}[];
  filters: Record<string, any>;
  formatValue?: string;
}

export default defineComponent({
  name: 'StatsWidgetData',
  components: {
    SpmTable,
    SpmGraph,
    SpmKpi,
    Skeleton,
    SpmButton,
    Checkbox,
    TermsAndConditions,
  },

  methods: { findPermission },

  props: {
    name: {
      type: String,
      required: true,
      default: '',
    },

    configuration: {
      type: Object as PropType<StatsWidgetDataConfiguration>,
      required: true,
    },

    defaultFilters: {
      type: Object,
      required: false,
      default: () => ({}),
    },

    pageName: {
      type: String,
      required: false,
      default: '',
    },
  },

  setup(props) {
    const store = useStore();
    const { t, locale } = useI18n();
    const isSpmTable = computed(() => props.configuration.widgetType === StatsWidgetType.TABLE);
    const isSpmKpi = computed(() => props.configuration.widgetType === StatsWidgetType.KPI);
    const isTimeline = computed(() => props.configuration.isTimeline);
    const childComponentRef = ref();

    const permissionToWidgetData = computed(() => {
      const { group, category, metric } = props.configuration;
      const featurePermission = hasAccessToFeatures(`${group}.${category}.${metric}`);
      return featurePermission;
    });
    const customSettings = ref();

    const statLoaded = ref(false);

    const tableCols = ref<SpmTableColumns[]>([]);
    const tableData: Ref<SpmTableState | null> = ref({
      items: [],
      isLoading: true,
      total: 0,
      error: '',
    });
    const tableColumnId: Ref<string> = ref('');
    const idShop = UserState.activeShop?.id ?? 0;

    const widgetName = typeof props.name === 'string' ? props.name : ref(props.name[locale.value]);

    // Get 30 last days (default filter for dates)
    const endDate = ref(moment());
    const startDate = ref(moment().subtract(30, 'days'));

    const calculateDateInterval = (filters: any, splitYearPeriod = false) => {
      const dateInterval = filters.date_interval.value.interval;
      if (dateInterval === IntervalDateEnum.CUSTOM_DATE_RANGE) {
        // Custom range
        const { customDateRange } = filters.date_interval.value;
        startDate.value = moment(customDateRange[0]);
        endDate.value = moment(customDateRange[1]);
      } else {
        // Interval from now
        let intervalValue: DurationInputArg1 = 30;
        let intervalUnit: DurationConstructor = 'days';

        switch (dateInterval) {
          case IntervalDateEnum.LAST_DAY:
            intervalValue = 1;
            break;
          case IntervalDateEnum.LAST_3_DAYS:
            intervalValue = 3;
            break;
          case IntervalDateEnum.LAST_7_DAYS:
            intervalValue = 7;
            break;
          case IntervalDateEnum.LAST_14_DAYS:
            intervalValue = 14;
            break;
          case IntervalDateEnum.LAST_30_DAYS:
            intervalValue = 30;
            break;
          case IntervalDateEnum.LAST_3_MONTHS:
            intervalValue = 3;
            intervalUnit = 'months';
            break;
          case IntervalDateEnum.LAST_12_MONTHS:
            // In case of 12 months, we only calculate 3 months, and let the user scroll left/right on the graph to show more data
            if (splitYearPeriod) {
              intervalValue = 3;
              intervalUnit = 'months';
            } else {
              intervalValue = 12;
              intervalUnit = 'months';
            }
            break;
          default:
            intervalValue = 30;
            intervalUnit = 'days';
            break;
        }

        endDate.value = moment();
        startDate.value = moment().subtract(intervalValue, intervalUnit);
      }
    };

    const dateFilters = computed(() => ({
      date_start: startDate.value.format('YYYY-MM-DD 00:00:00'),
      date_start_integer: parseInt(startDate.value.format('YYYYMMDD'), 10),
      date_start_hour_integer: parseInt(startDate.value.format('YYYYMMDD00'), 10),
      date_start_full_integer: parseInt(startDate.value.format('YYYYMMDD000000'), 10),
      date_end: endDate.value.format('YYYY-MM-DD 23:59:59'),
      date_end_integer: parseInt(endDate.value.format('YYYYMMDD'), 10),
      date_end_hour_integer: parseInt(endDate.value.format('YYYYMMDD23'), 10),
      date_end_full_integer: parseInt(endDate.value.format('YYYYMMDD235959'), 10),
    }));

    const customFilters: Record<string, any> = ref({});

    const formatStatValue = (value: any, type: string): any => {
      let returnValue = '';

      switch (type) {
        // TODO add other formatting methods if needed
        case 'currency':
          returnValue = formatNumberToCurrency(parseFloat(value ?? 0), UserState.activeShop?.currency ?? DEFAULT_CURRENCY);
          break;
        case 'country':
          returnValue = `countries.codes.${value.toUpperCase()}`;
          break;
        case 'lang':
          returnValue = `languages.codes.${value.toLowerCase()}`;
          break;
        default:
          returnValue = value;
          break;
      }

      return returnValue;
    };

    const getTable = async (additionalParams: any = null) => {
      const configuration = JSON.parse(JSON.stringify(props.configuration));

      let params: StatsReqParams = {
        type: configuration.widgetType,
        persistent_filters: JSON.stringify({
          id_shop: idShop,
          ...dateFilters.value,
        }),
      };

      let filters = {};
      if (Object.prototype.hasOwnProperty.call(configuration, 'filters')) {
        filters = {
          ...filters,
          ...configuration.filters,
        };
        delete configuration.filters;
      }

      if (Object.keys(customFilters.value).length > 0) {
        filters = {
          ...filters,
          ...customFilters.value,
        };
      }

      if (Object.keys(filters).length > 0) {
        params.filters = JSON.stringify(filters);
      }

      params.configuration = configuration;

      if (additionalParams) {
        params = { ...params, ...additionalParams };
      }

      customSettings.value = params;
      const results = await Request<StatsResponse>({
        name: 'StatsTableGet',
        variables: { params },
        query: `
          query ($params: StatsReqParams!)
          {
            StatsTableGet(params: $params) {
              table
            }
          }
        `,
      });

      const resultsCopy = JSON.parse(JSON.stringify(results));
      const { err, data } = resultsCopy;

      if (err) {
        statLoaded.value = true;
        return;
      }

      if (data?.table) {
        const json = JSON.parse(data?.table.toString());
        const spmTableData = json?.data;
        spmTableData.total = parseInt(spmTableData.total, 10);
        spmTableData.isLoading = false;

        tableCols.value = json?.columns;
        tableData.value = spmTableData;
        const idColumn = json.columns.filter((column: SpmTableColumns) => Object.prototype.hasOwnProperty.call(column, 'isId') && column.isId);

        if (idColumn && idColumn.length) {
          tableColumnId.value = idColumn[0].field;
        }

        // We apply translations on columns
        tableCols.value.forEach((column: SpmTableColumns) => {
          // eslint-disable-next-line no-param-reassign
          column.header = t(`stats.columns.${column.header}`);
        });

        // Set filters to columns
        if (additionalParams && additionalParams.filter && additionalParams.filter.length > 0) {
          additionalParams.filter.forEach((filter: SpmTableFilter) => {
            const col = tableCols.value.filter((column: SpmTableColumns) => column.field === filter.field);

            if (col) {
              col[0].filterValue = filter;
            }
          });
        }

        statLoaded.value = true;
      }
    };

    const chartData = ref({
      type: props.configuration.widgetType,
      options: {},
      data: {},
    });
    const getChart = async () => {
      const configuration = JSON.parse(JSON.stringify(props.configuration));

      const params: StatsReqParams = {
        type: configuration.widgetType,
        persistent_filters: JSON.stringify({
          id_shop: idShop,
          ...dateFilters.value,
        }),
      };

      let filters = {};
      if (Object.prototype.hasOwnProperty.call(configuration, 'filters')) {
        filters = {
          ...filters,
          ...configuration.filters,
        };
        delete configuration.filters;
      }

      if (Object.keys(customFilters.value).length > 0) {
        filters = {
          ...filters,
          ...customFilters.value,
        };
      }

      if (Object.keys(filters).length > 0) {
        params.filters = JSON.stringify(filters);
      }

      params.configuration = configuration;

      const results = await Request<StatsResponse>({
        name: 'StatsChartGet',
        variables: { params },
        query: `
          query ($params: StatsReqParams!)
          {
            StatsChartGet(params: $params) {
              chart {
                type
                options
                data {
                  labels
                  datasets {
                    data
                    type
                    label
                    backgroundColor
                    hoverBackgroundColor
                    borderColor
                    borderWidth
                    fill
                    lineTension
                    spanGaps
                  }
                  backgroundColor
                  borderColor
                  borderJoinStyle
                  borderWidth
                }
              }
            }
          }
        `,
      });

      const resultsCopy = JSON.parse(JSON.stringify(results));
      const { err, data } = resultsCopy;

      if (err) {
        statLoaded.value = true;
        return;
      }

      if (data && data.chart) {
        if (Object.prototype.hasOwnProperty.call(configuration, 'formatValue')) {
          // Format labels according to configuration
          if (data.chart.data && Object.prototype.hasOwnProperty.call(data.chart.data, 'labels') && data.chart.data.labels && data.chart.data.labels.length) {
            const newLabels: string[] = [];

            data.chart.data.labels.forEach((label: string | null) => {
              if (data.chart && data.chart.data) {
                newLabels.push(label ? formatStatValue(label, configuration.formatValue ?? '') : 'stats.statsLabels.unknown');
              }
            });

            data.chart.data.labels = newLabels;
          }
        }

        deleteEmptyPropsOfObject(data.chart.data);

        chartData.value = {
          type: configuration.widgetType,
          options: data.chart.options ? JSON.parse(data.chart.options) : {},
          data: data.chart.data || {},
        };
      } else {
        chartData.value = {
          type: configuration.widgetType,
          options: {},
          data: {},
        };
      }

      statLoaded.value = true;
    };

    const kpiData: Ref<SpmKpiConfiguration[]> = ref([]);
    const getKpi = async () => {
      kpiData.value = [];

      const configuration = JSON.parse(JSON.stringify(props.configuration));

      const params: StatsReqParams = {
        type: configuration.widgetType,
        persistent_filters: JSON.stringify({
          id_shop: idShop,
          ...dateFilters.value,
        }),
      };

      let filters = {};
      if (Object.prototype.hasOwnProperty.call(configuration, 'filters')) {
        filters = {
          ...filters,
          ...configuration.filters,
        };
        delete configuration.filters;
      }

      if (Object.keys(customFilters.value).length > 0) {
        filters = {
          ...filters,
          ...customFilters.value,
        };
      }

      if (Object.keys(filters).length > 0) {
        params.filters = JSON.stringify(filters);
      }

      params.configuration = configuration;

      const results = await Request<StatsResponse>({
        name: 'StatsKpiGet',
        variables: { params },
        query: `
          query ($params: StatsReqParams!)
          {
            StatsKpiGet(params: $params) {
              kpi {
                type
                color
                icon
                label
                value
                legend
              }
            }
          }
        `,
      });

      const resultsCopy = JSON.parse(JSON.stringify(results));
      const { err, data } = resultsCopy;

      if (err) {
        statLoaded.value = true;
        return;
      }

      if (data && data.kpi && data.kpi.length) {
        data.kpi.forEach((kpi: any) => {
          if (Object.prototype.hasOwnProperty.call(props.configuration, 'formatValue')) {
            // eslint-disable-next-line no-param-reassign
            kpi.value = formatStatValue(kpi.value, props.configuration.formatValue ?? '');
          }

          kpiData.value.push({
            type: kpi.type || null,
            color: kpi.color || null,
            icon: kpi.icon || null,
            label: kpi.label || null,
            value: kpi.value,
            legend: kpi.legend || null,
          });
        });
      }

      statLoaded.value = true;
    };

    const getData = () => {
      if (!permissionToWidgetData.value || (permissionToWidgetData.value && permissionToWidgetData.value.access)) {
        statLoaded.value = false;
        if (isSpmTable.value) {
          getTable();
        } else if (isSpmKpi.value) {
          getKpi();
        } else {
          getChart();
        }
      }
    };

    const applyFilters = (newFilters: any) => {
      const filters = deepcopy(newFilters);
      // Calculate new dates for filters (mandatory filter)
      if (Object.prototype.hasOwnProperty.call(filters, 'date_interval')) {
        calculateDateInterval(filters, isTimeline.value);
        delete filters.date_interval;
      }

      // Add new filters if value is set
      if (Object.keys(filters).length > 0) {
        Object.keys(filters).forEach((key) => {
          if ((!Array.isArray(filters[key].value) && filters[key].value !== '') || (Array.isArray(filters[key].value) && filters[key].value.length > 0)) {
            customFilters.value[key] = Array.isArray(filters[key].value) ? filters[key].value.join(',') : !filters[key].value ? '' : filters[key].value.toString();
          }
        });
      }
    };

    const loadMoreDatesOnGraph = (direction: string) => {
      const currentStartDate = moment(startDate.value.format('YYYY-MM-DD 00:00:00'));
      const currentEndDate = moment(endDate.value.format('YYYY-MM-DD 23:59:59'));

      if (direction === 'before' && currentStartDate.format('YYYY-MM-DD 00:00:00').toString() !== moment().subtract(12, 'months').format('YYYY-MM-DD 00:00:00').toString()) {
        // Subtract 3 months
        endDate.value = currentEndDate.subtract(3, 'months');
        startDate.value = currentStartDate.subtract(3, 'months');
        getData();
      } else if (direction === 'after' && currentEndDate.format('YYYY-MM-DD 23:59:59').toString() !== moment().format('YYYY-MM-DD 23:59:59').toString()) {
        // Add 3 months
        endDate.value = currentEndDate.add(3, 'months');
        startDate.value = currentStartDate.add(3, 'months');
        getData();
      }
    };

    onMounted(() => {
      // Apply filters
      applyFilters(props.defaultFilters);

      // Load widget data
      getData();
    });

    watch(() => store.getters['statsEditor/getPageFilters'], (newFilters) => {
      customFilters.value = {};

      // Apply filters
      applyFilters(newFilters);

      // Reload widget data
      getData();
    }, { deep: true });

    watch(() => store.getters['statsEditor/getRefreshWidgetsCount'], () => {
      // Reload widget data
      getData();
    });

    watch(() => permissionToWidgetData, () => {
      getData();
    }, { deep: true });

    const trialAvailable = computed(() => {
      if (UserState.activeOffer) {
        if (!UserState.activeOffer.trials) {
          return true;
        }
        if (permissionToWidgetData.value) {
          if (!UserState.activeOffer.trials[permissionToWidgetData.value.minimumPlan]) {
            return true;
          }
        }
        return false;
      }
      return false;
    });

    const acceptTTC = ref(false);
    const showTACModal = ref(false);

    const handleUpgradeButton = () => {
      store.commit('choosePlan/setIsVisible', true);
    };

    const handleTryPlanButton = async () => {
      if (permissionToWidgetData.value) {
        store.commit('general/showTheSpinner');
        await tryPlan(permissionToWidgetData.value.minimumPlan);
        store.commit('general/hideTheSpinner');
      }
    };

    return {
      t,
      idShop,
      isSpmTable,
      isSpmKpi,
      tableCols,
      tableData,
      tableColumnId,
      chartData,
      kpiData,
      statLoaded,
      startDate,
      endDate,
      getTable,
      loadMoreDatesOnGraph,
      widgetName,
      permissionToWidgetData,
      trialAvailable,
      acceptTTC,
      showTACModal,
      handleUpgradeButton,
      handleTryPlanButton,
      childComponentRef,
      customSettings,
    };
  },
});
