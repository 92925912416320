<template>
  <div class="flex flex-column gap-1">
    <Toolbar style="background-color: white; border-width: 0px;">
      <template #start>
        <Breadcrumb
          v-if="breadcrumbItems.length"
          :home="homeBreadcrumb"
          :model="breadcrumbItems"
          style="border-width: 0px; padding: 0;"
        />
      </template>
      <template #end>
        <SpmOverlayPanel
          class-trigger="flex align-items-center justify-content-center toolbar-buttons-trigger"
        >
          <template #trigger>
            <Button
              :label="t('fileManager.filesList.toolbar.newBtn.label')"
              icon="fal fa-plus fa-xl"
              class="p-button-secondary"
            />
          </template>
          <SpmPanelMenu
            :items="toolbarItems"
          />
        </SpmOverlayPanel>
      </template>
    </Toolbar>
    <div class="flex flex-column gap-2 files-list">
      <FileUpload
        name="files[]"
        :custom-upload="true"
        :choose-label="t('templateBuilder.choose')"
        :show-cancel-button="false"
        :accept="extensions"
        :auto="true"
        :show-upload-button="false"
        :max-file-size="5000000"
        :multiple="true"
        @uploader="handleUpload"
      >
        <template #header="{ chooseCallback }">
          <div class="flex flex-wrap justify-content-between align-items-center flex-1 gap-2">
            <Button
              ref="chooseFileBtnRef"
              class="hidden"
              @click="chooseCallback"
            />
          </div>
        </template>
        <template #empty>
          <div
            class="file-manager__empty-container"
            @click="chooseFileToUpload"
          >
            <div class="flex align-items-center justify-content-center flex-column cursor-pointer">
              <i class="fa-light fa-cloud-arrow-up border-2 border-circle p-5 text-8xl text-400 border-400" />
              <p class="mt-4 mb-0">
                {{ t('templateBuilder.fields.dragAndDrop') }}
              </p>
            </div>
          </div>
        </template>
      </FileUpload>
      <div class="flex flex-column align-items-end">
        <SelectButton
          v-model="layout"
          :options="layoutOptions"
          :allow-empty="false"
          :unselectable="false"
        >
          <template #option="{ option }">
            <i :class="[option === 'list' ? 'fal fa-bars' : 'fal fa-table-cells-large']" />
          </template>
        </SelectButton>
        <Divider class="files-list-divider" />
      </div>
      <div
        v-if="breadcrumbItems.length"
        class="flex align-items-start"
      >
        <Button
          :label="t('return')"
          icon="fa fa-arrow-left"
          class="p-button-secondary"
          @click="handleReturn"
        />
      </div>
      <div
        v-if="loading"
        class="justify-content-center align-items-center"
      >
        <ProgressSpinner
          class="progress-spinner"
          style="width: 25px; height: 25px; margin: auto"
          stroke-width="8"
          fill="var(--surface-ground)"
          animation-duration="1.0s"
          aria-label="Custom ProgressSpinner"
        />
      </div>
      <div
        v-if="!loading && folders.length"
        class="flex flex-column gap-1"
      >
        <span
          v-if="layout === 'grid'"
          class="text-left font-semibold"
        >
          {{ t('fileManager.folders') }}
        </span>
        <div
          class="grid"
        >
          <div
            v-for="(folder) of folders"
            :key="folder.id"
            :class="layout === 'grid' ? 'col-12 md:col-6 lg:col-2' : 'col-12'"
          >
            <FolderItem
              :folder="folder"
              @on-open-folder="onOpenFolder"
            />
          </div>
        </div>
      </div>
      <div
        v-if="!loading"
        class="flex flex-column gap-1"
      >
        <span
          v-if="layout === 'grid'"
          class="text-left font-semibold"
        >
          {{ t('fileManager.files') }}
        </span>
        <div
          class="grid"
          style="text-align: center"
        >
          <div
            v-if="displaySpinner"
            :class="layout === 'grid' ? 'col-12 md:col-6 lg:col-2 mb-3' : 'col-12'"
          >
            <ProgressSpinner
              class="progress-spinner"
              style="width: 25px; height: 25px; margin: auto"
              stroke-width="8"
              fill="var(--surface-ground)"
              animation-duration="1.0s"
              aria-label="Custom ProgressSpinner"
            />
          </div>
          <div
            v-for="(file) of files"
            :key="file.id"
            :class="layout === 'grid' ? 'col-12 md:col-6 lg:col-2 mb-3' : 'col-12'"
          >
            <FileItem
              :file="file"
              :layout="layout"
              :add-move-button="true"
              @on-choose-file="handleChooseFile"
              @on-delete-item="handleDeleteItem"
              @on-move-item="handleMoveItem"
            />
            <Divider
              v-if="layout === 'list'"
              class="files-list-divider"
            />
          </div>
        </div>
        <div class="justify-content-center align-items-center">
          <ProgressSpinner
            v-if="!loading && loadingNextPage"
            class="progress-spinner"
            style="width: 25px; height: 25px; margin: auto"
            stroke-width="8"
            fill="var(--surface-ground)"
            animation-duration="1.0s"
            aria-label="Custom ProgressSpinner"
          />
        </div>
      </div>
    </div>
  </div>
  <InputTextModal
    :display-dialog="displayNewFolderModal"
    :display-cancel-button="true"
    :input-value="newFolderName"
    :show-loading="true"
    header="fileManager.filesList.toolbar.newBtn.actions.newFolder"
    @on-close-dialog="displayNewFolderModal = false; newFolderName = ''"
    @on-validate="createFolder"
  />
</template>

<script lang="ts">
import {
  defineComponent,
  onMounted,
  ref,
  Ref,
} from 'vue';

import Breadcrumb from 'primevue/breadcrumb';
import Button from 'primevue/button';
import Divider from 'primevue/divider';
import SelectButton from 'primevue/selectbutton';
import FileUpload, { FileUploadUploaderEvent } from 'primevue/fileupload';
import Toolbar from 'primevue/toolbar';

import FileItem from '@/components/file-manager/FileItem.vue';
import FolderItem from '@/components/file-manager/FolderItem.vue';
import SpmOverlayPanel from '@/components/spm-primevue/SpmOverlayPanel.vue';
import SpmPanelMenu from '@/components/spm-primevue/SpmPanelMenu.vue';
import InputTextModal from '@/components/modals/InputTextModal.vue';

import { useToast } from 'primevue/usetoast';
import ProgressSpinner from 'primevue/progressspinner';
import { MenuItem } from 'primevue/menuitem';

import { UserState } from '@/composables/User';
import {
  getFilesFromPath,
  createFolderInMedia,
  createNewFileInMedia,
} from '@/composables/configs/configuration';

import { extensions as extensionsList } from '@/configs/file-manager';

import { File, Folder } from '@/types';

import { showToastError } from '@/helpers';

import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'FilesList',

  components: {
    Breadcrumb,
    Button,
    Divider,
    FileUpload,
    ProgressSpinner,
    FileItem,
    FolderItem,
    Toolbar,
    SpmOverlayPanel,
    SpmPanelMenu,
    InputTextModal,
    SelectButton,
  },

  props: {
    extensions: {
      type: String,
      required: false,
      default: '',
    },
  },

  emits: {
    'on-close-dialog': null,
    'on-choose-file': Object,
  },

  setup(props, context) {
    const { t } = useI18n();
    const files: Ref<File[]> = ref([]);
    const folders: Ref<Folder[]> = ref([]);
    const idShop: number = UserState.activeShop ? UserState.activeShop.id : 0;
    const data = ref('');
    const toast = useToast();

    const loading = ref(false);
    const loadingNextPage = ref(false);

    const displaySpinner = ref(false);

    const chooseFileBtnRef = ref();

    const newFolderName: Ref<string> = ref('');
    const displayNewFolderModal = ref(false);
    const currentPath = ref('');

    const layout = ref('grid');
    const layoutOptions = ref(['list', 'grid']);

    const handleChooseFile = (params: any) => {
      context.emit('on-choose-file', params);
      if (params.action === 'download') {
        context.emit('on-close-dialog');
      }
    };

    const handleDeleteItem = async (fileId: string) => {
      files.value = files.value.filter((file: File) => file.id !== fileId);
    };

    const handleMoveItem = async (sameFile: boolean, fileId: string) => {
      if (!sameFile) {
        files.value = files.value.filter((file: File) => file.id !== fileId);
      }
    };

    const convertImageToBase64 = async (file: any) => new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (e: any) => {
        const base64Data = e.target.result;
        data.value = base64Data;
        resolve(base64Data);
      };

      reader.onerror = (error) => {
        reject(error);
      };

      // Read the image file as a data URL
      reader.readAsDataURL(file);
    });

    const handleUpload = async (event: FileUploadUploaderEvent) => {
      if (event.files && Array.isArray(event.files)) {
        // eslint-disable-next-line no-restricted-syntax
        try {
          const dataFiles: string[] = [];
          displaySpinner.value = true;

          for (const file of event.files) {
            // eslint-disable-next-line no-await-in-loop
            await convertImageToBase64(file);
            // eslint-disable-next-line no-await-in-loop
            dataFiles.push(data.value);
          }

          const uploadResult = await createNewFileInMedia(dataFiles, idShop, currentPath.value);

          uploadResult.data.forEach((element: any) => {
            const { id, path, type } = element;

            const tempFile: File = {
              id,
              url: `https://media.shopimind.io/clients/${path}`,
              base_url: path,
              type,
            };
            files.value.unshift(tempFile);
          });
        } catch (error) {
          toast.add({
            severity: 'error', summary: t('error'), detail: t('templateBuilder.panels.fileManager.updateError'), life: 3000,
          });
        } finally {
          displaySpinner.value = false;
        }
      }
    };

    const chooseFileToUpload = () => {
      if (chooseFileBtnRef.value) {
        chooseFileBtnRef.value.$el.click();
      }
    };

    const toolbarItems: Ref<MenuItem[]> = ref([
      {
        label: t('fileManager.filesList.toolbar.newBtn.actions.newFolder'),
        icon: 'fal fa-folder',
        command: () => { displayNewFolderModal.value = true; },
      },
    ]);

    const lastFile = ref('');
    const checkNextPage = ref(true);

    const fetchData = async (path = '', nextPage = false) => {
      if (!nextPage) {
        files.value = [];
        folders.value = [];
        loading.value = true;
      } else {
        loadingNextPage.value = true;
      }

      const result = await getFilesFromPath(path, idShop, checkNextPage.value ? lastFile.value : '');
      let acceptedExtensions = props.extensions.split(',').map(ext => ext.trim());
      acceptedExtensions = acceptedExtensions.flatMap(ext => ext === 'image/*' ? extensionsList['image/*'].split(',') : ext);

      if (result.data) {
        if (result.data.files) {
          Object.entries(result.data.files)
            .forEach((row: any, name: any) => {
              if (!row[1].is_dir) {
                const index = row[0].split('.')[0];
                const url = `https://media.shopimind.io/clients/${row[1].src}`;

                if (acceptedExtensions.some((ext) => row[1].name.toLowerCase().endsWith(ext.toLowerCase()))) {
                  files.value.push({
                    id: index,
                    url,
                    base_url: row[1].src,
                    type: row[1].type
                  });
                }
              } else if (row[1].is_dir && !nextPage) {
                folders.value.push({
                  id: row[1].name,
                  name: row[1].name,
                  path: row[1].path,
                });
              }
            });
        }
        lastFile.value = result.data.lastFile;
        checkNextPage.value = result.data.moreFiles;
      }
      loading.value = false;
      loadingNextPage.value = false;
    };

    // Breadcrumb
    const breadcrumbItems: Ref<MenuItem[]> = ref([]);

    const toHome = async () => {
      breadcrumbItems.value = [];
      currentPath.value = '';
      // Reinit checkNextPage and lastFile
      lastFile.value = '';
      checkNextPage.value = true;
      await fetchData();
    }

    const homeBreadcrumb: Ref<MenuItem> = ref({
      icon: 'fal fa-house',
      command: async () => {
        await toHome();
      },
    });

    const onOpenFolder = async (folder: Folder) => {
      const toFolder = async () => {
        const path = breadcrumbItems.value.map((item) => item.label).join('/');
        if (currentPath.value !== path) {
          currentPath.value = path;
          // Reinit checkNextPage and lastFile
          lastFile.value = '';
          checkNextPage.value = true;
          await fetchData(path);
        }
      }

      if (!breadcrumbItems.value.find((item) => item.key === folder.id)) {
        breadcrumbItems.value.push({
          label: folder.name,
          key: folder.id,
          command: () => {
            onOpenFolder(folder);
          }
        });
        await toFolder();
      } else {
        const targetIndex = breadcrumbItems.value.findIndex(item => item.key === folder.id);
        breadcrumbItems.value = breadcrumbItems.value.splice(0, targetIndex + 1);
        await toFolder();
      }
    };

    const createFolder = async (folderName: string, callback: Function) => {
      try {
        const newPath = currentPath.value ? `${currentPath.value}/${folderName}` : folderName;
        const { data } = await createFolderInMedia(newPath, idShop);
        if (!folders.value.find(folder => folder.name.toLowerCase().trim() === folderName.toLowerCase().trim())) {
          folders.value.push({
            id: folderName,
            name: folderName,
            path: data.path,
          });
        }
      } catch (err) {
        showToastError(t('errorMessages.GENERIC_ERROR'));
      } finally {
        callback();
      }
    };

    const handleScroll = async () => {
      if (!loadingNextPage.value && checkNextPage.value) {
        let path = '';
        if (breadcrumbItems.value.length) {
          path = breadcrumbItems.value.map((item) => item.label).join('/');
        }
        await fetchData(path, true);
      }
    };

    const handleReturn = async () => {
      if (breadcrumbItems.value.length - 2 < 0) {
        await toHome();
      } else {
        const folderToReturnTo: Folder = {
          id: breadcrumbItems.value[breadcrumbItems.value.length - 2].key as string,
          name: breadcrumbItems.value[breadcrumbItems.value.length - 2].label as string,
          path: '',
        };
        await onOpenFolder(folderToReturnTo);
      }
    };

    onMounted(async () => {
      await fetchData();
    });

    return {
      t,
      files,
      folders,
      displaySpinner,
      chooseFileBtnRef,
      toolbarItems,
      homeBreadcrumb,
      breadcrumbItems,
      loading,
      newFolderName,
      displayNewFolderModal,
      layout,
      layoutOptions,
      loadingNextPage,

      handleChooseFile,
      handleUpload,
      handleDeleteItem,
      chooseFileToUpload,
      onOpenFolder,
      createFolder,
      handleScroll,
      handleReturn,
      handleMoveItem,
    };
  },
});
</script>

<style lang="scss" scoped>
.progress-spinner {
  transform: translateY(130%);
}
</style>

<style lang="scss">
.files-list {
  .p-fileupload .p-fileupload-buttonbar {
    padding: 0rem !important;
    border: 0px !important;
  }

  .p-fileupload-highlight {
    border: 1px dashed #94c840 !important;
    background:#f8fcf2!important;
  }

  &__empty-container {
    &:hover {
      background-color: #f7f7f7;
    }
  }
}
.action-buttons-trigger {
  color: $navbar-gray-color;
  cursor: pointer;
  width: 35px;
  height: 35px;
}

.files-list-divider {
  border-top: 1px solid #dee2e6;
  margin: 0.5rem 0 !important;
}
</style>
