<template>
  <div
    class="folder-container p-3 border-round cursor-pointer"
    @dblclick="openFolder"
  >
    <div class="flex align-items-center justify-content-between">
      <div class="flex align-items-center">
        <i class="fas fa-folder" />
        <span class="ml-2 text-sm font-medium text-gray-900">{{ folder.name }}</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  PropType,
} from 'vue';

import { Folder } from '@/types';

import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'FolderItem',

  components: {},

  props: {
    folder: {
      type: Object as PropType<Folder>,
      required: true,
    },
  },

  emits: {
    'on-open-folder': Object,
  },

  setup(props, context) {
    const { t } = useI18n();

    const openFolder = () => {
      context.emit('on-open-folder', props.folder);
    };

    return {
      t,

      openFolder,
    };
  },
});
</script>

<style lang="scss" scoped>
.folder-container {
  background-color: var(--surface-100);
  &:hover {
    background-color: var(--surface-300);
  }
}
</style>
