// eslint-disable-next-line import/no-cycle
import { ComponentData, ComponentMetadata, ErrorConfigForm } from '@/types/automated-scenarios';
// eslint-disable-next-line import/no-cycle
import { getLinkedOperators } from '@/composables/AutomatedScenarios';
import { i18n } from '@/i18n';
// eslint-disable-next-line import/no-cycle
import { StringMap, TypeCampaignEnum } from '@/types';
// eslint-disable-next-line import/no-cycle
import { asInt } from '@/helpers';
// eslint-disable-next-line import/no-cycle
import {
  checkIfTemplateOrRedirectContainsVoucher,
  getTemplateAndRedirect, reusePreviousCouponValues, voucherMethodsValues, voucherTypeValues,
} from '@/composables/automated-scenarios/AutomatedScenariosSendingChannel';
import {
  between, integer, maxLength, minLength, requiredIf,
} from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
// eslint-disable-next-line import/no-cycle
import { Lang, UserState } from '@/composables/User';

let translation: any;
(async () => {
  translation = await i18n;
})();

export interface ActionSendFbMessengerData {
  template_display_selector_id: number | null;
  reuse_a_previous_coupon: number;
  display_popup_on_site: number;
  template_display_reduction_method: number;
  template_display_reduction_descript: StringMap;
  template_display_reduction_type: number;
  template_display_reduction_identifiant: string;
  template_display_reduction_amount: number;
  template_display_reduction_duplicate: string;
  template_display_reduction_minorderamount: number;
  template_display_reduction_validtime: number;
  template_display_reduction_prefix: string;
  template_display_message_langs: string;
  [key: string]: any;
}

const ActionSendFbMessengerMetadata: ComponentMetadata<ActionSendFbMessengerData> = {
  Meta: {
    kind: 'action',
    id: 'boxsendfbmessenger',
    label: 'Envoyer un message Facebook Messenger',
    icon: 'fab fa-facebook-messenger',
    hasConfiguration: true,
    incompatibilities: {
      automation: {
        next_operators: [
          'boxabandonpanier', 'boxsitepasstime', 'boxquitsite',
          'boxperiodiclaunching', 'boxscrollpage', 'boxstatuscommand',
          'boxcustomjs', 'registeronsite', 'subscribetonewsletter',
          'boxdisplayfreepopup', 'boxdisplayfreemodel', 'boxsendfbmessenger',
          'boxvisitpage',
        ],
      },
      bulk: {
        next_operators: [
          'boxabandonpanier', 'boxsitepasstime', 'boxquitsite',
          'boxperiodiclaunching', 'boxscrollpage', 'boxstatuscommand',
          'boxcustomjs', 'registeronsite', 'subscribetonewsletter',
          'boxdisplayfreepopup', 'boxdisplayfreemodel', 'boxsendfbmessenger',
          'boxvisitpage',
        ],
      },
    },
    component: 'ActionSendFbMessenger',
    availableInCampaign: [TypeCampaignEnum.AUTOMATION],
  },
  Create(data: ActionSendFbMessengerData = {
    template_display_selector_id: null,
    reuse_a_previous_coupon: 0,
    display_popup_on_site: 0,
    template_display_reduction_method: 3,
    template_display_reduction_descript: {},
    template_display_reduction_type: 1,
    template_display_reduction_identifiant: '',
    template_display_reduction_amount: 0,
    template_display_reduction_duplicate: '',
    template_display_reduction_minorderamount: 0,
    template_display_reduction_validtime: 7,
    template_display_reduction_prefix: '',
    template_display_message_langs: '',
  }): ComponentData<ActionSendFbMessengerData> {
    const formattedData = data;
    const languages: string[] = UserState.activeShop?.langs.map((language: Lang) => language.id) ?? [];
    formattedData.template_display_message_langs = JSON.stringify(languages);

    return {
      id: 'boxsendfbmessenger',
      form_name: 'boxsendfbmessenger',
      logo_box: 'fa-facebook-official',
      ...data,
    };
  },
  async Validate(data: ActionSendFbMessengerData): Promise<ErrorConfigForm> {
    //         required: voucherTemplateValidator(data, 'method'),
    let mustConfigVoucher = false;
    if (data.template_display_selector_id && data.template_display_selector_id > 0) {
      const idTemplate = asInt(data.template_display_selector_id);
      let templatesContainsVoucher = false;
      const listTemplate = await getTemplateAndRedirect(idTemplate);
      if (listTemplate !== null) {
        templatesContainsVoucher = checkIfTemplateOrRedirectContainsVoucher(listTemplate);
      }
      if (templatesContainsVoucher && data.reuse_a_previous_coupon === reusePreviousCouponValues.CONFIGURE_NEW_VOUCHER) {
        mustConfigVoucher = true;
      }
    }

    const rules = {
      template_display_reduction_method: {
        required: mustConfigVoucher,
      },
      template_display_reduction_descript: {
        required: false,
      },
      template_display_reduction_type: {
        required: mustConfigVoucher,
      },
      template_display_reduction_identifiant: {
        required: requiredIf(() => mustConfigVoucher && data.template_display_reduction_method === voucherMethodsValues.MANUAL),
        minLength: minLength(2),
        maxLength: maxLength(45),
      },
      template_display_reduction_amount: {
        required: requiredIf(() => mustConfigVoucher && [voucherTypeValues.PERCENTAGE, voucherTypeValues.AMOUNT].includes(data.template_display_reduction_type)),
        integer,
      },
      template_display_reduction_duplicate: {
        // eslint-disable-next-line max-len
        required: requiredIf(() => mustConfigVoucher && data.template_display_reduction_method === voucherMethodsValues.AUTOMATIC && data.template_display_reduction_type === voucherTypeValues.DUPLICATED),
        minLength: minLength(2),
        maxLength: maxLength(45),
      },
      template_display_reduction_minorderamount: {
        required: requiredIf(() => mustConfigVoucher && data.template_display_reduction_method === voucherMethodsValues.AUTOMATIC),
        between: between(0, 100000),
      },
      template_display_reduction_validtime: {
        required: requiredIf(() => mustConfigVoucher && data.template_display_reduction_method === voucherMethodsValues.AUTOMATIC),
        between: between(1, 126),
      },
      template_display_reduction_prefix: {
        minLength: minLength(2),
        maxLength: maxLength(32),
      },
    };

    const v$ = useVuelidate(rules, data);
    const success = await v$.value.$validate();

    return {
      success,
      validate: v$,
    };
  },
  Outputs(data: ActionSendFbMessengerData): number {
    return 1;
  },
  CanBeDeleted(operatorId: string): boolean | string {
    const canI = getLinkedOperators(operatorId);
    if (canI.length) {
      const listBoxes = canI.join(', ');
      return translation.global.t('automatedScenarios.box_dialog.delete_operator.warning_linked_box', { boxes: listBoxes });
    }
    return true;
  },
  Label(data: ActionSendFbMessengerData): string {
    return 'Envoyer un message Facebook Messenger';
  },
};

export default ActionSendFbMessengerMetadata;
