import { UserState } from '@/composables/User';
// eslint-disable-next-line import/no-cycle
import {
  addSmartListOriginalCode,
  restoreSmartListsOriginalCode,
} from '@/composables/template-editor/TemplateEditor';
// eslint-disable-next-line import/no-cycle
import {
  applySpmCollections,
  TemplateContext,
} from '@/components/template-builder/utils/sanitizer';
// eslint-disable-next-line import/no-cycle
import { addWidgetsListeners } from '@/components/template-builder/utils/listeners';
import { Maybe } from '@/types/generated-types/graphql';
// eslint-disable-next-line import/no-cycle
import { ActiveLiveEditorItemData, TemplateStructureEnum } from '@/types';
// eslint-disable-next-line import/no-cycle
import { addActiveItemElementDecorations } from '@/components/template-builder/utils/active-item-builder';
// eslint-disable-next-line import/no-cycle
import { store } from '@/store';
// eslint-disable-next-line import/no-cycle
import { getTemplateWidgets } from '../utils/parser';
// eslint-disable-next-line import/no-cycle
import { getTemplateIframeDocument } from '../utils/helpers';

export const showButton = (selector: string) => {
  const template = getTemplateIframeDocument();
  const container: HTMLElement|null = template?.querySelector(selector);
  const button: HTMLElement|null = container?.querySelector('.spm_container_voucher_button') as HTMLElement;
  if (button) {
    button.classList.remove('spm_hide_on_builder');
  }
  const code: HTMLElement|null = container?.querySelector('.spm_container_voucher_code') as HTMLElement;
  if (code) {
    code.classList.add('spm_hidden');
    const children: Array<HTMLElement> = Array.from(code.querySelectorAll('.spm_hidden'));
    children.forEach((child: HTMLElement) => child.classList.add('spm_hidden'));
  }
};

export const showForm = (selector: string) => {
  const template = getTemplateIframeDocument();
  const container: HTMLElement|null = template?.querySelector(selector);
  const form: HTMLElement|null = container?.querySelector('.spm_step1') as HTMLElement;
  if (form) {
    form.classList.remove('spm_hidden');
  }
  const message: HTMLElement|null = container?.querySelector('.spm_step2') as HTMLElement;
  if (message) {
    message.classList.add('spm_hidden');
  }
};

export const switchVoucherButtonAndCode = (selector: string) => {
  const template = getTemplateIframeDocument();
  const container: HTMLElement|null = template?.querySelector(selector);
  const button: HTMLElement|null = container?.querySelector('.spm_container_voucher_button') as HTMLElement;
  if (button) {
    button.classList.toggle('spm_hide_on_builder');
  }
  const code: HTMLElement|null = container?.querySelector('.spm_container_voucher_code') as HTMLElement;
  if (code) {
    code.classList.toggle('spm_hidden');
    const children: Array<HTMLElement> = Array.from(code.querySelectorAll('.spm_hidden'));
    children.forEach((child: HTMLElement) => child.classList.toggle('spm_hidden'));
  }
};

export const switchFormAndMessage = (selector: string) => {
  const template = getTemplateIframeDocument();
  const container: HTMLElement|null = template?.querySelector(selector);
  const form: HTMLElement|null = container?.querySelector('.spm_step1') as HTMLElement;
  if (form) {
    form.classList.toggle('spm_hidden');
  }
  const message: HTMLElement|null = container?.querySelector('.spm_step2') as HTMLElement;
  if (message) {
    message.classList.toggle('spm_hidden');
  }
};

const refreshSmartProductListPending: Record<string, boolean> = {};
export const refreshSmartProductList = async (widgetToRefresh: Maybe<string> = null, lang: Maybe<string> = null) => {
  if (!widgetToRefresh || !Object.keys(refreshSmartProductListPending).includes(widgetToRefresh)) {
    if (widgetToRefresh) {
      refreshSmartProductListPending[widgetToRefresh] = true;
    }

    const context: TemplateContext = {
      shop: { ...UserState.activeShop },
    };

    const widgets: HTMLElement[] = getTemplateWidgets();
    widgets
      .filter((widget: HTMLElement) => (
        widget.getAttribute('data-widgettype') === 'spm_smart_products_list'
        || widget.getAttribute('data-widgettype') === 'spm_fb_productslist'
        || widget.getAttribute('data-widgettype') === 'spm_widget_import_zip'
      ) && (!widgetToRefresh || widgetToRefresh === widget.getAttribute('id')))
      .forEach((widget: HTMLElement) => {
        // We check if the original code of the widget is in the state otherwise we add it
        const widgetId = widget.getAttribute('id') ?? '';
        addSmartListOriginalCode(widgetId);
      });

    // We restore all smart lists in the template
    restoreSmartListsOriginalCode(widgetToRefresh);
    const template = getTemplateIframeDocument();
    await applySpmCollections(template, context, lang ?? UserState.user.lang, false);
    addWidgetsListeners();
    if (widgetToRefresh) {
      delete refreshSmartProductListPending[widgetToRefresh];

      if (store.getters['liveEditor/getSelectedStructure']) {
        // In case we refresh a specific widget, and structure panel is opened, we add the toolbar inside the widget
        const activeItemData: ActiveLiveEditorItemData = {
          selector: `#${widgetToRefresh}`,
          type: TemplateStructureEnum.WIDGET,
        };

        addActiveItemElementDecorations(activeItemData);
      }
    }
  }
};

export const removeImage = (selector: string) => {
  const template = getTemplateIframeDocument();
  const container: HTMLElement|null = template?.querySelector(selector);
  if (container && container.parentElement) {
    container.parentElement.classList.add('spm_hide');
  }
};

export const showImage = (selector: string) => {
  const template = getTemplateIframeDocument();
  const container: HTMLElement|null = template?.querySelector(selector);
  if (container && container.parentElement) {
    container.parentElement.classList.remove('spm_hide');
  }
};
