
import {
  defineComponent,
  onBeforeMount,
  Ref,
  ref,
} from 'vue';
import { StatsType } from '@/types';
import StatsCustoms from '@/views/stats/StatsCustoms.vue';
import { getUserStatsPages } from '@/composables/Statistics/usersPagesSubscriptions';
import { UserState } from '@/composables/User';
import AIInterface from '@/components/spm-ai/AIInterface.vue';

export default defineComponent({
  name: 'Dashboard',

  components: {
    AIInterface,
    StatsCustoms,
  },

  setup() {
    const dashboardId: Ref<number> = ref(0);

    onBeforeMount(async () => {
      const result = await getUserStatsPages(UserState.user.id, UserState.user.userType, StatsType.DASHBOARD, UserState.activeShop?.idUser);

      if (result && result.items && result.items.length && result.items[0].page?.deleted === false) {
        const dashboard = result.items[0];
        dashboardId.value = dashboard.id_users_pages_configuration;
      }
    });

    return {
      dashboardId,
    };
  },
});
