<template>
  <div
    v-if="!myListsState.showImportPanel"
    class="flex justify-content-between flex-wrap mb-3"
  >
    <div class="flex align-items-center justify-content-center">
      <h3>
        {{ t('myLists.manage.title') }}
      </h3>
    </div>
    <div class="flex align-items-center justify-content-center">
      <SearchCustomers
        key="sc"
        :original-filters="customerFilters"
        @search-customers="execute"
        @close-panel="changeSearchValues"
      />
      <router-link
        v-if=" !showCustomerList"
        class="mr-2"
        to="/lists/import-unsub"
      >
        <SpmButton
          v-if="findPermission('lists-segments.unsubscribers_import')"
          :label="t('myLists.manage.actions.listImport.btnLabel')"
          icon="far fa-download"
          class-style="p-button p-button-secondary"
        />
      </router-link>
      <router-link
        v-if="!showCustomerList"
        to="/lists/import-reports"
        class="mr-2"
      >
        <SpmButton
          v-if="findPermission('lists-segments.import-reports')"
          :label="t('myLists.manage.actions.importReports')"
          icon="far fa-bug"
          class-style="p-button p-button-secondary"
        />
      </router-link>
      <SpmButton
        v-if="showCustomerList"
        :label="t('myLists.importReports.return')"
        icon="far fa-arrow-left"
        :is-global-loading="isGlobalLoading"
        class-style="p-button p-button-secondary mr-2"
        @click="toggleCustomerList"
      />
      <ListCreatePanel
        v-if="!showCustomerList && findPermission('lists-segments.create')"
        :enable-no-import="true"
        :id-shop="idShop"
      />
    </div>
  </div>
  <div
    v-if="!showCustomerList"
    key="main"
    class="ListsManage"
  >
    <SpmTable
      :key="listManageKey"
      ref="childComponentRef"
      v-model="tableData"
      :id-shop="idShop"
      :paginator="true"
      :is-global-loading="isGlobalLoading"
      :table-columns="columns"
      index="ID"
      name=""
      :export-file-name="t('myLists.manage.title')"
    >
      <template #Name="slotProps">
        <span
          class="text-nowrap"
        >
          {{ slotProps.data.Name }}
        </span>
        <i
          v-if="!slotProps.data.isValid"
          v-tooltip="t('myLists.manage.listDeactivated')"
          class="far fa-ban ml-1"
        />
      </template>
      <template #type="slotProps">
        <span
          v-if="slotProps.data.type"
        >
          {{ t(`myLists.manage.listTypes.${slotProps.data.type}`) }}
        </span>
        <span
          v-else
        >
        {{ t('myLists.manage.listTypes.not_defined') }}
        </span>
      </template>
      <template #TotalContacts="slotProps">
        <span
          v-if="slotProps.data.TotalContacts > 0"
        >
          <i class="far fa-users" />
          {{ slotProps.data.TotalContacts }}
        </span>
        <span v-else>-</span>
      </template>
      <template #Subscribers="slotProps">
        <span
          v-if="slotProps.data.Subscribers > 0"
          class="font-light"
        >
          {{ slotProps.data.Subscribers }}
          <span class="font-light"><small> ({{ slotProps.data.PercentSubscribers }})</small></span>
        </span>
        <span v-else>-</span>
      </template>
      <template #NonSubscribers="slotProps">
        <span
          v-if="slotProps.data.NonSubscribers > 0"
          class="font-light"
        >
          {{ slotProps.data.NonSubscribers }}
          <span class="font-light"><small> ({{ slotProps.data.PercentNonSubscribers }})</small></span>
        </span>
        <span v-else>-</span>
      </template>
      <template #Actions="slotProps">
        <ListsManageActions
          :key="`${slotProps.data.ID}${listManageKey}`"
          :data="slotProps.data"
          :func="toggleDialogFunc"
          :id-shop="idShop"
          :type="slotProps.data.typeList"
          :auto-updated-list="slotProps.data.autoUpdatedList"
        />
      </template>
    </SpmTable>
  </div>

  <div
    v-else
    key="list"
  >
    <ListManageCustomers
      :id-shop="idShop"
      :locale="locale"
      :persistent-filters="persistentFilters"
      :translation="t"
      :table-title="t('myLists.manage.title')"
    />
  </div>

  <ImportActions
    v-if="displayDialog"
    :data="importData"
    :display-dialog="displayDialog"
    @close-import-dialog="displayDialog=false"
  />
</template>

<script lang="ts">
import {
  defineComponent, onMounted, Ref, ref, watch,
} from 'vue';
import { useI18n } from 'vue-i18n';
import Tooltip from 'primevue/tooltip';
import {
  MenuItemsModel,
  SpmTableColumns, SpmTableFilter, SpmTableState,
} from '@/types';
import SpmTable from '@/components/table/SpmTable.vue';
import ListsManageActions from '@/components/mes-lists/ListsManageActions.vue';
import {
  TypeList,
  getMyLists, myListsState, MyLists, listManageKey, myListMod,
} from '@/composables/mes-listes/ListsManage';
import { findPermission, UserState } from '@/composables/User';
import { OperatorType } from '@/types/generated-types/graphql';
import SearchCustomers from '@/components/mes-lists/SearchCustomers.vue';
import ListManageCustomers from '@/views/mes-listes/ListManageCustomers.vue';
// eslint-disable-next-line import/no-cycle
import router from '@/router';
import SpmButton from '@/components/spm-primevue/SpmButton.vue';
import ImportActions from '@/components/mes-lists/ImportActions.vue';
import ListCreatePanel from '@/components/mes-lists/ListCreatePanel.vue';
import { SpmTableFilterOption, ListType } from '@/types'
import { FilterOperatorsEnum } from '@/types/enums';

export default defineComponent({
  name: 'ListsManage',
  components: {
    ListCreatePanel,
    ImportActions,
    SpmTable,
    ListsManageActions,
    SearchCustomers,
    ListManageCustomers,
    SpmButton,
  },

  directives: {
    tooltip: Tooltip,
  },

  setup() {
    const {
      t, locale,
    } = useI18n();
    const idShop = ref(UserState.activeShop ? UserState.activeShop.id : 0);
    const search = ref(false);
    const showCustomerList = ref(false);
    const originalFilters = ref();
    const childComponentRef = ref();
    const menuSeparator = { separator: true };
    const displayDialog = ref(false);
    const importData = ref<MyLists>();
    const isGlobalLoading = ref(true);

    const shopVersion = '3.0.0'; // todo: get from UserState
    let typeLists = [TypeList.SYNC, TypeList.RETARGETING].join(',');
    if (shopVersion >= '2.1.4') {
      typeLists = [TypeList.SYNC, TypeList.NEWSLETTER, TypeList.RETARGETING].join(',');
    }

    const toggleDialogFunc = (listId: string, message: string, modalHeader: string, icon: string, acceptLabel: string, rejectLabel: string,
      actionType: string, customerId: number | null, listName?: string) => {
      childComponentRef.value.toggleDialog(
        listId,
        message,
        modalHeader,
        icon,
        acceptLabel,
        rejectLabel,
        actionType,
        customerId,
        listName,
      );
    };

    const persistentFilters = ref<SpmTableFilter[]>([
      {
        field: 'id_shop',
        operator: OperatorType.Equals,
        value: idShop.value,
      },
      {
        field: 'locale',
        operator: OperatorType.Equals,
        value: locale,
      },
      {
        field: 'type_list',
        operator: OperatorType.Equals,
        value: typeLists,
      },
    ]);

    originalFilters.value = persistentFilters.value;

    const actions = ref<MenuItemsModel>({
      button: {
        label: 'action',
        icon: 'far fa-cog',
        class: 'p-button',
        iconPos: 'left',
      },

      menuItems: [
        {
          icon: 'fas fa-pencil-alt',
          label: t('myLists.manage.actions.clientManage'),
          command: (myList: MyLists) => {
            router.push({
              name: 'lists.manage-customers',
              params: { id: myList.ID },
            });
          },

          hide: (myList: MyLists) => true,
        },
        {
          icon: 'far fa-cog',
          label: t('myLists.manage.actions.updateListSegment'),
          command: (myList: MyLists) => {
            if (myList.idShopListImport) {
              myListsState.idShopListImportId = myList.idShopListImport;
              myListsState.idShopList = myList.ID;
              myListsState.name = myList.Name;
              myListsState.type = 'segmentation';
              myListsState.showImportPanel = true;
            }
          },

          hide: (myList: MyLists) => myList.autoUpdatedList ?? true,
        },
        {
          icon: 'fa fa-upload',
          label: t('myLists.manage.actions.clientImport'),
          command: (myList: MyLists) => {
            displayDialog.value = true;

            // method gets called twice because of calling sub-component from here
            if (!Object.prototype.hasOwnProperty.call(myList, 'originalEvent')) {
              importData.value = myList;
            }
          },

          hide: (myList: MyLists) => !myList.autoUpdatedList ?? false,
        },
        /* {
          icon: 'fas fa-file-export',
          label: t('myLists.manage.actions.clientExport'),
          command: (myList: MyLists) => {
            toggleDialogFunc(
              myList.ID.toString(),
              t('myLists.manage.bulkActions.export.text'),
              t('myLists.manage.bulkActions.export.formTitle'),
              'fas fa-file-export',
              t('myLists.manage.bulkActions.export.acceptLabel'),
              t('myLists.manage.bulkActions.export.rejectLabel'),
              'export',
              null,
            );
          },

          hide: () => true,
        }, */
        {
          separator: true,
        },
        {
          icon: 'far fa-copy',
          label: t('myLists.manage.actions.clientCopy'),
          command: (myList: MyLists) => {
            toggleDialogFunc(
              myList.ID.toString(),
              t('myLists.manage.bulkActions.copy.text'),
              t('myLists.manage.bulkActions.copy.formTitle'),
              'fa fa-level-down',
              t('myLists.manage.bulkActions.copy.acceptLabel'),
              t('myLists.manage.bulkActions.copy.rejectLabel'),
              'copy',
              null,
            );
          },

          hide: () => true,
        },
        {
          icon: 'far fa-copy',
          label: t('myLists.manage.actions.listMove'),
          command: (myList: MyLists) => {
            toggleDialogFunc(
              myList.ID.toString(),
              t('myLists.manage.bulkActions.move.text'),
              t('myLists.manage.bulkActions.move.formTitle'),
              'fa fa-level-down',
              t('myLists.manage.bulkActions.move.acceptLabel'),
              t('myLists.manage.bulkActions.move.rejectLabel'),
              'move',
              null,
            );
          },

          hide: () => true,
        },
        {
          icon: 'far fa-plus',
          label: t('myLists.manage.actions.listRename.menuItem'),
          command: (myList: MyLists) => {
            toggleDialogFunc(
              myList.ID.toString(),
              t('myLists.manage.actions.listRename.text'),
              t('myLists.manage.actions.listRename.formTitle'),
              'far fa-plus',
              t('myLists.manage.actions.listRename.acceptLabel'),
              t('myLists.manage.actions.listRename.rejectLabel'),
              'rename',
              null,
              myList.Name,
            );
          },

          hide: () => true,
        },
        {
          icon: 'far fa-clone',
          label: t('myLists.manage.actions.clientClone'),
          command: (myList: MyLists) => {
            toggleDialogFunc(
              myList.ID.toString(),
              t('myLists.manage.bulkActions.duplicate.text'),
              t('myLists.manage.bulkActions.duplicate.formTitle'),
              'far fa-info-circle',
              t('myLists.manage.bulkActions.duplicate.acceptLabel'),
              t('myLists.manage.bulkActions.duplicate.rejectLabel'),
              'duplicate',
              null,
              myList.Name,
            );
          },

          hide: () => true,
        },
        {
          icon: 'far fa-plus',
          label: t('myLists.manage.bulkActions.create.menuItem'),
          command: (myList: MyLists) => {
            toggleDialogFunc(
              myList.ID.toString(),
              t('myLists.manage.bulkActions.create.text'),
              t('myLists.manage.bulkActions.create.formTitle'),
              'far fa-info-circle',
              t('myLists.manage.bulkActions.create.acceptLabel'),
              t('myLists.manage.bulkActions.create.rejectLabel'),
              'newlist',
              null,
            );
          },

          hide: () => false,
        },
        {
          icon: 'far fa-minus-circle',
          label: t('myLists.manage.bulkActions.unsub.menuItem'),
          command: () => {
            toggleDialogFunc(
              '',
              t('myLists.manage.bulkActions.unsub.text'),
              t('myLists.manage.bulkActions.unsub.formTitle'),
              'far fa-info-circle',
              t('myLists.manage.bulkActions.unsub.acceptLabel'),
              t('myLists.manage.bulkActions.unsub.rejectLabel'),
              'unsub_newsletter',
              null,
            );
          },

          hide: () => false,
        },
        {
          icon: 'far fa-trash',
          label: t('myLists.manage.actions.clientDeleteAll'),
          command: () => {
            toggleDialogFunc(
              '',
              t('myLists.manage.bulkActions.deleteFromAll.text'),
              t('myLists.manage.bulkActions.deleteFromAll.formTitle'),
              'far fa-exclamation-triangle',
              t('myLists.manage.bulkActions.deleteFromAll.acceptLabel'),
              t('myLists.manage.bulkActions.deleteFromAll.rejectLabel'),
              'delete_from_all_lists',
              null,
            );
          },

          hide: () => false,
        },
        {
          icon: 'far fa-trash',
          label: t('myLists.manage.actions.listDelete.menuItem'),
          command: (myList: MyLists) => {
            toggleDialogFunc(
              myList.ID.toString(),
              t('myLists.manage.bulkActions.delete.text'),
              t('myLists.manage.bulkActions.delete.formTitle'),
              'far fa-exclamation-triangle',
              t('myLists.manage.bulkActions.delete.acceptLabel'),
              t('myLists.manage.bulkActions.delete.rejectLabel'),
              'delete',
              null,
            );
          },

          hide: () => true,
        },
      ],
    });

    const typeOptions: SpmTableFilterOption[] = [
      {
        label: t('myLists.manage.listTypes.list'),
        value: `${ListType.LIST}, ${ListType.NOT_DEFINED}`
      },
      {
        label: t('myLists.manage.listTypes.segment'),
        value: ListType.SEGMENT,
      }
    ]

    const columns: SpmTableColumns[] = [
      {
        field: 'ID',
        header: '',
        sortable: false,
        filterable: false,
        editable: false,
        style: '',
        type: 'text',
        hidden: true,
      },
      {
        field: 'typeList',
        header: '',
        sortable: false,
        filterable: false,
        editable: false,
        style: '',
        type: 'text',
        hidden: true,
      },
      {
        field: 'Name',
        header: t('myLists.manage.headers.name'),
        sortable: false,
        filterable: true,
        editable: false,
        style: '',
        type: 'text',
      },
      {
        field: 'type',
        header: t('myLists.manage.headers.type'),
        sortable: false,
        filterable: true,
        editable: false,
        style: '',
        type: 'text',
        filterSettings: {
          type: 'multiSelect',
          options: typeOptions,
          hideFilterMenu: true,
          showFilterInput: false,
          defaultMatchMode: FilterOperatorsEnum.IN
        },
      },
      {
        field: 'TotalContacts',
        header: t('myLists.manage.headers.totalContacts'),
        sortable: false,
        filterable: false,
        editable: false,
        style: 'width:20%',
        type: 'text',
      },
      {
        field: 'Subscribers',
        header: t(t('myLists.manage.stats.subscribers')),
        sortable: false,
        filterable: false,
        editable: false,
        style: 'width:20%',
        type: 'text',
      },
      {
        field: 'NonSubscribers',
        header: t('myLists.manage.stats.nonSubscribers'),
        sortable: false,
        filterable: false,
        editable: false,
        style: 'width:20%',
        type: 'text',
      },
      {
        field: 'Actions',
        header: t('myLists.manage.headers.actions'),
        sortable: false,
        filterable: false,
        editable: false,
        style: 'width:4%',
        type: 'text',
        custom: true,
      },
    ];

    const changeSearchValues = () => {
      search.value = false;
    };

    const customerFilters: SpmTableFilter[] = [
      {
        field: 'sc.id_shop',
        operator: OperatorType.Equals,
        value: idShop.value,
      },
    ];
    originalFilters.value = persistentFilters.value;

    const toggleCustomerList = (event: Event) => {
      showCustomerList.value = !showCustomerList.value;
      persistentFilters.value = originalFilters.value;
      search.value = false;
    };

    const execute = (values: any) => {
      persistentFilters.value = values.value;
      showCustomerList.value = true;
    };

    const tableData = ref<SpmTableState>({
      items: [],
      total: 0,
      error: '',
    });

    const reloadData = () => {
      isGlobalLoading.value = true;

      // reset the state
      myListsState.listing = [];
      myListsState.totalItems = 0;

      getMyLists(idShop.value, t)
        .then(() => {
          tableData.value = {
            items: myListsState.listing,
            total: myListsState.totalItems,
            error: '',
          };
          isGlobalLoading.value = false;
        });
    };

    watch(listManageKey, () => {
      reloadData();
      actions.value = {
        button: actions.value.button,
        menuItems: actions.value.menuItems.filter((menuItem) => (menuItem.hide && typeof menuItem.hide === 'function' ? menuItem.hide(myListMod.value) : true)),
      };
    });

    onMounted(() => {
      reloadData();
    });

    return {
      t,
      columns,
      idShop,
      persistentFilters,
      showCustomerList,
      toggleCustomerList,
      execute,
      changeSearchValues,
      customerFilters,
      locale,
      childComponentRef,
      findPermission,
      tableData,
      myListsState,
      listManageKey,
      displayDialog,
      importData,
      toggleDialogFunc,
      isGlobalLoading,
    };
  },
});
</script>

<style lang="scss" scoped>
.blue   { color: rgb(157, 200, 241); }
.green  { color: rgb(132, 195, 0); }
.orange { color: rgb(247, 163, 92); }
.dark   { color: rgb(89, 100, 114); }

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
