// eslint-disable-next-line import/no-cycle
import { Maybe } from '@/types/generated-types/graphql';
// eslint-disable-next-line import/no-cycle
import { UserState } from '@/composables/User';

/**
 * Remove a key from local storage and its expiration key if exists
 * @param key
 * @param useShopId
 * @param useUserId
 */
export function removeLocalStorageElement(key: string, useShopId = true, useUserId = true): boolean {
  let keyName = `${key}`;

  if (useUserId) {
    keyName += `_${UserState.user.userType}_${UserState.user.id}`;
  }

  if (useShopId) {
    keyName += `_${UserState.activeShop?.id ?? 0}`;
  }

  try {
    localStorage.removeItem(keyName);
    localStorage.removeItem(`${keyName}_expiresIn`);
  } catch (e) {
    // console.log(`removeLocalStorageElement: Error removing key [${keyName}] from localStorage: ${JSON.stringify(e)}`);
    return false;
  }

  return true;
}

/**
 * Get a value from local storage by key, or null if key is expired
 * @param key
 * @param useShopId
 * @param useUserId
 */
export function getLocalStorageElement(key: string, useShopId = true, useUserId = true): any {
  let keyName = `${key}`;

  if (useUserId) {
    keyName += `_${UserState.user.userType}_${UserState.user.id}`;
  }

  if (useShopId) {
    keyName += `_${UserState.activeShop?.id ?? 0}`;
  }

  const now = Date.now();

  // set expiration for storage
  let expiresIn;
  expiresIn = localStorage.getItem(`${keyName}_expiresIn`);

  if (expiresIn === undefined || expiresIn === null) {
    expiresIn = 0;
  }

  let returnValue = null;
  if (expiresIn !== 0 && expiresIn < now) {
    // Expired
    removeLocalStorageElement(keyName, false);
  } else {
    try {
      returnValue = localStorage.getItem(keyName);
    } catch (e) {
      // console.log(`getLocalStorageElement: Error reading key [${keyName}] from localStorage: ${JSON.stringify(e)}`);
    }
  }

  return returnValue;
}

/**
 * Store a value inside local storage
 * Create a second key for expiration time if expiration is specified
 * @param key
 * @param value
 * @param useShopId
 * @param expires
 * @param useUserId
 */
export function setLocalStorageElement(key: string, value: any, useShopId = true, expires: Maybe<number> = null, useUserId = true): boolean {
  let keyName = `${key}`;

  if (useUserId) {
    keyName += `_${UserState.user.userType}_${UserState.user.id}`;
  }

  if (useShopId) {
    keyName += `_${UserState.activeShop?.id ?? 0}`;
  }

  const now = Date.now();
  let expiration;

  if (expires === undefined || expires === null) {
    // default no expiration
    expiration = 0;
  } else {
    // get positive value
    expiration = Math.abs(expires);
  }

  try {
    localStorage.setItem(keyName, value);

    if (expiration !== 0) {
      const schedule = now + (expiration * 1000);
      localStorage.setItem(`${keyName}_expiresIn`, schedule.toString());
    }
  } catch (e) {
    // console.log(`setLocalStorageElement: Error setting key [${keyName}] in localStorage: ${JSON.stringify(e)}`);
    return false;
  }

  return true;
}
