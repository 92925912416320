<template>
  <div class="grid flex align-items-stretch">
    <div class="col-12 xl:col-6 flex align-items-center flex-plan">
      <div
        class="p-card currentPlan current-plan-container w-full h-full relative"
      >
        <div
          v-if="shopifyPaymentState.isShopify && shopifyPaymentState.plan.blockPaymentUI"
          class="blocking-element absolute flex flex-column gap-2 justify-content-center align-items-center w-full h-full"
        >
          <div class="text-center m-3">
            <p v-html="shopifyPaymentState.plan.paymentMessage" />
          </div>
          <div class="flex gap-2">
            <SpmButton
              :label="shopifyPaymentState.plan.type === 'simple' ? t('shop.menu.myOffer.payment.shopify.buttonConfirmationSimpleCharge') : t('shop.menu.myOffer.payment.shopify.buttonConfirmationRecurringCharge')"
              class-style="btnBuyableElement p-button p-button-primary"
              @click="redirectToLink(shopifyPaymentState.plan.urlConfirmation)"
            />
          </div>
        </div>
        <BlockUI
          :blocked="shopifyPaymentState.isShopify && shopifyPaymentState.plan.blockPaymentUI"
        >
          <i
            v-if="!shopifyPaymentState.isShopify || !shopifyPaymentState.plan.blockPaymentUI"
            class="chosenPlan far fa-check"
          />

          <div
            v-if="hasOfferV3"
            class="p-card-title relative pb-0 mb-0"
          >
            <span>{{ t('offers.oldPlan') }}</span>
          </div>
          <div
            v-else
            class="p-card-title relative pb-0 mb-0"
          >
            <span v-if="!isCurrentPlanTrial">{{ t('offers.plan') }} : </span>
            <span v-else>{{ t('offers.currentPlan') }} : </span>

            <span v-if="isCurrentPlanTrial"> {{ t('offers.trial') }} </span> {{ t(`offers.plans.${shopPlanOffer}`) }}

            <SpmButton
              v-if="shopPlanOffer === null"
              :label="t('offers.addPlanButton')"
              icon="fa-regular fa-add"
              class-style="btnUpdatePlan p-button p-button-primary"
              @click="updatePlan"
            />

            <div
              v-if="shopPlanPrice && shopPlanFrequency"
              class="currentPlanPrice"
            >
              {{ shopPlanPrice }}/<span class="currentPlanPriceCurrency">{{ t(`offers.frequencyPrice.${shopPlanFrequency}`) }}</span>
            </div>
          </div>
          <div class="p-card-body pt-0">
            <div
              v-if="hasOfferV3"
            >
              <div>
                <div
                  v-if="offerV3 && ['2', '3'].includes(offerV3.bundle_options) && offerV3.max_newsletter && offerV3.nb_sends_newsletter"
                  class="pt-3 flex gap-2 align-items-center"
                >
                  <span>
                    {{ t('offers.oldPlanNewsletters') }} : {{ formatNumber(offerV3.nb_sends_newsletter) }} / {{ formatNumber(offerV3.max_newsletter) }}.
                  </span>
                </div>
                <div class="grid pt-3">
                  <div
                    class="col-6 "
                  >
                    <SpmButton
                      :label="t('offers.updatePlanButton')"
                      icon="fa-regular fa-edit"
                      class-style="btnUpdatePlan p-button p-button-secondary"
                      @click="updateOldPlan"
                    />
                  </div>
                  <div
                    class="col-6 text-right"
                  >
                    <Button
                      :label="t('offers.discoverNewOffersButton')"
                      icon="far fa-arrow-right"
                      class="p-button mb-3 discover-new-offers-btn"
                      icon-pos="right"
                      @click="updatePlan"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div v-else-if="shopPlan">
              <div v-if="shopPlanOffer">
                <div class="currentPlanDateLimit">
                  <span>{{ t('dateUntil') }} {{ shopPlanDateLimit }} </span>
                  <span v-if="!isCurrentPlanTrial"> ({{ t('offers.plan') }} : {{ t(`offers.frequencyRenewal.${shopPlanFrequency}`) }})</span>
                </div>
                <div
                  v-if="shopPlanDiscount"
                  class="currentPlanSms pt-3 flex gap-2 align-items-center"
                >
                  <span>
                    {{ shopPlanDiscountLimit
                      ? t('offers.planWithDiscount', { 'percentage': shopPlanDiscount }) + ` ` + t('offers.planWithDiscountLimit', { 'date': shopPlanDiscountLimit})
                      : t('offers.planWithDiscount', { 'percentage': shopPlanDiscount })
                    }}.
                  </span>
                </div>
                <div
                  v-if="shopPlanPriceDiscount"
                  class="currentPlanSms pt-3 flex gap-2 align-items-center"
                >
                  <span>
                    {{ shopPlanPriceDiscountLimit
                    ? t('offers.planWithPriceDiscount', { 'price': shopPlanPriceDiscount }) + ` ` + t('offers.planWithPriceDiscountLimit', { 'date': shopPlanPriceDiscountLimit})
                    : t('offers.planWithPriceDiscount', { 'price': shopPlanPriceDiscount })
                    }}.
                  </span>
                </div>
                <div
                  v-if="shopPlanSmsPrice"
                  class="currentPlanSms pt-3 flex gap-2 align-items-center"
                >
                  <span>
                    {{ t('offers.planWithSms') }} {{ shopPlanSmsPrice }}.
                  </span>
                </div>
                <div
                  v-if="isCurrentPlanTrial"
                  class="mt-3 flex gap-1 align-items-center"
                >
                  <span v-if="actualShopOffer">{{ t('offers.planBeforeTrial') }} : </span>
                  <span v-if="actualShopOffer">{{ t(`offers.plans.${actualShopOffer.plan}`) }}</span>
                  <span
                    v-if="actualShopOffer && actualShopOffer.limit"
                    class="text-sm"
                  >
                    ({{ t('dateUntil') }} {{ actualShopOffer.limit }})
                  </span>
                  <SpmButton
                    v-if="!isFirstTrial"
                    :label="t('offers.cancelTrialButton')"
                    icon="fa-regular fa-xmark"
                    class-style="p-button p-button-secondary"
                    @click="handleCancelTrial"
                  />
                </div>
                <div class="pt-3">
                  <div v-if="'nextPlan' in shopPlan && shopPlan.nextPlan && (!'unsubscribe' in shopPlan || !shopPlan.unsubscribe)">
                    <SpmButton
                      :label="t('offers.unsubButton')"
                      icon="fa-regular fa-xmark"
                      class-style="p-button p-button-secondary"
                      @click="unsubscribe"
                    />
                    <SpmButton
                      :label="t('offers.updatePlanButton')"
                      icon="fa-regular fa-edit"
                      class-style="btnUpdatePlan p-button p-button-primary"
                      @click="updatePlan"
                    />
                  </div>
                  <div v-else>
                    <SpmButton
                      v-if="!shopPlan.unsubscribe"
                      :label="t('offers.updatePlanButton')"
                      icon="fa-regular fa-edit"
                      class-style="btnUpdatePlan p-button p-button-primary mb-3"
                      @click="updatePlan"
                    />
                    <Message
                      v-if="!isFirstTrial"
                      severity="warn"
                      :closable="false"
                    >
                      {{ t('offers.unsubNextRenewal') }}
                    </Message>

                    <div
                      v-if="!isFirstTrial"
                      class="btnUnsubscribe pt-3"
                    >
                      <SpmButton
                        :label="t('offers.cancelUnsubButton')"
                        icon="fa-regular fa-xmark"
                        class-style="btnUpdatePlan p-button p-button-primary"
                        @click="cancelUnsubscription"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </BlockUI>
      </div>
    </div>
    <div class="col-12 xl:col-6 flex align-items-center flex-plan">
      <div class="p-card p-card-border contactUs w-full h-full">
        <div class="p-card-title relative">
          {{ t('offers.anyQuestions') }}
          <i class="offersQuestions far fa-question" />
        </div>
        <div class="p-card-body">
          <a
            href="javascript:void(0);"
            @click="openGleap"
          >{{ t('offers.sendMessageToCS') }}</a><br>
        </div>
      </div>
    </div>
  </div>
  <div class="grid flex align-items-stretch pt-2">
    <div
      v-for="(buyableElement, index) in elementsToBuy"
      :key="`buyableElement${index}`"
      class="col-12 xl:col-6 flex align-items-center flex-plan relative"
    >
      <BuyableElementV14
        :buyable-element="buyableElement"
        :sms-pricing="smsPricing"
        :shopify-payment-state="shopifyPaymentState.credits"
      />
    </div>
  </div>
  <div class="grid">
    <div class="col-12">
      <div class="p-card">
        <div class="p-card-title">
          {{ t('offers.otherInformation') }}
        </div>
        <div class="p-card-body pt-0">
          <div class="currentPagesViewed">
            {{ t(`offers.pagesViewed`, { 'viewedPages': pagesViewed }) }}
          </div>
          <div class="currentSmsCredits flex gap-2 align-items-center">
            <span>
              {{ t(`offers.smsCreditsPack`, { 'smsCreditsPack': shopSmsCredits.amount }) }}
            </span>
            <SpmButton
              v-if="findPermission('my_offer.pay') && !shopifyPaymentState.isShopify && Object.keys(shopSmsCredits.renewData).length > 0"
              :label="`${shopSmsCredits.autoRenew ? t('offers.deactivateSmsCreditsAutoRenew') : t('offers.activateSmsCreditsAutoRenew')}`"
              class-style="btnBuyableElement p-button p-button-secondary"
              @click="toggleSmsCreditsPackRenewal"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="findPermission('payment_method.view') && !shopifyPaymentState.isShopify"
      class="col-12"
    >
      <div class="p-card paymentMethods">
        <div class="p-card-title">
          {{ t('profileMenu.myBankCards') }}
        </div>
        <div class="p-card-body pt-0 relative">
          <Loader
            v-if="adyenTokensLoading"
            style="z-index:1102"
          />
          <div class="flex align-items-stretch flex-wrap">
            <div
              v-if="findPermission('payment_method.create')"
              class="col-6 xl:col-4 flex align-items-center newPaymentMethod"
              @click="addPaymentMethod"
            >
              <div class="paymentMethodContainer">
                <i class="far fa-plus" />
              </div>
            </div>
            <div
              v-for="(token, index) in adyenTokens"
              :key="`token_${index}`"
              class="col-6 xl:col-4 flex align-items-center paymentMethod"
              :class="{ 'currentPaymentMethod': token.is_default }"
            >
              <div class="paymentMethodContainer">
                <i
                  v-if="token.is_default"
                  class="defaultPaymentMethod far fa-check"
                />

                <div class="cardType">
                  {{ token.brand_name }}
                </div>
                <div class="cardNumber">
                  <Image
                    v-if="getPaymentMethodLogo(token.payment_method, token.brand) !== ''"
                    alt="Mastercard"
                    :src="`${getPaymentMethodLogo(token.payment_method, token.brand)}`"
                    image-class="credit-card"
                  />
                  **** **** **** {{ token.last_four }}
                </div>
                <div class="buttons">
                  <div
                    v-if="findPermission('payment_method.makeDefault') && !token.is_default"
                    class="p-button p-button-primary buttonDefault"
                    @click="setDefaultPaymentMethod(token)"
                  >
                    <i class="far fa-check" />
                  </div>
                  <div
                    v-if="findPermission('payment_method.delete') && adyenTokens.length > 1"
                    class="p-button p-button-secondary buttonRemove"
                    @click="deletePaymentMethod(token)"
                  >
                    <i class="far fa-trash" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="findPermission('my_bills.view')"
      class="col-12"
    >
      <div class="p-card">
        <div class="p-card-title">
          {{ t('offers.bills') }}
        </div>
        <div class="p-card-body pt-0">
          <ShopBills
            v-if="!shopifyPaymentState.isShopify"
            :display-title="false"
          />
          <Message
            v-else
            severity="info"
            :closable="false"
          >
            {{ t('shop.menu.myOffer.payment.shopify.invoiceMessage') }}
          </Message>
        </div>
      </div>
    </div>
  </div>
  <ConfirmDialog group="dialog" />
  <ConfirmPopup group="popup" />
  <Dialog
    v-model:visible="addPaymentMethodModal"
    modal
    :closable="true"
    :style="{ width: '50vw' }"
    :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
  >
    <template #header>
      <h3>{{ t('profile.myBankCards.dialog.title') }}</h3>
    </template>
    <section class="contents">
      <div class="font-light mb-3">
        {{ t('profile.myBankCards.dialog.summary') }}
      </div>
      <div
        id="dropInComponent"
        ref="dropInComponent"
      />
    </section>
    <template #footer>
      <Button
        v-if="findPermission('payment_method.create')"
        :label="t('close')"
        icon="far fa-times"
        class="p-button-secondary"
        @click="addPaymentMethodModal = false"
      />
    </template>
  </Dialog>
</template>

<script lang="ts">
import Gleap from 'gleap';
import {
  computed, ComputedRef,
  defineComponent, onBeforeMount, Ref, ref,
  onUnmounted, reactive,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { getMonthlyPagesViewed } from '@/composables/shop/Shops';
import ShopBills from '@/views/shop/ShopBills.vue';
import SpmButton from '@/components/spm-primevue/SpmButton.vue';
import Loader from '@/components/layout/Loader.vue';
import Image from 'primevue/image';
import ConfirmDialog from 'primevue/confirmdialog';
import Dialog from 'primevue/dialog';
import { formatDate } from '@/helpers/Date';
import { List } from '@/composables/GraphQL';
import {
  AdyenTokens, AdyenTokensUpdateInputItem, OperatorType,
} from '@/types/generated-types/graphql';
import {
  findPermission,
  setCurrentPlanUnsubscription,
  cancelTrial,
  UserState,
} from '@/composables/User';
import { formatNumberToCurrency, formatNumbersInString } from '@/helpers/Number';
import { useStore } from '@/store';
import { showToastError, showToastSuccess } from '@/helpers';
import GetPaymentMethods, {
  AddPaymentMethod, adyenConfig, MakeDefault, MakeDetailsCall, MarkUserTokenAsDelete,
} from '@/composables/user/AdyenConfig';
import { useConfirm } from 'primevue/useconfirm';
import AdyenCheckout from '@adyen/adyen-web';
import { buyableElements } from '@/composables/shop/shopsPlans';
import { getShopsCredits, toggleSmsCreditsRenewal } from '@/composables/credits/Credits';
import { SmsPricing } from '@/types/offer-types';
import { getConfigurationKey } from '@/composables/configs/configuration';
import ConfirmPopup from 'primevue/confirmpopup';
import Message from 'primevue/message';
import Button from 'primevue/button';
import BuyableElementV14 from '@/components/my-offer/MyOffer14/BuyableElementV14.vue';
import BlockUI from 'primevue/blockui';

// External library
import moment from 'moment';
import { getShopPendingSimpleCharges } from '@/composables/user/ShopifyConfig';
import { DEFAULT_CURRENCY } from '@/components/template-builder/utils/constants';
import CryptoJS from 'crypto-js';

export default defineComponent({
  name: 'SummaryPlanV14',
  components: {
    Loader,
    SpmButton,
    ShopBills,
    Image,
    Dialog,
    ConfirmDialog,
    ConfirmPopup,
    Message,
    Button,
    BuyableElementV14,
    BlockUI,
  },

  props: {
    hasOfferV3: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['show-old-offer-page'],

  setup(props, { emit }) {
    const { t } = useI18n();
    const store = useStore();
    const confirmDialog = useConfirm();
    const typeOffer = computed(() => (UserState.hasOfferV3 ? 'v3' : 'v4'));
    const elementsToBuy = buyableElements.filter((buyableElement) => buyableElement.availableForOffers.includes(typeOffer.value));

    // User and shop settings
    const userId = UserState.user.id;
    const locale = UserState.user.lang;
    const countryCode = locale.toLocaleUpperCase();
    const smsPricing = ref<Record<string, SmsPricing[]>>({});
    const planSmsPricing = ref<SmsPricing[]>([]);
    const currency = ref(UserState.activeShop?.currency ?? DEFAULT_CURRENCY);
    currency.value = UserState.activeShop?.solutionEcommerce === 'shopify' ? 'USD' : currency.value;

    // Current plan of shop
    const pagesViewed = ref(0);
    const offerV3 = ref(UserState.offerV3);
    const shopPlan: Ref<Record<string, any> | null> = ref(UserState.activeOffer);
    const shopPlanFrequency: ComputedRef<string | null> = computed(() => ((shopPlan.value && 'currentPlanFrequency' in shopPlan.value && shopPlan.value.currentPlanFrequency)
      ? shopPlan.value.currentPlanFrequency
      : null));
    const shopSmsCredits: Ref<Record<string, any>> = ref({
      type: 'smsV4',
      date: null,
      amount: '0',
      renewData: {},
      autoRenew: 0,
    });
    const shopPlanOffer = computed(() => {
      let returnValue = null;

      if (shopPlan.value && 'trialPlan' in shopPlan.value && shopPlan.value.trialPlan) {
        returnValue = shopPlan.value.trialPlan;
      } else if (shopPlan.value && 'forcedPlan' in shopPlan.value && shopPlan.value.forcedPlan) {
        returnValue = shopPlan.value.forcedPlan;
      } else if (shopPlan.value && 'currentPlan' in shopPlan.value && shopPlan.value.currentPlan) {
        returnValue = shopPlan.value.currentPlan;
      }

      return returnValue;
    });
    const shopPlanDateLimit = computed(() => {
      let returnValue = null;

      if (shopPlan.value && 'trialPlanLimit' in shopPlan.value && shopPlan.value.trialPlanLimit) {
        returnValue = formatDate(shopPlan.value.trialPlanLimit, 'YYYY-MM-DD', 'DD/MM/YYYY');
      } else if (shopPlan.value && 'forcedPlanLimit' in shopPlan.value && shopPlan.value.forcedPlanLimit) {
        returnValue = formatDate(shopPlan.value.forcedPlanLimit, 'YYYY-MM-DD', 'DD/MM/YYYY');
      } else if (shopPlan.value && 'currentPlanLimit' in shopPlan.value && shopPlan.value.currentPlanLimit) {
        returnValue = formatDate(shopPlan.value.currentPlanLimit, 'YYYY-MM-DD', 'DD/MM/YYYY');
      }

      return returnValue;
    });
    const shopPlanPrice: ComputedRef<string | null> = computed(() => {
      let returnValue = null;

      if (shopPlan.value && 'currentPlanPrice' in shopPlan.value && shopPlan.value.currentPlanPrice
        && 'currentPlanPriceCurrency' in shopPlan.value && shopPlan.value.currentPlanPriceCurrency) {
        returnValue = formatNumberToCurrency(shopPlan.value.currentPlanPrice, shopPlan.value.currentPlanPriceCurrency);
      }

      return returnValue;
    });
    const isCurrentPlanTrial = computed(() => {
      if (shopPlan.value && shopPlan.value.trialPlan) {
        if (moment().isBefore(moment(shopPlan.value.trialPlanLimit, 'YYYY-MM-DD'))) {
          return true;
        }
      }
      return false;
    });
    const actualShopOffer = computed(() => {
      let returnValue = null;

      if (shopPlan.value && 'forcedPlan' in shopPlan.value && shopPlan.value.forcedPlan) {
        returnValue = {
          plan: shopPlan.value.forcedPlan,
          limit: shopPlan.value.forcedPlanLimit ? formatDate(shopPlan.value.forcedPlanLimit, 'YYYY-MM-DD', 'DD/MM/YYYY') : '',
        };
      } else if (shopPlan.value && 'currentPlan' in shopPlan.value && shopPlan.value.currentPlan) {
        returnValue = {
          plan: shopPlan.value.currentPlan,
          limit: shopPlan.value.currentPlanLimit ? formatDate(shopPlan.value.currentPlanLimit, 'YYYY-MM-DD', 'DD/MM/YYYY') : '',
        };
      }

      return returnValue;
    });
    const shopPlanSmsPrice = computed(() => {
      let returnValue = null;

      if (shopPlan.value && shopPlan.value.smsPlan && shopPlan.value.smsPlanCountry && shopPlan.value.smsPlanCurrency) {
        const actualSmsPricingValue = planSmsPricing.value.find((item: any) => item.value === shopPlan?.value?.smsPlanCountry);
        if (actualSmsPricingValue) {
          const price = Math.round(actualSmsPricingValue.smsPrice * shopPlan.value.smsPlan * 100) / 100;
          returnValue = formatNumberToCurrency(price, shopPlan.value.smsPlanCurrency);
        }
      }

      return returnValue;
    });
    const shopPlanDiscount = computed(() => {
      let returnValue = null;

      if (
        shopPlan.value
        && 'discount' in shopPlan.value
        && shopPlan.value.discount
        && 'plan' in shopPlan.value.discount
        && (
          (!('discountLimit' in shopPlan.value)
            || !shopPlan.value.discountLimit
            || !('plan' in shopPlan.value.discountLimit)
            || ('plan' in shopPlan.value.discountLimit
              && (shopPlan.value.discountLimit.plan >= moment().format('YYYY-MM-DD')
                || !shopPlan.value.discountLimit.plan)))
        )
      ) {
        returnValue = shopPlan.value.discount.plan;
      }

      return returnValue;
    });
    const shopPlanDiscountLimit = computed(() => {
      let returnValue = null;

      if (
        shopPlan.value
        && ('discountLimit' in shopPlan.value)
        && 'plan' in shopPlan.value.discountLimit
        && shopPlan.value.discountLimit.plan
        && shopPlan.value.discountLimit.plan >= moment().format('YYYY-MM-DD')
      ) {
        returnValue = formatDate(shopPlan.value.discountLimit.plan, 'YYYY-MM-DD', 'DD/MM/YYYY');
      }

      return returnValue;
    });
    const shopPlanPriceDiscount = computed(() => {
      let returnValue = null;

      if (
        shopPlan.value
        && 'discount' in shopPlan.value
        && shopPlan.value.discount
        && 'plan_price' in shopPlan.value.discount
        && (
          (!('discountLimit' in shopPlan.value)
            || !shopPlan.value.discountLimit
            || !('plan_price' in shopPlan.value.discountLimit)
            || ('plan_price' in shopPlan.value.discountLimit
              && (shopPlan.value.discountLimit.plan_price >= moment().format('YYYY-MM-DD')
                || !shopPlan.value.discountLimit.plan_price)))
        )
      ) {
        returnValue = formatNumberToCurrency(shopPlan.value.discount.plan_price, currency.value);
      }

      return returnValue;
    });
    const shopPlanPriceDiscountLimit = computed(() => {
      let returnValue = null;

      if (
        shopPlan.value
        && ('discountLimit' in shopPlan.value)
        && 'plan_price' in shopPlan.value.discountLimit
        && shopPlan.value.discountLimit.plan_price
        && shopPlan.value.discountLimit.plan_price >= moment().format('YYYY-MM-DD')
      ) {
        returnValue = formatDate(shopPlan.value.discountLimit.plan_price, 'YYYY-MM-DD', 'DD/MM/YYYY');
      }

      return returnValue;
    });
    const isFirstTrial = computed(() => {
      if (UserState.hasOfferV3) {
        return false;
      }
      if (shopPlan.value) {
        return !shopPlan.value.currentPlan && !shopPlan.value.forcedPlan && shopPlan.value.trialPlan;
      }
      return false;
    });
    // Current plan of shop

    // Adyen configuration
    const adyenTokens: Ref<AdyenTokens[]> = ref([]);
    const adyenTokensLoading = ref(false);
    const addPaymentMethodModal = ref(false);
    const adyenLocale = `${locale}_${countryCode}`;
    const adyenEnvironment: string = process.env.VUE_APP_ADYEN_ENVIRONMENT ?? 'live';
    // Adyen configuration

    /**
     * Ask to unsubscribe next month
     */
    const unsubscribe = (event: any) => {
      confirmDialog.require({
        group: 'popup',
        target: event.currentTarget,
        message: t('offers.unsubscribe.popupMessage'),
        icon: 'pi pi-info-circle',
        acceptLabel: t('yes'),
        acceptClass: 'p-button-danger confirm-popup-button',
        rejectLabel: t('no'),
        rejectClass: 'p-button-secondary confirm-popup-button',
        accept: () => {
          setCurrentPlanUnsubscription(true);
        },
      });
    };

    /**
     * Cancel unsubscription
     */
    const cancelUnsubscription = (event: any) => {
      confirmDialog.require({
        group: 'popup',
        target: event.currentTarget,
        message: t('offers.resubscribe.popupMessage'),
        icon: 'pi pi-info-circle',
        acceptLabel: t('yes'),
        acceptClass: 'p-button-primary confirm-popup-button',
        rejectLabel: t('no'),
        rejectClass: 'p-button-secondary confirm-popup-button',
        accept: () => {
          setCurrentPlanUnsubscription(false);
        },
      });
    };

    /**
     * Cancel trial
     */
    const handleCancelTrial = (event: any) => {
      confirmDialog.require({
        group: 'popup',
        target: event.currentTarget,
        message: t('offers.cancelTrial.popupMessage'),
        icon: 'pi pi-info-circle',
        acceptLabel: t('yes'),
        acceptClass: 'p-button-danger confirm-popup-button',
        rejectLabel: t('no'),
        rejectClass: 'p-button-secondary confirm-popup-button',
        accept: () => {
          cancelTrial();
        },
      });
    };

    /**
     * Open plans component
     */
    const updatePlan = () => {
      store.commit('choosePlan/setIsVisible', true);
    };

    /**
     * Load payment tokens from database
     */
    const loadAdyenTokens = async () => {
      adyenTokensLoading.value = true;
      adyenTokens.value = [];

      const { items } = await List<AdyenTokens>({
        name: 'AdyenTokens',
        settings: {
          offset: 0,
          limit: 0,
          order: [
            { field: 'is_default', type: 'DESC' },
            { field: 'id', type: 'ASC' },
          ],
          filter: [
            {
              field: 'id_user',
              value: UserState.user.id,
              operator: OperatorType.Equals,
            },
            {
              field: 'deleted',
              value: 0,
              operator: OperatorType.Equals,
            },
          ],
        },
        fields: ['id', 'id_user', 'payment_method', 'brand_name', 'brand', 'last_four', 'is_default'],
      });

      if (items && items.length > 0) {
        adyenTokens.value = items;
      }

      adyenTokensLoading.value = false;
    };

    const openGleap = () => {
      Gleap.open();
    };

    /**
     * Add new payment method
     */
    const addPaymentMethod = async () => {
      addPaymentMethodModal.value = true;
      const paymentMethods = await GetPaymentMethods({
        id_user: userId, currency: 'EUR', amount: 0, country_code: countryCode, locale: adyenLocale,
      });

      const configuration = {
        translations: {
          'fr-FR': {
            payButton: t('profile.myBankCards.buttons.register'),
          },
        },

        paymentMethodsResponse: paymentMethods.data,
        removePaymentMethods: ['paypal'], // Retrait temporaire avant de pouvoir générer des tokens
        clientKey: adyenConfig[adyenEnvironment]['client-key'],
        locale: adyenLocale,
        environment: adyenConfig[adyenEnvironment].environment,
        onSubmit: async (state: any, dropin: any) => {
          dropin.setStatus('loading');

          // Your function calling your server to make the `/payments` request
          AddPaymentMethod(userId, state.data).then(async (response) => {
            if (response.data.action) {
              // Drop-in handles the action object from the /payments response
              dropin.handleAction(response.data.action);
            } else if (response.data.resultCode === 'Authorised' || response.data.resultCode === 'Received') {
              // Your function to show the final result to the shopper
              dropin.setStatus('success', { message: t('profile.myBankCards.addPayment.addCardAccepted') });
              await loadAdyenTokens();
            } else {
              if (typeof (response.data.message) !== 'undefined') {
                showToastError(response.message);
              }
              dropin.setStatus('error', { message: response.data.message });
            }
          }).catch((reason: any) => {
            dropin.setStatus('error', { message: reason.response.data.refusalReason });
          });
        },

        onAdditionalDetails: (state: any, dropin: any) => {
          // Your function calling your server to make a `/payments/details` request
          MakeDetailsCall(JSON.stringify(state.data))
            .then((response) => {
              if (response.data.action) {
                // Drop-in handles the action object from the /payments response
                dropin.handleAction(response.data.action);
              } else if (response.data.resultCode === 'Authorised') {
                // Your function to show the final result to the shopper
                dropin.setStatus('success', { message: t('profile.myBankCards.addPayment.addCardAccepted') });

                window.setTimeout(() => {
                  showToastSuccess(t('profile.myBankCards.addPayment.addCardAccepted'));
                }, 2000);
              } else {
                if (typeof (response.message) !== 'undefined') {
                  showToastError(response.message);
                }

                dropin.setStatus('error', { message: response.message });
              }
            })
            .catch((error) => {
              throw Error(error);
            });
        },

        onPaymentCompleted: (result: any, component: any) => {
          console.info(result, component);
        },

        onError: (error: any, component: any) => {
          console.error(error.name, error.message);
        },

        paymentMethodsConfiguration: {
          card: {
            hasHolderName: true,
            holderNameRequired: true,
            hideCVC: false,
          },
        },
      };

      const checkout = await AdyenCheckout(configuration);
      checkout.create('dropin', { showStoredPaymentMethods: false }).mount('#dropInComponent').setComponentRef('dropInComponent');
    };

    /**
     * Mark token as default token
     * @param token
     */
    const setDefaultPaymentMethod = (token: AdyenTokens) => {
      confirmDialog.require({
        group: 'dialog',
        rejectLabel: t('no'),
        acceptLabel: t('yes'),
        icon: 'fas fa-money-check-alt',
        header: t('profile.myBankCards.buttons.default'),
        message: t('profile.myBankCards.confirm.defaultTitle'),
        accept: async () => {
          const adyenInput: AdyenTokensUpdateInputItem = {
            id: token.id,
            id_user: token.id_user,
          };
          const {
            err,
          } = await MakeDefault([adyenInput]);

          if (err !== '') {
            await showToastError('GENERIC_ERROR');
          } else {
            await showToastSuccess('Success');
            await loadAdyenTokens();
          }
        },
      });
    };

    /**
     * Delete a payment token
     * @param token
     */
    const deletePaymentMethod = (token: AdyenTokens) => {
      confirmDialog.require({
        group: 'dialog',
        rejectLabel: t('no'),
        acceptLabel: t('yes'),
        icon: 'far fa-trash',
        header: t('profile.myBankCards.buttons.delete'),
        message: t('profile.myBankCards.confirm.deleteTitle'),
        accept: async () => {
          MarkUserTokenAsDelete(token.id_user, { id: token.id }).then(async (response) => {
            if (!response.data.success) {
              await showToastError('GENERIC_ERROR');
            } else {
              await showToastSuccess('success');
              await loadAdyenTokens();
            }
          });
        },
      });
    };

    /**
     * Returns logo of payment method
     * @param paymentMethod
     * @param brand
     */
    const getPaymentMethodLogo = (paymentMethod: string, brand: string) => {
      let image = '';

      switch (paymentMethod) {
        case 'scheme':
          switch (brand) {
            case 'mc':
              image = '/images/payment/MasterCard-dark.svg';
              break;
            case 'visa':
              image = '/images/payment/Visa-card-dark.svg';
              break;
            case 'cartebancaire':
              image = '/images/payment/CB-dark.svg';
              break;
            case 'amex':
              image = '/images/payment/AmericanExpress-dark.svg';
              break;
            default:
              image = '';
          }
          break;
        case 'sepadirectdebit':
          image = '/images/payment/Sepa-card-dark.svg';
          break;
        case 'paypal':
          image = '/images/payment/Paypal-card-dark.svg';
          break;
        default:
          image = '';
      }

      return image;
    };

    const toggleSmsCreditsPackRenewal = async (event: any) => {
      confirmDialog.require({
        group: 'popup',
        target: event.currentTarget,
        message: shopSmsCredits.value.autoRenew ? t('offers.deactivateSmsCreditsRenewal.popupMessage') : t('offers.activateSmsCreditsRenewal.popupMessage'),
        icon: 'pi pi-info-circle',
        acceptLabel: t('yes'),
        acceptClass: 'p-button-danger confirm-popup-button',
        rejectLabel: t('no'),
        rejectClass: 'p-button-secondary confirm-popup-button',
        accept: () => {
          toggleSmsCreditsRenewal(UserState.activeShop?.id ?? 0, shopSmsCredits.value);
          shopSmsCredits.value.autoRenew = shopSmsCredits.value.autoRenew ? 0 : 1;
          shopSmsCredits.value.renewData.autoRenew = shopSmsCredits.value.autoRenew.toString();
        },
      });
    };

    const updateOldPlan = () => {
      //emit('show-old-offer-page');
      const idShop: number = UserState.activeShop ? UserState.activeShop.id : 0;
      const cryptedKey = CryptoJS.SHA1(`remoteplanZn26687S2682MF2ZV4Ag2g9JGbhF686X${idShop}`).toString();
      let query = '/advisor/logAsShop/';
      query += `${idShop}/remoteplan/${cryptedKey}`;
      const url = `${process.env.VUE_APP_ZEND_URL}${query}`;
      const options = 'width=1280,height=768,top=100,left=100,toolbar=no,menubar=no,scrollbars=no,status=no'; // Ajustez les valeurs selon vos besoins
      const newWindow = window.open(url, '_blank', options);
      if (newWindow) newWindow.opener = null;
    };

    const shopifyPaymentState = reactive({
      isShopify: UserState.activeShop && UserState.activeShop.solutionEcommerce === 'shopify',
      plan: {
        type: '',
        blockPaymentUI: false,
        paymentMessage: '',
        idCharge: 0,
        urlConfirmation: '',
      },
      credits: {
        blockPaymentUI: false,
        paymentMessage: '',
        idCharge: 0,
        urlConfirmation: '',
      },
    });
    const intervalCheckShopifyPaymentState = ref();
    const redirectToLink = (url: string) => {
      window.location.href = url;
    };

    const checkShopifyPaymentState = async (): Promise<any> => {
      const shopPendingSimpleCharges = await getShopPendingSimpleCharges(UserState.activeShop?.id ?? 0);
      if (shopPendingSimpleCharges && shopPendingSimpleCharges.success) {
        if (shopPendingSimpleCharges.credits && shopPendingSimpleCharges.credits.id) {
          shopifyPaymentState.credits.blockPaymentUI = true;
          shopifyPaymentState.credits.paymentMessage = t('shop.menu.myOffer.payment.shopify.pendingOneTimePayment',
            [shopPendingSimpleCharges.credits.title, shopPendingSimpleCharges.credits.price]);
          shopifyPaymentState.credits.idCharge = shopPendingSimpleCharges.credits.id;
          shopifyPaymentState.credits.urlConfirmation = shopPendingSimpleCharges.credits.urlConfirmation;
        } else {
          shopifyPaymentState.credits.blockPaymentUI = false;
          shopifyPaymentState.credits.paymentMessage = '';
          shopifyPaymentState.credits.idCharge = 0;
          shopifyPaymentState.credits.urlConfirmation = '';
        }

        if (shopPendingSimpleCharges.plan && shopPendingSimpleCharges.plan.id) {
          shopifyPaymentState.plan.blockPaymentUI = true;
          shopifyPaymentState.plan.paymentMessage = t('shop.menu.myOffer.payment.shopify.pendingOneTimePayment',
            [shopPendingSimpleCharges.plan.title, shopPendingSimpleCharges.plan.price]);
          shopifyPaymentState.plan.idCharge = shopPendingSimpleCharges.plan.id;
          shopifyPaymentState.plan.urlConfirmation = shopPendingSimpleCharges.plan.urlConfirmation;
          shopifyPaymentState.plan.type = 'simple';
        } else {
          shopifyPaymentState.plan.blockPaymentUI = false;
          shopifyPaymentState.plan.paymentMessage = '';
          shopifyPaymentState.plan.idCharge = 0;
          shopifyPaymentState.plan.urlConfirmation = '';
          shopifyPaymentState.plan.type = '';
        }
      }

      if (intervalCheckShopifyPaymentState.value && !shopifyPaymentState.plan.blockPaymentUI && !shopifyPaymentState.credits.blockPaymentUI) {
        clearInterval(intervalCheckShopifyPaymentState.value);
        window.location.reload();
      }
    };

    onBeforeMount(async () => {
      try {
        store.commit('general/showTheSpinner');
        if (shopifyPaymentState.isShopify) {
          try {
            await checkShopifyPaymentState();
            if (shopifyPaymentState.plan.blockPaymentUI || shopifyPaymentState.credits.blockPaymentUI) {
              intervalCheckShopifyPaymentState.value = setInterval(async () => {
                await checkShopifyPaymentState();
              }, 5000);
            }
          } catch (error) {
            await showToastError('GENERIC_ERROR');
          }
        }

        // Get correct SMS prices according to offer and CMS
        let keyPriceSms;
        let keyPricePlanSms;

        if (UserState.hasOfferV3) {
          keyPriceSms = shopifyPaymentState.isShopify ? await getConfigurationKey('prices_sms_usd_version_12') : await getConfigurationKey('prices_sms_version_12');
          keyPricePlanSms = shopifyPaymentState.isShopify ? await getConfigurationKey('prices_sms_plan_usd_version_12') : await getConfigurationKey('prices_sms_plan_version_12');
        } else {
          keyPriceSms = shopifyPaymentState.isShopify ? await getConfigurationKey('prices_sms_usd_version_14') : await getConfigurationKey('prices_sms_version_14');
          keyPricePlanSms = shopifyPaymentState.isShopify ? await getConfigurationKey('prices_sms_plan_usd_version_14') : await getConfigurationKey('prices_sms_plan_version_14');
        }

        if (keyPriceSms && keyPriceSms.value) {
          smsPricing.value = JSON.parse(keyPriceSms.value);
        }
        if (keyPricePlanSms && keyPricePlanSms.value) {
          planSmsPricing.value = JSON.parse(keyPricePlanSms.value);
        }

        // Get monthly pages viewed
        const { data } = await getMonthlyPagesViewed();

        if (data && data.pages) {
          pagesViewed.value = data.pages;
        }

        // Display SMS credits V4
        const shopCreditsV4 = await getShopsCredits(UserState.activeShop?.id ?? 0, 'smsV4');
        if (shopCreditsV4 && typeof shopCreditsV4 !== 'number') {
          shopSmsCredits.value.date = shopCreditsV4.date;
          shopSmsCredits.value.type = shopCreditsV4.type;
          shopSmsCredits.value.amount = shopCreditsV4.amount;
          const renewData = shopCreditsV4.renew_data ? JSON.parse(shopCreditsV4.renew_data) : {};
          if (renewData) {
            shopSmsCredits.value.renewData = renewData;
            shopSmsCredits.value.autoRenew = renewData.autoRenew || 0;
          }
        }

        // Display SMS credits V3
        const shopCreditsV3 = await getShopsCredits(UserState.activeShop?.id ?? 0, 'sms');
        if (shopCreditsV3 && typeof shopCreditsV3 !== 'number') {
          shopSmsCredits.value.amount += shopCreditsV3.amount;
        }

        shopSmsCredits.value.amount = formatNumberToCurrency(shopSmsCredits.value.amount, currency.value);

        await loadAdyenTokens();

        store.commit('general/hideTheSpinner');
      } catch (error) {
        showToastError('GENERIC_ERROR');
      } finally {
        store.commit('general/hideTheSpinner');
      }
    });

    const formatNumber = (number: string) => formatNumbersInString(number.toString());

    onUnmounted(() => {
      if (intervalCheckShopifyPaymentState.value) {
        clearInterval(intervalCheckShopifyPaymentState.value);
      }
    });

    return {
      t,
      elementsToBuy,
      pagesViewed,
      offerV3,
      shopPlan,
      shopPlanOffer,
      shopPlanDateLimit,
      shopPlanFrequency,
      shopPlanPrice,
      adyenTokens,
      adyenTokensLoading,
      addPaymentMethodModal,
      shopSmsCredits,
      isCurrentPlanTrial,
      actualShopOffer,
      shopPlanSmsPrice,
      isFirstTrial,
      smsPricing,
      shopifyPaymentState,
      shopPlanDiscount,
      shopPlanDiscountLimit,
      shopPlanPriceDiscount,
      shopPlanPriceDiscountLimit,
      unsubscribe,
      cancelUnsubscription,
      updatePlan,
      openGleap,
      getPaymentMethodLogo,
      addPaymentMethod,
      setDefaultPaymentMethod,
      deletePaymentMethod,
      toggleSmsCreditsPackRenewal,
      handleCancelTrial,
      updateOldPlan,
      redirectToLink,
      formatNumber,
    };
  },

  methods: { findPermission },
});
</script>

<style lang="scss" scoped>
@media all and (max-width: 1199px) {
  .flex-plan {
    display: block !important;
  }
}

.flex-plan {
  margin-top: 5px;
}

.p-card {
  box-shadow: none !important;
  border-radius: 5px;

  & .p-card-title {
    padding: 10px 15px;
  }

  &.p-card-border {
    border: solid 1px $heather;
  }

  &.contactUs {
    background: #f8f9fa;
  }

  &.currentPlan {
    box-shadow: none !important;
    border: solid 1px $brand-color-primary;
    background: #f8ffee !important;
    position: relative;

    & .chosenPlan {
      position: absolute;
      top: 0;
      right: 0;
      background: $brand-color-primary;
      color: $white;
      transform: translate(50%, -50%);
      border-radius: 50%;
      padding: 3px;
      width: 22px;
      height: 22px;
      text-align: center;
    }

    & .p-card-title {
      & .currentPlanPrice {
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);

        & .currentPlanPriceCurrency {
          font-size: 1rem;
        }
      }
    }

    & .currentPlanDateLimit {
      padding-top: 3px;
      font-size: 0.9rem;
    }

    & .nextPlan {
      color: red;
    }
  }

  & .offersQuestions {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 2.5rem;
    background: #495057;
    color: $white;
    width: 50px;
    height: 50px;
    text-align: center;
    border-radius: 50%;
    padding: 5px;
  }

  &.paymentMethods {
    & .newPaymentMethod {
      cursor: pointer;
      flex-direction: column;

      & .paymentMethodContainer {
        background: #f9f9f9;
        border: solid 1px #f2f2f2;
        border-radius: 5px;
        padding: 15px;
        width: 100%;
        height: 200px;
        position: relative;

        &:hover {
          background: #f2f2f2;
        }

        & > i {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 2.5rem;
          background: #eaeaea;
          width: 50px;
          height: 50px;
          border-radius: 50%;
          line-height: 1em !important;
          text-align: center;
          padding: 5px;
        }
      }
    }

    & .paymentMethod {
      flex-direction: column;

      & .paymentMethodContainer {
        height: 200px;
        width: 100%;
        border: solid 1px #d9d9d9;
        padding: 15px;
        border-radius: 5px;
        position: relative;

        & > .cardType {
          display: block;
          width: 100%;
        }

        & > .cardNumber {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
          width: 100%;
          text-align: center;

          & > .p-image {
            margin-right: 15px;

            &:deep() > img {
              width: 30px;
            }
          }
        }

        & > .buttons {
          position: absolute;
          bottom: 0px;
          width: 100%;
          left: 0;
          padding: 10px 15px;
          text-align: right;

          & > .p-button {
            margin-left: 5px;
          }
        }
      }

      &.currentPaymentMethod {
        & .paymentMethodContainer {
          box-shadow: none !important;
          border: solid 1px $brand-color-primary;
          background: #f8ffee !important;
          position: relative;

          & .defaultPaymentMethod {
            position: absolute;
            top: 0;
            right: 0;
            background: $brand-color-primary;
            color: $white;
            transform: translate(50%, -50%);
            border-radius: 50%;
            padding: 3px;
            width: 22px;
            height: 22px;
            text-align: center;
          }

          & .buttonDefault {
            display: none;
          }
        }
      }
    }
  }

  &:deep() .CountriesList {
    padding-left: 0px;
    padding-right: 0px;

    & .field {
      margin-bottom: 0px;
    }
  }

  & .buyableElementOptions {
    & .p-dropdown {
      height: 40px;
    }
  }

  & .buyableElementPrice {
    padding: 25px 10px 0;
    font-size: 1.2rem;

    & .buyableElementPriceTotal {
      color: $brand-color-primary;
      font-size: 1.5rem;
    }
  }

  & .buyableElementButton {
    padding: 25px 10px 0;
  }
}

.buyableElementDropdownLabel {
  font-size: 1.1rem;
}

.buyableElementDropdownLegend {
  padding-top: 2px;
  font-size: 0.85rem;
  color: gray;
}

.p-message {
  margin: 0;

  &:deep() .p-message-wrapper {
    padding: 10px 5px;
  }
}
</style>

<style lang="scss">
.confirm-popup-button:enabled:focus {
  border: none !important;
}
.current-plan-container {
  .p-component-overlay-enter {
    background-color: white !important;
    opacity: 0.9;
  }
  .blocking-element {
    opacity: 1 !important;
    z-index: 1104 !important;
  }

  .p-blockui-container {
    height: 100% !important;
  }
}
</style>
