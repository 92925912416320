<template>
  <div class="field-wrapper">
    <MultiSelect
      :model-value="selectedValues"
      :options="listObjects"
      :show-toggle-all="false"
      :filter="true"
      style="height: 2.5rem;"
      :option-label="optionLabel"
      @update:model-value="handleSelected"
    >
      <template #value="slotProps">
        <div v-if="slotProps.value.length < 3">
          <span class="segment-multi-dropdown__preview">{{ slotProps.value.map((item) => item[optionLabel]).join(', ') }}</span>
        </div>
        <div v-else-if="slotProps.value.length > 2">
          <span>{{ slotProps.value.length }} {{ t('itemsSelected') }} </span>
        </div>
      </template>
      <template #option="slotProps">
        <div class="p-multiselect-option">
          <span>{{ slotProps.option[optionLabel] }}</span>
        </div>
      </template>
      <template #footer>
        <div class="py-2 px-3">
          <b>{{ selectedValues ? selectedValues.length : 0 }}</b> item{{ (selectedValues ? selectedValues.length : 0) > 1 ? 's' : '' }} selected.
        </div>
      </template>
    </MultiSelect>
  </div>
</template>

<script lang="ts">
import {
  defineComponent, onMounted, PropType, ref, SetupContext,
} from 'vue';
import MultiSelect from 'primevue/multiselect';
import { DropdownOption, StringMap } from '@/types';
import { asInt } from '@/helpers';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'SegmentMultiDropdown',

  components: {
    MultiSelect,
  },

  props: {
    modelValue: {
      type: Object as PropType<StringMap>,
      required: true,
    },

    options: {
      type: Function,
      required: true,
    },

    optionValue: {
      type: String,
      required: false,
      default: 'value',
    },

    optionLabel: {
      type: String,
      required: false,
      default: 'label',
    },
  },

  emits: ['update:modelValue'],

  setup(props: { modelValue: StringMap; options: Function; optionValue: string; optionLabel: string }, { emit }: SetupContext) {
    const { t } = useI18n();
    const selectedValues = ref<any>([]);
    const listObjects = ref<any>([]);

    const handleSelected = (value: any) => {
      selectedValues.value = value;
      emit('update:modelValue', selectedValues.value.reduce((acc: StringMap, current: any) => ({ ...acc, [current[props.optionValue]]: current[props.optionLabel] }), {}));
    };

    onMounted(async () => {
      listObjects.value = await props.options();

      if (listObjects.value) {
        listObjects.value = listObjects.value.map((item: any) => ({
          ...item,
          [props.optionLabel]: t(item[props.optionLabel]),
        }));
      }
      if (props.modelValue && listObjects.value) {
        selectedValues.value = Object.entries(props.modelValue)
          .map((item) => ({
            [props.optionValue]: asInt(item[0]),
            [props.optionLabel]: listObjects.value.filter((option: any) => asInt(option[props.optionValue]) === asInt(item[0]))[0][props.optionLabel],
          }));
      }
    });

    return {
      t,
      selectedValues,
      handleSelected,
      listObjects,
    };
  },
});
</script>

<style lang="scss" scoped>
.p-multiselect {
  -moz-appearance:none; /* Firefox */
  -webkit-appearance:none; /* Safari and Chrome */
  appearance:none;
  border: 1px solid $heather;
  border-radius: $field-border-radius;
  width: 100%;
  min-height: 37px;
  font-size: $field-font-size;
  line-height: $field-line-height;
  padding: $field-padding $field-icon-up-down-width $field-padding $field-padding;
  cursor: pointer;

  &:hover {
    border-color: $brand-color-primary;
  }

  &:focus,
  &:focus-visible {
    box-shadow: $box-shadow;
    outline: 0 none;
  }
}
.segment-multi-dropdown {
  &__preview {
    display: inline-block;
    width: 15rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
