<template>
  <Dialog
    v-model:visible="displayDialog"
    :header="name + translation('myLists.importContacts.header')"
    :modal="true"
    :draggable="false"
    :closable="true"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    :style="{ width: '50vw' }"
    @hide="closeModal"
  >
    <template #header>
      <h3>{{ translation('templateSelector.dialogTypeTemplate.header') }}</h3>
    </template>

    <div class="grid justify-content-center">
      <div class="col-4">
        <Button
          type="button"
          class="p-button-outlined p-button-success button-dialog-type"
          @click="showImportCustomers(idList, name, 'file', type)"
        >
          <div class="legend">
            {{ translation('myLists.importContacts.files.option') }}
          </div>
        </Button>
      </div>
      <div class="col-4">
        <Button
          type="button"
          class="p-button-outlined p-button-success button-dialog-type"
          @click="showImportCustomers(idList, name, 'copyPaste', type)"
        >
          <div class="legend">
            {{ translation('myLists.importContacts.copyPaste.option') }}
          </div>
        </Button>
      </div>
      <div
        v-if="showSegmentation"
        class="col-4"
      >
        <Button
          type="button"
          class="p-button-outlined p-button-success button-dialog-type"
          @click="showImportCustomers(idList, name, 'segmentation', type)"
          :disabled="false"
        >
          <div class="legend">
            {{ translation('myLists.importContacts.segmentation.option') }}
          </div>
        </Button>
      </div>
    </div>
  </Dialog>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from 'vue';
import { store } from '@/store';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import { ListType } from '@/types';

export default defineComponent({
  name: 'ImportListCustomers',

  components: {
    Dialog,
    Button,
  },

  props: {
    idList: {
      type: Number,
      required: true,
    },

    name: {
      type: String,
      required: true,
    },

    translation: {
      type: Function,
      required: true,
    },

    type: {
      type: String,
      required: true,
    }
  },

  emits: ['close-modal'],

  setup(props, { emit }) {
    const displayDialog = ref(true);

    const showSegmentation = computed(() => props.type === ListType.SEGMENT || props.type === ListType.NOT_DEFINED);

    const showImportCustomers = async (idShopList: number, name: string, typeImport: string, listType: string) => {
      await store.dispatch('listImportEditor/importCustomersAction', {
        idShopList,
        typeImport,
        listName: name,
        listType
      });
      displayDialog.value = false;
    };

    const closeModal = () => {
      displayDialog.value = false;
      emit('close-modal');
    };

    return {
      showImportCustomers,
      displayDialog,
      closeModal,
      showSegmentation,
    };
  },
});
</script>

<style scoped lang="scss">
.button-dialog-type {
  height: 6rem;
  width: 100%;
  text-align: center !important;
  justify-content: center;
}
</style>
