
import {
  computed,
  defineComponent, onBeforeMount,
  PropType,
  ref, SetupContext, toRaw, watch,
} from 'vue';
import {
  getShopDefaultLang,
  Lang,
  UserState,
  getUserLang,
} from '@/composables/User';
import Dropdown from 'primevue/dropdown';
import { DropdownOption } from '@/types';
import { MultiLangInput } from '@/types/store-parameters-types';
import BaseInputText from '@/components/fields/BaseInputText.vue';
import AutoComplete from 'primevue/autocomplete';
import deepcopy from 'deepcopy';
import { Variable } from '../template-builder/utils/variables-list';

export default defineComponent({
  name: 'LocalizedTextInput',

  components: {
    Dropdown,
    BaseInputText,
    AutoComplete,
  },

  props: {
    id: {
      type: String,
      required: true,
    },

    type: {
      type: String,
      required: false,
      default: 'text',
    },

    modelValue: {
      type: Object as PropType<MultiLangInput>,
      required: false,
      default: () => ({}),
    },

    displayEmojis: {
      type: Boolean,
      required: false,
      default: false,
    },

    displayTranslate: {
      type: Boolean,
      required: false,
      default: false,
    },

    displayVariables: {
      type: Boolean,
      required: false,
      default: false,
    },

    displayAiButton: {
      type: Boolean,
      required: false,
      default: false,
    },

    aiParams: {
      type: Object,
      required: false,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: {},
    },

    variablesList: {
      type: Array as PropType<Variable[]>,
      required: false,
      default: () => [],
    },

    placeholder: {
      type: String,
      required: false,
      default: '',
    },

    complete: {
      type: Function,
      required: false,
      default: () => null,
    },

    defaultLanguages: {
      type: Array as PropType<string[]>,
      required: false,
      default: () => [],
    },

    continueReloading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  emits: ['update:modelValue'],

  setup(props: {
    id: string;
    type: string;
    modelValue: MultiLangInput;
    displayEmojis: boolean;
    displayTranslate: boolean;
    displayVariables: boolean;
    displayAiButton: boolean;
    aiParams: object;
    variablesList: DropdownOption[];
    placeholder: string;
    complete: Function;
    defaultLanguages: string[];
    continueReloading: boolean;
  }, { emit }: SetupContext) {
    const values = ref(toRaw(props.modelValue));
    const defaultLanguage = ref(getShopDefaultLang());

    const languages = computed<string[]>(() => {
      if (props.defaultLanguages.length) {
        return props.defaultLanguages;
      }
      const tempLanguages = UserState.activeShop?.langs.map((language: Lang) => language.id) ?? [];
      // put the default language first
      tempLanguages.sort((a, b) => {
        if (a === defaultLanguage.value) {
          return -1;
        }
        if (b === defaultLanguage.value) {
          return 1;
        }
        return 0;
      });

      return tempLanguages;
    });

    const selectedLanguage = ref();
    selectedLanguage.value = deepcopy(languages.value.includes(defaultLanguage.value) ? defaultLanguage.value : 'fr');
    const inputModel = computed({
      get() {
        return values.value[selectedLanguage.value];
      },
      set(newValue) {
        if (newValue && typeof newValue === 'object' && Object.keys(newValue).length > 0) {
          // In case we update all values at once (from builder to campaign)
          Object.entries(newValue).forEach(([key, value]) => {
            values.value[key] = value;
          });
        } else {
          if (selectedLanguage.value === defaultLanguage.value) {
            const oldValue = values.value[selectedLanguage.value] ? values.value[selectedLanguage.value] : '';
            languages.value.filter((lang) => lang !== defaultLanguage.value).forEach((lang) => {
              if (oldValue.startsWith(values.value[lang]) || values.value[lang] === '' || values.value[lang] === null || values.value[lang] === false) {
                values.value[lang] = newValue;
              }
            });
          }

          values.value[selectedLanguage.value] = newValue;
        }
      },
    });

    const refreshBaseInputTextKey = ref(0);
    const filteredItems = ref([]);

    watch([inputModel], () => {
      emit('update:modelValue', values.value);
    });

    watch(() => props.modelValue, (newValue) => {
      inputModel.value = newValue;
    });

    watch(languages, () => {
      defaultLanguage.value = getUserLang();
    });

    const handleComplete = async ({ query }: { query: string }) => {
      // Load items for the first time the filteredItems is blank or if props continueReloading is set to true
      filteredItems.value = (await props.complete(query));
    };

    onBeforeMount(() => {
      // put the default language first
      languages.value.sort((a, b) => {
        if (a === defaultLanguage.value) {
          return -1;
        }
        if (b === defaultLanguage.value) {
          return 1;
        }
        return 0;
      });

      // if one of the lang is missing, we add it with defaultLanguage value
      languages.value.forEach((lang) => {
        if (values.value && !values.value[lang]) {
          values.value[lang] = values.value[defaultLanguage.value] ?? '';
        }
      });

      // if lang exist in values.value but not in languages, we delete it
      Object.keys(values.value).forEach((lang) => {
        if (!languages.value.includes(lang)) {
          delete values.value[lang];
        }
      });

      refreshBaseInputTextKey.value += 1;
    });

    return {
      languages,
      selectedLanguage,
      inputModel,
      refreshBaseInputTextKey,
      filteredItems,
      handleComplete,
      defaultLanguage,
    };
  },

});
