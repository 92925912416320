/**
 * Format a number as a price with the currency in parameters
 * @param number
 * @param currency
 */
// eslint-disable-next-line import/prefer-default-export
export const formatNumberToCurrency = (number: number, currency: string, maximumFractionDigits?: number): string => {
  const options: Intl.NumberFormatOptions = {
    style: 'currency',
    currency,
  };

  if (maximumFractionDigits !== undefined) {
    options.maximumFractionDigits = maximumFractionDigits;
  }

  const formatNumber = new Intl.NumberFormat(undefined, options);

  return formatNumber.format(number);
};

/**
 * Format all numbers in a string according to user's locale
 * @param string
 */
export const formatNumbersInString = (string: string): string => {
  const { locale } = Intl.NumberFormat().resolvedOptions();

  return string.replace(/\b\d{1,3}(?:[\s,]?\d{3})*(?:[.,]\d+)?\b/g, (match) => {
    if (/^0[0-9]+/.test(match)) {
      return match; // Return the number if it starts with leading zeros
    }

    const cleanedNumber = match.replace(/\s/g, '');

    let number;
    if (cleanedNumber.includes(',') && cleanedNumber.includes('.')) {
      number = locale.startsWith('en')
        ? parseFloat(cleanedNumber.replace(/,/g, ''))
        : parseFloat(cleanedNumber.replace(/\./g, '').replace(',', '.'));
    } else if (cleanedNumber.includes(',')) {
      number = parseFloat(cleanedNumber.replace(',', '.'));
    } else {
      number = parseFloat(cleanedNumber);
    }
    return new Intl.NumberFormat(locale).format(number);
  });
};

export const getCurrencySymbol = (currencyCode: string, locale: string): string => {
  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencyCode,
    currencyDisplay: 'symbol',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  });

  const formatted = formatter.format(0);
  const symbol = formatted.replace(/[0-9\s,.]/g, '');

  return symbol;
}
