<template>
  <div
    v-if="columnData.widgets === null || columnData.widgets.length === 0"
    class="stats-builder-col"
    :class="{ 'enabledCol': (selectedWidgetState !== null) && (rowId === selectedWidgetState.location.rowId && columnData.id === selectedWidgetState.location.columnId
                && (selectedWidgetState.location.panelData === undefined || selectedWidgetState.location.panelData === null)),
              'emptyCol': columnData.widgets === null || columnData.widgets.length === 0,
              'col-12': columnData.layout === StatsBuilderColumnLayoutEnum.TWELVE,
              'col-9': columnData.layout === StatsBuilderColumnLayoutEnum.NINE,
              'col-6': columnData.layout === StatsBuilderColumnLayoutEnum.SIX,
              'col-4': columnData.layout === StatsBuilderColumnLayoutEnum.FOUR,
              'col-3': columnData.layout === StatsBuilderColumnLayoutEnum.THREE,
              'align-items-center': !rowHasColumnWithMultipleWidgets,
              'align-items-start': rowHasColumnWithMultipleWidgets }"
    style="position: relative;"
  >
    <div
      class="flex justify-content-center sb-col-content"
    >
      <draggable
        v-if="columnData.widgets === null || columnData.widgets.length === 0"
        class="emptyColumnSlot"
        style="width: 100%;"
        :list="[{ id: 1 }]"
        :data-column-layout="columnData.layout"
        :data-location="JSON.stringify({ rowId, columnId: columnData.id, panelData: null })"
        v-bind="dragOnEmptyColumnOptions"
      >
        <template #item="{ element }">
          <div
            class="flex align-items-center justify-content-center"
            style="height: 100%; min-height: 2rem;"
            :data-id-test="element.id"
          >
            <i
              class="fa-regular fa-arrow-down-to-line fa-lg"
              style="color: #d0d0d0;"
            />
          </div>
        </template>
      </draggable>
    </div>
  </div>
  <div
    v-else
    class="stats-builder-col"
    :class="{ 'enabledCol': (selectedWidgetState !== null) && (rowId === selectedWidgetState.location.rowId && columnData.id === selectedWidgetState.location.columnId
                && (selectedWidgetState.location.panelData === undefined || selectedWidgetState.location.panelData === null)),
              'emptyCol': columnData.widgets === null,
              'col-12': columnData.layout === StatsBuilderColumnLayoutEnum.TWELVE,
              'col-9': columnData.layout === StatsBuilderColumnLayoutEnum.NINE,
              'col-6': columnData.layout === StatsBuilderColumnLayoutEnum.SIX,
              'col-4': columnData.layout === StatsBuilderColumnLayoutEnum.FOUR,
              'col-3': columnData.layout === StatsBuilderColumnLayoutEnum.THREE,
              'align-items-center': !rowHasColumnWithMultipleWidgets,
              'align-items-start': rowHasColumnWithMultipleWidgets }"
    style="position: relative;"
  >
    <draggable
      :key="JSON.stringify(dragElementsOptions)"
      :list="columnData.widgets"
      handle=".handle-widget"
      class="w-full sb-col-content flex flex-column gap-1"
      :data-from-widget="true"
      :data-location="JSON.stringify({ rowId: rowId, columnId: columnData.id, columnLayout: columnData.layout })"
      :data-has-menu="hasMenu"
      v-bind="dragElementsOptions"
    >
      <template #item="{ element, index }">
        <StatsBuilderColItem
          :element="element"
          :index="index"
          :column-data="columnData"
          :row-id="rowId"
          @edit-widget="editWidget"
          @remove-widget="removeWidget"
        />
      </template>
    </draggable>
  </div>
  <ConfirmDialog :group="`removeWidget_${rowId}_${columnData.id}`" />
</template>

<script lang="ts">
import {
  computed, defineComponent,
  Ref,
  ref, SetupContext,
} from 'vue';
import { StatsBuilderColumnLayoutEnum, StatsEditorPanelEnum, StatsWidgets } from '@/types';
import StatsWidgetMetadata, { getComponentStatsWidgetMetadata } from '@/components/stats-builder/metadata';
import { StatsBuilderSelectedWidget } from '@/types/stats-editor-types';
import { useStore } from '@/store';
import Tooltip from 'primevue/tooltip';
import { useI18n } from 'vue-i18n';
import draggable from 'vuedraggable';
import ConfirmDialog from 'primevue/confirmdialog';
import { useConfirm } from 'primevue/useconfirm';
import StatsBuilderColItem from '@/components/stats-builder/StatsBuilderColItem.vue';
import { removePlaceholderClass} from '@/helpers';

export default defineComponent({
  name: 'StatsBuilderCol',

  components: {
    draggable,
    ConfirmDialog,
    StatsBuilderColItem,
  },

  directives: {
    tooltip: Tooltip,
  },

  props: {
    rowId: {
      type: String,
      required: true,
    },

    columnData: {
      type: Object,
      required: true,
    },

    rowHasColumnWithMultipleWidgets: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['hover:col'],

  setup(props: { rowId: string; columnData: any}, { emit }: SetupContext) {
    const { t } = useI18n();
    const store = useStore();
    const confirm = useConfirm();

    const selectedWidgetState: Ref<StatsBuilderSelectedWidget|null> = computed(() => store.getters['statsEditor/getSelectedWidget']);

    const pageTypeState = computed(() => store.getters['statsEditor/getPageType']);

    const availableElements: any = computed(() => Object.values(StatsWidgetMetadata)
      .filter((item: any) => item.StatsWidgetMeta.availability.includes(pageTypeState.value))
      .map((item: any) => ({
        type: 'placeholder',
        widgetType: item.StatsWidgetMeta.component,
        iconClass: item.StatsWidgetMeta.icon,
        label: item.StatsWidgetMeta.label,
        group: 'widget',
        minColumnsRequired: item.StatsWidgetMeta.min_columns_required,
      })));

    const dragOnEmptyColumnOptions = ref({
      animation: 200,
      sort: true,
      group: { name: 'empty_column', pull: false, put: ['widget_element', 'statsWidget'] },
      disabled: false,
      onAdd: (evt: any) => {
        evt.preventDefault();
        if (evt.from.getAttribute('data-from-widget')) {
          const dataWidget = JSON.parse(evt.clone.getAttribute('data-widget'));
          const dataWidgetLocation = JSON.parse(evt.clone.getAttribute('data-widget-location'));
          const dataLocation = JSON.parse(evt.to.getAttribute('data-location'));
          const dataColumnLayout = JSON.parse(evt.to.getAttribute('data-column-layout'));

          const widgetType = availableElements.value.find((item: any) => item.widgetType === dataWidget.type);
          if (parseInt(widgetType.minColumnsRequired, 10) > dataColumnLayout) {
            store.dispatch('statsEditor/undoMoveWidget', {
              dataWidget,
              dataWidgetLocation,
            });
          } else {
            store.dispatch('statsEditor/addWidgetToEmptyCol', {
              dataWidget,
              dataLocation,
            });
          }
        } else if (evt.from.getAttribute('data-from-subwidget')) {
          const dataWidget = JSON.parse(evt.clone.getAttribute('data-widget'));
          const dataLocation = JSON.parse(evt.to.getAttribute('data-location'));
          const dataLastLocation = JSON.parse(evt.clone.getAttribute('data-location'));

          store.dispatch('statsEditor/addWidgetToEmptyCol', {
            dataWidget,
            dataLocation,
            dataLastLocation,
          });
        }
      },
    });

    const editWidget = (index: number) => {
      const meta = getComponentStatsWidgetMetadata(props.columnData?.widgets[index]?.type);
      if (!meta) {
        return;
      }

      const selectedWidget: StatsBuilderSelectedWidget = {
        component: props.columnData?.widgets[index]?.type,
        data: JSON.parse(JSON.stringify(props.columnData?.widgets[index])),
        location: {
          rowId: props.rowId,
          columnId: props.columnData.id,
          panelData: null,
          columnLayout: props.columnData.layout,
          widgetIndex: index,
        },
      };

      store.commit('statsEditor/setSelectedWidget', selectedWidget);
      store.commit('statsEditor/showLeftToolbar', StatsEditorPanelEnum.WIDGET_EDIT_PANEL);
    };

    const removeWidget = (index: number) => {
      confirm.require({
        message: t('statsEditor.confirmationMessages.removeWidget'),
        header: 'Confirmation',
        group: `removeWidget_${props.rowId}_${props.columnData.id}`,
        icon: 'far fa-exclamation-triangle',
        acceptLabel: t('yes'),
        rejectLabel: t('no'),
        accept: () => {
          store.dispatch('statsEditor/removeWidget', {
            location: {
              rowId: props.rowId,
              columnId: props.columnData.id,
              widgetIndex: index,
            },
          });
        },
      });
    };

    // Prevent dropping widget into col with menu widgets
    const hasMenu = computed(() => props.columnData.widgets.some((widget: any) => [StatsWidgets.MENU, StatsWidgets.TABS].includes(widget.type)));

    const dragElementsOptions = computed(() => ({
      animation: 200,
      sort: true,
      group: { name: 'statsWidget', pull: true, put: true },
      disabled: false,
      onAdd: (evt: any) => {
        if (evt.from.getAttribute('data-from-subwidget')) {
          const dataLastLocation = JSON.parse(evt.clone.getAttribute('data-location'));
          store.dispatch('statsEditor/removeWidgetFromSubCol', {
            dataLastLocation,
          });
        }
        const dataWidget = JSON.parse(evt.clone.getAttribute('data-widget'));
        const dataWidgetLocation = JSON.parse(evt.clone.getAttribute('data-widget-location')) || JSON.parse(evt.clone.getAttribute('data-location'));
        let location = evt.to.getAttribute('data-location');

        if (location && dataWidget) {
          location = JSON.parse(location);
          const widgetType = availableElements.value.find((item: any) => item.widgetType === dataWidget.type);
          const destinationHasMenu = evt.to.getAttribute('data-has-menu');

          if (parseInt(widgetType.minColumnsRequired, 10) > location.columnLayout || destinationHasMenu === 'true') {
            store.dispatch('statsEditor/undoMoveWidget', {
              dataWidget,
              dataWidgetLocation,
            });
            store.dispatch('statsEditor/removeWidget', {
              location: {
                rowId: location.rowId,
                columnId: location.columnId,
                widgetIndex: evt.newIndex,
              },
            });
          }
        }
      },
      onMove: (evt: any) => {
        // On move function for widgets inside column and sub column
        evt.preventDefault();
        if (evt.dragged.getAttribute('data-widget-location')) {
          const dataLocation = JSON.parse(evt.dragged.getAttribute('data-widget-location'));
          const dataWidget = JSON.parse(evt.dragged.getAttribute('data-widget'));

          const choosedWidgetForDrag: Record<string, any> = {
            rowId: dataLocation.rowId,
            columnId: dataLocation.columnId,
            index: dataLocation?.widgetIndex,
          };
          if (dataLocation.panelData) {
            choosedWidgetForDrag.index = dataLocation.panelData.widgetIndex;
            choosedWidgetForDrag.panelData = {
              panelId: dataLocation.panelData.panelId,
              subRowId: dataLocation.panelData.subRowId,
              subColumnId: dataLocation.panelData.subColumnId,
            };
          }

          store.commit('statsEditor/setChoosedWidgetForDrag', choosedWidgetForDrag);

          removePlaceholderClass(evt.dragged);

          if (evt.from !== evt.to) {
            const columnDestinationLayout = evt.to.getAttribute('data-column-layout');
            const destination = evt.to.getAttribute('data-from-widget') || evt.to.getAttribute('data-from-subwidget');

            const widgetType = availableElements.value.find((item: any) => item.widgetType === dataWidget.type);

            // Check if destination is empty column
            if (columnDestinationLayout) {
              const isOnPanel = evt.to.getAttribute('data-is-on-panel');

              if ((parseInt(widgetType.minColumnsRequired, 10) > parseInt(columnDestinationLayout, 10))
                || (isOnPanel && [StatsWidgets.MENU, StatsWidgets.TABS].includes(dataWidget.type))) {
                evt.to.classList.add('notCompatibleSlot');
              } else {
                evt.to.classList.add('chosenSlot');
              }
            } else if (destination) {
              // Check if destination is inside column with widgets
              const location = evt.to.getAttribute('data-location');
              const dataHasMenu = evt.to.getAttribute('data-has-menu');

              if (location) {
                const locationObject = JSON.parse(location);
                if ((parseInt(widgetType.minColumnsRequired, 10) > parseInt(locationObject.columnLayout, 10))
                  || ([StatsWidgets.MENU, StatsWidgets.TABS].includes(dataWidget.type) && locationObject.panelData) || dataHasMenu === 'true') {
                  evt.dragged.classList.add('placeholder-on-add-widget', 'placeholder-on-add-widget--disallowed', 'm-4');
                } else {
                  evt.dragged.classList.add('placeholder-on-add-widget', 'placeholder-on-add-widget--allowed', 'm-4');
                }
              }
            }
          } else {
            evt.dragged.classList.add('placeholder-on-add-widget', 'placeholder-on-add-widget--allowed', 'm-4');
          }
        }
        return true;
      },
      onUnchoose: (evt: any) => {
        removePlaceholderClass(evt.item);

        store.commit('statsEditor/resetChoosedWidgetForDrag');
      },
    }));

    return {
      dragOnEmptyColumnOptions,
      selectedWidgetState,
      StatsBuilderColumnLayoutEnum,
      dragElementsOptions,
      hasMenu,

      editWidget,
      removeWidget,
    };
  },
});
</script>

<style lang="scss">
.stats-builder-col {
  position: relative;
  display: flex;
  padding-top: 0;
  padding-bottom: 0;
  justify-content: center;

  &.emptyCol {
    & > .sb-col-content {
      height: 62px;
    }
  }

  &.emptyCol {
    & > .sb-col-content {
      border: dashed 1px #747474 !important;
      background: rgba(240, 240, 240, 0.7) !important;
      position: relative;
      clear: both;
    }
  }

  &.enabledCol {
    & > .sb-col-content .emptyColumnSlot {
      background-color: rgba(148, 200, 64, 0.4);
      i {
        color: white !important;
      }
    }

    & > .sb-col-content {
      border: 2px solid #495057;
    }
  }

  .sb-col-content {
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0;

    & > div {
      width: 100%;
      height: 100%;

      & .graph-container,
      & .card-skeleton {
        border: none;
      }
    }
  }
}

.emptyColumnSlot {
  &.chosenSlot {
    background-color: rgba(148, 200, 64, 0.4);
    i {
      color: white !important;
    }
  }

  &.notCompatibleSlot {
    background-color: rgba(200, 64, 64, 0.4);
    i {
      color: white !important;
    }
  }
}
</style>
