
import {
  defineComponent, PropType, ref,
} from 'vue';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import { ShopsProducts } from '@/types/generated-types/graphql';
import Listbox from 'primevue/listbox';
import InputText from 'primevue/inputtext';
import ShopsProductsList from '@/composables/shop-products/Shop-products';
import Skeleton from 'primevue/skeleton';

export default defineComponent({
  name: 'ProductSelection',

  components: {
    Dialog,
    Button,
    Listbox,
    InputText,
    Skeleton,
  },

  props: {
    translation: {
      type: Function,
      require: true,
      default: () => ({}),
    },

    defaultLang: {
      type: String,
      required: true,
    },

    availableProductsArr: {
      type: Array as PropType<ShopsProducts[]>,
      required: true,
    },

    chosenProductsArr: {
      type: Array as PropType<ShopsProducts[]>,
      required: true,
    },

    productSearchStr: {
      type: String,
      required: true,
    },
  },

  emits: ['close-action-modal'],

  setup(props, context) {
    const dialogVisible = ref(true);
    const product = ref(props.productSearchStr);
    const productsAvailableList = ref<ShopsProducts[]>(props.availableProductsArr);
    const productsChosenList = ref<ShopsProducts[]>(props.chosenProductsArr);
    const chosenProducts = ref<ShopsProducts[]>([]);
    const availableProducts = ref<ShopsProducts[]>([]);
    let timer: any = null;

    const updateDialogVisibility = () => {
      dialogVisible.value = false;
      context.emit('close-action-modal', productsChosenList.value, productsAvailableList.value, product.value);
    };

    const handleSearch = async () => {
      clearTimeout(timer);
      timer = setTimeout(async () => {
        const result = await ShopsProductsList(product.value, props.defaultLang, 10000);
        if (result !== null) {
          availableProducts.value = [];
          productsAvailableList.value = result;
        }
      }, 1000);
    };

    const moveItemsToChosen = () => {
      productsChosenList.value = chosenProducts.value.concat(productsChosenList.value);

      productsChosenList.value.forEach((record: ShopsProducts) => {
        availableProducts.value = availableProducts.value.filter((chosenRecord: ShopsProducts) => chosenRecord.id_shop_product !== record.id_shop_product);
      });
      productsChosenList.value = productsChosenList.value.concat(availableProducts.value);

      productsChosenList.value.forEach((chosenProduct: ShopsProducts) => {
        productsAvailableList.value = productsAvailableList.value.filter((productAvailable: ShopsProducts) => productAvailable.id_shop_product !== chosenProduct.id_shop_product);
      });
      availableProducts.value = [];
      chosenProducts.value = [];
    };

    const moveItemsToAvailable = () => {
      productsAvailableList.value = productsAvailableList.value.concat(chosenProducts.value);

      chosenProducts.value.forEach((chosenProduct: ShopsProducts) => {
        productsChosenList.value = productsChosenList.value.filter((listProduct: ShopsProducts) => listProduct.id_shop_product !== chosenProduct.id_shop_product);
      });
      chosenProducts.value = [];
    };

    return {
      dialogVisible,
      updateDialogVisibility,
      product,
      handleSearch,
      productsAvailableList,
      productsChosenList,
      chosenProducts,
      availableProducts,
      moveItemsToChosen,
      moveItemsToAvailable,
    };
  },
});
