
import {
  computed,
  defineComponent,
  onBeforeMount,
  PropType,
  Ref,
  ref,
} from 'vue';
import { useI18n } from 'vue-i18n';
import Editor from 'primevue/editor';
import {
  ColorFormatEnum,
  FieldConfig,
  ParserFieldObject,
  Property,
} from '@/types';
import EmojisButton from '@/components/fields/partials/EmojisButton.vue';
import VariablesButton from '@/components/fields/partials/VariablesButton.vue';
import Quill from 'quill';
import CustomColorPicker from '@/components/fields/partials/CustomColorPicker.vue';
import OverlayPanel from 'primevue/overlaypanel';
import getLineHeightList from '@/configs/line-height';
import BaseInputNumber from '@/components/fields/partials/BaseInputNumber.vue';
import AIButton from '@/components/fields/AIButton.vue';

export default defineComponent({
  name: 'WysiwygEditor',
  components: {
    AIButton,
    BaseInputNumber,
    OverlayPanel,
    CustomColorPicker,
    VariablesButton,
    EmojisButton,
    Editor,
  },

  props: {
    configs: {
      type: Object as PropType<FieldConfig>,
      required: true,
    },

    parserValues: {
      type: Object as PropType<ParserFieldObject>,
      required: true,
    },
  },

  emits: {
    'on-change-properties': Object,
  },

  setup(props, context) {
    const { t } = useI18n();
    const properties: Ref<Array<Property>> = ref(props.parserValues.properties);
    const inputValue: Ref<string> = ref(properties.value[0].value ?? props.configs.options.defaultValue);
    const editorRef = ref();
    const editorKey = ref(0);
    const overlayPanelRef = ref();
    const overlayPanelBackgroundRef = ref();
    const overlayPanelLetterSpacingRef = ref();
    const colorFormat: Ref<string> = ref(ColorFormatEnum.HEX);
    const lineHeightList = ref([{}]);
    const lineHeightListValues = computed(() => lineHeightList.value.map((lineHeight: Record<string, string>) => lineHeight.value));
    const letterSpacingValue = ref();
    const selectedRange = ref();

    // Import quill icons
    const Icons = Quill.import('ui/icons');

    // This part is mandatory to use inline styles instead of Quill classes (problems could occur if we use classes in emails)
    // Add direction attributor
    const DirectionAttribute = Quill.import('attributors/attribute/direction');
    Quill.register(DirectionAttribute, true);

    // Add text-align attributor and create a custom icon for text-align: left;
    const AlignStyle = Quill.import('attributors/style/align');
    Icons.align.left = Icons.align['']; // set icon for 'left' option, otherwise it's replaced with 'undefined' text
    AlignStyle.whitelist = ['left', 'center', 'right', 'justify']; // whitelist available options including 'left' option
    Quill.register(AlignStyle, true);

    const BackgroundStyle = Quill.import('attributors/style/background');
    Quill.register(BackgroundStyle, true);

    const ColorStyle = Quill.import('attributors/style/color');
    Quill.register(ColorStyle, true);

    const DirectionStyle = Quill.import('attributors/style/direction');
    Quill.register(DirectionStyle, true);

    // Add font-family attributor and set accepted font-families
    const FontAttributor = Quill.import('attributors/style/font');
    FontAttributor.whitelist = [
      '',
      'arial, helvetica, sans-serif',
      'comic sans ms, cursive',
      'courier new, courier, monospace',
      'georgia, serif',
      'lucida sans unicode, lucida grande, sans-serif',
      'tahoma, geneva, sans-serif',
      'times new roman, times, serif',
      'trebuchet ms, helvetica, sans-serif',
      'verdana, geneva, sans-serif',
    ];
    Quill.register(FontAttributor, true);

    // Add font-size attributor and set accepted font-sizes
    const SizeStyle = Quill.import('attributors/style/size');
    SizeStyle.whitelist = [
      '',
      '8px', '9px', '10px', '11px', '12px',
      '13px', '14px', '15px', '16px', '18px',
      '20px', '22px', '24px', '26px', '28px',
      '30px', '32px', '34px', '36px', '38px',
      '40px',
    ];
    Quill.register(SizeStyle, true);

    // Import bold formater and force <b> tag instead of <strong>
    const Bold = Quill.import('formats/bold');
    Bold.tagName = 'B'; // Quill uses <strong> by default
    Quill.register(Bold, true);

    const parchment = Quill.import('parchment');
    const lineHeightConfig = computed(() => ({
      scope: parchment.Scope.INLINE,
      whitelist: lineHeightListValues.value,
    }));

    const lineHeightStyle = new parchment.Attributor.Style('lineheight', 'line-height', lineHeightConfig);
    Quill.register(lineHeightStyle, true);

    const letterSpacingStyle = new parchment.Attributor.Style('letterspacing', 'letter-spacing', {
      scope: parchment.Scope.INLINE,
      whitelist: null,
    });
    Quill.register(letterSpacingStyle, true);

    const handleValueChange = () => {
      inputValue.value = editorRef.value.quill.root.innerHTML;
      const updatedProperties: Array<Property> = properties.value.map((prop: Property) => {
        const updatedProperty = prop;
        updatedProperty.value = inputValue.value;
        return updatedProperty;
      });
      context.emit('on-change-properties', {
        selector: props.parserValues.selector,
        properties: updatedProperties,
      });
    };

    const insertContent = (value: string | number | undefined) => {
      editorRef.value.quill.insertText(editorRef.value.quill.selection.savedRange.index, value);
      handleValueChange();
    };

    const replaceContent = (value: string) => {
      editorRef.value.quill.clipboard.dangerouslyPasteHTML(value);
      handleValueChange();
    };

    const toggleColorPicker = (event: MouseEvent) => {
      overlayPanelRef.value.toggle(event);
    };

    const toggleColorPickerBackground = (event: MouseEvent) => {
      overlayPanelBackgroundRef.value.toggle(event);
    };

    const changeColor = (color: string) => {
      editorRef.value.quill.format('color', color);
      handleValueChange();
    };

    const changeColorBackground = (color: string) => {
      editorRef.value.quill.format('background-color', color);
      handleValueChange();
    };

    const toggleLetterSpacing = (event: MouseEvent) => {
      letterSpacingValue.value = null;

      // Store selected range (because when opening the panel, the range disappears)
      const range = editorRef.value.quill.getSelection();
      selectedRange.value = range;
      overlayPanelLetterSpacingRef.value.toggle(event);
      editorRef.value.quill.setSelection(selectedRange.value);
    };

    const changeLetterSpacing = (value: any) => {
      if (selectedRange.value) {
        editorRef.value.quill.formatText(selectedRange.value.index, selectedRange.value.length, {
          'letter-spacing': `${value}px`,
        });
      }

      handleValueChange();
    };

    onBeforeMount(async () => {
      try {
        lineHeightList.value = await getLineHeightList();
        lineHeightList.value.unshift({
          label: t('templateBuilder.fields.defaultLineHeight'),
          value: '',
        });
      } catch (error) {
        lineHeightList.value = [{}];
      }

      editorKey.value += 1;
    });

    return {
      t,
      inputValue,
      editorRef,
      editorKey,
      overlayPanelRef,
      overlayPanelBackgroundRef,
      overlayPanelLetterSpacingRef,
      colorFormat,
      lineHeightList,
      letterSpacingValue,
      changeColor,
      toggleColorPicker,
      changeColorBackground,
      toggleColorPickerBackground,
      handleValueChange,
      insertContent,
      replaceContent,
      toggleLetterSpacing,
      changeLetterSpacing,
    };
  },
});
