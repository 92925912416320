
import {
  defineComponent,
  PropType,
  Ref,
  ref,
  watch,
  computed,
  onBeforeMount,
} from 'vue';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';

import BaseDropdown from '@/components/fields/partials/BaseDropdown.vue';
import FileManager from '@/components/file-manager/FileManager.vue';
import EditImageModal from '@/components/modals/EditImageModal.vue';
import ToggleDisplaySettings from '@/components/template-builder/fields/partials/ToggleDisplaySettings.vue';
import BackgroundImageOption from '@/components/template-builder/fields/partials/BackgroundImageOption.vue';

import {
  FieldConfig,
  File,
  ParserFieldObject,
  Property,
  BackgroundImageOptionTypeEnum,
} from '@/types';
import { useI18n } from 'vue-i18n';
import { removeImage } from '../callbacks';

export default defineComponent({
  name: 'BackgroundImage',

  components: {
    FileManager,
    EditImageModal,
    Button,
    InputText,
    ToggleDisplaySettings,
    BackgroundImageOption,
    BaseDropdown,
  },

  props: {
    configs: {
      type: Object as PropType<FieldConfig>,
      required: true,
    },

    parserValues: {
      type: Object as PropType<ParserFieldObject>,
      required: true,
    },
  },

  emits: {
    'on-change-properties': Object,
  },

  setup(props, context) {
    const { t } = useI18n();
    const displayImageModal = ref(false);
    const editImageModal = ref(false);

    const displayProperties = ref(!(!Object.prototype.hasOwnProperty.call(props.configs, 'displayToggle') || props.configs.displayToggle === true));
    const displayConfigs = ref(props.configs);

    const srcProperty: Ref<Property> = ref(props.parserValues.properties && props.parserValues.properties.filter((property) => property.name === 'background-image')[0]);
    const selectedImage: Ref<string> = ref(srcProperty.value.value.match(/url\("([^"]+)"\)/) ? srcProperty.value.value.match(/url\("([^"]+)"\)/)[1] : '');
    const savedSelectedImage: Ref<string> = ref(selectedImage.value);

    const isForSection = ref(props.configs.options.type === BackgroundImageOptionTypeEnum.SELECTOR);
    const noOptions = ref(props.configs.options.type === BackgroundImageOptionTypeEnum.NO_OPTION);

    const selectedSectionOption = ref(props.configs.options.defaultValue ?? '');
    const lastSelectedSectionOption = ref(selectedSectionOption.value);
    const sectionOptions = computed(() => {
      if (props.configs.options.options) {
        return props.configs.options.options.map((option: any) => ({
          label: t(option.label),
          value: option.value,
        }));
      }
      return [];
    });

    const isRemoved = computed(() => selectedImage.value.trim() === '');

    const saveProperties = (imageValue: string) => {
      // Remove last selectedSectionOption
      if (props.configs.options.type === BackgroundImageOptionTypeEnum.SELECTOR) {
        srcProperty.value.value = 'none';
        let lastSelectedSelector = props.parserValues.selector;
        if (lastSelectedSectionOption.value === '.container') {
          lastSelectedSelector = `${lastSelectedSelector} ${selectedSectionOption.value}`;
        }
        context.emit('on-change-properties', {
          selector: lastSelectedSelector,
          properties: [srcProperty.value],
        });
      }

      // Change with new value
      srcProperty.value.value = `url("${imageValue}")`;
      if (imageValue.trim() === '') {
        srcProperty.value.value = 'none';
      }

      let selectedSelector = props.parserValues.selector;
      // Update selector only if in section
      if (selectedSectionOption.value === '.container' && props.configs.options.type === BackgroundImageOptionTypeEnum.SELECTOR) {
        selectedSelector = `${selectedSelector} ${selectedSectionOption.value}`;
      }

      context.emit('on-change-properties', {
        selector: selectedSelector,
        properties: [srcProperty.value],
      });
    };

    const handleImageChange = () => {
      savedSelectedImage.value = selectedImage.value;
      saveProperties(selectedImage.value);
    };

    const handleRemove = () => {
      selectedImage.value = '';
      removeImage(props.parserValues.selector);
      handleImageChange();
    };

    const onChooseImage = (image: File) => {
      selectedImage.value = image.url;
      handleImageChange();
    };

    const onSaveImage = (imageUrl: string) => {
      selectedImage.value = imageUrl;
      handleImageChange();
    };

    const handleOptionChange = (property: Property) => {
      context.emit('on-change-properties', {
        selector: props.parserValues.selector,
        properties: [property],
      });
    };

    watch(() => displayProperties.value, () => {
      if (!displayProperties.value) {
        savedSelectedImage.value = selectedImage.value;
        saveProperties('');
      } else {
        // setTimeout to avoids undesirable visual effects
        setTimeout(async () => {
          saveProperties(savedSelectedImage.value);
        }, 100);
      }
    }, { deep: true });

    watch(() => selectedSectionOption.value, (newValue, oldValue) => {
      if (props.configs.options.type === BackgroundImageOptionTypeEnum.SELECTOR) {
        lastSelectedSectionOption.value = oldValue;
        saveProperties(selectedImage.value);
      }
    }, { deep: true });

    onBeforeMount(() => {
      if (props.configs.options.type === BackgroundImageOptionTypeEnum.SELECTOR) {
        const backgroundProperty = props.parserValues.properties.find((property) => property.name === 'backgroundImageCustomSelector');
        if (backgroundProperty && backgroundProperty.value.includes('.container')) {
          selectedSectionOption.value = '.container';
        } else {
          selectedSectionOption.value = 'full-width';
        }
      }
    });

    return {
      t,
      displayImageModal,
      editImageModal,
      selectedImage,
      displayProperties,
      isForSection,
      selectedSectionOption,
      sectionOptions,
      displayConfigs,
      isRemoved,
      noOptions,

      onChooseImage,
      onSaveImage,
      handleImageChange,
      handleRemove,
      handleOptionChange,
    };
  },
});
