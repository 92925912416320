<template>
  <div class="flex flex-column gap-1">
    <div class="complex-property-list flex flex-column pl-4 gap-2 relative">
      <div
        v-for="(subProperty, index) in configProperty.sub_properties" :key="index"
        class="complex-property-container flex flex-column flex-wrap md:flex-row gap-2 relative"
        :class="[
          level > 1 ? 'indent': '',
          subProperty.sub_properties && subProperty.id ? 'flex-wrap' : ''
        ]"
      >
        <div class="mt-2">
          <div v-if="index===0" class="mx-2 center input-height">
            <p>{{ t('segmentation.complex.whereProperty') }}</p>
          </div>
          <div v-else class="mx-2 center input-height">
            <p>{{ t('and') }}</p>
          </div>
        </div>
        
        <div class="flex gap-2">
          <SegmentationComplexPropertyDropdown
            v-if="showEditForm(subProperty)"
            v-model:property="subProperty.id"
            :key="`${subProperty.id} - ${subProperty.segmentId}`"
            :property-config="configProperty"
            :property-definition="definitionProperty"
            :index="index"
            @reset-property-input="resetPropertyInput(index, subProperty.id, subPropertiesDefinitions[subProperty.id])"
          />
          <PropertyDeletePanel
            v-if="!subProperty.segmentId"
            :index="index"
            :title="t('segmentation.complex.simplePropertyDelete')"
            @remove-callback="removeConfigProperty(index)"
          />
        </div>
  
        <div v-if="subProperty.sub_properties && subProperty.id" style="width:100%">
          <SegmentationComplexProperty
            v-model:property-definition="subPropertiesDefinitions[subProperty.id]"
            v-model:property-config="configProperty.sub_properties[index]"
            :level="level + 1"
            @refresh-component="refreshComplexPropertyComponent"
          />
        </div>
        <div v-else-if="subProperty.id" class="flex gap-1">
          <SegmentationSimpleProperty
            :key="subProperty.segmentId"
            v-model:property-definition="subPropertiesDefinitions[subProperty.id]"
            v-model:property-config="configProperty.sub_properties[index]"
            :field-id="subProperty.id"
            :show-delete-property="(index !== 0 || subProperty.id !== '')"
            :index="index"
            @remove-config-property="() => removeConfigProperty(index)"
          />
        </div>
        <div
          v-if="index === configProperty.sub_properties.length - 1"
          class="mx-2 add-filter-container" style="width: 100%;"
        >
          <SpmButton
            icon="fa-thin fa-filter"
            class="p-button-secondary"
            :label="t('segmentation.complex.addFilter')"
            @click="addEmptyConfigSimpleProperty(true)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent, onMounted, ref, SetupContext, watch,
} from 'vue';
import Dropdown from 'primevue/dropdown';
import InputText from 'primevue/inputtext';
import SegmentationSimpleProperty from "@/components/segmentations/SegmentationSimpleProperty.vue";
import SpmButton from '@/components/spm-primevue/SpmButton.vue';
import SpmOverlayPanel from '@/components/spm-primevue/SpmOverlayPanel.vue';
import PropertyDeletePanel from '@/components/segmentations/PropertyDeletePanel.vue';
import { useI18n } from "vue-i18n";
import { store } from "@/store";
import validateSegmentProperty from '@/components/segmentations/utils/utils';
import SegmentationComplexPropertyDropdown from '@/components/segmentations/SegmentationComplexPropertyDropdown.vue';

export default defineComponent({
  name: 'SegmentationComplexProperty',

  components: {
    Dropdown,
    InputText,
    SegmentationSimpleProperty,
    SpmButton,
    SpmOverlayPanel,
    PropertyDeletePanel,
    SegmentationComplexPropertyDropdown,
  },

  props: {
    propertyConfig: {
      type: Object,
      required: true,
    },

    propertyDefinition: {
      type: Object,
      required: true,
    },

    level: {
      type: Number,
      required: true,
    }
  },

  emits: ['refreshComponent'],


  setup(props, { emit }: SetupContext) {
    const { t } = useI18n();
    const configProperty = ref(props.propertyConfig);
    const definitionProperty = ref(props.propertyDefinition);

    const subPropertiesOptions: any = ref(Object.entries(definitionProperty.value.sub_properties).map(([key, values]: [string, any]) => {
      return {
        label: t(values.label),
        value: values.id,
      };
    }));

    const subPropertiesDefinitions = computed(() => {
      return definitionProperty.value.sub_properties.reduce((acc: any, item: any) => {
        acc[item.id] = item;
        return acc;
      }, {});
    });

    const currentSegmentConfig = computed(() => store.getters['segmentationsEditor/getCurrentSegmentConfig']);

    const showEditForm = (subProperty: any) => {
      if (!subProperty.id || (currentSegmentConfig.value && currentSegmentConfig.value.configProperty.segmentId === subProperty.segmentId) || (subProperty.sub_properties)) {
        return true;
      }
      return false;
    };

    const addEmptyConfigSimpleProperty = async (verifyCurrent = false) => {
      if (!verifyCurrent) {
        configProperty.value.sub_properties.push({
          id: "",
        });
        return true;
      }
      if (currentSegmentConfig.value) {
        const { segmentId } = currentSegmentConfig.value.configProperty;
        const validation = await validateSegmentProperty(currentSegmentConfig.value.configProperty, currentSegmentConfig.value.propertyDefinition);
        if (validation && !validation.success) {
          store.commit('segmentationsEditor/pushError', { type: 'segments', value: { segmentId, value: validation.validate.value } });
          return false;
        }
        store.commit('segmentationsEditor/removeError', { type: 'segments', id: 'segmentId', value: segmentId });
        store.commit('segmentationsEditor/setRefreshContactsNumber', true);
      }
      store.commit('segmentationsEditor/setCurrentSegmentConfig', null);
      configProperty.value.sub_properties.push({
        id: "",
      });
    }

    const removeConfigProperty = (index: number) => {
      const checkCurrentSegmentConfig = (properties: any[]) => {
        if (currentSegmentConfig.value) {
          properties.forEach((property) => {
            if (property.sub_properties) {
              checkCurrentSegmentConfig(property.sub_properties);
            } else if (property.segmentId === currentSegmentConfig.value.configProperty.segmentId) {
              store.commit('segmentationsEditor/setCurrentSegmentConfig', null);
            }
          });
        }
      }

      if (currentSegmentConfig.value) {
        checkCurrentSegmentConfig(configProperty.value.sub_properties);
      }
      configProperty.value.sub_properties.splice(index,1);
      if(configProperty.value.sub_properties.length === 0){
        addEmptyConfigSimpleProperty();
      }
      store.commit('segmentationsEditor/setRefreshContactsNumber', true);
    };

    const refreshComplexPropertyComponent = () => {
      emit('refreshComponent');
    }

    const resetPropertyInput = (index: number, subPropertyId: any, subPropertyDef: any) => {
      if (subPropertyDef.sub_properties){
        configProperty.value.sub_properties[index] = {
          id: subPropertyId,
          sub_properties: [
            {
              id: ""
            }
          ]
        };
      } else {
        configProperty.value.sub_properties[index] = {
          id: subPropertyId
        };
      }

      if (subPropertyDef && !subPropertyDef.sub_properties) {
        store.commit('segmentationsEditor/setCurrentSegmentConfig', {
          configProperty: configProperty.value.sub_properties[index],
          propertyDefinition: subPropertyDef,
        });
      }
      if (subPropertyDef.sub_properties) {
        store.commit('segmentationsEditor/setCurrentSegmentConfig', null);
      }

      refreshComplexPropertyComponent();
    }

    const dropdownRefs = ref<any[]>([]);
    const onBeforeShow = async (index: number) => {
      if (currentSegmentConfig.value) {
        if (currentSegmentConfig.value.configProperty.segmentId !== configProperty.value.sub_properties[index].segmentId) {
          const { segmentId } = currentSegmentConfig.value.configProperty;
          const validation = await validateSegmentProperty(currentSegmentConfig.value.configProperty, currentSegmentConfig.value.propertyDefinition);
          if (validation && !validation.success) {
            store.commit('segmentationsEditor/pushError', { type: 'segments', value: { segmentId, value: validation.validate.value } });
            dropdownRefs.value[index].hide();
            return false;
          }
          store.commit('segmentationsEditor/removeError', { type: 'segments', id: 'segmentId', value: segmentId });
          store.commit('segmentationsEditor/setCurrentSegmentConfig', null);
          store.commit('segmentationsEditor/setRefreshContactsNumber', true);
        }
      } else {
        store.commit('segmentationsEditor/setCurrentSegmentConfig', null);
      }
    };

    onMounted(() => {
      // if no sub properties on config, add one
      if(configProperty.value.sub_properties.length === 0){
        addEmptyConfigSimpleProperty();
      }
    });

    return {
      t,
      configProperty,
      subPropertiesDefinitions,
      subPropertiesOptions,
      dropdownRefs,
      definitionProperty,
      addEmptyConfigSimpleProperty,
      removeConfigProperty,
      resetPropertyInput,
      refreshComplexPropertyComponent,
      onBeforeShow,
      showEditForm,
    };
  },
});
</script>

<style lang="scss">

.center {
  display: grid;
  align-items: center;
}

.input-height {
 max-height: 2.5rem;
}

.indent {
  padding-left: 2rem;
}

</style>

<style lang="scss" scoped>
.complex-property-list {
  margin-left: calc(10px - 1.5rem);
  padding-left: 0;

  .complex-property-container {
    padding-left: calc(2 * 0.6rem - 10px - 2px);
  }

  .complex-property-container:not(:last-child)::after {
    content: "";
    display: block;
    position: absolute;
    left: 0.1rem;
    height: 100%;
    border: solid #dee2e6;
    border-width: 0 0 1.5px 1.5px;
  }

  .complex-property-container::before {
    content: '';
    display: block;
    position: absolute;
    top: calc(0.8rem / -2);
    left: 0.1rem;
    width: 0.5rem;
    height: calc(1.5rem + 1px);
    border: solid #dee2e6;
    border-width: 0 0 1.5px 1.5px;
  }

  .add-filter-container::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0.1rem;
    width: 0.5rem;
    height: calc(100% - 1rem);
    border: solid #dee2e6;
    border-width: 0 0 1.5px 1.5px;
  }
  
}
</style>
