import EmailServiceParameter from '@/views/shop/tabs/ServicesParameters/services/EmailServiceParameter.vue';
import SmsServiceParameter from '@/views/shop/tabs/ServicesParameters/services/SmsServiceParameter.vue';
import FacebookServiceParameter from '@/views/shop/tabs/ServicesParameters/services/FacebookServiceParameter.vue';
import PushNotificationServiceParameter from '@/views/shop/tabs/ServicesParameters/services/PushNotificationServiceParameter/PushNotificationServiceParameter.vue';
import SocialNetworkServiceParameter from '@/views/shop/tabs/ServicesParameters/services/SocialNetworkServiceParameter.vue';
import ImportantLinksServiceParameter from '@/views/shop/tabs/ServicesParameters/services/ImportantLinksServiceParameter.vue';
import VoucherServiceParameter from '@/views/shop/tabs/ServicesParameters/services/VoucherServiceParameter.vue';
import AIServiceParameter from '@/views/shop/tabs/ServicesParameters/services/AIServiceParameter.vue';

const ComponentsServiceParameters = {
  EmailServiceParameter,
  SmsServiceParameter,
  FacebookServiceParameter,
  PushNotificationServiceParameter,
  SocialNetworkServiceParameter,
  ImportantLinksServiceParameter,
  VoucherServiceParameter,
  AIServiceParameter,
};

export default ComponentsServiceParameters;
