import { List, ListSettings } from '@/composables/GraphQL';
import { showToastError } from '@/helpers';
import {
  OperatorType,
  ShopsCustomersGroupsList,
  ShopsLang,
  ShopsLists, ShopsOrdersStatus,
  ShopsTags,
} from '@/types/generated-types/graphql';
import { UserState } from '@/composables/User';
// eslint-disable-next-line import/no-cycle
import getCountries from '@/configs/countries';

const fetchList = async <T>(name: string, settings: ListSettings, fields: string[]): Promise<T[]> => {
  const res = await List<T>(
    {
      name,
      settings,
      fields,
    },
  );
  if (res.err) {
    await showToastError('GENERIC_ERROR');
  } else {
    return res.items;
  }
  return [];
};

export const fetchShopsLang = async () => {
  const settings = {
    limit: 1000,
    offset: 0,
    order: [],
    filter: [
      {
        field: 'id_shop',
        operator: OperatorType.Equals,
        value: UserState.activeShop ? UserState.activeShop.id : 0,
      },
    ],
  };

  const res = await List<ShopsLang>(
    {
      name: 'ShopsLang',
      settings,
      fields: ['lang'],
    },
  );
  if (res.err) {
    await showToastError('GENERIC_ERROR');
  } else {
    return res.items.map((item) => ({
      label: item.lang,
      value: item.lang,
    }));
  }
  return [];
};

export const fetchCountries = async (t: Function) => getCountries(t);

export const fetchLists = async () => {
  const settings = {
    limit: 1000,
    offset: 0,
    order: [],
    filter: [
      {
        field: 'id_shop',
        operator: OperatorType.Equals,
        value: UserState.activeShop ? UserState.activeShop.id : 0,
      },
    ],
  };
  const res = await fetchList<ShopsLists>('ShopsLists', settings, ['id_shop_list', 'name']);

  if (res && res.length !== 0) {
    return res.map((item) => ({
      label: item.name,
      value: item.id_shop_list,
    }));
  }
  return [];
};

export const fetchGroups = async () => {
  const settings = {
    limit: 1000,
    offset: 0,
    order: [],
    filter: [{ field: 'id_shop', operator: OperatorType.Equals, value: UserState.activeShop ? UserState.activeShop.id : 0 }],
  };
  const res = await fetchList<ShopsCustomersGroupsList>('ShopsCustomersGroupsList', settings, ['id_group', 'name']);

  if (res && res.length !== 0) {
    return res.map((item) => ({
      label: item.name,
      value: item.id_group,
    }));
  }
  return [];
};

export const fetchTags = async () => {
  const settings = {
    limit: 1000,
    offset: 0,
    order: [],
    filter: [
      { field: 'id_shop', operator: OperatorType.Equals, value: UserState.activeShop ? UserState.activeShop.id : 0 },
    ],
  };
  const res = await fetchList<ShopsTags>('ShopsTags', settings, ['id_shop_tag', 'tag']);

  if (res && res.length !== 0) {
    return res.map((item) => ({
      label: item.tag,
      value: item.id_shop_tag,
    }));
  }
  return [];
};

export const fetchOrderStatus = async () => {
  const settings = {
    limit: 1000,
    offset: 0,
    order: [],
    filter: [
      {
        field: 'id_shop',
        operator: OperatorType.Equals,
        value: UserState.activeShop ? UserState.activeShop.id : 0,
      },
    ],
  };
  const res = await fetchList<ShopsOrdersStatus>('ShopsOrdersStatus', settings, ['id_status', 'name']);

  if (res && res.length !== 0) {
    return res.map((item: any) => ({
      label: item.name,
      value: item.id_status,
    }));
  }
  return [];
};
