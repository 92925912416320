import {
  WidgetTypeConfig,
  ConfigurationPanelStructureItemEnum,
  ComponentNameEnum,
  TextAlignValueEnum,
  AppearEffectEnum,
  VoucherIconListEnum,
  StepperSignEnum,
  TemplateStructureEnum,
} from '@/types';
import getHorizontalSpacesFieldConfig from '@/components/template-builder/config/fields/horizontal-spaces-config';
import getVerticalSpacesFieldConfig from '@/components/template-builder/config/fields/vertical-spaces-config';
import variables from '@/components/template-builder/utils/variables-list';
// eslint-disable-next-line import/no-cycle
import {
  recalculateLineHeightValue,
  setAnimated,
  setClass,
  setCssProperty,
  setCssPropertyAndRemoveIfZero,
  setCssPropertyWithImportant,
  setMediaDisplay, setVoucherIconClass,
} from '@/components/template-builder/setters';
// eslint-disable-next-line import/no-cycle
import {
  getClassByName,
  getContent,
  getCssPropertyByName,
} from '@/components/template-builder/getters';
// eslint-disable-next-line import/no-cycle
import {
  showButton,
  switchVoucherButtonAndCode,
} from '@/components/template-builder/callbacks';

// eslint-disable-next-line import/no-cycle
import setContent from '@/components/template-builder/setters/voucher';

import mediaDisplayFieldConfig from '@/components/template-builder/config/fields/media-display-config';
import fontFieldConfig from '@/components/template-builder/config/fields/font-config';
import getTextAlignFieldConfig from '../fields/text-align-config';
import fontSizeFieldConfig from '../fields/font-size-config';
import getColorFieldConfig from '../fields/color-config';
import textStylesFieldConfig from '../fields/text-styles-config';
import bordersFieldConfig from '../fields/borders-config';
import getTriggerButtonFieldConfig from '../fields/trigger-button-config';
import getInputVariablesFieldConfig from '../fields/input-variables-config';
import getDropdownFieldConfig from '../fields/dropdown-config';
import getWidthFieldConfig from '../fields/width-config';
import getElementAlignFieldConfig from '../fields/element-align-config';
import getBorderRadiusFieldConfig from '../fields/border-radius-config';
import getIconsFieldConfig from '../fields/icons-config';
import getStepperFieldConfig from '../fields/stepper-config';

const widgetVoucherConfig: WidgetTypeConfig = {
  tabPanel: {
    label: 'templateBuilder.widgetsHeaders.voucher',
    items: [
      {
        type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
        label: '',
        selector: 'body #spm_body #{ID}',
        isNotCollapsible: true,
        items: [
          {
            label: 'templateBuilder.fields.voucherCodeDisplay',
            selector: '{SELECTOR} .spm_generate_voucher_button',
            properties: [],
            ...getTriggerButtonFieldConfig('templateBuilder.fields.showCode', 'templateBuilder.fields.hideCode', switchVoucherButtonAndCode, showButton),
          },
        ],
      },
      {
        type: ConfigurationPanelStructureItemEnum.TAB_ITEM,
        tabItemType: TemplateStructureEnum.GROUP,
        label: 'templateBuilder.configs.widgetConfiguration',
        tabPanel: {
          items: [
            {
              type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
              label: 'templateBuilder.configs.buttonConfiguration',
              selector: 'body #spm_body #{ID}',
              items: [
                {
                  label: 'templateBuilder.fields.buttonText',
                  selector: '{SELECTOR} .spm_container_voucher_button a.spm_button',
                  properties: [{ name: 'content', getter: getContent, setters: [setContent] }],
                  ...getInputVariablesFieldConfig('Texte du bouton', variables.allVariables, false, true),
                },
                {
                  label: 'templateBuilder.fields.appearEffect',
                  selector: '{SELECTOR} .spm_container_voucher_button',
                  properties: [{ name: 'spmEffect_', getter: getClassByName, setters: [setClass, setAnimated] }],
                  ...getDropdownFieldConfig(Object.values(AppearEffectEnum).map((effect) => ({ label: effect, value: effect })), AppearEffectEnum.NONE),
                },
                {
                  label: 'templateBuilder.fields.voucherIcon',
                  selector: '{SELECTOR} .spm_container_voucher_button .spm_voucher_icon',
                  properties: [{ name: 'spm_icon-', getter: getClassByName, setters: [setVoucherIconClass] }],
                  ...getIconsFieldConfig(Object.values(VoucherIconListEnum), 'spm_icon-', 'hidden'),
                },
              ],
            },
            {
              type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
              label: 'templateBuilder.configs.codeConfiguration',
              selector: 'body #spm_body #{ID}',
              items: [
                {
                  label: 'templateBuilder.fields.appearEffect',
                  selector: '{SELECTOR} .spm_container_voucher_code',
                  properties: [{ name: 'spmEffect_', getter: getClassByName, setters: [setClass, setAnimated] }],
                  ...getDropdownFieldConfig(Object.values(AppearEffectEnum).map((effect) => ({ label: effect, value: effect })), AppearEffectEnum.NONE),
                },
                {
                  label: 'templateBuilder.fields.voucherIcon',
                  selector: '{SELECTOR} .spm_container_voucher_code .spm_voucher_icon_code',
                  properties: [{ name: 'spm_icon-', getter: getClassByName, setters: [setClass] }],
                  ...getIconsFieldConfig(Object.values(VoucherIconListEnum), 'spm_icon-', 'hidden'),
                },

              ],
            },
          ],
        },
      },
      {
        type: ConfigurationPanelStructureItemEnum.TAB_ITEM,
        tabItemType: TemplateStructureEnum.GROUP,
        label: 'templateBuilder.configs.widgetStyles',
        tabPanel: {
          items: [
            {
              type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
              label: 'templateBuilder.configs.voucherButtonStyles',
              selector: 'body #spm_body #spm_content #{ID}',
              items: [
                {
                  label: 'templateBuilder.fields.iconColor',
                  selector: '{SELECTOR} .spm_voucher_icon, {SELECTOR} .spm_voucher_icon_code',
                  properties: [{ name: 'color', getter: getCssPropertyByName, setters: [setCssPropertyWithImportant] }],
                  ...getColorFieldConfig('#333333'),
                },
                {
                  label: 'templateBuilder.fields.buttonWidth',
                  selector: '{SELECTOR} .spm_generate_voucher_button',
                  properties: [{ name: 'width', getter: getCssPropertyByName, setters: [setCssProperty] }],
                  ...getWidthFieldConfig(100),
                },
                {
                  label: 'templateBuilder.fields.buttonAlignment',
                  selector: '{SELECTOR} .spm_generate_voucher_button, {SELECTOR} .spm_container_voucher_code',
                  properties: [{ name: 'spm_div_align_', getter: getClassByName, setters: [setClass] }],
                  ...getElementAlignFieldConfig('spm_div_align_'),
                },
                {
                  label: 'templateBuilder.fields.contours',
                  selector: '{SELECTOR} a.spm_button',
                  properties: [{ name: 'border-radius', getter: getCssPropertyByName, setters: [setCssProperty] }],
                  ...getBorderRadiusFieldConfig('5px'),
                },
                {
                  label: 'templateBuilder.fields.borders',
                  selector: '{SELECTOR} .spm_generate_voucher_button a.spm_button, {SELECTOR} .spm_generate_voucher_button a.spm_button:active, '
                    + '{SELECTOR} .spm_generate_voucher_button a.spm_button:visited, {SELECTOR} .spm_generate_voucher_button a.spm_button:hover',
                  properties: [
                    { name: 'border-color', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'border-style', getter: getCssPropertyByName, setters: [] },
                    { name: 'border-width', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
                    { name: 'border-bottom-style', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'border-bottom-color', getter: getCssPropertyByName, setters: [] },
                    { name: 'border-bottom-width', getter: getCssPropertyByName, setters: [] },
                    { name: 'border-top-style', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'border-top-color', getter: getCssPropertyByName, setters: [] },
                    { name: 'border-top-width', getter: getCssPropertyByName, setters: [] },
                    { name: 'border-left-style', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'border-left-color', getter: getCssPropertyByName, setters: [] },
                    { name: 'border-left-width', getter: getCssPropertyByName, setters: [] },
                    { name: 'border-right-style', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'border-right-color', getter: getCssPropertyByName, setters: [] },
                    { name: 'border-right-width', getter: getCssPropertyByName, setters: [] },
                  ],
                  ...bordersFieldConfig,
                },
                {
                  label: 'templateBuilder.fields.buttonBackgroundColor',
                  selector: '{SELECTOR} .spm_generate_voucher_button a.spm_button, {SELECTOR} .spm_generate_voucher_button a.spm_button:active, '
                    + '{SELECTOR} .spm_generate_voucher_button a.spm_button:visited, {SELECTOR} .spm_generate_voucher_button a.spm_button:hover',
                  properties: [{
                    name: 'background',
                    getter: getCssPropertyByName,
                    setters: [setCssProperty],
                  }],
                  ...getColorFieldConfig('#ECECEC', false, true),
                },
                {
                  label: 'templateBuilder.fields.font',
                  selector: '{SELECTOR} .spm_generate_voucher_button a.spm_button, {SELECTOR} .spm_generate_voucher_button a.spm_button:active, '
                    + '{SELECTOR} .spm_generate_voucher_button a.spm_button:visited, {SELECTOR} .spm_generate_voucher_button a.spm_button:hover',
                  properties: [
                    { name: 'font-family', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'font-size', getter: getCssPropertyByName, setters: [recalculateLineHeightValue, setCssProperty] },
                    { name: 'font-weight', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'font-style', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'text-decoration-line', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'text-transform', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'color', getter: getCssPropertyByName, setters: [setCssPropertyWithImportant] },
                  ],
                  ...fontFieldConfig,
                },
                {
                  label: 'templateBuilder.fields.contentAlignment',
                  selector: '{SELECTOR} .spm_generate_voucher_button a.spm_button, {SELECTOR} .spm_generate_voucher_button a.spm_button:active, '
                    + '{SELECTOR} .spm_generate_voucher_button a.spm_button:visited, {SELECTOR} .spm_generate_voucher_button a.spm_button:hover',
                  properties: [{ name: 'text-align', getter: getCssPropertyByName, setters: [setCssProperty] }],
                  ...getTextAlignFieldConfig(TextAlignValueEnum.CENTER),
                },
                {
                  label: 'templateBuilder.fields.spaceBetweenChars',
                  selector: '{SELECTOR} .spm_generate_voucher_button a.spm_button, {SELECTOR} .spm_generate_voucher_button a.spm_button:active, '
                    + '{SELECTOR} .spm_generate_voucher_button a.spm_button:visited, {SELECTOR} .spm_generate_voucher_button a.spm_button:hover',
                  properties: [{ name: 'letter-spacing', getter: getCssPropertyByName, setters: [setCssProperty] }],
                  ...getStepperFieldConfig(StepperSignEnum.PX),
                },
                {
                  label: 'templateBuilder.fields.internalMargins',
                  selector: '{SELECTOR} .spm_generate_voucher_button a.spm_button, {SELECTOR} .spm_generate_voucher_button a.spm_button:active, '
                    + '{SELECTOR} .spm_generate_voucher_button a.spm_button:visited, {SELECTOR} .spm_generate_voucher_button a.spm_button:hover',
                  properties: [
                    { name: 'padding-top', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
                    { name: 'padding-bottom', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
                    { name: 'padding-left', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
                    { name: 'padding-right', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
                  ],
                  componentName: ComponentNameEnum.SPACES,
                  options: {
                    ...getVerticalSpacesFieldConfig('padding').options,
                    ...getHorizontalSpacesFieldConfig('padding').options,
                  },
                },
                {
                  label: 'templateBuilder.fields.mediaDisplay',
                  selector: '{SELECTOR} ',
                  properties: [{ name: 'show-for-', getter: getClassByName, setters: [setMediaDisplay] }],
                  ...mediaDisplayFieldConfig,
                },
              ],
            },
            {
              type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
              label: 'templateBuilder.configs.voucherCodeStyles',
              selector: 'body #spm_body #spm_content #{ID}',
              items: [
                {
                  label: 'templateBuilder.fields.iconColor',
                  selector: '{SELECTOR} .spm_container_voucher_code .spm_voucher_icon_code',
                  properties: [{ name: 'color', getter: getCssPropertyByName, setters: [setCssPropertyWithImportant] }],
                  ...getColorFieldConfig('#333333'),
                },
                {
                  label: 'templateBuilder.fields.borders',
                  selector: '{SELECTOR} .spm_generate_voucher_button .spm_container_voucher_code a.spm_button, '
                    + '{SELECTOR} .spm_generate_voucher_button .spm_container_voucher_code a.spm_button:active, '
                + '{SELECTOR} .spm_generate_voucher_button .spm_container_voucher_code a.spm_button:visited, '
                    + '{SELECTOR} .spm_generate_voucher_button .spm_container_voucher_code a.spm_button:hover',
                  properties: [
                    { name: 'border-color', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'border-style', getter: getCssPropertyByName, setters: [] },
                    { name: 'border-width', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
                    { name: 'border-bottom-style', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'border-bottom-color', getter: getCssPropertyByName, setters: [] },
                    { name: 'border-bottom-width', getter: getCssPropertyByName, setters: [] },
                    { name: 'border-top-style', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'border-top-color', getter: getCssPropertyByName, setters: [] },
                    { name: 'border-top-width', getter: getCssPropertyByName, setters: [] },
                    { name: 'border-left-style', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'border-left-color', getter: getCssPropertyByName, setters: [] },
                    { name: 'border-left-width', getter: getCssPropertyByName, setters: [] },
                    { name: 'border-right-style', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'border-right-color', getter: getCssPropertyByName, setters: [] },
                    { name: 'border-right-width', getter: getCssPropertyByName, setters: [] },
                  ],
                  ...bordersFieldConfig,
                },
                {
                  label: 'templateBuilder.fields.backgroundColor',
                  selector: '{SELECTOR} .spm_generate_voucher_button .spm_container_voucher_code a.spm_button, '
                    + '{SELECTOR} .spm_generate_voucher_button .spm_container_voucher_code a.spm_button:active, '
                    + '{SELECTOR} .spm_generate_voucher_button .spm_container_voucher_code a.spm_button:visited, '
                    + '{SELECTOR} .spm_generate_voucher_button .spm_container_voucher_code a.spm_button:hover',
                  properties: [{
                    name: 'background',
                    getter: getCssPropertyByName,
                    setters: [setCssProperty],
                  }],
                  ...getColorFieldConfig('#ECECEC', false, true),
                },
                {
                  label: 'templateBuilder.fields.codeColor',
                  selector: '{SELECTOR} .spm_generate_voucher_button .spm_container_voucher_code a.spm_button, '
                    + '{SELECTOR} .spm_generate_voucher_button .spm_container_voucher_code a.spm_button:active, '
                    + '{SELECTOR} .spm_generate_voucher_button .spm_container_voucher_code a.spm_button:visited, '
                    + '{SELECTOR} .spm_generate_voucher_button .spm_container_voucher_code a.spm_button:hover',
                  properties: [{ name: 'color', getter: getCssPropertyByName, setters: [setCssPropertyWithImportant] }],
                  ...getColorFieldConfig('#000000'),
                },
                {
                  label: 'templateBuilder.fields.codeFontSize',
                  selector: '{SELECTOR} .spm_generate_voucher_button .spm_container_voucher_code a.spm_button, '
                    + '{SELECTOR} .spm_generate_voucher_button .spm_container_voucher_code a.spm_button:active, '
                    + '{SELECTOR} .spm_generate_voucher_button .spm_container_voucher_code a.spm_button:visited, '
                    + '{SELECTOR} .spm_generate_voucher_button .spm_container_voucher_code a.spm_button:hover',
                  properties: [{ name: 'font-size', getter: getCssPropertyByName, setters: [recalculateLineHeightValue, setCssProperty] }],
                  ...fontSizeFieldConfig,
                  options: {
                    ...fontSizeFieldConfig.options,
                    px: {
                      ...fontSizeFieldConfig.options.px,
                      defaultValue: 18,
                    },
                  },
                },
                {
                  label: 'templateBuilder.fields.codeStyles',
                  selector: '{SELECTOR} .spm_generate_voucher_button .spm_container_voucher_code a.spm_button, '
                    + '{SELECTOR} .spm_generate_voucher_button .spm_container_voucher_code a.spm_button:active, '
                    + '{SELECTOR} .spm_generate_voucher_button .spm_container_voucher_code a.spm_button:visited, '
                    + '{SELECTOR} .spm_generate_voucher_button .spm_container_voucher_code a.spm_button:hover',
                  properties: [
                    { name: 'font-weight', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'font-style', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'text-decoration-line', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'text-transform', getter: getCssPropertyByName, setters: [setCssProperty] },
                  ],
                  ...textStylesFieldConfig,
                },
              ],
            },
          ],
        },
      },
    ],
  },
};

export default widgetVoucherConfig;
