
import {
  defineComponent, ref, SetupContext, watch,
} from 'vue';
import InputText from 'primevue/inputtext';

export default defineComponent({
  name: 'SegmentInputText',

  components: {
    InputText,
  },

  props: {
    modelValue: {
      type: String,
      required: true,
    },
  },

  emits: ['update:modelValue'],

  setup(props: { modelValue: string }, { emit }: SetupContext) {
    const value = ref(props.modelValue);

    watch(value, () => {
      emit('update:modelValue', value.value);
    });

    return {
      value,
    };
  },
});
