<template>
  <div class="textarea-variables-block">
    <label
      class="block my-3"
    >
      {{ t(configs.label) }}
    </label>
    <div class="p-fluid">
      <Editor
        ref="editorRef"
        :key="editorKey"
        v-model="inputValue"
        editor-style="height: 320px"
        @text-change="handleValueChange"
        @editor-change="handleValueChange"
      >
        <template #toolbar>
          <span class="ql-formats">
            <select class="ql-header">
              <option value="1">{{ t('templateBuilder.configs.title1', null) }}</option>
              <option value="2">{{ t('templateBuilder.configs.title2', null) }}</option>
              <option value="3">{{ t('templateBuilder.configs.title3', null) }}</option>
              <option value="4">{{ t('templateBuilder.configs.title4', null) }}</option>
              <option
                value=""
                selected
              >{{ t('templateBuilder.configs.normal', null) }}</option>
            </select>
            <select class="ql-font ql-picker">
              <option
                value=""
                selected
              >{{ t('templateBuilder.fields.defaultFontFamily', null) }}</option>
              <option value="arial, helvetica, sans-serif">Arial</option>
              <option value="comic sans ms, cursive">Comic Sans MS</option>
              <option value="courier new, courier, monospace">Courier New</option>
              <option value="georgia, serif">Georgia</option>
              <option value="lucida sans unicode, lucida grande, sans-serif">Lucida Sans Unicode</option>
              <option value="tahoma, geneva, sans-serif">Tahoma</option>
              <option value="times new roman, times, serif">Times New Roman</option>
              <option value="trebuchet ms, helvetica, sans-serif">Trebuchet MS</option>
              <option value="verdana, geneva, sans-serif">Verdana</option>
            </select>
            <select class="ql-size ql-picker">
              <option
                value=""
                selected
              >{{ t('templateBuilder.fields.defaultFontSize', null) }}</option>
              <option value="8px">8</option>
              <option value="9px">9</option>
              <option value="10px">10</option>
              <option value="11px">11</option>
              <option value="12px">12</option>
              <option value="13px">13</option>
              <option value="14px">14</option>
              <option value="15px">15</option>
              <option value="16px">16</option>
              <option value="18px">18</option>
              <option value="20px">20</option>
              <option value="22px">22</option>
              <option value="24px">24</option>
              <option value="26px">26</option>
              <option value="28px">28</option>
              <option value="30px">30</option>
              <option value="32px">32</option>
              <option value="34px">34</option>
              <option value="36px">36</option>
              <option value="38px">38</option>
              <option value="40px">40</option>
            </select>
          </span>
          <span class="ql-formats">
            <select
              class="ql-lineheight ql-picker"
            >
              <option
                v-for="(lineHeight, index) in lineHeightList"
                :key="`lineHeight_${index}`"
                :value="lineHeight.value ?? lineHeight"
              >{{ lineHeight.label ?? lineHeight }}</option>
            </select>
            <span
              class="ql-custom-button ql-letterspacing"
              @click="toggleLetterSpacing"
            >
              <span class="ql-custom-button-icon">
                <svg
                  height="1em"
                  viewBox="0 0 512 512"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M32 24C32 10.7 42.7 0 56 0S80 10.7 80 24V488c0 13.3-10.7 24-24 24s-24-10.7-24-24V24zM160 144c0-26.5 21.5-48 48-48h96c26.5
                    0 48 21.5 48 48V368c0 26.5-21.5 48-48 48H208c-26.5 0-48-21.5-48-48V144zM456 0c13.3 0 24 10.7 24 24V488c0 13.3-10.7
                    24-24 24s-24-10.7-24-24V24c0-13.3 10.7-24 24-24z"
                  />
                </svg>
              </span>
            </span>
            <button class="ql-bold" />
            <button class="ql-italic" />
            <button class="ql-underline" />
            <button class="ql-strike" />
            <EmojisButton
              class="ql-custom-button"
              @on-choose-emoji="insertContent"
            />
            <VariablesButton
              :variables-list="configs.options.variablesList"
              class="ql-custom-button"
              placeholder="T"
              @on-choose-variable="insertContent"
            />
            <AIButton
              type="button"
              class="input-button-ai ql-custom-button"
              icon="fa-light fa-microchip-ai ql-custom-button-icon"
              :ai-selector="parserValues.selector"
              :ai-params="{ target: '', type: 'callback', callback: replaceContent }"
              ai-content-type="1"
            />
          </span>
          <span class="ql-formats">
            <span
              class="ql-custom-button"
              @click="toggleColorPicker"
            >
              <span
                class="ql-custom-button-icon"
              >
                <svg viewBox="0 0 18 18">
                  <line
                    class="ql-color-label ql-stroke ql-transparent"
                    x1="3"
                    x2="15"
                    y1="15"
                    y2="15"
                  />
                  <polyline
                    class="ql-stroke"
                    points="5.5 11 9 3 12.5 11"
                  />
                  <line
                    class="ql-stroke"
                    x1="11.63"
                    x2="6.38"
                    y1="9"
                    y2="9"
                  />
                </svg>
              </span>
            </span>
            <span
              class="ql-custom-button"
              @click="toggleColorPickerBackground"
            >
              <span
                class="ql-custom-button-icon"
              >
                <svg viewBox="0 0 18 18">
                  <g class="ql-fill ql-color-label"> <polygon points="6 6.868 6 6 5 6 5 7 5.942 7 6 6.868"/> <rect
                    height="1"
                    width="1"
                    x="4"
                    y="4"
                  /> <polygon points="6.817 5 6 5 6 6 6.38 6 6.817 5"/> <rect
                    height="1"
                    width="1"
                    x="2"
                    y="6"
                  /> <rect
                    height="1"
                    width="1"
                    x="3"
                    y="5"
                  /> <rect
                    height="1"
                    width="1"
                    x="4"
                    y="7"
                  /> <polygon points="4 11.439 4 11 3 11 3 12 3.755 12 4 11.439"/> <rect
                    height="1"
                    width="1"
                    x="2"
                    y="12"
                  /> <rect
                    height="1"
                    width="1"
                    x="2"
                    y="9"
                  /> <rect
                    height="1"
                    width="1"
                    x="2"
                    y="15"
                  /> <polygon points="4.63 10 4 10 4 11 4.192 11 4.63 10"/> <rect
                    height="1"
                    width="1"
                    x="3"
                    y="8"
                  /> <path d="M10.832,4.2L11,4.582V4H10.708A1.948,1.948,0,0,1,10.832,4.2Z"/> <path
                    d="M7,4.582L7.168,4.2A1.929,1.929,0,0,1,7.292,4H7V4.582Z"/> <path
                    d="M8,13H7.683l-0.351.8a1.933,1.933,0,0,1-.124.2H8V13Z"/> <rect
                    height="1"
                    width="1"
                    x="12"
                    y="2"
                  /> <rect
                    height="1"
                    width="1"
                    x="11"
                    y="3"
                  /> <path d="M9,3H8V3.282A1.985,1.985,0,0,1,9,3Z"/> <rect
                    height="1"
                    width="1"
                    x="2"
                    y="3"
                  /> <rect
                    height="1"
                    width="1"
                    x="6"
                    y="2"
                  /> <rect
                    height="1"
                    width="1"
                    x="3"
                    y="2"
                  /> <rect
                    height="1"
                    width="1"
                    x="5"
                    y="3"
                  /> <rect
                    height="1"
                    width="1"
                    x="9"
                    y="2"
                  /> <rect
                    height="1"
                    width="1"
                    x="15"
                    y="14"
                  /> <polygon
                    points="13.447 10.174 13.469 10.225 13.472 10.232 13.808 11 14 11 14 10 13.37 10 13.447 10.174"/> <rect
                    height="1"
                    width="1"
                    x="13"
                    y="7"
                  /> <rect
                    height="1"
                    width="1"
                    x="15"
                    y="5"
                  /> <rect
                    height="1"
                    width="1"
                    x="14"
                    y="6"
                  /> <rect
                    height="1"
                    width="1"
                    x="15"
                    y="8"
                  /> <rect
                    height="1"
                    width="1"
                    x="14"
                    y="9"
                  /> <path d="M3.775,14H3v1H4V14.314A1.97,1.97,0,0,1,3.775,14Z"/> <rect
                    height="1"
                    width="1"
                    x="14"
                    y="3"
                  /> <polygon points="12 6.868 12 6 11.62 6 12 6.868"/> <rect
                    height="1"
                    width="1"
                    x="15"
                    y="2"
                  /> <rect
                    height="1"
                    width="1"
                    x="12"
                    y="5"
                  /> <rect
                    height="1"
                    width="1"
                    x="13"
                    y="4"
                  /> <polygon points="12.933 9 13 9 13 8 12.495 8 12.933 9"/> <rect
                    height="1"
                    width="1"
                    x="9"
                    y="14"
                  /> <rect
                    height="1"
                    width="1"
                    x="8"
                    y="15"
                  /> <path d="M6,14.926V15H7V14.316A1.993,1.993,0,0,1,6,14.926Z"/> <rect
                    height="1"
                    width="1"
                    x="5"
                    y="15"
                  /> <path d="M10.668,13.8L10.317,13H10v1h0.792A1.947,1.947,0,0,1,10.668,13.8Z"/> <rect
                    height="1"
                    width="1"
                    x="11"
                    y="15"
                  /> <path d="M14.332,12.2a1.99,1.99,0,0,1,.166.8H15V12H14.245Z"/> <rect
                    height="1"
                    width="1"
                    x="14"
                    y="15"
                  /> <rect
                    height="1"
                    width="1"
                    x="15"
                    y="11"
                  /> </g> <polyline
                  class="ql-stroke"
                  points="5.5 13 9 5 12.5 13"
                /> <line
                  class="ql-stroke"
                  x1="11.63"
                  x2="6.38"
                  y1="11"
                  y2="11"
                /> </svg>
              </span>
            </span>

            <button
              class="ql-list"
              value="ordered"
            />
            <button
              class="ql-list"
              value="bullet"
            />
            <select class="ql-align">
              <option
                value="left"
                selected
              />
              <option value="center"/>
              <option value="right"/>
              <option value="justify"/>
            </select>
          </span>
          <span class="ql-formats">
            <button class="ql-link" />
            <button class="ql-code-block" />
            <button class="ql-clean" />
          </span>
        </template>
      </Editor>
      <OverlayPanel
        ref="overlayPanelRef"
      >
        <CustomColorPicker
          data-test-id="color-picker"
          color="#000000"
          :color-format="colorFormat"
          title="Color Picker"
          @color-changed="changeColor"
        />
      </OverlayPanel>
      <OverlayPanel
        ref="overlayPanelBackgroundRef"
      >
        <CustomColorPicker
          color="#000000"
          :color-format="colorFormat"
          title="Color Picker"
          @color-changed="changeColorBackground"
        />
      </OverlayPanel>
      <OverlayPanel
        ref="overlayPanelLetterSpacingRef"
      >
        <BaseInputNumber
          v-model="letterSpacingValue"
          suffix="px"
          @update:model-value="changeLetterSpacing"
        />
      </OverlayPanel>
    </div>
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onBeforeMount,
  PropType,
  Ref,
  ref,
} from 'vue';
import { useI18n } from 'vue-i18n';
import Editor from 'primevue/editor';
import {
  ColorFormatEnum,
  FieldConfig,
  ParserFieldObject,
  Property,
} from '@/types';
import EmojisButton from '@/components/fields/partials/EmojisButton.vue';
import VariablesButton from '@/components/fields/partials/VariablesButton.vue';
import Quill from 'quill';
import CustomColorPicker from '@/components/fields/partials/CustomColorPicker.vue';
import OverlayPanel from 'primevue/overlaypanel';
import getLineHeightList from '@/configs/line-height';
import BaseInputNumber from '@/components/fields/partials/BaseInputNumber.vue';
import AIButton from '@/components/fields/AIButton.vue';

export default defineComponent({
  name: 'WysiwygEditor',
  components: {
    AIButton,
    BaseInputNumber,
    OverlayPanel,
    CustomColorPicker,
    VariablesButton,
    EmojisButton,
    Editor,
  },

  props: {
    configs: {
      type: Object as PropType<FieldConfig>,
      required: true,
    },

    parserValues: {
      type: Object as PropType<ParserFieldObject>,
      required: true,
    },
  },

  emits: {
    'on-change-properties': Object,
  },

  setup(props, context) {
    const { t } = useI18n();
    const properties: Ref<Array<Property>> = ref(props.parserValues.properties);
    const inputValue: Ref<string> = ref(properties.value[0].value ?? props.configs.options.defaultValue);
    const editorRef = ref();
    const editorKey = ref(0);
    const overlayPanelRef = ref();
    const overlayPanelBackgroundRef = ref();
    const overlayPanelLetterSpacingRef = ref();
    const colorFormat: Ref<string> = ref(ColorFormatEnum.HEX);
    const lineHeightList = ref([{}]);
    const lineHeightListValues = computed(() => lineHeightList.value.map((lineHeight: Record<string, string>) => lineHeight.value));
    const letterSpacingValue = ref();
    const selectedRange = ref();

    // Import quill icons
    const Icons = Quill.import('ui/icons');

    // This part is mandatory to use inline styles instead of Quill classes (problems could occur if we use classes in emails)
    // Add direction attributor
    const DirectionAttribute = Quill.import('attributors/attribute/direction');
    Quill.register(DirectionAttribute, true);

    // Add text-align attributor and create a custom icon for text-align: left;
    const AlignStyle = Quill.import('attributors/style/align');
    Icons.align.left = Icons.align['']; // set icon for 'left' option, otherwise it's replaced with 'undefined' text
    AlignStyle.whitelist = ['left', 'center', 'right', 'justify']; // whitelist available options including 'left' option
    Quill.register(AlignStyle, true);

    const BackgroundStyle = Quill.import('attributors/style/background');
    Quill.register(BackgroundStyle, true);

    const ColorStyle = Quill.import('attributors/style/color');
    Quill.register(ColorStyle, true);

    const DirectionStyle = Quill.import('attributors/style/direction');
    Quill.register(DirectionStyle, true);

    // Add font-family attributor and set accepted font-families
    const FontAttributor = Quill.import('attributors/style/font');
    FontAttributor.whitelist = [
      '',
      'arial, helvetica, sans-serif',
      'comic sans ms, cursive',
      'courier new, courier, monospace',
      'georgia, serif',
      'lucida sans unicode, lucida grande, sans-serif',
      'tahoma, geneva, sans-serif',
      'times new roman, times, serif',
      'trebuchet ms, helvetica, sans-serif',
      'verdana, geneva, sans-serif',
    ];
    Quill.register(FontAttributor, true);

    // Add font-size attributor and set accepted font-sizes
    const SizeStyle = Quill.import('attributors/style/size');
    SizeStyle.whitelist = [
      '',
      '8px', '9px', '10px', '11px', '12px',
      '13px', '14px', '15px', '16px', '18px',
      '20px', '22px', '24px', '26px', '28px',
      '30px', '32px', '34px', '36px', '38px',
      '40px',
    ];
    Quill.register(SizeStyle, true);

    // Import bold formater and force <b> tag instead of <strong>
    const Bold = Quill.import('formats/bold');
    Bold.tagName = 'B'; // Quill uses <strong> by default
    Quill.register(Bold, true);

    const parchment = Quill.import('parchment');
    const lineHeightConfig = computed(() => ({
      scope: parchment.Scope.INLINE,
      whitelist: lineHeightListValues.value,
    }));

    const lineHeightStyle = new parchment.Attributor.Style('lineheight', 'line-height', lineHeightConfig);
    Quill.register(lineHeightStyle, true);

    const letterSpacingStyle = new parchment.Attributor.Style('letterspacing', 'letter-spacing', {
      scope: parchment.Scope.INLINE,
      whitelist: null,
    });
    Quill.register(letterSpacingStyle, true);

    const handleValueChange = () => {
      inputValue.value = editorRef.value.quill.root.innerHTML;
      const updatedProperties: Array<Property> = properties.value.map((prop: Property) => {
        const updatedProperty = prop;
        updatedProperty.value = inputValue.value;
        return updatedProperty;
      });
      context.emit('on-change-properties', {
        selector: props.parserValues.selector,
        properties: updatedProperties,
      });
    };

    const insertContent = (value: string | number | undefined) => {
      editorRef.value.quill.insertText(editorRef.value.quill.selection.savedRange.index, value);
      handleValueChange();
    };

    const replaceContent = (value: string) => {
      editorRef.value.quill.clipboard.dangerouslyPasteHTML(value);
      handleValueChange();
    };

    const toggleColorPicker = (event: MouseEvent) => {
      overlayPanelRef.value.toggle(event);
    };

    const toggleColorPickerBackground = (event: MouseEvent) => {
      overlayPanelBackgroundRef.value.toggle(event);
    };

    const changeColor = (color: string) => {
      editorRef.value.quill.format('color', color);
      handleValueChange();
    };

    const changeColorBackground = (color: string) => {
      editorRef.value.quill.format('background-color', color);
      handleValueChange();
    };

    const toggleLetterSpacing = (event: MouseEvent) => {
      letterSpacingValue.value = null;

      // Store selected range (because when opening the panel, the range disappears)
      const range = editorRef.value.quill.getSelection();
      selectedRange.value = range;
      overlayPanelLetterSpacingRef.value.toggle(event);
      editorRef.value.quill.setSelection(selectedRange.value);
    };

    const changeLetterSpacing = (value: any) => {
      if (selectedRange.value) {
        editorRef.value.quill.formatText(selectedRange.value.index, selectedRange.value.length, {
          'letter-spacing': `${value}px`,
        });
      }

      handleValueChange();
    };

    onBeforeMount(async () => {
      try {
        lineHeightList.value = await getLineHeightList();
        lineHeightList.value.unshift({
          label: t('templateBuilder.fields.defaultLineHeight'),
          value: '',
        });
      } catch (error) {
        lineHeightList.value = [{}];
      }

      editorKey.value += 1;
    });

    return {
      t,
      inputValue,
      editorRef,
      editorKey,
      overlayPanelRef,
      overlayPanelBackgroundRef,
      overlayPanelLetterSpacingRef,
      colorFormat,
      lineHeightList,
      letterSpacingValue,
      changeColor,
      toggleColorPicker,
      changeColorBackground,
      toggleColorPickerBackground,
      handleValueChange,
      insertContent,
      replaceContent,
      toggleLetterSpacing,
      changeLetterSpacing,
    };
  },
});
</script>

<style scoped lang="scss">
:deep() .p-togglebutton.p-button.emoji-button,
:deep() .variable-dropdown {
  font-size: 1rem;
  background: none;
  border: none;
  cursor: pointer;
  display: inline-block !important;
  float: left;
  height: 24px;
  padding: 1px 0 !important;
  width: 28px;
  filter: grayscale(100%);
  .p-dropdown-trigger {
    display: none;
  }
  .p-dropdown-label {
    display: inline;
  }
}
</style>

<style lang="scss">
.ql-custom-button {
  width: 28px!important;
  color: #444;
  display: inline-block!important;
  float: left;
  font-size: 14px;
  font-weight: 500;
  height: 24px!important;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
}

.ql-custom-button .ql-custom-button-icon {
  padding: 2px 4px!important;
  display: inline-block;
  width: 100%!important;
  height: 100%!important;
}
.ql-custom-button .ql-custom-button-icon.fa-microchip-ai {
  vertical-align: top;
  font-size:18px;
}

.p-editor-container .ql-snow.ql-toolbar button.ql-active,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected  {
  color: $brand-color-primary;
}

.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke {
  stroke: $brand-color-primary;
}

.ql-snow .ql-formats .ql-picker.ql-header {
  width: 80px;
}

.ql-snow .ql-formats .ql-picker.ql-font {
  width: 165px;
}

.ql-snow .ql-formats .ql-picker.ql-size {
  width: 75px;
}

.ql-snow .ql-formats .ql-picker.ql-lineheight {
  width: 25px;
  padding: 0px 5px;

  .ql-picker-label {
    padding-left: 0 !important;

    &:after {
      color: #6c757d !important;
      content: '\f871';
      font-family: 'Font Awesome 6 Pro';
      position: absolute;
      top: 4px !important;
      right: 0;
    }

    & > svg {
      display: none;
    }
  }

  .ql-picker-item:before {
    content: attr(data-label);
  }
}

.ql-snow .ql-formats .ql-custom-button.ql-letterspacing {
  width: 20px;
  padding: 3px 0px;
  position: relative;

  & svg {
    fill: #6c757d;
  }
}
</style>
