
import {
  defineComponent,
  PropType, ref, SetupContext, watch,
} from 'vue';
import { useI18n } from 'vue-i18n';
import InputNumber from '@/components/automated-scenarios/fields/InputNumber.vue';
import { Location } from '@/components/automated-scenarios/metadata/filtres/FiltreCustomerMetadata';
import { asFloat, asInt } from '@/helpers';

export default defineComponent({
  name: 'Location',

  components: {
    InputNumber,
  },

  props: {
    modelValue: {
      type: Object as PropType<Location[]>,
      required: true,
    },

  },

  emits: ['update:modelValue'],

  setup(props: { modelValue: Location[] }, { emit }: SetupContext) {
    const { t } = useI18n();
    const radius = ref(asInt(props.modelValue[0].radius ?? 50));
    const latitude = ref(asFloat(props.modelValue[0].lat));
    const longitude = ref(asFloat(props.modelValue[0].long));
    const address = ref(props.modelValue[0].address);
    const circleOptions = {
      strokeColor: '#629d03',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#629d03',
      fillOpacity: 0.35,
    };

    const setPlace = (place: { geometry: { location: { lat: () => number; lng: () => number } }; formatted_address: string }) => {
      latitude.value = place.geometry.location.lat();
      longitude.value = place.geometry.location.lng();
      address.value = place.formatted_address;
    };

    watch([radius, latitude, longitude], () => {
      emit('update:modelValue', {
        radius: radius.value,
        lat: latitude.value,
        long: longitude.value,
        address: address.value,
      });
    });

    return {
      t,
      circleOptions,
      latitude,
      longitude,
      radius,
      address,
      setPlace,
    };
  },
});
